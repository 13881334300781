import { MainInFocusVisibilitySettings } from "../../interfaces/contractInFocus.interfaces";
import { RightVisibilityMenuT } from "../../interfaces/personalContractSettings.interfaces";
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";


export const logsRightVisibilityMenu = ({ visibilitySettings }: GetRightVisibilityMenu): RightVisibilityMenuT => ({
  "H&S Incidents": {
    table: {
      value: visibilitySettings.show_health_and_safety_incident_table,
      updateKey: 'show_health_and_safety_incident_table'
    },
  },
  "Technical Event": {
    table: {
      value: visibilitySettings.show_technical_event_table,
      updateKey: 'show_technical_event_table'
    },
  },
  "Sub-Con Events": {
    table: {
      value: visibilitySettings.show_subcontractor_event_table,
      updateKey: 'show_subcontractor_event_table'
    },
  },
  "Plant Condition": {
    table: {
      value: visibilitySettings.show_plant_condition_table,
      updateKey: 'show_plant_condition_table'
    },
  },
  "Personnel Matter": {
    table: {
      value: visibilitySettings.show_personnel_matter_table,
      updateKey: 'show_personnel_matter_table'
    },
  },
  "Leave": {
    table: {
      value: visibilitySettings.show_leave_table,
      updateKey: 'show_leave_table'
    },
  },
  "Innovations": {
    table: {
      value: visibilitySettings.show_innovation_table,
      updateKey: 'show_innovation_table'
    },
  }
});