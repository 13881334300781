import { Observable, of } from "rxjs";
import { APIR } from "services/API/API";
import { switchMap } from 'rxjs/operators';
import { catchErrorOperator } from 'helpers/Pipelines/errorOperators';
import { unWrapListDataAndMeta } from "services/API/API.helper";
import { reactiveCategoryOptionsAPI } from "services/API/common/optionAPIs";
import { contractPeriodFrozenForListApi } from "./contractPeriodAPIs";
import path from "path";

import { CONTRACT_ROUTE, PORTFOLIO_ROUTE } from "services/API/common/globalAPIs";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

export interface ContractId {
  contractId?: number | string | undefined,
  contractRef?: string,
}

export interface ContractIdOrPortfolioId {
  portfolioId?: number | string | undefined,
  contractId?: number | string | undefined,
  contractRef?: string,
}

export interface ContractOrPortfolio {
  portfolio?: HydratedPortfolio,
  contract?: SiteContract
}

export const ACCESS_ROUTE = "/contract-accessors/"
export const contractPeopleWithAccessRoute = 'access';

export interface APISnapshotProps extends ContractIdOrPortfolioId {
  snapshotId: string;
}


export interface GetContractOrPortfolioBasedRouteProps extends ContractIdOrPortfolioId {
  endpoint: string;
  querystring?: string;
}

export interface GetContractOrPortfolioChildProps extends GetContractOrPortfolioBasedRouteProps {
  id: string | number;

}

export const getContractOrPortfolioOrBaseRoute = (props: GetContractOrPortfolioBasedRouteProps) => {
  let baseRoute = '';
  if (props.portfolioId) {
    baseRoute = `${PORTFOLIO_ROUTE}${props.portfolioId}`;
  } else if (props.contractId) {
    baseRoute = `${CONTRACT_ROUTE}${props.contractId}`;
  }
  return `${baseRoute}/${props.endpoint}/?format=json${props.querystring ? '&' + props.querystring : ''}`
}

export const peopleWithAccessListBaseRoute = (contractRef: string) => `${CONTRACT_ROUTE}${contractRef}/${contractPeopleWithAccessRoute}/?format=json`;

export const contractPersonAccessRoute = (contractRef: string, accessId: string | number) => `${CONTRACT_ROUTE}${contractRef}/${contractPeopleWithAccessRoute}/${accessId}/`;

export const getContractOrPortfoliosListAPI = (props: GetContractOrPortfolioBasedRouteProps) => {
  const route = getContractOrPortfolioOrBaseRoute(props);
  return APIR.get(route).pipe(
    unWrapListDataAndMeta(),
    catchErrorOperator
  );
}

export const contractsEndpoint = (endpoint: string, contract_ref: any) => `contracts/${contract_ref}/${endpoint}/`;

export const portfoliosEndpoint = (endpoint: string, portfolioId: any) => `portfolios/${portfolioId}/${endpoint}/`;

export const contractContractPeriodsRoute = 'contract-periods';
export const portfolioPortfolioPeriodsRoute = 'portfolio-periods';

export const contractPeriodsEndpoint = (contract_ref: any) => `${CONTRACT_ROUTE}${contract_ref}/${contractContractPeriodsRoute}/?format=json`

export const portfolioPeriodsEndpoint = (portfolioId: number) => `${PORTFOLIO_ROUTE}${portfolioId}/${portfolioPortfolioPeriodsRoute}/?format=json`

export const contractPeriodsAPI = (contract: any) => APIR.get(contractPeriodsEndpoint(contract)).pipe(catchErrorOperator);

export const portfolioPeriodsAPI = (portfolioId: number) => APIR.get(portfolioPeriodsEndpoint(portfolioId)).pipe(catchErrorOperator);

export const peopleWithAccessAPI = (contract: any) => APIR.get(`${CONTRACT_ROUTE}${contract}/${contractPeopleWithAccessRoute}/?format=json`).pipe(catchErrorOperator);

export const reportDistributionRoute = 'monthly-maintenance-report-distribution';

export const maintenanceReportDistributionBaseRoute = (ids: ContractIdOrPortfolioId) => {
  let route = 'never-maintenance-report-distribution';
  if (ids.portfolioId) {
    route = `${PORTFOLIO_ROUTE}${ids.portfolioId}/${reportDistributionRoute}/`
  } else if (ids.contractRef) {
    route = `${CONTRACT_ROUTE}${ids.contractRef}/${reportDistributionRoute}/`
  }
  return route;
}

export interface maintenanceDistributionObjProps extends ContractIdOrPortfolioId {
  accessId: number | string;
}

export const maintenanceReportDistributionRoute = (props: maintenanceDistributionObjProps) => path.join(maintenanceReportDistributionBaseRoute({ contractRef: props.contractRef, portfolioId: props.portfolioId }), `${props.accessId}/`);

export const fetchMaintenanceReportDistributionListAPI = (props: ContractIdOrPortfolioId) => APIR.get(maintenanceReportDistributionBaseRoute(props)).pipe(catchErrorOperator);

export const contractMaintenanceReportConfigurationRoute = 'monthly-maintenance-report-configuration';

export const maintenanceReportConfigurationAPI = (props: ContractOrPortfolio) => {
  let baseRoute = '';
  if (props.portfolio) {
    baseRoute = `${PORTFOLIO_ROUTE}${props.portfolio.id}`
  }
  else if (props.contract) {
    baseRoute = `${CONTRACT_ROUTE}${props.contract.contract_ref}`
  }
  return APIR.get(`${baseRoute}/monthly-maintenance-report-configuration/?format=json`).pipe(catchErrorOperator)
};

export const frozenForListRoute = 'frozen-for-list';

export const monthlyMaintenanceReportRoute = 'monthly-maintenance-reports';

export const documentsRoute = 'documents';

export const snapshotDocumentsRoute = 'snapshot-documents';

export const frozenForListApi = (props: ContractIdOrPortfolioId) => getContractOrPortfoliosListAPI({ contractId: props.contractId, portfolioId: props.portfolioId, endpoint: frozenForListRoute });

export const monthlyMaintenanceReportsApi = (props: ContractIdOrPortfolioId) => getContractOrPortfoliosListAPI({ contractId: props.contractId, portfolioId: props.portfolioId, endpoint: monthlyMaintenanceReportRoute });

export const contractOrPortfolioDocumentsApi = (props: ContractIdOrPortfolioId) => getContractOrPortfoliosListAPI({ contractId: props.contractId, portfolioId: props.portfolioId, endpoint: documentsRoute });

export const contractSnapshotDocumentsApi = (props: ContractIdOrPortfolioId) => getContractOrPortfoliosListAPI({ contractId: props.contractId, portfolioId: props.portfolioId, endpoint: snapshotDocumentsRoute });

export const getSnapshotMMRAppendicesRoute = (props: APISnapshotProps) => `${props.portfolioId ? 'portfolio-' : ''}snapshots/${props.snapshotId}/mmr-appendices/`;

export const getSnapshotPublicationsRoute = (props: APISnapshotProps) => {
  let baseRoute = '/get-snapshot-publications/never/';
  if (props.portfolioId) {
    baseRoute = 'portfolio-snapshots'
  }
  if (props.contractId) {
    baseRoute = 'snapshots';
  }
  return `${baseRoute}/${props.snapshotId}/publications/`;
}

export const contractOpenPPMRoute = 'open-ppm-tasks';

export const contractOpenReactiveRoute = 'open-reactive-tasks';

export const contractInsuranceAuditsRoute = 'insurance-audits';

export const contractMeterReadingsRoute = 'meter-readings';

export const contractMeterReadingEventsRoute = 'meter-reading-events';

export const contractTechnicalEventLogsRoute = 'technical-event-logs';

export const contractHealthAndSafetyIncidentRoute = 'health-and-safety-incident-logs';

export const contractSubcontractorEvenLogsRoute = 'subcontractor-event-logs';

export const contractPlantConditionLogsRoute = 'plant-condition-logs';

export const contractPersonnelMatterLogsRoute = 'personnel-matter-logs';

export const contractInnovationLogsRoute = 'innovation-logs';

export const contractLeaveLogsRoute = 'leave-logs';

export const contractConsumableSpendRoute = 'consumable-spend';

export const contractManagedSpendRoute = 'managed-spend';

export const contractComprehensiveMonthlyAggregateSpendRoute = 'comprehensive-monthly-aggregate-spend';

export const contractVisitsRoute = 'visits';

export const contractMonthlyPPMTaskCountsRoute = 'monthly-ppm-task-counts';

export const contractMonthlyPPMSLATaskCountsRoute = 'monthly-ppm-sla-task-counts';

export const contractMonthlyRMSLATaskCountsRoute = 'monthly-rm-sla-task-counts';

export const contractMonthlyRmTaskCountRoute = 'monthly-rm-task-counts';

export const contractMonthlyRmCategoryCountRoute = "monthly-rm-category-counts";

export const contractMonthlyRMCategoryHoursCountRoute = "monthly-rm-category-hour-counts";

export const contractConsumableMonthlyAggregateSpendRoute = 'consumable-monthly-aggregate-spend';

export const contractManagedMonthlyAggregateSpendRoute = 'managed-monthly-aggregate-spend';


//export const contractCommittedComprehensiveSpendPOsRoute = 'committed-comprehensive-spend-pos';

export const contractComprehensiveSpendPOsRoute = 'comprehensive-spend-pos';

export const contractComprehensiveSpendRoute = 'comprehensive-spend';

export const contractTenantHoursRoute = 'tenant-hours';

export const contractTenantHoursGivenBackRoute = 'tenant-hours-given-back';

export const contractFinancialsRoute = 'financials';

export const snapshotContractApis = [
  contractOpenPPMRoute,
  contractOpenReactiveRoute,
  contractInsuranceAuditsRoute,
  contractMeterReadingsRoute,
  contractMeterReadingEventsRoute,
  contractTechnicalEventLogsRoute,
  contractHealthAndSafetyIncidentRoute,
  contractSubcontractorEvenLogsRoute,
  contractPlantConditionLogsRoute,
  contractPersonnelMatterLogsRoute,
  contractComprehensiveSpendRoute,
  contractConsumableSpendRoute,
  contractManagedSpendRoute,
  contractInnovationLogsRoute,
  contractLeaveLogsRoute,
  contractComprehensiveMonthlyAggregateSpendRoute,
  //contractCommittedComprehensiveSpendPOsRoute,
  contractComprehensiveSpendPOsRoute,
  contractVisitsRoute,
  contractMonthlyPPMTaskCountsRoute,
  contractMonthlyPPMSLATaskCountsRoute,
  contractMonthlyRMSLATaskCountsRoute,
  contractMonthlyRmTaskCountRoute,
  contractMonthlyRmCategoryCountRoute,
  contractMonthlyRMCategoryHoursCountRoute,
  contractConsumableMonthlyAggregateSpendRoute,
  contractManagedMonthlyAggregateSpendRoute,
  contractTenantHoursRoute,
  contractTenantHoursGivenBackRoute,
  contractFinancialsRoute,
];

export const withAdminAccess$ = (observable: Observable<any>, adminAccess: boolean) => adminAccess ? observable : of(undefined);

export const withAdminAccess = (payload: () => any, adminAccess: boolean) => adminAccess ? payload() : undefined;

/* 
* Contract Requests used in the Admin section of the system
*/

export const contractsAdminApiRequests = (contractRef: string, adminAccess: boolean = true) => ({
  contract: APIR.get(`${CONTRACT_ROUTE}${contractRef}/?format=json`).pipe(catchErrorOperator),
  ...contractsAdminSublistApiRequests(contractRef, adminAccess)
});

/* 
* Contract secondary endpoints - used in the admin and 
*/

export const contractsAdminSublistApiRequests = (contractRef: string, adminAccess: boolean) => ({
  peopleWithAccess: withAdminAccess$(peopleWithAccessAPI(contractRef), adminAccess), // TODO: remove for contract in focus <<<<<<<<<<<<
  distribution: fetchMaintenanceReportDistributionListAPI({ contractRef: contractRef }),
  contractPeriod: contractPeriodsAPI(contractRef),
});