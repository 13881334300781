export const ORGANISATION_ROUTE = '/organisations/';

export const PEOPLE_ROUTE = '/people/';

export const SITE_ROUTE = '/sites/';

export const PORTFOLIO_ROUTE = '/portfolios/';
export const CONTRACT_ROUTE = "/contracts/";

export const CONTRACT_PERIOD_ROUTE = '/contract-periods/';

export const PORTFOLIO_PERIOD_ROUTE = '/portfolio-periods/';

export const HAS_TASKS_ROUTE = 'h-a-s-tasks/';
export const ADMIN_HAS_TASKS_ROUTE = 'admin-h-a-s-tasks/';
export const HAS_ACCIDENTS_ROUTE = 'h-a-s-accidents/';
export const HAS_RIDDOR_ROUTE = 'h-a-s-riddor/';
export const HAS_NEAR_MISSES_ROUTE = 'h-a-s-near-misses/';

export const HAS_EVENTS_ROUTE = 'health-and-safety-incident-logs/';

