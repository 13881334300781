import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

// Own
import { addAdminPanel } from '../../AdminPanel/Actions/adminPanel.actions';
import { adminAccessSelector, userIdSelector, healthAndSafetyAdminAccessSelector } from 'components/Profile/Selectors/Profile.selector';
import { HeaderAdminButton } from './Components/HeaderAdminButton';
import { refreshPersonMeta } from 'components/AdminPanel/People/Actions/People.actions';
import HeaderAvatar from '../Common/HeaderAvatar/HeaderAvatar';
import HeaderLogo from '../Common/HeaderLogo/HeaderLogo';
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model.js";


// Styles
import {
  HeaderWrapper,
  useHeaderStyles
} from './BaseHeaderHorizontalStyles';

interface BaseHeaderHorizontalProps {
  position?: "fixed" | "absolute" | "relative" | "static" | "sticky" | undefined;
  isDark: boolean;
  className?: string;
  appBarclassName?: string;
  children: React.ReactNode | React.ReactNodeArray;
}

const BaseHeaderHorizontal = ({
  isDark = true,
  position = 'fixed',
  className,
  appBarclassName,
  children
}: BaseHeaderHorizontalProps) => {

  const classes = useHeaderStyles();

  const userId = useSelector(userIdSelector);
  const adminAccess = useSelector(adminAccessSelector);
  const HASAdminAccess = useSelector(healthAndSafetyAdminAccessSelector);

  const dispatch = useDispatch();

  const openAdminPanel = (): void => {
    let adminPanelConfig = {};
    if (HASAdminAccess && !adminAccess) {
      adminPanelConfig = {
        panelType: adminPanelType.h_a_s,
        onlyShowTabs: [adminPanelType.h_a_s]
      }
    }
    dispatch(refreshPersonMeta());
    dispatch(addAdminPanel(adminPanelConfig));
  };

  return (
    <HeaderWrapper id="BaseHeaderHorizontal" className={className || ''} >
      <AppBar
        position={position}
        className={appBarclassName || classes.darkAppBar}
      >
        <Toolbar>
          <HeaderLogo isDark={isDark} />
          {userId &&
            <HeaderAdminButton
              visible={adminAccess || HASAdminAccess}
              isDark={isDark}
              onOpenAdminPanel={openAdminPanel}
            />
          }
          {children}
          <HeaderAvatar visible={!!userId} />
        </Toolbar>
      </AppBar>
    </HeaderWrapper>
  );
};

export default BaseHeaderHorizontal;
