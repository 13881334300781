import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import moment from "moment";
import DataGrid, { Column, Editing, Paging, RequiredRule, MasterDetail, RemoteOperations } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Paper } from "@material-ui/core";
import { isEqual } from "lodash";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";

// Own
import { dateTimeUDF } from "components/Common/Utils/Dates.js";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import API from 'services/API/API.js';
import { downloadCSV } from "store/Common/Helpers/commonHelpers";
import { InlineWrapper } from 'components/ContractInFocus/Styles/CommonStyles';
import GridHeader from "components/Common/Components/GridHeader/GridHeader.js";
import { getSubTitle } from "components/ContractInFocus/Components/ContractPrintTitle/ContractPrintTitle";
import { ColumnProps, DataGridMeta } from '../interfaces/dataGridColumn.interface';
import { getColumnProps, editableTextAreaOnPreparing, getGridProps } from '../../../helpers/DataGrid/DataGridColumn.helper';
import { columnPropsPlaceHolder } from 'components/ContractInFocus/Models/ColumnProps';
import { gridMetaInitialState } from 'components/ContractInFocus/Models/Grid';
import { saveDateFormat } from "components/Common/Utils/Dates";
import { PrintChartAndTableLabels } from "components/Common/constants.js";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { getRemoteOpParamsAndContractScopeParams } from 'helpers/Pipelines/contractScopeOperator';
import { IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import HASAccidentDetail from "components/ContractInFocus/HAS/HASAccidentDetail";
import SaveOnRowCollapseWrapper from "components/Common/Components/GridPatterns/SaveOnRowCollapseWrapper";
import { disableDxKeyProcessor } from "components/Common/Components/FormFieldComments/FormFieldComments";
import { healthAndSafetyAdminAccessSelector } from 'components/Profile/Selectors/Profile.selector';

import "components/ContractInFocus/HAS/Styles/forms.scss";

const getContractEndpoint = (contractId: string | number) => `contracts/${contractId}/h-a-s-accidents/`;
const getContractAccidentCSVsEndpoint = (contractId: string | number) => `${getContractEndpoint(contractId)}csv/`;
const retrieveContractAccidentReportingEndpoint = (contractId: string | number) => `${getContractEndpoint(contractId)}get_submission_form_url/`;
const getPortfolioEndpoint = (portfolioId: string | number) => `portfolios/${portfolioId}/h-a-s-accidents/`;
const getPortfolioAccidentCSVEndpoint = (portfolioId: string | number) => `${getPortfolioEndpoint(portfolioId)}csv/`;
const getBaseEndpoint = () => `h-a-s-accidents/`;
const getBaseCSVEndpoint = () => `${getBaseEndpoint()}csv/`;
const retrieveBaseAccidentReportingEndpoint = () => `${getBaseEndpoint()}get_submission_form_url/`;

export const useAccidentEndpoint = ({ contractRef, portfolioId }: { contractRef?: string, portfolioId?: number | string }) => {
    // this 'hook' is pulled outside the component so it can be used in the HAS Centre as well as here.
    // using never on next lines because we can't use useCallback 'conditionally'
    // useCallback is essential to prevent infinite rendering loop
    const getPortfolioRoute = useCallback(() => getPortfolioEndpoint(portfolioId || "never"), [portfolioId]);
    const getContractRoute = useCallback(() => getContractEndpoint(contractRef || "never"), [contractRef]);
    if (portfolioId) {
        return getPortfolioRoute
    } else if (contractRef) {
        return getContractRoute
    }
    return getBaseEndpoint;
    // NB:TODO this is all a bit contrived and is required because most of the 'services' below expect a function for the endpoint construction, rather than a simple string
    // the thinking being that a callback is more flexible.  But in reality, we're probably better off simply rerendering the component if something
    // changes that would cause the endpoint to change - and passing in a simple string to the service endpoints
}
interface HASAccidentProps {
    contract?: SiteContract;//ContractInterface;
    portfolio?: HydratedPortfolio;
    showContractsColumn?: boolean;
    contractField?: string;
    remoteOperations?: boolean;
    pageSize?: number;
    onRowCollapsing?: (e: any) => void;
    onRowExpanding?: (e: any) => void;
    masterViewSharedSpace?: React.MutableRefObject<any>;
    setFullScreenForm: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    refreshAt?: number;
}



const HASAccidents: React.FC<HASAccidentProps> = ({
    contract,
    portfolio,
    showContractsColumn,
    contractField,
    pageSize,
    remoteOperations,
    onRowCollapsing,
    onRowExpanding,
    masterViewSharedSpace,
    setFullScreenForm,
    refreshAt
}) => {

    const getAccidentEndpoint = useAccidentEndpoint({ contractRef: contract?.contract_ref, portfolioId: portfolio?.id });
    const accidentEndpoint = getAccidentEndpoint();

    const getAccidentCSVEndpoint = useCallback(() => {
        if (contract) {
            return getContractAccidentCSVsEndpoint(contract.contract_ref)
        } else if (portfolio) {
            return getPortfolioAccidentCSVEndpoint(portfolio.id);
        }
        return getBaseCSVEndpoint();
    }, [contract, portfolio]);

    const accidentCSVEndpoint = getAccidentCSVEndpoint();

    const getIncidentReportingEndpoint = useCallback(() => {
        let incidentReportingEndpoint = retrieveBaseAccidentReportingEndpoint()
        if (contract) {
            incidentReportingEndpoint = retrieveContractAccidentReportingEndpoint(contract.contract_ref);
            incidentReportingEndpoint = `${incidentReportingEndpoint}?contract_ref=${contract.contract_ref}`;
        } else if (portfolio) {
            incidentReportingEndpoint = `${incidentReportingEndpoint}?portfolio_id=${portfolio.id}`;
        }
        return incidentReportingEndpoint;
    }, [contract, portfolio]);

    const incidentReportingEndpoint = getIncidentReportingEndpoint();

    const getCSVFileNameCallBack = useCallback(() => {
        let csvFileNameBase = "Accidents Exported For";
        if (contract) {
            csvFileNameBase = `${csvFileNameBase} Contract ${contract.contract_ref}`;
        } else if (portfolio) {
            csvFileNameBase = `${csvFileNameBase} Portfolio ${portfolio.name}`;
        } else {
            csvFileNameBase = `${csvFileNameBase} all contracts`;
        }
        const getCsvFileName = () => `${csvFileNameBase} as of ${moment().format(dateTimeUDF)}`;
        return getCsvFileName
    }, [contract, portfolio]);

    const getCSVFileName = getCSVFileNameCallBack();

    const hasHealthAndSafetyAdminAccess = useSelector(healthAndSafetyAdminAccessSelector);


    const thisShowContractsColumn = portfolio || showContractsColumn;
    const [dataSource, setDataSource] = useState<DataSource>();
    const [metadata, setMetadata] = useState<DataGridMeta>(gridMetaInitialState);
    const [contentReady, setContentReady] = useState(false);
    const disableDxNavKeys = useRef(false);
    const storedLoadOptions = useRef<any>();
    const storedData = useRef<any>();
    const thisSharedSpace = useRef<any>({});
    const tableLevelSharedSpace = masterViewSharedSpace || thisSharedSpace;
    tableLevelSharedSpace.current.dataSource = dataSource;

    useEffect(() => {
        if (refreshAt) {
            storedLoadOptions.current = { ...storedLoadOptions.current, refreshAt: refreshAt } //this means that the comparison in the datasource load method
            // will allow a reload, the actual refreshAt 'option', does nothing
        }
    }, [refreshAt, tableLevelSharedSpace]);

    useEffect(() => {
        tableLevelSharedSpace.current.allowReload = false;
        const thisKey = portfolio?.id || contract?.contract_ref;
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => {
                // NB the inclusion of the contract column in portfolio mode made this dxDatagrid get stuck in a loading loop
                // when a filter was applied to the header (regardless of whether we were using remoteOperations and auto filter application or onclick)
                // it only happened in a portfolio context where the contract represented a choice field under the hood
                // (it didn't happen in contract mode even if the contract column was included - there the column is just a simple field) 
                //  and it only made the grid keep triggering it's own load function - it wasn't refreshing the whole component and it was not
                // re-calling this useEffect.
                // It seemed to be a bug, and it's been dealt with by comparing the load options to a cached version of the load options before
                // firing a reload.
                if (!storedData.current || tableLevelSharedSpace.current.allowReload || !isEqual(storedLoadOptions.current, loadOptions)) {
                    tableLevelSharedSpace.current.allowReload = false;
                    storedLoadOptions.current = loadOptions;
                    const params = getRemoteOpParamsAndContractScopeParams({ loadOptions })
                    const thisData = CommonAPIService.getAll<IHASAccident>(
                        getAccidentEndpoint,
                        setMetadata,
                        thisKey,
                        params
                    )
                    storedData.current = thisData;
                    return thisData;
                } else {
                    return storedData.current;
                }
            },
            insert: values => {
                storedData.current = undefined;
                return CommonAPIService.create<any>({ getEndpoint: getAccidentEndpoint, portfolio: portfolio, contract: contract || values.contract, values })
            },
            // @ts-ignore
            remove: key => {
                storedData.current = undefined;
                return CommonAPIService.del<any>(getAccidentEndpoint, thisKey, key)
            },
            update: (id, values) => {
                storedData.current = undefined;
                return CommonAPIService.update<any>(getAccidentEndpoint, thisKey, id, values)
            }
        });

        setDataSource(
            new DataSource({
                store: custom
            })
        );
    }, [portfolio, getAccidentEndpoint, contract, tableLevelSharedSpace, refreshAt]);

    const handleDateColumns = (data: any): void => {
        ["date", "completion_date"].map(
            (x) => {
                if (data[x]) {
                    data[x] = saveDateFormat(data[x])
                }
            }
        )
    };

    const handleRowInserting = (values: any): void => {
        handleDateColumns(values.data);
    };

    const handleRowUpdating = (values: any): void => {
        handleDateColumns(values.newData);
    }

    const getColumnPropsExt = useCallback((field: string): ColumnProps | undefined => {
        return metadata.loaded ? getColumnProps(field, metadata.activeMeta) : columnPropsPlaceHolder;
    }, [metadata])

    const handleRowUpdated = () => {
        if (metadata) {
            setMetadata({ ...metadata, activeMeta: metadata.POSTMeta });
        }
    }

    const getCSV = () => {
        API.get(accidentCSVEndpoint, { headers: { "Accept": "text/csv" } }).then((response) => {
            downloadCSV(response.data, getCSVFileName());
        });
    }

    const openAccidentReporting = () => {
        API.get(incidentReportingEndpoint).then((response) => {
            window.open(response.data.url, '_blank');
        });
    }

    const renderColIfMetaDataDefined = useCallback((fieldName: string, requiredOveride: boolean, colPropsOverride = {}) => {
        const metaColProps = getColumnPropsExt(fieldName);
        if (metaColProps) {
            const colProps = { ...metaColProps, ...colPropsOverride }
            return (<Column {...colProps}>
                {requiredOveride && <RequiredRule />}
            </Column>
            )
        }
    }, [getColumnPropsExt])

    const ExtraButtons = () => <div className="extra-actions">
        <Button className="report-accident-button" onClick={() => openAccidentReporting()}>
            REPORT AN ACCIDENT
        </Button>
        <Button className="get-accidents-csv" onClick={() => getCSV()}>
            DOWNLOAD AS CSV
        </Button>
    </div>

    return (
        <InlineWrapper>
            <Paper elevation={3}>
                <GridHeader
                    title="Accidents"
                    className={`${PrintChartAndTableLabels ? '' : 'no-print'} withCSVExport`}
                    subTitle={getSubTitle(metadata)}
                    ExtraButtons={ExtraButtons}
                />

                {dataSource && (
                    <DataGrid
                        repaintChangesOnly //important if you wish to push changes to a row without updating the detail view
                        // className no-print causes issues with printing detail forms
                        dataSource={dataSource}
                        onKeyDown={(e) => {
                            if (disableDxNavKeys.current) {
                                disableDxKeyProcessor(e);
                            }

                        }}
                        onRowInserting={handleRowInserting}
                        onRowUpdating={handleRowUpdating}
                        onRowUpdated={handleRowUpdated}
                        onRowCollapsing={onRowCollapsing}
                        onRowExpanding={onRowExpanding}
                        {...getGridProps(metadata.activeMeta)}
                        onEditorPreparing={editableTextAreaOnPreparing(metadata.activeMeta)}
                        onContentReady={() => setContentReady(true)}
                        showColumnHeaders
                        filterRow={{
                            visible: true,
                            applyFilter: 'auto',
                            //showOperationChooser: false,
                        }}
                    >
                        {pageSize && <Paging defaultPageSize={pageSize} />}
                        {remoteOperations && <RemoteOperations
                            groupPaging={false}
                            grouping={true}
                            filtering={true}
                            paging={!!pageSize}
                        />}
                        {/* <Editing
                            mode="cell"
                            allowUpdating={metadata.privileges.PUT}
                            allowDeleting={metadata.privileges.DELETE}
                            allowAdding={metadata.privileges.POST}
                        /> */}

                        {/* <Column {...getColumnPropsExt('asset_description')}>
              <RequiredRule />
            </Column> */}
                        {thisShowContractsColumn && renderColIfMetaDataDefined('contract', true, { caption: 'Contract' })}
                        {renderColIfMetaDataDefined('occurred_at', true, { format: 'dd/MM/yyyy HH:mm' })}
                        {renderColIfMetaDataDefined('ap_name', true)}
                        {renderColIfMetaDataDefined('location', false)}
                        {renderColIfMetaDataDefined('status', true)}
                        {metadata.POSTMeta.confidential && hasHealthAndSafetyAdminAccess && renderColIfMetaDataDefined('confidential', true)}
                        {renderColIfMetaDataDefined('full_investigation_mode', false)}
                        {renderColIfMetaDataDefined('fields_require_attention', false)}

                        <MasterDetail
                            enabled={true}
                            component={
                                (e) => {
                                    return (<HASAccidentDetail
                                        data={e.data.data}
                                        rowKey={e.data.key}
                                        masterViewSharedSpace={tableLevelSharedSpace}
                                        permissions={metadata.privileges}
                                        gridDataSource={dataSource}
                                        tableLevelSharedSpace={tableLevelSharedSpace}
                                        setFullScreenForm={setFullScreenForm}
                                        disableDxNavKeys={disableDxNavKeys}
                                        baseEndpoint={accidentEndpoint}
                                    />)
                                }
                            }
                        />
                    </DataGrid>
                )}
            </Paper>
        </InlineWrapper>
    );
};

// HASAccidents.whyDidYouRender = true;

export default SaveOnRowCollapseWrapper(HASAccidents, "save?");
