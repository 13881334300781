import { ColumnProps } from '../../components/ContractInFocus/interfaces/dataGridColumn.interface';
import { FieldMetaGroup, Dictionary } from '../../components/Common/Interfaces/Entity.interface';
import { TablePosition } from './Interfaces/DataGrid.interfaces';
import {
  DataGridOnEditPreparing
} from "interfaces/datagrid.interfaces";

export const metaMultiChoiceType = 'multichoice';
export const metaChoiceType = 'choice';
export const metaTextareaType = 'textarea';
export const metaStringType = 'string';
export const metaNumberType = 'number';
export const metaBooleanType = 'boolean';

const dataTypeMap: Dictionary<string> = {
  'decimal': metaNumberType,
  'integer': metaNumberType,
  'date': 'date',
  'datetime': 'datetime',
  [metaStringType]: metaStringType,
  [metaTextareaType]: metaStringType,
  [metaChoiceType]: metaStringType,
  [metaMultiChoiceType]: metaStringType,
  [metaBooleanType]: metaBooleanType
};

// 'currency'
const formatMap: Dictionary<any> = {
  'decimal': { type: 'fixedPoint', precision: 2 },
  'integer': undefined,
  'date': 'dd/MM/yyyy',
  'datetime': 'dd/MM/yyyy hh:mm',
  [metaStringType]: undefined,
  [metaMultiChoiceType]: undefined,
  [metaTextareaType]: undefined,
  [metaChoiceType]: undefined,
  'boolean': undefined
}

const widthMap: Dictionary<any> = {
  'decimal': 150,
  'integer': 150,
  'date': 120,
  'datetime': 240,
  [metaStringType]: undefined,
  [metaTextareaType]: undefined,
  [metaChoiceType]: 225,
  [metaMultiChoiceType]: 225,
  'boolean': 230
}

export const getAlignment = (dataType: string) => {
  if (dataType === metaNumberType) {
    return 'right'
  }

  if (dataType === metaBooleanType) {
    return 'center'
  }

  return 'left'
}

export const columnVisibility = (visible: boolean = false, submit?: boolean) => {
  const edit = submit === undefined ? visible : submit
  return {
    gridModeInsert: edit,
    gridModeRead: visible,
    gridModeEdit: edit,
  }
};

export const getRelationChoiceId = (relationChoices: any[], choice: string) => {
  const findElement = (element: any) => element['display_name'] === choice
  const el = relationChoices.find(findElement)
  return el ? el.value : null
}

export const getColumnPropsIfFoundInMeta = (dataField: string, metadata: FieldMetaGroup | null, useWidth = true): ColumnProps | undefined => {
  if (metadata && metadata[dataField] == undefined) {
    return undefined
  }
  return getColumnProps(dataField, metadata, useWidth);
}

export const getColumnProps = (dataField: string, metadata: FieldMetaGroup | null, useWidth = true): ColumnProps => {
  try {
    const metaLookup = metadata && metadata[dataField] !== undefined;
    const options = <ColumnProps>{
      dataField,
      alignment: ((metadata && metaLookup && getAlignment(dataTypeMap[metadata[dataField].type])) || 'left'),
      caption: metadata && metaLookup ? metadata[dataField].label : '',
      allowEditing: metadata && metaLookup ? !metadata[dataField].read_only : false,
      width: metadata && metaLookup && useWidth ? widthMap[metadata[dataField].type] : undefined,
      minValue: metadata && metaLookup && metadata[dataField].min_value ? metadata[dataField].min_value : undefined,
      maxValue: metadata && metaLookup && metadata[dataField].max_value ? metadata[dataField].max_value : undefined,
      minWidth: (metadata && metaLookup && useWidth && metadata[dataField].type === metaStringType) ? 180 : undefined,
      dataType: metadata && metaLookup ? dataTypeMap[metadata[dataField].type] : metaStringType,
      format: metadata && metaLookup ? formatMap[metadata[dataField].type] : undefined,
      lookup: (metadata && metaLookup && (metadata[dataField].type === metaChoiceType || metadata[dataField].type === metaMultiChoiceType)) ? {
        dataSource: (metadata[dataField].choices || []),
        displayExpr: 'display_name',
        valueExpr: 'value'
      } : undefined
    }
    const existingOptions = options.editorOptions || {};
    if (metadata && metaLookup && (metadata[dataField].type === metaChoiceType || metadata[dataField].type === metaMultiChoiceType)) {
      if (!metadata[dataField].required) {
        options.editorOptions = { ...existingOptions, showClearButton: true }
      }
    }
    if (metadata && metaLookup && (metadata[dataField].type === "string")) {
      options.editorOptions = { ...existingOptions, spellcheck: true }
    }
    return options
  } catch (e) {
    const eString = `GetColumnProps ${dataField} doesn't exist in of type: ${typeof (metadata)} with keys: ${metadata ? Object.keys(metadata).join(', ') : '"". metadata type is: ' + typeof (metadata)}`;
    console.warn(e);
    throw new Error(eString);
    //throw new Error(`GetColumnProps ${dataField} doesn't exist in of type: ${typeof (metadata)} with keys: ${metadata ? Object.keys(metadata).join(', ') : '"". metadata type is: ' + typeof (metadata)}`);
  }
};



export function swapEditorType(metadata: FieldMetaGroup, e: DataGridOnEditPreparing, targetTypeMapping: Dictionary<string>, currentType?: string) {

  const thisMeta = metadata.PUTMeta || metadata.POSTMeta;
  if (thisMeta && e.dataField) {
    const targetType = targetTypeMapping[e.dataField];
    if (targetType) {
      //@ts-ignore
      const thisType = thisMeta[e.dataField].type;
      //@ts-ignore
      const thisReadOnly = thisMeta[e.dataField].read_only;
      if (currentType && currentType !== thisType) {
        return;
      }
      if (!thisReadOnly) {
        e.editorName = targetType;
      }
    }
  }
}

export const editableTextAreaOnPreparing = (meta: FieldMetaGroup) => (e: any) => {
  if (meta[e.dataField]?.type === metaStringType) {
    e.editorName = "dxTextArea";
    e.editorOptions.autoResizeEnabled = true;
    e.editorOptions.onKeyDown = () => {
      const overlayTarget = document.querySelector('.dx-datagrid-focus-overlay');
      if (overlayTarget && !overlayTarget.classList.contains('dx-hidden')) {
        overlayTarget.classList.add('dx-hidden')
      }
    }
  }
}

export const getGridProps = (metadata: FieldMetaGroup) => ({
  rowAlternationEnabled: true,
  showBorders: true,
  wordWrapEnabled: true,
  onInitNewRow: (e: any) => {
    if (metadata) {
      Object.keys(metadata).forEach(key => {
        if (metadata[key].type === metaBooleanType) {
          e.data[metadata[key].field_name] = false;
        }
      })
    }
  }
});

export const navigateDataGridOnKeyDown = (dataGridRef: any, tablePosition: TablePosition, e: any) => {
  const event = e.event;

  const isDataGridValid = (): boolean => dataGridRef && dataGridRef.current;
  const isKeyDownEventEnter = (): boolean => event.keyCode === 13;
  const isAtTheBottomOfDataGrid = (): boolean => tablePosition && tablePosition.rowIndex + 1 === tablePosition.rowCount;

  if (isDataGridValid() && isKeyDownEventEnter() && isAtTheBottomOfDataGrid()) {
    const dataGrid = dataGridRef.current.instance;
    const cellElement = dataGrid.getCellElement(0, (tablePosition.colIndex || 0) + 1);
    return () => setTimeout(() => { dataGrid.focus(cellElement) }, 250);
  } else {
    return () => { };
  }
}

export const onFocusedCellChangingSetPosition = (e: any, tablePosition: TablePosition, setTablePositionCallback: (position: TablePosition) => void) => {
  const positioning = { ...tablePosition, rowIndex: e.newRowIndex, colIndex: e.newColumnIndex };
  setTablePositionCallback(positioning);
};

export const createdAtColumn = (created_at?: string, visible = false) => ({
  created_at: {
    created_at,
    alignment: "left",
    editCellComponent: null,
    dataType: "datetime",
    format: "dd/MM/yyyy HH:mm",
    ...columnVisibility(false)
  }
})

export const expandAndCollapseRow = (thisGridRowEvent: any) => {
  thisGridRowEvent.event?.preventDefault();
  const expanded = thisGridRowEvent.row.isExpanded;
  if (expanded) {
    thisGridRowEvent.component.collapseRow(thisGridRowEvent.row.data.id);
  } else {
    thisGridRowEvent.component.expandRow(thisGridRowEvent.row.data.id);
  }
}

export const getRowElementByKey = (gridInstance: any, key: string | number) => {
  const rowIndex = gridInstance.getRowIndexByKey(key);
  return gridInstance.getRowElement(rowIndex);
}