/* eslint-disable */
import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';
import API from "services/API/API";

// Own
import { store } from "store/store";
import { SearchParams, Option } from "components/Common/Components/JAAutoComplete/JAAutoComplete";
import { FieldMetaGroup, FormValues, LookupEntity } from 'components/Common/Interfaces/Entity.interface';
import { getPreFlightInfo } from 'components/ContractInFocus/Services/commonAPI.services';
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { getPayloadFromRef } from 'services/API/API.helper';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { SupplierService } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { getOptionFromMetaByValue } from "helpers/Forms/form.helper";
import MatConfirmationDialog from 'components/Common/Components/Material/MatConfirmationDialog/MatConfirmationDialog';

import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"

const getSupplierTargetOptionLabel = (option: Option | string) => typeof option === 'string' ? option : option?.equipment_name;
const getSupplierTargetOptionValue = (option: Option | string) => typeof option === 'string' ? option : option?.id;

interface SupplierServiceFormProps {
    supplierService: any;
    refreshGrid: React.DispatchWithoutAction | undefined;
    populateSupplierContractsResponse: () => void;
    setSignalDelete: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setShowTransferForm: React.Dispatch<React.SetStateAction<boolean>>;
    showTransferForm: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function SupplierDataTransferFormView({
    supplierService,
    refreshGrid,
    populateSupplierContractsResponse,
    setSignalDelete,
    setShowTransferForm,
    showTransferForm,
    setOpen,
}: SupplierServiceFormProps) {
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const formValuesRef = useRef<any>(supplierService || {});
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const [meta, setMeta] = useState<FieldMetaGroup>();
    const [canChange, setCanChange] = useState(false);
    const currentFocus = useRef<string>();

    const baseRoute = `supplier-services/${supplierService.id}/transfers/`;

    // const fetchTransferTargetOptions = (params: SearchParams, callback: (results: Option[]) => any) => {
    //     searchSupplierNames(params).then((response) => {
    //         callback(response);
    //     })
    // }

    const confirmationInitialState = { open: false, agreeCallback: () => { }, message: '' };

    const [confirmationDialog, setConfirmationDialog] = useState(confirmationInitialState);

    interface target {
        id: string;
        equipment_name: string;
    }

    const currentlySelectedTargetOption = useRef<LookupEntity>();
    const [hasTargetChoices, setHasTargetChoices] = useState(false);

    const addToCallOnChange = useCallback((formValues: FormValues) => {
        currentlySelectedTargetOption.current = getOptionFromMetaByValue('target_supplier_service', formValues, meta);
    }, [meta])

    const handleTransfer = (recordId?: string | number, callback?: any) => {
        const route = `${baseRoute}`
        const payload = getPayloadFromRef(formValuesRef, meta);
        // we need to update a task that isn't tied to a specific contract
        recordId && API.post(route, payload).then((response) => {
            processSuccessfulResponse(response, callback);
            populateSupplierContractsResponse();
            //setSignalDelete(true);
            refreshGrid && refreshGrid();
            setShowTransferForm(false);
            setOpen(false);
        }).catch((e) => {
            console.log('issue: ', e);
        });

    }

    const handleSubmit = (recordId?: string | number, callback?: any) => {
        const thisChoice = getOptionFromMetaByValue('target_supplier_service', formValuesRef.current, meta);
        thisChoice ? setUpConfirmation(thisChoice) : alert('no value detected');
    }

    const setUpConfirmation = (target: LookupEntity): void => {
        setConfirmationDialog({
            open: true,
            agreeCallback: () => {
                handleTransfer(target.value);
                setConfirmationDialog(confirmationInitialState);
            },
            message: `Are you sure you want to transfer all data from '${supplierService.equipment_name}' to '${target.display_name}' and remove '${supplierService.equipment_name}'?`,
        });
    }

    useEffect(() => {
        if (supplierService) {
            getPreFlightInfo(baseRoute).then((response) => {
                const { canCreate } = response;
                setCanChange(!!canCreate);
                setMeta(response.meta);
            })
        }
    }, [supplierService])

    useEffect(() => {
        setHasTargetChoices(!!meta?.target_supplier_service?.choices?.length);
    }, [meta])

    const getFormFieldsConfig = useCallback((supplierService?: SupplierService,
        currentValuesRef?: React.MutableRefObject<FormValues>,
        currentFocus?: React.MutableRefObject<string | undefined>,
        canChange?: boolean): FieldsFormConfig => {
        const config: FieldsFormConfig = {
            source_supplier_service: {
                hidden: true,
                autoCalculator: () => supplierService?.id,
            },
            target_supplier_service: {
                controlClassName: 'full-height',
                //typeOverride: 'autoComplete',
                disabled: !canChange,
                freeSolo: false,
                getOptionLabel: getSupplierTargetOptionLabel,
                getOptionValue: canChange ? getSupplierTargetOptionValue : undefined,
                label: 'Transfer all data to',
            },
        }
        return config;
    }, []);

    const processSuccessfulResponse = (response: any, callback: any) => {
        store.dispatch(addNotification({ message: "Data Transferred", type: NOTIFICATION_SUCCESS }))
        forceUpdate();
    }

    const getButtons = useCallback(() => generateStandardGeneralActionButtonDefinitions({
        handleSave: handleSubmit,
        overrideSaveLabel: 'Transfer',
        showDelete: false,
        formErrors
    }), [handleTransfer, supplierService]);

    const formLevelSharedSpace = useRef({});

    return <>
        {
            meta && mustRefresh && supplierService && canChange &&
            <>
                <MatConfirmationDialog
                    onAgree={confirmationDialog.agreeCallback}
                    onDisagree={() => setConfirmationDialog(confirmationInitialState)}
                    open={confirmationDialog.open}
                    actions={{ agree: 'Yes', disagree: 'No' }}
                >
                    {confirmationDialog.message}
                </MatConfirmationDialog>
                <div className={`showTransferControlWrapper ${hasTargetChoices ? '' : 'disabled'}`}
                    onClick={() => setShowTransferForm(!showTransferForm && hasTargetChoices)}>
                    {`${showTransferForm ? 'Hide Merge Form' : 'Show Merge Form'}`}
                </div>
                {showTransferForm && hasTargetChoices &&
                    <div className="supplierTransferFormWrapper">
                        <div className="supplierTransferInfo">Select the service to which the supplier contracts, visits and any associated documents from this service will be transferred. The options available through this form are restricted to services connected to the
                            same supplier, under the same client contract.  Once the transfer is complete, this service record will be removed.
                        </div>
                        <GeneralEntityForm
                            useDefaultRevertChanges={false}
                            buttons={getButtons()}
                            formValuesRef={formValuesRef}
                            refreshSignal={mustRefresh}
                            formLevelSharedSpace={formLevelSharedSpace.current}
                            initialData={supplierService}
                            canWrite={!!canChange}
                            formFieldsConfig={getFormFieldsConfig(supplierService, formValuesRef, currentFocus, !!canChange)}
                            meta={meta}
                            dispatchRefreshContext={forceUpdate}
                            gridClass="SupplierDataTransferForm"
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                            addToCallOnChange={addToCallOnChange}
                        />
                    </div>

                }
            </>
        }
    </>
}

export default SupplierDataTransferFormView;