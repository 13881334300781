import { forkJoin } from 'rxjs';
import { store } from "store/store";
import { ppmRightVisibilityMenu, rmRightVisibilityMenu } from "components/ContractInFocus/Maintenance/Models/maintenanceRightVisibilityMenu";
import { auditRightVisibilityMenu } from "components/ContractInFocus/Audit/Models/auditRightVisibilityMenu";
import { specialistRightVisibilityMenu } from 'components/Schedulers/Models/specialistRightVisibilityMenu';
import { logsRightVisibilityMenu } from "components/ContractInFocus/Logs/Models/logsRightVisibilityMenu";
import { financialsRightVisibilityMenu } from "components/ContractInFocus/Financials/Models/financialsRightVisibilityMenu";
import { spendRightVisibilityMenu } from "components/ContractInFocus/Spend/Models/spendRightVisibilityMenu";
import { meterReadingsRightVisibilityMenu } from "components/ContractInFocus/MeterReadings/Models/meterReadingsRightVisibilityMenu";
import { contractInFocusMeterStreamsSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { useSelector } from "react-redux";
import { IMeterReadingsStream } from 'components/ContractInFocus/MeterReadings/Interfaces/MeterReadings.interface';
import { togglePersonalProfileSettings } from 'components/Profile/Actions/Profile.actions';
import { togglePersonalSettings } from 'components/Profile/Actions/PersonalSettings.actions';
import { GetRightVisibilityMenu } from "components/Common/Components/RightVisibilityMenu/Interfaces/RightContext.interfaces";
import * as contractInFocusSelectors from "components/ContractInFocus/Selectors/contractInFocus.selectors";
import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';
import { portfolioHydratedFactory } from "components/Portfolios/Selectors/Portfolio.selectors";
import { portfolioInFocusPortfolio } from "components/PortfolioInFocus/Selectors/portfolioInFocus.selectors";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { getPreFlightInfo } from 'components/ContractInFocus/Services/commonAPI.services';
import { ContractOrPortfolio } from "services/API/common/contractAPIs";
import { contractInFocusACLSelector, portfolioInFocusACLSelector } from "components/ContractInFocus/Selectors/ACL.selectors";

export function useShouldViewContractReportVisibility() {
    const CIFACL = useSelector(contractInFocusACLSelector);
    return !!CIFACL?.report?.canCreate
}

export function useShouldViewPortfolioReportVisibilitySettings() {
    const PIFACL = useSelector(portfolioInFocusACLSelector);
    return !!PIFACL?.report?.canCreate
}

export function useEnablePersonalVisibilitySettings() {
    const profile = store.getState().profile;
    const shouldView = profile.data?.internal_member || profile.data?.api_admin_level == "editor" || profile.data?.global_contract_level == "editor"
    return shouldView;
}

export const defaultToggleProfileSettings = (updateKey: string): void => {
    //default because if a custom toggle function is provided in the visibility settings, it will run that instead (VisibilityControl takes care of that)
    //HT HOW: this will become the default that may be overridden in visibility controls
    store.dispatch(togglePersonalProfileSettings(updateKey));
}

export const defaultTogglePersonalSettings = (updateKey: string): void => {
    //default because if a custom toggle function is provided in the visibility settings, it will run that instead (VisibilityControl takes care of that)
    //HT HOW: this will become the default that may be overridden in visibility controls
    store.dispatch(togglePersonalSettings(updateKey));
}

function shouldView(calcCallBack: Function, props: GetRightVisibilityMenu, forceTrue = false) {
    if (forceTrue) {
        return true
    }
    const visibilitySettings = calcCallBack(props);
    const visibleContents = Object.keys(visibilitySettings).filter((vKey) => {
        let lookup = visibilitySettings[vKey];
        let vizValues = Object.keys(lookup).filter((part) => {
            let setting = lookup[part];
            return setting.value;
        })
        return vizValues.length > 0;
    })
    return visibleContents.length > 0;
}

export function useCheckHASCompatibleEnv() {
    const internal = useSelector(internalMemberSelector);
    const selectedContract = useSelector(
        contractInFocusSelectors.contractInFocusContractSelector
    );
    const frozenFor = useSelector(contractInFocusSelectors.contractOrPortfolioFrozenForSelector({ portfolioId: undefined, contractId: selectedContract?.id }));
    if (frozenFor) {
        return false;
    }
    const shouldViewHAS = internal ? selectedContract?.data.include_h_a_s : selectedContract?.data.include_h_a_s && selectedContract?.data.expose_h_a_s;
    return shouldViewHAS;
}

export function usePortfolioContextShouldViewHAS() {
    const portfolioInFocusState = useSelector(portfolioInFocusPortfolio);
    const portfolio = useSelector(portfolioHydratedFactory(portfolioInFocusState.id))?.data;
    const frozenFor = useSelector(contractInFocusSelectors.contractOrPortfolioFrozenForSelector({ portfolioId: portfolio?.id, contractId: undefined }));
    const internal = useSelector(internalMemberSelector);
    if (frozenFor) {
        return false;
    }
    let shouldViewHAS = false;
    if (portfolio?.contracts) {
        for (var i = 0; i < portfolio?.contracts.length; i++) {
            let thisContract = portfolio.contracts[i];
            if (thisContract) {
                if (internal) {
                    if (thisContract.include_h_a_s) {
                        shouldViewHAS = true;
                        break;
                    }

                } else {
                    if (thisContract.include_h_a_s && thisContract.expose_h_a_s) {
                        shouldViewHAS = true;
                        break;
                    }
                }
            }
        }
    }
    return shouldViewHAS;
}

export type ViewMenuValues = Dictionary<boolean>;

export function getMenuAccess({ contract, portfolio }: ContractOrPortfolio) {
    const config = { omitEdit: true, ignoreErrors: true }
    let baseUrl = '';
    if (contract) {
        baseUrl = `contracts/${contract?.contract_ref}`;
    }
    if (portfolio) {
        baseUrl = `portfolios/${portfolio?.id}`;
    }
    if (contract) {
        return forkJoin({
            planned: getPreFlightInfo(`${baseUrl}/monthly-ppm-task-counts`, undefined, config),
            reactive: getPreFlightInfo(`${baseUrl}/monthly-rm-task-counts`, undefined, config),
            specialist: getPreFlightInfo(`${baseUrl}/supplier-service-visits-for-schedule`, undefined, config),
            events: getPreFlightInfo(`${baseUrl}/technical-event-logs`, undefined, config),
            financials: getPreFlightInfo(`${baseUrl}/financials`, undefined, config),
            audit: getPreFlightInfo(`${baseUrl}/insurance-audits`, undefined, config),
            spend: getPreFlightInfo(`${baseUrl}/combined-consumable-monthly-aggregate-spend`, undefined, config),
            meter: getPreFlightInfo(`${baseUrl}/meter-readings`, undefined, config),
            report: getPreFlightInfo(`${baseUrl}/monthly-maintenance-reports`, undefined, config),
            health_and_safety: getPreFlightInfo(`${baseUrl}/ptws`, undefined, config),
            h_a_s_checks: getPreFlightInfo(`${baseUrl}/hascontracttasks-with-month-checks`, undefined, config),
            h_a_s_accidents: getPreFlightInfo(`${baseUrl}/h-a-s-accidents`, undefined, config),
            h_a_s_near_misses: getPreFlightInfo(`${baseUrl}/h-a-s-near-misses`, undefined, config),
            h_a_s_permits: getPreFlightInfo(`${baseUrl}/ptws`, undefined, config),
        }).toPromise();
    } else {
        return forkJoin({
            planned: getPreFlightInfo(`${baseUrl}/monthly-ppm-task-counts`, undefined, config),
            reactive: getPreFlightInfo(`${baseUrl}/monthly-rm-task-counts`, undefined, config),
            specialist: getPreFlightInfo(`${baseUrl}/supplier-service-visits-for-schedule`, undefined, config),
            events: getPreFlightInfo(`${baseUrl}/technical-event-logs`, undefined, config),
            financials: getPreFlightInfo(`${baseUrl}/financials`, undefined, config),
            audit: getPreFlightInfo(`${baseUrl}/insurance-audits`, undefined, config),
            spend: getPreFlightInfo(`${baseUrl}/combined-consumable-monthly-aggregate-spend`, undefined, config),
            report: getPreFlightInfo(`${baseUrl}/monthly-maintenance-reports`, undefined, config),
            health_and_safety: getPreFlightInfo(`${baseUrl}/ptws`, undefined, config),
            h_a_s_checks: getPreFlightInfo(`${baseUrl}/hascontracttasks-with-month-checks`, undefined, config),
            h_a_s_accidents: getPreFlightInfo(`${baseUrl}/h-a-s-accidents`, undefined, config),
            h_a_s_near_misses: getPreFlightInfo(`${baseUrl}/h-a-s-near-misses`, undefined, config),
            h_a_s_permits: getPreFlightInfo(`${baseUrl}/ptws`, undefined, config),
        }).toPromise();
    }

}

export function useGetCIFSideBarMenuVisibility(props: GetRightVisibilityMenu) {
    //const vs = useSelector(visibilitySelector.mainClientVisibilitySettingsObjectsSelector);
    const CIFACL = useSelector(contractInFocusACLSelector);
    const contractMeterStreams = useSelector(contractInFocusMeterStreamsSelector);
    const numberOfVisibleContractMeterStreams = contractMeterStreams.data?.map((stream: IMeterReadingsStream) => {
        return stream.show_graph_in_monthly_maintenance_report || stream.show_table_in_monthly_maintenance_report
    }).filter(show => show).length;
    const HASCompatible = useCheckHASCompatibleEnv();
    const overRideShouldView = useShouldViewContractReportVisibility(); // this is here because if the user should be able to adjust visibilitySettings
    // they should also be able to see all menu items on the left 
    const viewMenuValues = {
        // here we're going to drill down using the calcs performed by the visibilityMenu, injecting the stored visibility settings for the contract
        // rather than any personal settings, if the override isn't set to True of course.
        "Planned": !!CIFACL?.planned.canRead && shouldView(ppmRightVisibilityMenu, props, overRideShouldView),
        "Reactive": !!CIFACL?.reactive.canRead && shouldView(rmRightVisibilityMenu, props, overRideShouldView),
        "Specialist": !!CIFACL?.specialist.canRead && shouldView(specialistRightVisibilityMenu, props, overRideShouldView),
        "Events": !!CIFACL?.events.canRead && shouldView(logsRightVisibilityMenu, props, overRideShouldView),
        "Financials": !!CIFACL?.financials.canRead && shouldView(financialsRightVisibilityMenu, props, overRideShouldView),
        "Audit": !!CIFACL?.audit.canRead && shouldView(auditRightVisibilityMenu, props, overRideShouldView),
        "Spend": !!CIFACL?.spend.canRead && shouldView(spendRightVisibilityMenu, props, overRideShouldView),
        "Meter Reading": !!CIFACL?.meter_reading?.canRead && shouldView(meterReadingsRightVisibilityMenu, props, overRideShouldView) || numberOfVisibleContractMeterStreams > 0,
        // The assumption in the next line is that the people who need to view the report preview are those who can create it
        "Report": !!CIFACL?.report?.canCreate,// this means if the user should be able to adjust visibility setting they'll also see the Report page
        //"Health and Safety": !!CIFACL?.health_and_safety.canRead && !!HASCompatible,
        "H&S Checks": !!CIFACL?.h_a_s_checks.canRead && !!HASCompatible,
        "Accidents": !!CIFACL?.h_a_s_accidents.canRead && !!HASCompatible,
        "Near Misses": !!CIFACL?.h_a_s_near_misses.canRead && !!HASCompatible,
        "Permits": !!CIFACL?.h_a_s_permits.canRead && !!HASCompatible,
    }
    return viewMenuValues;
}

export function useGetPIFSideBarMenuVisibility(props: GetRightVisibilityMenu) {
    // const vs = useSelector(visibilitySelector.mainClientVisibilitySettingsObjectsSelector);
    const PIFACL = useSelector(portfolioInFocusACLSelector);
    const shouldViewHAS = usePortfolioContextShouldViewHAS();
    const overRideShouldView = useShouldViewPortfolioReportVisibilitySettings(); // this is here because if the user should be able to adjust visibilitySettings
    // they should also be able to see all menu items on the left 
    const viewMenuValues = {
        // here we're going to drill down using the calcs performed by the visibilityMenu, injecting the stored visibility settings for the contract
        // rather than any personal settings, if the override isn't set to True of course.
        "Planned": !!PIFACL?.planned.canRead && shouldView(ppmRightVisibilityMenu, props, overRideShouldView),
        "Reactive": !!PIFACL?.reactive.canRead && shouldView(rmRightVisibilityMenu, props, overRideShouldView),
        "Specialist": !!PIFACL?.specialist.canRead && shouldView(specialistRightVisibilityMenu, props, overRideShouldView),
        "Events": !!PIFACL?.events.canRead && shouldView(logsRightVisibilityMenu, props, overRideShouldView),
        "Financials": !!PIFACL?.financials.canRead && shouldView(financialsRightVisibilityMenu, props, overRideShouldView),
        "Audit": !!PIFACL?.audit.canRead && shouldView(auditRightVisibilityMenu, props, overRideShouldView),
        "Spend": !!PIFACL?.spend.canRead && shouldView(spendRightVisibilityMenu, props, overRideShouldView),
        // "Meter Reading": !!PIFACL?.meter_reading?.canRead && shouldView(meterReadingsRightVisibilityMenu, props, overRideShouldView) || numberOfVisibleContractMeterStreams > 0,
        // The assumption in the next line is that the people who need to view the report preview are those who can create it
        "Report": !!PIFACL?.report?.canCreate,// this means if the user should be able to adjust visibility setting they'll also see the Report page
        // "Health and Safety": !!PIFACL?.health_and_safety.canRead && !!shouldViewHAS,
        "H&S Checks": !!PIFACL?.h_a_s_checks.canRead && !!shouldViewHAS,
        "Accidents": !!PIFACL?.h_a_s_accidents.canRead && !!shouldViewHAS,
        "Near Misses": !!PIFACL?.h_a_s_near_misses.canRead && !!shouldViewHAS,
        "Permits": !!PIFACL?.h_a_s_permits.canRead && !!shouldViewHAS,
    }
    //console.log('viewMenuValues: ', viewMenuValues);
    return viewMenuValues;
}

