import moment from "moment";

import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldGroup, Dictionary, FormValues } from 'components/Common/Interfaces/Entity.interface';
import { ProfileState } from "components/Profile/Interfaces/ProfileState.interface";
import { BasePermitToWork, ConfinedSpacesPTW, HotWorksPTW, LimitationOfAccessPTW, WorkPermitType, WorkingAtHeightPTW } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { FieldMeta } from 'components/Common/Interfaces/Entity.interface';

export type PtwType = BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW;

interface GetPTWFormFieldsConfigProps {
    data: BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW;
    canWrite: boolean;
    permitType: WorkPermitType;
    currentUser: ProfileState;
    scheduledFor?: string;
}

export const getPTWFormFieldsConfig = ({ data, canWrite, permitType, currentUser, scheduledFor }: GetPTWFormFieldsConfigProps): FieldsFormConfig => {
    // NB ANY CHANGES HERE SHOULD ALSO BE MADE IN THE CORRESPONDING BACK END 'OPEN' SIGNATURE FORM.  TODO - AT SOME POINT WE SHOULD PROBABLY LOOK
    // TO CONNECT THEM AND DERIVE THE ONE FROM THE OTHER.
    let config;

    const dateInductionReceivedConfig = {
        fieldValidator: ({ formValuesRef, value, meta }: any) => {
            let validations: Dictionary<string> = {};
            const visitDate = moment(scheduledFor);
            const twoYearsAgo = visitDate.subtract(2, "years");
            if (value) {
                const inductionReceived = moment(value as string);
                if (twoYearsAgo > inductionReceived) {
                    const inducationDateValidationMessage = `The induction date should be within two years of the scheduled works date (after ${twoYearsAgo})`
                    validations['inputValidations'] = validations['save'] = validations['submit'] = inducationDateValidationMessage;
                }
            }

            return validations

        },
        getMinDate: (meta: FieldMeta) => {
            const visitDate = moment(scheduledFor);
            const twoYearsAgo = visitDate.subtract(2, "years");
            return twoYearsAgo.toDate();
        },
        getMaxDate: (meta: FieldMeta) => {
            return moment().toDate();
        }
    }
    // const job_title_choices = currentUser.meta?.job_title?.choices;
    // const current_user_job_title_id = currentUser.data.job_title;
    // const current_user_job_title_choice = job_title_choices.filter((x: any) => x.value === current_user_job_title_id);
    // console.log('job title choices: ', currentUser.meta.job_title.choices);
    // console.log('jt id: ', current_user_job_title_id);
    // const current_user_job_title_string = current_user_job_title_choice.length ? current_user_job_title_choice[0].display_name : undefined;
    const coreConfig: FieldsFormConfig = {
        reference: {},
        agreement: {
            ignoreMissing: true
        },
        // Authorisation by Authorised Person
        start_time: {
            addColonToLabel: true
        },
        finish_time: {
            addColonToLabel: true
        },
        supervisor_first_name: {
            addColonToLabel: true
        },
        supervisor_last_name: {
            addColonToLabel: true
        },
        supervisor_position: {
            addColonToLabel: true
        },
        supervisor_email: {
            addColonToLabel: true
        },
        jbs_authorised_person_first_name: {
            addColonToLabel: true,
            defaultValue: currentUser.data.first_name
        },
        jbs_authorised_person_last_name: {
            addColonToLabel: true,
            defaultValue: currentUser.data.last_name
        },
        jbs_authorised_person_position: {
            addColonToLabel: true,
            defaultValue: currentUser.data.job_title_display,
        },
        jbs_authorised_person_email: {
            addColonToLabel: true,
            defaultValue: currentUser.data.email
        },
        // Safe Systems of Work
        risk_assessment_document_number: {
            addColonToLabel: true
        },
        risk_assessment_author: {
            addColonToLabel: true
        },
        risk_assessment_not_uploaded_reason: {
            skipForm: !data.risk_assessment_missing,
        },
        method_statement_document_number: {
            addColonToLabel: true
        },
        method_statement_author: {
            addColonToLabel: true
        },
        method_statement_not_uploaded_reason: {
            skipForm: !data.method_statement_missing,
        },
        communication_systems: {
            useRadioButtons: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                return {
                    "inputValidations": (value === false || value === 0) ? "A system of communications must be put in place." : undefined,
                    "save": (value === false || value === 0) ? "A system of communications must be put in place." : undefined,
                    "submit": (value === false || value === 0 || value === undefined) ? "A system of communications must be put in place." : undefined
                }
            },
            sideEffect: (values: FormValues, fieldConfigs: FieldsFormConfig) => {
                // recipent name disabling control
                let changed = false;
                const thisValue = values['communication_systems'];
                const communicationSystemsDetailsConfig = fieldConfigs['communication_systems_details']
                if (communicationSystemsDetailsConfig !== undefined) {
                    if (thisValue) {
                        if (communicationSystemsDetailsConfig.skipForm) {
                            communicationSystemsDetailsConfig.skipForm = false;
                            changed = true;
                        }
                    } else {
                        if (!communicationSystemsDetailsConfig.skipForm) {
                            communicationSystemsDetailsConfig.skipForm = true;
                            changed = true;
                        }
                    }
                }
                return changed;
            },

        },
        communication_systems_details: { addColonToLabel: true },


        // Documentation
        attendance_register_signed: {
            useRadioButtons: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                return {
                    "inputValidations": (value === false || value === 0) ? "The attendance register must be signed." : undefined,
                    "save": (value === false || value === 0) ? "The attendance register must be signed." : undefined,
                    "submit": (value === false || value === 0 || value === undefined) ? "The attendance register must be signed." : undefined
                }
            }
        },
        induction_given: {
            useRadioButtons: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                return {
                    "inputValidations": (value === false || value === 0) ? "An induction must be given." : undefined,
                    "save": (value === false || value === 0) ? "An induction must be given." : undefined,
                    "submit": (value === false || value === 0 || value === undefined) ? "An induction must be given." : undefined
                }
            },
            sideEffect: (values: FormValues, fieldConfigs: FieldsFormConfig) => {
                // recipent name disabling control
                let changed = false;
                const thisValue = values['induction_given'];
                const dateInductionReceivedConfig = fieldConfigs['date_induction_received']
                if (dateInductionReceivedConfig !== undefined) {
                    if (thisValue) {
                        if (dateInductionReceivedConfig.skipForm) {
                            dateInductionReceivedConfig.skipForm = false;
                            changed = true;
                        }
                    } else {
                        if (!dateInductionReceivedConfig.skipForm) {
                            dateInductionReceivedConfig.skipForm = true;
                            changed = true;
                        }
                    }
                }
                return changed;
            },
        },
        rams_reviewed: {
            useRadioButtons: true,
            fieldValidator: ({ formValuesRef, value, meta }) => {
                return {
                    "inputValidations": (value === false || value === 0) ? "Rams must be reviewed and approved." : undefined,
                    "save": (value === false || value === 0) ? "Rams must be reviewed and approved." : undefined,
                    "submit": (value === false || value === 0 || value === undefined) ? "Rams must be reviewed and approved." : undefined
                }
            }
        },
        workers_informed_of_local_hazards: { useRadioButtons: true, },
        relevant_ppe_checked: { useRadioButtons: true, },
    }
    switch (permitType) {
        case "Hot Works": {
            config = {
                ...coreConfig,
                description: {
                    multiline: true,
                    rows: 3,
                    label: "Hot works location and description, including any plant or processes",
                    addColonToLabel: true
                },
                client_permission_granted: {
                    useRadioButtons: true,
                    fieldValidator: ({ formValuesRef, value, meta }: any) => {
                        return {
                            "inputValidations": (value === false || value === 0) ? "Client permission must be granted" : undefined,
                            "save": (value === false || value === 0) ? "Client permission must be granted." : undefined,
                            "submit": (value === false || value === 0 || value === undefined) ? "Client permission must be granted." : undefined
                        }
                    },
                },
                fire_detection_systems_will_be_affected: {
                    useRadioButtons: true,
                },
                suppression_systems_will_be_affected: {
                    useRadioButtons: true,
                },
                // Description of Isolations
                isolation_comments: {
                    multiline: true,
                    rows: 2,
                    addColonToLabel: true
                },
                // Control measures which must be in place before issuing a Permit to work - Hot Work
                workers_informed_of_local_hazards: {
                    useRadioButtons: true,
                },
                relevant_ppe_checked: {
                    useRadioButtons: true,
                },
                combustibles_removed: {
                    useRadioButtons: true,
                },
                combustibles_protected: {
                    useRadioButtons: true
                },
                date_induction_received: dateInductionReceivedConfig,
                equipment_certification_submitted: {
                    useRadioButtons: true,
                },
                area_protected_from_fire: {
                    useRadioButtons: true,
                },
                firefighting_equipment_available: {
                    useRadioButtons: true,
                },
                fire_watch_to_be_maintained: {
                    useRadioButtons: true,
                },
                // Primary Hazards - Fire and Explosion
                fire_alarm_can_be_sounded: {
                    useRadioButtons: true,
                },
                surfaces_protected: {
                    useRadioButtons: true,
                },
                vessels_or_lines_protected_and_flammables_free: {
                    useRadioButtons: true,
                },
                area_to_be_wetted_or_fire_blankets_used: {
                    useRadioButtons: true,
                },
                ppe_required: {
                    multiline: true,
                    rows: 2,
                    addColonToLabel: true
                },
                other_precautions: {
                    multiline: true,
                    rows: 3,
                    addColonToLabel: true,
                }
            }
        }
            break;
        case "Confined Space": {
            config = {
                ...coreConfig,
                description: {
                    label: "Confined space location and description, including any plant or processes",
                    addColonToLabel: true
                },
                type_of_confined_space_and_specified_risks: {
                    addColonToLabel: true
                },
                //Safe system of work
                rescue_plan_document_number: {
                    addColonToLabel: true
                },
                rescue_plan_author: {
                    addColonToLabel: true
                },
                rescue_plan_not_uploaded_reason: {
                    skipForm: !data.rescue_plan_missing,
                },
                // hazards
                acceptable_access: { useRadioButtons: true },
                isolated_from_pipework: { useRadioButtons: true, },
                space_purged: { useRadioButtons: true, },
                electrically_and_mechanically_isolated: { useRadioButtons: true, },
                temperature_maintainable: { useRadioButtons: true, },
                breathing_apparatus_checked: { useRadioButtons: true, },
                backup_equipment_checked: { useRadioButtons: true, },
                emergency_arrangements_in_place: { useRadioButtons: true, },
                atmospheric_testing_required: { useRadioButtons: true, },
                respirable_air_assured: { useRadioButtons: true, },
                atmospheric_testing_unit_info: {
                    addColonToLabel: true
                },
                atmospheric_testing_calibration_date: {
                    getMaxDate: (meta: FieldMeta) => {
                        return moment().toDate();
                    }
                },
                residual_hazards: {
                    addColonToLabel: true
                },
                worker_competences_checked: { useRadioButtons: true, },
            }
        }
            break;
        case "Limitation of Access": {
            config = {
                ...coreConfig,
                description: {
                    label: "Authorised area location and description, including any plant or processes",
                    addColonToLabel: true,
                },
                // Documentation
                date_induction_received: dateInductionReceivedConfig,
                escort_required: { useRadioButtons: true, },
                supervision_required: { useRadioButtons: true, },
                // Hazards. Have the following potential hazards been considered:
                access: { useRadioButtons: true, },
                egress: { useRadioButtons: true, },
                safe_routes: { useRadioButtons: true, },
                additional_information: {
                    addColonToLabel: true
                },
                // Safe Systems of Work
                signs_and_barriers: { useRadioButtons: true, },
                site_specific_rules_communicated: { useRadioButtons: true },
            }
        }
            break;
        case "Working At Height": {
            config = {
                ...coreConfig,
                description: {
                    label: "Working at height location and description",
                    addColonToLabel: true
                },
                // SSOW
                signs_and_barriers: { useRadioButtons: true, },
                signs_and_barriers_in_place: { useRadioButtons: true, },
                site_specific_rules_communicated: { useRadioButtons: true },
                activities: {
                    addColonToLabel: true
                },
                // Documentation
                date_induction_received: dateInductionReceivedConfig,
                // Hazards. Have the following potential hazards been considered:
                qualified: {
                    useRadioButtons: true,
                    fieldValidator: ({ formValuesRef, value, meta }: any) => {
                        return {
                            "inputValidations": (value === false || value === 0) ? "All persons involved must be qualified to use the WAH equipment " : undefined
                        }
                    }
                },
                boards_handrails_ladders: { useRadioButtons: true, },
                scaffolding_and_arrest_systems: { useRadioButtons: true, },
                scaffolding_inspected_and_tagged: { useRadioButtons: true, },
                harness_inspected: { useRadioButtons: true, },
                harness_lanyard_certificate_provided: { useRadioButtons: true, },
                access_equipment_inspected: { useRadioButtons: true, },
                weather_conditions: { useRadioButtons: true, },
                ducts_and_outlets: {
                    useRadioButtons: true,
                    sideEffect: (values: FormValues, fieldConfigs: FieldsFormConfig) => {
                        // recipent name disabling control
                        let changed = false;
                        console.log('values:', values);
                        const thisValue = values['ducts_and_outlets'];
                        if (thisValue && typeof thisValue === "string" && thisValue?.toLowerCase() === 'yes') {
                            if (fieldConfigs['fume_hazards'].skipForm) {
                                fieldConfigs['fume_hazards'].skipForm = false;
                                changed = true;
                            }
                        } else {
                            if (!fieldConfigs['fume_hazards'].skipForm) {
                                fieldConfigs['fume_hazards'].skipForm = true;
                                changed = true;
                            }
                        }
                        return changed;
                    },
                },
                fume_hazards: { useRadioButtons: true, },
                falling_objects_risk: { useRadioButtons: true, },
                equipment_tethered: { useRadioButtons: true, },
                overhead_services: { useRadioButtons: true, },
                edge_protection: { useRadioButtons: true, },
                guard_rails: { useRadioButtons: true, },
                ppe_required: {
                    addColonToLabel: true
                },
            }
        }
            break;
        default: {
            config = coreConfig;
        }
    }
    return config;
}

type PermitData = BasePermitToWork | HotWorksPTW | WorkingAtHeightPTW | LimitationOfAccessPTW | ConfinedSpacesPTW;

interface PTWFormLayoutProps {
    permitType: WorkPermitType;
    RenderDescriptionOfIsolations?: () => JSX.Element;
    RenderPTWTrainingCourses: () => JSX.Element;
    RenderRoles: () => JSX.Element;
    RenderPermitTypePicker: () => JSX.Element | undefined;
    RenderPermitRef: () => JSX.Element | undefined;
    data: PermitData;
}

const getGeneralPTWFieldLookups = (data: PermitData, permitType: WorkPermitType): Dictionary<FieldGroup> => {
    //@ts-ignore
    const safeSystemOfWorkFields = [
        "risk_assessment_document_number",
        "risk_assessment_author",
        ...data.risk_assessment_missing ? ["risk_assessment_not_uploaded_reason"] : [],
        "method_statement_document_number",
        "method_statement_author",
        ...data.method_statement_missing ? ["method_statement_not_uploaded_reason"] : [],
        ...permitType === "Confined Space" ? ["rescue_plan_document_number"] : [],
        ...permitType === "Confined Space" ? ["rescue_plan_author"] : [],
        ...data.rescue_plan_missing && permitType === "Confined Space" ? ["rescue_plan_not_uploaded_reason"] : [],
        "rams_reviewed",
        "workers_informed_of_local_hazards",
        "relevant_ppe_checked",
        "communication_systems",
        "communication_systems_details"
    ]
    return {
        authorisedSection: {
            fields: [
                // Authorisation by Authorised Person
                // "date",
                "start_time",
                "finish_time",
                "description",
            ],
            group_title: "Authorisation by Authorised Person",
            group_id: "Authorisation by Authorised Person",
            className: "authorisation",
            children: [
                {
                    fields: [
                        "jbs_authorised_person_first_name",
                        "jbs_authorised_person_last_name",
                        "jbs_authorised_person_position",
                        "jbs_authorised_person_email",
                    ],
                    group_title: "JBS Authorised Person",
                    group_id: "JBSAuthorisedPerson",
                    className: "JBSAuthorisedPerson",
                },
                {
                    fields: [
                        "supervisor_first_name",
                        "supervisor_last_name",
                        "supervisor_position",
                        "supervisor_email",
                    ],
                    group_title: "Works Supervisor",
                    group_id: "Supervisor",
                    className: "Supervisor",
                },
            ]
        },
        safeSystemsOfWorkSection: {
            fields: safeSystemOfWorkFields,
            group_title: "Safe Systems of Work",
            group_id: "Safe Systems of Work",
            className: "safeSystemsOfWork",
        },
        documentationSection: {
            fields: [
                "attendance_register_signed",
                "induction_given",

            ],
            group_title: "Documentation",
            group_id: "Documentation",
            className: "documentation",
            // children: [
            //     {
            //         fields: [
            //             "keys_issued",
            //             "key_recipient_name",
            //             "date_keys_issued",
            //         ],
            //         group_title: "Keys",
            //         group_id: "keysIssued",
            //         className: "keysIssued",

            //     }
            // ]
        },
        controlMeasuresSection: {
            fields: [
                "combustibles_removed",
                "combustibles_protected",
                "area_protected_from_fire",
                "firefighting_equipment_available",
                "fire_watch_to_be_maintained",
            ],
            group_title: "Control Measures",
            group_id: "Control Measures",
            className: "controlMeasures",
        }
    }
}

export const useGetPTWFormLayout = ({ data, permitType, RenderPTWTrainingCourses, RenderRoles, RenderDescriptionOfIsolations, RenderPermitTypePicker, RenderPermitRef }: PTWFormLayoutProps) => {
    let layout;
    // we modify a copy of all generalPTWFieldLookups so that if the method is called more than once fields aren't added several times...
    const generalPTWFieldLookups = getGeneralPTWFieldLookups(data, permitType);
    let authorisedSection = { ...generalPTWFieldLookups['authorisedSection'] };
    authorisedSection.children = authorisedSection.children || [];

    let safeSystemsOfWorkSection = { ...generalPTWFieldLookups['safeSystemsOfWorkSection'] };
    let documentationSection = { ...generalPTWFieldLookups['documentationSection'] };
    let controlMeasuresSection = { ...generalPTWFieldLookups['controlMeasuresSection'] };

    const permitTypePicker = {
        fields: [],
        group_title: "",
        group_id: "PermitTypePicker",
        className: "permitTypePicker",
        component: RenderPermitTypePicker
    }

    const permitRef = {
        fields: [],
        group_title: "",
        group_id: "PermitRef",
        className: "permitRef",
        component: RenderPermitRef
    }

    const permitTypeWithoutSpaces = permitType ? permitType.replaceAll(" ", '') : 'noPermitType';

    switch (permitType) {
        case "Hot Works": {
            authorisedSection.fields = [
                ...authorisedSection.fields,
                "client_permission_granted",
                "fire_detection_systems_will_be_affected",
                "suppression_systems_will_be_affected",
                "isolation_comments",
            ]
            documentationSection.fields = [
                ...documentationSection.fields,
                "date_induction_received",
                "equipment_certification_submitted",
            ]
            documentationSection.className = `${documentationSection.className} ${permitTypeWithoutSpaces}Group`;

            const descriptionOfIsolationsSection = {
                fields: [],
                group_title: "",
                group_id: "RenderDescriptionOfIsolations",
                className: "isolations",
                component: RenderDescriptionOfIsolations
            }

            let primaryHazardsSection = {
                fields: [
                    "fire_alarm_can_be_sounded",
                    "surfaces_protected",
                    "vessels_or_lines_protected_and_flammables_free",
                    "area_to_be_wetted_or_fire_blankets_used",
                    "ppe_required",
                    "other_precautions"
                ],
                group_title: "Primary Hazards - Fire and Explosion",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                controlMeasuresSection,
                primaryHazardsSection,
                descriptionOfIsolationsSection,
            ]

        }
            break;
        case "Working At Height": {
            authorisedSection.fields = [
                ...authorisedSection.fields,
            ]

            let primaryHazardsSection = {
                fields: [
                    "qualified",
                    "boards_handrails_ladders",
                    "scaffolding_and_arrest_systems",
                    "scaffolding_inspected_and_tagged",
                    "harness_inspected",
                    "harness_lanyard_certificate_provided",
                    "access_equipment_inspected",
                    "weather_conditions",
                    "ducts_and_outlets",
                    "fume_hazards",
                    "falling_objects_risk",
                    "equipment_tethered",
                    "overhead_services",
                    "edge_protection",
                    "guard_rails",
                    "ppe_required",
                ],
                group_title: "Hazards",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                "signs_and_barriers",
                "signs_and_barriers_in_place",
                "site_specific_rules_communicated",
                "activities"
            ]

            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;

            documentationSection.fields = [
                ...documentationSection.fields,
                "date_induction_received",
            ]

            documentationSection.className = `${documentationSection.className} ${permitTypeWithoutSpaces}Group`;

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                primaryHazardsSection,
            ]

        }
            break;
        case "Limitation of Access": {
            authorisedSection.fields = [
                ...authorisedSection.fields,
            ]
            // Documentation
            documentationSection.fields = [
                ...documentationSection.fields,
                "date_induction_received",
                "escort_required",
                "supervision_required",
            ]

            documentationSection.className = `${documentationSection.className} ${permitTypeWithoutSpaces}Group`;

            // Safe Systems of Work

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                "signs_and_barriers",
                "site_specific_rules_communicated"
            ]

            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;


            // Hazards. Have the following potential hazards been considered:
            let primaryHazardsSection = {
                fields: [
                    "access",
                    "egress",
                    "safe_routes",
                    "additional_information",
                ],
                group_title: "Hazards",
                group_subtitle: "Have the following potential hazards been considered?",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                primaryHazardsSection,
            ]

        }
            break;

        case "Confined Space": {
            authorisedSection.fields = [
                ...authorisedSection.fields,
                "type_of_confined_space_and_specified_risks"
            ]

            authorisedSection.className = `${authorisedSection.className} ${permitTypeWithoutSpaces}Group`;

            // Safe Systems of Work

            safeSystemsOfWorkSection.fields = [
                ...safeSystemsOfWorkSection.fields,
                "worker_competences_checked",
                RenderPTWTrainingCourses,
                RenderRoles,
            ]

            safeSystemsOfWorkSection.className = `${safeSystemsOfWorkSection.className} ${permitTypeWithoutSpaces}Group`;


            // Hazards. Have the following potential hazards been considered:
            let primaryHazardsSection = {
                fields: [
                    "acceptable_access",
                    "isolated_from_pipework",
                    "space_purged",
                    "electrically_and_mechanically_isolated",
                    "temperature_maintainable",
                    "breathing_apparatus_checked",
                    "backup_equipment_checked",
                    "emergency_arrangements_in_place",
                    "atmospheric_testing_required",
                    "respirable_air_assured",
                    "atmospheric_testing_unit_info",
                    "atmospheric_testing_calibration_date",
                    "residual_hazards",
                ],
                group_title: "Hazards",
                group_id: `PrimaryHazards${permitTypeWithoutSpaces}Group`,
                className: `primaryHazards ${permitTypeWithoutSpaces}Group`,
            }

            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
                primaryHazardsSection,
            ]

        }
            break;


        default: {
            layout = [
                permitTypePicker,
                permitRef,
                authorisedSection,
                safeSystemsOfWorkSection,
                documentationSection,
            ]
        }

    }
    return layout;
};
