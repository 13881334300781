import { FieldMetaGroup, FormValues } from "components/Common/Interfaces/Entity.interface";
import { FormItem, FormItemKey, FormItemKeyGroup, GroupedFormItems } from 'interfaces/form.interface';
import DataSource from "devextreme/data/data_source";

const translateMetaType = (type: string) => {
  switch (type) {
    case 'choice': return "dxSelectBox";
    case 'integer': return "dxNumberBox";
    default: return undefined;
  }
}

const extractFormItemProps = (item: FormItemKey, meta: FieldMetaGroup): FormItem => {
  const { key, choices } = item;
  return {
    dataField: meta[key].field_name,
    editorType: translateMetaType(meta[key].type),
    label: {
      text: item.label
    },
    editorOptions: {
      items: meta[key].choices || item.choices || [],
      readOnly: meta[key].read_only,
      ...(meta[key].type === 'choice'
        ? ({
          searchEnabled: true,
          displayExpr: 'display_name',
          valueExpr: 'value',
        })
        : undefined)
    }
  }
}

export const extractFormProps = (item: FormItemKey | FormItemKeyGroup, meta: FieldMetaGroup): FormItem | GroupedFormItems => {
  const fIK = item as FormItemKey;
  const fIKG = item as FormItemKeyGroup;
  if (fIKG.items) {
    const items = fIKG.items.map(item => extractFormItemProps(item, meta))
    return {
      key: fIKG.key,
      caption: fIKG.caption,
      className: fIKG.className,
      items: items
    }
  } else {
    return extractFormItemProps(fIK, meta)
  }
}

export const removeDisabledFieldsBasedOnRecordMeta = (e: any, metadata?: any) => {
  const record_meta = (e?.row?.data?.record_meta || metadata);
  const thisFieldMeta = record_meta ? record_meta[e?.dataField] : undefined;
  let disabled_keys: any[] = [];
  if (e?.row?.data?.record_meta) {
    disabled_keys = thisFieldMeta?.disabled_choices;
  } else if (metadata) {
    disabled_keys = thisFieldMeta?.choices?.filter((x: any) => {
      return x.disabled && x.value !== e.value
    }).map((x: any) => x.value);
  }
  if (e.lookup) {
    e.lookup.allowClearing = true;
  }
  if (record_meta) {
    if (disabled_keys) {
      const newOptions = e.lookup.dataSource.filter((d: any) => {
        const not_disabled = !disabled_keys.includes(d.value);
        return not_disabled
      })
      e.editorOptions.dataSource = new DataSource(newOptions);
      e.editorOptions.dataSource = newOptions;
    }
    // NB it seems as if it isn't necessary to put the editorOptions 'back as they were' for the next cell edit - on each new on editPreparing it appears to get the standard ones
    // if for any reason it seems to become necessary...
    // if (e.row && e.row.data && e.row.data.internal_member == true) {
    //   if (peopleAccessOptionsRef.current.length) {
    //     e.editorOptions.dataSource = peopleAccessOptionsRef.current;
    //   }
  }
}

// function prepareFormMeta(meta: FormItem[]): groupedForm {

// }
export function getOptionFromMetaByValue(dataField: string, formValues: FormValues, meta: FieldMetaGroup | undefined) {
  const thisMeta = meta ? meta[dataField] : undefined;
  const choices = thisMeta?.choices;
  const thisValue = formValues[dataField];
  return choices && thisValue ? choices.find((x) => x.value === thisValue) : undefined;
}