import React, { useRef, useEffect, memo, useState, useMemo, useCallback } from "react";
import { Editing } from "devextreme-react/data-grid";
import { useSelector, useDispatch } from "react-redux";
import 'devextreme/integration/jquery';
import "./AdminDataGrid.scss";

// Own
import { variableGroupingColumnName } from "constants/grid.constants";
import DataGrid, {
  Form,
  RowDragging,
  Column,
  Paging,
  Pager,
  FilterRow,
  MasterDetail,
  RequiredRule,
  Grouping,
  Selection
} from "devextreme-react/data-grid";
import { Item, GroupItem } from 'devextreme-react/form';
import {
  expandDataGridRow, getDatagridRowElementById, getVisibleRows
} from "components/AdminPanel/Helpers/AdminPanel.helper"
import {
  DataGridEvent,
  DataGridDropEvent,
  DataGridEUDvent,
  DataGridEditEvent,
  DataGridUpdateEvent,
  DataGridOnEditPreparing,
  DataGridKey,
} from "interfaces/datagrid.interfaces";
import { APIPrivileges } from "services/Interface/Interface";
import { getColumnProps } from "helpers/DataGrid/DataGridColumn.helper";
import {
  RecordMeta,
  FieldMetaGroup,
} from "components/Common/Interfaces/Entity.interface";
import { getIdDiffBetweenArrayAndDictionary } from "helpers/General/diff";
import {
  ColumnPropGroup,
  ColumnProps,
} from "components/ContractInFocus/Interfaces/DataGridColumn.interface";
import { getPanelWithActiveSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import { FormItem, AdminAction, GroupedFormItems } from "interfaces/form.interface";
import { setDragging } from "store/actions/interface.actions";
import { setNewRecordId } from "components/AdminPanel/Actions/adminPanel.actions";
//import { setAdminPanelGroupingMode } from "components/AdminPanel/Actions/adminPanel.actions";
import { AdminKey } from "components/AdminPanel/Interfaces/Panel";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model"; // HT Note: Maybe worth tracing usage - seemed at a glance to be a lot of repeating code (i.e. similar for each entity type)
import { useSelectionParams } from "./customHooks";
import { IDHelper } from "components/Common/Utils/Key";
// Styles
import "styles/app/common/dev-extreme-overrides.scss";

// function getColumnOptionsInfo(grid: any) {
//   let cols = grid.option("columns");
//   console.log('cols: ', cols);
//   let info = [];
//   for (var i = 0; i < cols.length; i++) {
//     info.push(grid.columnOption(cols[i].dataField));
//   }
//   console.log('cols info: ', info);
//   return cols
// }

function groupByColumn(grid: any, dataField: string) {
  grid.columnOption(dataField, "groupIndex", 0);
}

interface DataSourceControlConfig {
  setGridDataSource: React.Dispatch<any>;

}

interface AdminDataGridProps<T> {
  data: T[];
  meta: FieldMetaGroup;
  putMeta?: FieldMetaGroup;
  recordmeta?: RecordMeta;
  permissions?: APIPrivileges;
  masterView?: boolean;
  detailView?: any;
  masterViewSharedSpace?: any;
  createFormMeta?: (FormItem | GroupedFormItems)[];
  panelId: string;
  editMode?: string;
  dragAndDrop?: boolean;
  dataTestId?: string;
  formOnly?: boolean;
  entityKey?: AdminKey;
  datagridActions?: any;
  repaintChangesOnly?: boolean;
  getDatagridColumns: (meta: FieldMetaGroup) => ColumnPropGroup;
  headerFilterPage?: string;
  columnActions?: AdminAction[];
  passedEditorPreparing?: Function;
  passedEditingStart?: Function;
  onUpdate?: (e: DataGridUpdateEvent) => void;
  onDelete?: (e: DataGridEvent) => void;
  onCreate?: (e: DataGridEvent) => void;
  dataSourceInserts?: boolean;
  onDrop?: (e: DataGridDropEvent) => void;
  onRowCollapsing?: (e: DataGridEvent) => void;
  onRowCollapsed?: (e: DataGridEvent) => void;
  handleOnReorder?: (e: any, callback: () => void) => void;
  onLink?: () => void;
  onRowExpanding?: (e: DataGridEUDvent) => void;
  fetchGroup?: (data: T[]) => void;
  fetchDetail?: (e: DataGridEUDvent) => void;
  getPreFilterExp?: (meta: FieldMetaGroup, headerFilterPage: string) => void;
  toolbarTitle?: string;
}

type GridMode = "insert" | "edit" | "read" | undefined;

const AdminDataDataGrid: React.FC<AdminDataGridProps<any>> = ({
  data = [],
  meta,
  panelId,
  recordmeta,
  putMeta,
  permissions,
  editMode = undefined,
  masterView,
  masterViewSharedSpace,
  detailView,
  createFormMeta,
  dataTestId,
  entityKey,
  formOnly = false,
  datagridActions,
  getDatagridColumns,
  headerFilterPage,
  columnActions = [],
  passedEditorPreparing,
  passedEditingStart,
  onUpdate,
  onDelete,
  onCreate,
  dataSourceInserts,
  onDrop,
  onLink,
  onRowExpanding,
  onRowCollapsing,
  onRowCollapsed,
  handleOnReorder,
  repaintChangesOnly,
  dragAndDrop,
  fetchGroup,
  fetchDetail,
  getPreFilterExp,
  toolbarTitle
}) => {
  // HT Note: This is re-rendering too many times
  const {
    hideDetailViews,
    activateSelectionMode,
    selectionHandleCellPrepared,
    handleSelectionChanged,
    allowSelectionLaunch,
    rowsForPreSelection,
    submitLinksButtonConfig
  } = useSelectionParams(data, panelId);

  const gridRef = useRef<any>(null);
  const [gridMode, setGridMode] = useState<GridMode>("read");
  const selectAdminPanelState = useSelector(
    getPanelWithActiveSelectorFactory(panelId || "")
  );

  function handleCellPrepared(e: any) {
    selectionHandleCellPrepared && selectionHandleCellPrepared(e);
  }

  const masterViewExists = !!masterView;
  const detailViewExists = !!detailView;
  const showDetailViews = useMemo(() => {
    let thisShowDetailView = false;
    if (!masterViewExists || !detailViewExists || hideDetailViews) {
      thisShowDetailView = false
    } else {
      thisShowDetailView = true;
    }
    return thisShowDetailView;
  }, [detailViewExists, masterViewExists, hideDetailViews])

  const getGrid = (): DataGrid => gridRef.current;
  const dispatch = useDispatch();
  const preFilterExpression = getPreFilterExp && headerFilterPage ? getPreFilterExp(meta, headerFilterPage) : null
  const includeCreateFormFilter = ['id', '=', FORM_ROW_ID]
  const fullPreFilterExpression = preFilterExpression ? [preFilterExpression, 'or', includeCreateFormFilter] : null // we only add additional filters if there are already 
  const keyOfRowBeingEdited = useRef(null);
  const createFormId = useRef(IDHelper());
  //const formRef = useRef(null);
  const formRef = useRef<any>(null);

  const [detailShowing, setDetailShowing] = useState(false); // this is here to be used by the 'detail' view if desired to display it conditionally
  // which is useful for releasing memory when the row state is collapsed.  cacheEnabled={false} on the grid seems to have no effect so this
  // fills the gap.  If you use it on a detail view, you'll probably also want to clean up anything in memory attached to refs outside the component,
  // cancel any subscriptions, etc.

  useEffect(() => {
    const preSelectRows = !!rowsForPreSelection && !!gridRef.current && !!gridRef.current.instance
    preSelectRows && gridRef.current.instance.selectRows(rowsForPreSelection, true);
  }, [rowsForPreSelection])
  //filters as we're trying to add back the entry form
  // useEffect(() => {
  //   // ! The below needs to be changed to a onInitialsed or ContentReady event - first time only
  //   const id = setTimeout(() => {
  //     groupRequest && groupRequest(); // Can probably move this to handleOnContentReady in accordance with Dan's note... but should check if setTimeout is still required...
  //     //HT Note think expandRecord this is currently unused (as it relies on shouldExpand being set) 
  //     // but it's like the expandDataGridRow I wrote for use with newRecords... should probably alter that code to use this 
  //     // and update shouldExpand straight afterwards (as I now currently delete the newRecordId)
  //     //expandRecord();
  //   }, 100);
  //   // HT there should be a handler to clean up timeouts
  //   return () => clearTimeout(id)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (selectAdminPanelState.panel.groupingMode) {
      groupByColumn(gridRef.current.instance, variableGroupingColumnName);
    } else {
      gridRef.current?.instance && gridRef.current.instance.clearGrouping();
    }
  }, [selectAdminPanelState.panel.groupingMode]);

  const getRecords = (data: object[]) => {
    try {
      return recordmeta
        ? getIdDiffBetweenArrayAndDictionary(data, recordmeta)
        : [];
    } catch (e) {
      throw new Error(
        `unable to get record diff in datagrid data with record count:${data.length} and with recordmeta:${recordmeta}`
      );
    }
  };

  const localHandleOnReorder = (e: any) => {
    handleOnReorder && handleOnReorder(e, () => {
    });
  }

  const columnsMeta = getDatagridColumns(meta);

  const rowButtons = columnActions.map((action) => ({
    icon: action.icon,
    visible: true,
    onClick: (e: any) => {
      action.onClick && action.onClick(e.row.data);
    },
  }));

  const doesRecordMetaExist = (key: string) => recordmeta && recordmeta[key];

  const getRecordMeta = (key: any) => (recordmeta ? recordmeta[key] : meta);

  const shouldCancel = (key: string, dataField: string) => {
    return doesRecordMetaExist(key) && getRecordMeta(key)[dataField]?.read_only;
  };

  const handleEditingStart = (e: DataGridEditEvent) => {
    // NB THIS ISN'T EVEN CALLED FOR FORMS THAT CREATE NEW ITEMS, ONLY ON EDITING AN EXISTING ROW
    if (e.key) {
      e.cancel = true;
      e.cancel = e.column ? shouldCancel(e.key, e.column.dataField) : false;
      // doesRecordMetaExist(e.key)
      // ? (e.cancel = shouldCancel(e.key, e.column.dataField))
      // : (e.cancel = true);
    }
    keyOfRowBeingEdited.current = e.key;
    passedEditingStart && passedEditingStart(e);
  };

  function setCellValue(newData: any, value: any, currentRowData: any) {
    // leave this here... to be used in column config below when filtering one cell based on another one on forms
    //@ts-ignore
    let column = this;
    // console.log('newData: ', newData);
    // console.log('value: ', value);
    // console.log('currentRowData: ', currentRowData);
    // console.log('column: ', column);
    column.defaultSetCellValue(newData, value);
  }
  //newData, value, currentRowData

  // this function determines if there are any records which require detail request
  const groupRequest = () => {
    if (fetchGroup) {
      const visibleRows = getVisibleRows(gridRef.current);
      const rows: any[] = visibleRows.length ? visibleRows : data.slice(0, 21);
      if (rows && rows.length) {
        const requiredRecords = getRecords(rows);
        requiredRecords.length && fetchGroup && fetchGroup(requiredRecords);
      }
    }
  };

  const handleRowExpanding = (e: DataGridEvent) => {
    const visibleRows = getVisibleRows(gridRef.current);
    const row = visibleRows.filter(r => r.id === e.key)[0]; //yes this assumes the key will always be an attribute called id... if it isn't move to passing this as callback
    if (row) { // we also need to check for the existence of the row as when expanding 'group' rows (i.e. 'other') it won't exist...
      if (fetchDetail) {
        fetchDetail(e)
      } else if (fetchGroup) {
        fetchGroup([row]);
      }
      setDetailShowing(true);
      onRowExpanding && onRowExpanding(e);
    }
  };

  const handleRowCollapsing = useCallback((e: DataGridEvent) => {
    // NB be very careful what you put in here.  It can get called various times and can even create an issue where the component gets invoked
    // one more time on expanding for every time the component is collapsed.  Calling setDetailShowing in here did that, for example.
    // I think the best way of avoiding this is to ensure that any
    // functions called inside here call e.cancel first, if they are then going to do anything to the state.
    onRowCollapsing && onRowCollapsing(e);
  }, [onRowCollapsing]);

  const handleRowCollapsed = useCallback((e: DataGridEvent) => {
    setDetailShowing(false);
    onRowCollapsed && onRowCollapsed(e);
  }, [onRowCollapsed, setDetailShowing]);

  const dataGridActionsWrapper = (action: Function) => {
    const wrappedAction = (e: DataGridEvent) => {
      action(e);
      setTimeout(groupRequest, 500); //backed up by fetching record on row expansion if its not already there...
    }
    return wrappedAction;
  }

  const toolbarPrep = (e: any) => {

    if (datagridActions) {
      Object.keys(datagridActions).forEach((key) => {
        e.toolbarOptions.items.unshift({
          location: datagridActions[key].location || "before",
          widget: "dxButton",
          cssClass: key === headerFilterPage ? 'selected' : null,
          options: {
            text: datagridActions[key].caption,
            //onClick: datagridActions[key].onClick,
            onClick: dataGridActionsWrapper(datagridActions[key].onClick)
          },
        });
      });
    }

    toolbarTitle && e.toolbarOptions.items.unshift({
      location: "center",
      text: toolbarTitle,
      cssClass: "toolbarTitle"
    })

    allowSelectionLaunch && onLink &&
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "link",
          onClick: onLink,
        },
      });

    submitLinksButtonConfig &&
      e.toolbarOptions.items.unshift(submitLinksButtonConfig);
  };

  const handleEditPreparing = (e: DataGridOnEditPreparing) => {
    passedEditorPreparing && passedEditorPreparing(e);
    const preparationWork = () => {
      if (e.editorName === "dxSelectBox" || e.editorName === "dxTagBox") {
        e.editorOptions.onOpened = (e: any) => {
          e.component._popup.option("width", 320);
        };
        //e.editorOptions.showClearButton=true;
      }
    }
    // setTimeout(preparationWork, 200);
    preparationWork();
  };

  const handleKeyDown = (e: DataGridKey) => {
    if (e.event?.keyCode === 27) {
      //escape
      gridMode !== "read" && setGridModeRead();
    }
  };

  const handleInserted = (e: DataGridEUDvent): void => {
    setGridModeRead();
    // HT NOTE: this doesn't seem to be called!! Instead the grid is re-rendered
  };

  const handleUpdated = () => {
    // HT NOTE: this doesn't seem to be called!! Instead the grid is re-rendered
    setGridModeRead();
  }

  const setGridModeRead = () => setGridMode("read");

  const handleRowInserting = (e: any) => {
    // ? DevExtreme creates a row but also we call the api which creates a row in redux
    // ? So we need to cancel DevExtreme creation
    // ? Cancel the new row, which would be inserted as a duplicate by Dev Extreme
    e.component?.cancelEditData();
    e.cancel = true;
    // HT NOTE: this doesn't seem to be called!! Instead the grid is re-rendered
    // ? Create record callback
    onCreate && onCreate(e);
  };

  const handleUpdating = (e: DataGridUpdateEvent) => onUpdate && onUpdate(e);

  const handleDelete = (e: any) => !!onDelete && onDelete(e);

  const onDragStart = (): void => {
    dispatch(setDragging(entityKey));
  };

  const onDragEnd = (e: any): void => {
    dispatch(setDragging(undefined));
  };

  const isColumnVisible = (columnMeta: Partial<ColumnProps>): boolean => {
    return (
      (columnMeta.gridModeEdit === undefined &&
        columnMeta.gridModeInsert === undefined &&
        columnMeta.gridModeRead === undefined) ||
      (columnMeta.gridModeInsert !== false && gridMode === "insert") ||
      (columnMeta.gridModeEdit !== false && gridMode === "edit") ||
      (columnMeta.gridModeRead !== false && gridMode === "read")
    );
  };

  const handleActionNewRecord = () => {
    const row = getDatagridRowElementById(gridRef.current, FORM_ROW_ID)
    if (row) {
      gridRef.current.instance.expandRow(FORM_ROW_ID);
      row[0].setAttribute("style", "display:none;");
    }
  };

  const handleContentReady = () => {

    gridRef.current.instance.filter(fullPreFilterExpression); //important to filter even if the expression is null - that clears the filter
    //gridRef.current.instance.filter(gridRef.current.instance.getCombinedFilter())
    if (selectAdminPanelState.panel.newRecordId) {
      expandDataGridRow(gridRef.current, selectAdminPanelState.panel.newRecordId);
      panelId && dispatch(setNewRecordId(panelId, null));
    }
    //call dispatch action here to set to newRecordId to null
    handleActionNewRecord();
  }

  const handleOnInitialized = () => {
    setTimeout(() => {
      const shouldExpandRecord =
        !!selectAdminPanelState?.panel.expandRecordId && !!gridRef.current;
      if (shouldExpandRecord) {
        gridRef.current.instance.navigateToRow(
          selectAdminPanelState.panel.expandRecordId
        );
        gridRef.current.instance.expandRow(selectAdminPanelState.panel.expandRecordId);
      }
    }, 500);
  }

  const DetailView = detailView;
  //DetailView.whyDidYouRender = true;

  // useEffect(() => {
  //   let formIdSelector = '#' + createFormId.current;
  //   let form = $(formIdSelector).dxForm("instance");
  //   console.log('here...: ', form);
  //   if (formRef && formRef.current) {
  //     console.log('...: ', formRef.current.instance.element());
  //     formRef.current.instance.on(
  //       "fieldDataChanged", (e: any) => {
  //         console.log('component: ', e?.component);
  //         console.log('dataField: ', e?.dataField);
  //         console.log('value: ', e?.value);
  //         console.log('items: ', formRef.current.instance.itemOption(e.dataField));
  //       }
  //     )
  //   }

  // }, [formRef.current?.instance])
  const getDetailComponent = useCallback((e) => {
    return <DetailView
      panelId={panelId}
      {...e}
      formOnly={formOnly}
      meta={meta}
      putMeta={putMeta}
      masterViewSharedSpace={masterViewSharedSpace}
      rowKey={e.data.key}
      permissions={permissions}
      detailShowing={detailShowing}
    />
  }, [permissions, panelId, formOnly, meta, masterViewSharedSpace, putMeta, detailShowing]);

  //}, [panelId, formOnly, recordmeta, masterViewSharedSpace, permissions, detailShowing])

  return (
    <>
      <DataGrid
        //paging={{ enabled: true }}
        // repaintChangesOnly={repaintChangesOnly}
        // cacheEnabled={false} NOTE this appears to have no effect whatever regardless of value, at least in this version of the lib
        className="admin-data-grid compact-grid"
        dataSource={data}
        ref={gridRef}
        height="100%"
        keyExpr="id"
        id={panelId}
        showColumnHeaders={true}
        //columnAutoWidth={false}
        loadPanel={{ enabled: false }}
        data-test-id={dataTestId}
        showBorders={true}
        onEditingStart={handleEditingStart}
        onRowRemoving={typeof onDelete === "function" ? handleDelete : undefined}
        onRowCollapsed={handleRowCollapsed}
        onRowCollapsing={handleRowCollapsing}
        onKeyDown={handleKeyDown}
        onRowUpdating={handleUpdating}
        onRowUpdated={handleUpdated}
        onFocusedRowChanged={() => setGridModeRead()} // ! Need to find the cancel hook - closest for now.
        onRowInserting={typeof onCreate === "function" ? handleRowInserting : undefined} // if onCreate isn't a function, then it's assumed that the grid merely calls the default store insert
        onRowInserted={handleInserted}
        onCellPrepared={handleCellPrepared}
        onEditorPreparing={handleEditPreparing}
        onToolbarPreparing={toolbarPrep}
        onRowExpanding={handleRowExpanding}
        dateSerializationFormat="yyyy-MM-dd"
        onContentReady={handleContentReady}
        onInitialized={handleOnInitialized}
        rowDragging={
          {
            allowReordering: !!handleOnReorder,
            onReorder: localHandleOnReorder
          }
        }
        filterRow={{ visible: true, showAllText: '' }}
        // this is used to catch searches and and paging actions the delay ensure that
        // the function obtains the correct page rather than the previous page
        // timeout can be set to 50 - 100 is just a precaution
        onOptionChanged={(e) => {
          if (e.name === "paging" || e.name === "columns") {
            setTimeout(groupRequest, 100); // 50
          }
        }}
        onSelectionChanged={handleSelectionChanged}
      //NB we're not using selectedRowKeys when in selection mode 
      // - it seems to cause a lot of rerenders and manually calling selectRows in UseEffect does the job...
      >
        <Grouping autoExpandAll="true" />
        <Paging defaultPageSize={15} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[15, 30, 50]}
          showInfo={true}
        />

        <FilterRow visible={true} />

        {dragAndDrop && (
          <RowDragging
            data={data.length}
            group={entityKey}
            onAdd={onDrop}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          // allowDropInsideItem={true}
          />
        )}

        <Editing
          mode={editMode}
          //allowUpdating={true} //this just controls whether you devextreme drops it's editing symbol in there and opens up it's own editing form 
          // but we use our own detail view for editing instead
          allowDeleting={permissions?.POST && !!onDelete}
          allowAdding={permissions?.POST && (!!onCreate || dataSourceInserts)}
          allowUpdating={permissions?.PUT && editMode}
        >
          {createFormMeta && (

            <Form elementAttr={{ id: createFormId.current }}
              onInitialized={
                (e: any) => {
                  formRef.current = e.component // call .instance() on this for the actual form widget
                  // as per https://supportcenter.devexpress.com/ticket/details/t377032/dxdatagrid-the-onfielddatachanged-event-does-not-fire-if-the-form-edit-grid-form-is-used
                  // it appears 'fieldDataChanged' isn't fired when the datagrid is controlling the form (e.g. when a form is 'passed' to the datagrid as config -
                  // they just use the form to 'align' things rather than to manipulate data.  So a different approach is needed here - we use datagrid apis including
                  // on editor preparing - e.g. getPeopleFormFieldsAdjuster)
                }
              }
            >

              {createFormMeta.map(
                item => {
                  const fI = item as FormItem;
                  const fIG = item as GroupedFormItems;
                  if (fIG.items) {
                    return <GroupItem
                      //colSpan={2}
                      key={fIG.key}
                      caption={fIG.caption}
                      cssClass={`apprise-form-group-item ${fIG.className}`}
                      {...fI}
                    >
                      {fIG.items.map(
                        i => <Item
                          //colSpan={1}
                          key={i.dataField}
                          {...i}
                        >
                          {meta[i.dataField]?.required ? <RequiredRule /> : null}
                        </Item>
                      )}
                    </GroupItem>
                  } else {
                    return <Item
                      key={fI.dataField}
                      {...fI}
                    >
                      {meta[fI.dataField].required ? <RequiredRule /> : null}
                    </Item>
                  }

                }
              )}
            </Form>

          )}
        </Editing>

        {activateSelectionMode && <Selection mode="multiple" showCheckBoxesMode="always" />}

        {Object.keys(columnsMeta).map((key: any) => {
          const columnPropsFromMeta = getColumnProps(key, meta, false);

          const sortByLookup = function (data: any) {
            //@ts-ignore //this is column config, passed into hook
            var value = this.calculateCellValue(data);
            //@ts-ignore  //this is column config, passed into hook
            return this.lookup.calculateCellValue(value);
          }
          const columnMeta = columnsMeta[key];
          const caption = columnMeta.caption || columnPropsFromMeta.caption;
          return (
            columnMeta && (

              <Column
                key={key}
                {...columnPropsFromMeta}
                caption={caption}
                width={columnMeta.width}
                editorOptions={columnMeta.editorOptions}
                visible={columnPropsFromMeta.visible !== undefined ? columnPropsFromMeta.visible : isColumnVisible(columnMeta)}
                calculateSortValue={columnPropsFromMeta.lookup?.displayExpr ? sortByLookup : undefined}
                //visible={true}
                editCellComponent={columnMeta.editCellComponent}
                cellRender={
                  columnMeta.cellRender
                    ? ({ value }) =>
                      columnMeta.cellRender({
                        value,
                        choices: columnPropsFromMeta.lookup?.dataSource,
                      })
                    : undefined
                }
                // pass in the info needed to automate this... setCellValue forces editor refreshes when the value changes on that cell...
                // setCellValue={
                //   columnPropsFromMeta.dataField === "organisation" ? setCellValue : null
                // }
                setCellValue={setCellValue}
              >
                {meta[key]?.required ? <RequiredRule /> : null}

              </Column>
            )
          );
        })}

        <Column dataField={variableGroupingColumnName} visible={false} />

        {columnActions.length && (
          <Column type="buttons" dataField="actions" buttons={rowButtons} />
        )}

        {showDetailViews && (
          <MasterDetail
            enabled={true}
            component={getDetailComponent}
          />
        )}
      </DataGrid>
    </>

  );

};

// AdminDataDataGrid.whyDidYouRender = true;

export default memo(AdminDataDataGrid);



