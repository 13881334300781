import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import { AutoInputProps } from "components/Common/Components/AutoInput/AutoInput";

import '../../../../components/Common/Components/AutoInput/AutoInputStyles.scss';
import { FieldFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";


export interface OptionObject {
    [index: string]: string
}

export type Option = OptionObject | string;

export interface SearchParams {
    [index: string]: string
}

export type StandardSearchParams = { input: string }

interface JAAutoCompleteLookupProps extends AutoInputProps {
    inputWrapperClass?: string;
    disabled?: boolean;
    onBlur: any;
    inputRef?: any;
}

export default function JAAutoComplete({
    dataField,
    onChangeFormValues,
    fieldConfig,
    inputWrapperClass,
    zIndex,
    fieldMeta,
    disabled,
    formValuesRef,
    currentFocus,
    onBlur,
    inputRef
}: JAAutoCompleteLookupProps) {
    const [value, setValue] = React.useState<string | undefined>(formValuesRef.current[dataField] as string || undefined);
    const [inputValue, setInputValue] = React.useState<string | undefined>(formValuesRef.current[dataField] as string);
    const [options, setOptions] = React.useState<Option[]>(fieldConfig.initialOptions || []);
    const loaded = React.useRef(false);
    const fetchActive = React.useRef(false);
    const thisLabel = fieldConfig.skipLabel ? undefined : fieldConfig.label ? fieldConfig.label : fieldMeta?.label;
    const required = fieldConfig.forceRequired ? fieldConfig.forceRequired : fieldMeta?.required;
    const getOptionValue = React.useCallback((option: Option | undefined) => {
        if (option === null) {
            return undefined
        }
        return fieldConfig.getOptionValue ? fieldConfig?.getOptionValue(option) : undefined;
    }, [fieldConfig]);
    const finalStyles = {
        width: '100%',
        zIndex: zIndex || 1200,
        height: '100%',
        ...fieldConfig.style
    }

    if (typeof window !== 'undefined' && !loaded.current) {
        // use this for any initial conditions that must be set first
        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle(
                (
                    params: SearchParams,
                    callback: (results?: Option[]) => void,
                    fieldConfig: FieldFormConfig
                ) => {
                    fieldConfig.fetchOptions && fieldConfig.fetchOptions(
                        params,
                        callback,
                        fieldConfig.getSuggestions,
                    );
                },
                200,
            ),
        [],
    );

    const thisOnBlur = React.useCallback(() => {
        fetchActive.current = false;
        return onBlur(formValuesRef.current, onChangeFormValues);
    }, [onBlur, formValuesRef, onChangeFormValues])

    const updateOptions = React.useCallback(() => {
        // if (inputValue === '') {
        //     setOptions(value ? [value] : []);
        //     return undefined;
        // }
        let minCh = fieldConfig.minCharactersForLookup;
        if (minCh) {
            let trimmedInput = inputValue ? inputValue.trim() : '';
            if (trimmedInput.length < minCh) {
                setOptions(value ? [value] : []);
                return undefined;
            }
        }
        const inputParams = fieldConfig.additionalAutoCompleteLookupParams ? { ...fieldConfig.additionalAutoCompleteLookupParams, input: inputValue as string } : { input: inputValue as string }
        fetchActive && fetch(inputParams, (results?: Option[]) => {
            let newOptions: Option[] = [];
            if (value) {
                newOptions = [value];
            }
            if (results) {
                newOptions = [...newOptions, ...results];
            }
            setOptions(newOptions);
        }, fieldConfig);
    }, [fieldConfig, fetch, inputValue, value])

    React.useEffect(() => {
        updateOptions();
    }, [updateOptions]);

    return (
        <FormControl className={inputWrapperClass} required={required} hidden={fieldConfig.hidden} style={{
            // put any defaults in here
            ...finalStyles
        }}>
            {
                !fieldConfig.skipLabel &&
                <InputLabel shrink>
                    {thisLabel}
                </InputLabel>
            }
            <Autocomplete
                id={`autoField-${dataField}`}
                className="autocomplete"
                getOptionLabel={fieldConfig.getOptionLabel}
                filterOptions={(x: any) => x}
                options={options}
                disabled={disabled}
                autoComplete
                freeSolo={fieldConfig.freeSolo}
                onFocus={() => {
                    fetchActive.current = true;
                    currentFocus.current = dataField;
                    updateOptions();
                }}
                includeInputInList
                filterSelectedOptions
                //defaultValue={newValue|undefined}
                value={value}
                onChange={(event: any, newOption: Option | null, reason: string) => {
                    setOptions(newOption ? [newOption, ...options] : options);
                    setValue(getOptionValue(newOption || undefined));
                    if (fieldConfig.onChangeOption) {
                        fieldConfig.onChangeOption(newOption, onChangeFormValues, reason);
                    }
                    onChangeFormValues({ [dataField]: getOptionValue(newOption || undefined) })
                }}
                onInputChange={(event: any, newInputValue: string) => {
                    setInputValue(newInputValue);
                    onChangeFormValues({ [dataField]: newInputValue })
                }}
                // onBlur={() => {
                //     fieldConfig.onBlur && fieldConfig.onBlur(formValuesRef.current, onChangeFormValues)
                // }}
                onBlur={thisOnBlur}
                renderInput={(params: any) => (
                    <TextField {...params}
                        ref={inputRef}
                        //label="Supplier Name" 
                        fullWidth
                    />
                )}
            // renderOption={(option: Option) => (
            //             <li>
            //             {option.label}
            //             </li>
            //     )
            // }
            />
        </FormControl>
    );
}
