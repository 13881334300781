/* eslint-disable */
import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';
import DataSource from "devextreme/data/data_source";
import { isEqual } from "lodash";

import InvestigationOrSignOffForm from 'components/ContractInFocus/HAS/InvestigationOrSignOffForm';
import InitialDataForm from 'components/ContractInFocus/HAS/HASAccidentInitialInfo';
import { FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { APIPrivileges, ParseAPIResponse } from "services/Interface/Interface";
import { IHASAccident, IHASRiddor } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import AccidentWitnessStatements from "components/ContractInFocus/HAS/HASWitnessStatements";
import { TabbedPanel } from "components/Common/Components/TabPanel";
import AccidentImages from "components/ContractInFocus/HAS/HASIncidentImages";
import AccidentDocuments from "components/ContractInFocus/HAS/HASIncidentDocument";
import { getInitiallySelectedTabIndex } from "components/ContractInFocus/HAS/HASIncidentHelpers";
import { getAccidentRelevantUsers, simpleGetRiddor } from "components/AdminPanel/HAS/HASIncidentServices";
import { MentionablePerson } from "components/Common/Components/FormFieldComments/FormFieldComments";
import { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { HASAccidentFormSettingControls } from "components/ContractInFocus/HAS/HASFormInfoSettingControls";
import commonAPIServices from 'components/ContractInFocus/Services/commonAPI.services';
import { APIResult } from 'components/Common/Interfaces/Entity.interface';

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"

// Styles
import SettingsIcon from '@material-ui/icons/Settings';
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';
import RiddorForm from 'components/ContractInFocus/HAS/RiddorForm';

export function getTabBarButtonsComponent({ onClick, hint }: { onClick: () => void, hint?: string }) {
    return <div className='incidentDetailSettingsAction'>
        <MatIconButton
            hint={hint}
            onClick={onClick}
        >
            <SettingsIcon className='quick-links__icon' />
        </MatIconButton>
    </div>
}

export interface HASIncidentDetailViewProps {
    rowKey: string | number;
    permissions?: APIPrivileges;
    masterViewSharedSpace: React.MutableRefObject<any>;
    onClose?: () => void;
    gridDataSource?: DataSource;
    tableLevelSharedSpace: React.MutableRefObject<any>;
    setFullScreenForm?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    disableDxNavKeys?: React.MutableRefObject<boolean>;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    initiallySelectedTab?: React.MutableRefObject<string | undefined>;
    initiallySelectedComment?: React.MutableRefObject<string | undefined>;
    baseEndpoint: string;
}

interface HASAccidentDetailViewProps extends HASIncidentDetailViewProps {
    data: IHASAccident;
}

function HASAccidentDetailView({
    data: data,
    permissions,
    onClose,
    masterViewSharedSpace,
    rowKey,
    gridDataSource,
    tableLevelSharedSpace,
    setFullScreenForm,
    disableDxNavKeys,
    initiallySelectedDataField,
    initiallySelectedTab,
    initiallySelectedComment,
    baseEndpoint
}: HASAccidentDetailViewProps) {

    if (!masterViewSharedSpace.current[rowKey]) {
        masterViewSharedSpace.current[rowKey] = {}; //important to ONLY set this to an empty object when not yet defined
    }
    // console.log('HASAccidentDetail');
    const [signalClearFieldInfoInFocus, setSignalCLearFieldInfoInFocus] = useReducer((x) => x + 1, 0);
    const rowLevelSharedSpace = masterViewSharedSpace.current[rowKey];
    const [formSettingsInFocus, setFormSettingsInFocus] = useState<boolean>(rowLevelSharedSpace.formSettingsInFocus);
    const [signalSaveChildren, setSignalSaveChildren] = useReducer((x) => x + 1, 0); // it's important that this starts at 0, as we don't need to save on opening
    const [signalCancelChildren, setSignalCancelChildren] = useReducer((x) => x + 1, 0); // it's important that this starts at 0, as we don't need to save on opening
    const [detailMeta, setDetailMeta] = useState<FieldMetaGroup | undefined>();
    const TabExtraButtons = useCallback(() => {
        return getTabBarButtonsComponent({
            onClick: () => {
                //setFieldInfoInFocus(undefined);
                setSignalCLearFieldInfoInFocus();
                setFormSettingsInFocus(true);
                rowLevelSharedSpace.formSettingsInFocus = true;
            }
        });
    }, []);

    const updateDetailMeta = useCallback(() => {
        baseEndpoint && commonAPIServices.getPreFlightInfo(baseEndpoint, data.id).then(response => {
            if (!isEqual(detailMeta, response.meta)) {
                setDetailMeta(response.meta)
            };
        }).catch(
            (error) => { console.log(error) }
        )
    }, [baseEndpoint]);

    useEffect(() => {
        updateDetailMeta();
    }, []);

    const accidentMainFormValuesRef = useRef<any>({});

    const FormSettingsComponent = useCallback(() => {
        return <>
            {detailMeta && <HASAccidentFormSettingControls
                data={data}
                parentFormRef={accidentMainFormValuesRef}
                meta={detailMeta}
                tableLevelSharedSpace={tableLevelSharedSpace}
                gridDataSource={gridDataSource}
                recordType="accident"
            />}
        </>
    }, [data, accidentMainFormValuesRef, detailMeta, tableLevelSharedSpace, gridDataSource]);

    const updateRowValue = useCallback((newValues: any) => {
        commonUpdateGridRow(
            {
                dataSource: gridDataSource,
                key: data.id,
                changes: newValues
            })
    }, [gridDataSource, data]);

    const incidentMentionablePersons = useRef<MentionablePerson[]>(); // use a ref for this as by the time it is used, it will be set - no need to create rerender

    useEffect(() => {
        getAccidentRelevantUsers(data.id).then((response) => {
            incidentMentionablePersons.current = response.data.data
        }
        )
    }, [])

    const newNamespaces = useRef<any>({
        "incidentMainForm": {},
        "riddorForm": {},
    });
    if (!rowLevelSharedSpace.namespaces) { // this conditional is important as it prevents the rowLevelSharedSpace.namespaces from being completely reset on collapse
        // e.g this component appears to be completely rewritten by dx on row collapse, and at that point it can only get old data from the existing grid 'source' 
        // so for modified data to survive it needs to be hooked into something 'outside'.  We use the masterViewSharedSpace as the 'anchor'.
        rowLevelSharedSpace.namespaces = newNamespaces;
    }
    const accidentFormSharedSpace = rowLevelSharedSpace.namespaces.current["incidentMainForm"];
    const riddorFormSharedSpace = rowLevelSharedSpace.namespaces.current["riddorForm"];
    const [thisRecord, setThisRecord] = useState<any>(data);
    const riddorFormValuesRef = useRef<any>({});
    const [fullInvestigationMode, setFullInvestigationMode] = useState(thisRecord?.full_investigation_mode);
    const [riddorRequired, setRiddorRequired] = useState(thisRecord?.riddor_required);
    const [riddor, setRiddor] = useState<ParseAPIResponse<IHASRiddor[]>>();
    const [refreshRiddor, signalRefreshRiddor] = useReducer((x) => x + 1, 0);

    const getTabs = useCallback(() => {
        const theseTabs = ['Initial Data', `${fullInvestigationMode ? 'Investigation Form' : 'Sign Off Form'}`, "Images", "Documents"];
        if (riddor) {
            theseTabs.splice(2, 0, "Riddor");
        }
        return theseTabs;
    }, [fullInvestigationMode, riddor]);

    useEffect(() => {
        if (riddorRequired) {
            simpleGetRiddor({ accidentId: data.id }).then(
                (response) => {
                    setRiddor(response);
                    if (response.data && response.data[0]) {
                        riddorFormValuesRef.current = response.data[0]; // necessary - otherwise the accident based 
                        // riddor fields won't show as changed in the riddor tab.  This does mean saved changes to 
                        // another accident form will overwrite any unsaved changes on the riddor tab...
                    }
                }
            );
        }
    }, [riddorRequired, refreshRiddor])


    const tabs = getTabs();
    const initiallySelectedTabIndex = getInitiallySelectedTabIndex(tabs, initiallySelectedTab?.current);
    const [index, setIndex] = useState(rowLevelSharedSpace["tabIndex"] || initiallySelectedTabIndex || 0);
    useEffect(() => {
        initiallySelectedTabIndex && setIndex(initiallySelectedTabIndex);
    }, [initiallySelectedTabIndex])
    const handleChangeTab = (index: number) => {
        //setIndexChangeRequest(index);
        setIndex(index);
        rowLevelSharedSpace["tabIndex"] = index;
    };

    const handleCancelMain = useCallback(() => {
        const record = { ...thisRecord };
        accidentFormSharedSpace.data = false;
        accidentMainFormValuesRef.current = record;
        setThisRecord(record); // this effectively forces a rerender
        setSignalCancelChildren();
    }, []);

    const handleRowSave = useCallback((recordId?: string, callback?: any) => {
        setSignalSaveChildren();
        setTimeout(() => callback && callback(), [500]); // This allows any child forms to save with the modified values before the callback re-renders
        // the form with the data from the freshly loaded list.
        // This is a bit hackish but it keeps it simple - no need for the parent to
        // know what the children are (e.g. to use a forkjoin) - and we'd want to call the 'callback' regardless of result anyway - so a simple 'headstart' should be 
        // fine and it shouldn't be mission critical anyway
    }, []);

    rowLevelSharedSpace.handleSave = handleRowSave;

    const handleRowChangeCancel = useCallback((recordId?: string | number, callback?: any) => {
        // function signature is kept common...
        handleCancelMain();
        callback && callback();
    }, []);

    rowLevelSharedSpace.handleCancel = handleRowChangeCancel;

    const handleFormSettingsClose = useCallback(() => {
        rowLevelSharedSpace.formSettingsInFocus = false;
        setFormSettingsInFocus(false);
    }, [])

    const RenderWitnessStatements = useCallback(() => {
        return <>
            <AccidentWitnessStatements
                data={thisRecord}
            />
            <br />
            <br />
        </>
    }, []);

    // const handleDocRaptorDownload = () => {
    //     createPDFDocRaptor({ fileName: 'testFile', baseUrl: 'https://be8d-82-17-142-33.eu.ngrok.io' })
    // }

    // NB we pass each FormSettingsComponent into each component with open state and handle close, rather than wrapping each component, 
    // as each component may have special logic about where and when to show it (e.g. certain components hide it when 'printable' is true)

    const onSaveCallBack = useCallback((recordId: string | number, response: APIResult<IHASAccident>) => {
        gridDataSource && commonUpdateGridRow(
            {
                dataSource: gridDataSource,
                key: recordId,
                changes: response.data
            });
        setDetailMeta(response.metadata.actions?.PUT);
        signalRefreshRiddor();
    }, []);

    return <div>

        {data && detailMeta && <TabbedPanel
            pageIndex={index}
            showTabs={data?.id.toString() !== "0"}
            onChangeTab={handleChangeTab}
            tabs={tabs}
            ExtraButtons={TabExtraButtons}
        >
            <InitialDataForm
                data={data}
                rowKey={rowKey}
                masterViewSharedSpace={masterViewSharedSpace}
                signalCancel={signalCancelChildren}
                signalSave={signalSaveChildren}
                meta={detailMeta}
                permissions={permissions}
                handleFormSettingsClose={handleFormSettingsClose}
                formSettingsInFocus={formSettingsInFocus}
                signalClearFieldInfoInFocus={signalClearFieldInfoInFocus}
                formValuesRef={accidentMainFormValuesRef}
                setFullScreenForm={setFullScreenForm}
                disableDxNavKeys={disableDxNavKeys}
                initiallySelectedDataField={initiallySelectedDataField}
                containing_tab={tabs[0]}
                incidentMentionablePersons={incidentMentionablePersons}
                initiallySelectedComment={initiallySelectedComment}
                updateRowValue={updateRowValue}
                incidentFormSharedSpace={accidentFormSharedSpace}
                FormSettingsComponent={FormSettingsComponent}
                gridDataSource={gridDataSource}
                onSaveCallBack={onSaveCallBack}
            />
            <InvestigationOrSignOffForm
                data={data}
                rowKey={rowKey}
                masterViewSharedSpace={masterViewSharedSpace}
                signalCancel={signalCancelChildren}
                signalSave={signalSaveChildren}
                meta={detailMeta}
                permissions={permissions}
                handleFormSettingsClose={handleFormSettingsClose}
                formSettingsInFocus={formSettingsInFocus}
                signalClearFieldInfoInFocus={signalClearFieldInfoInFocus}
                formValuesRef={accidentMainFormValuesRef}
                setFullScreenForm={setFullScreenForm}
                disableDxNavKeys={disableDxNavKeys}
                initiallySelectedDataField={initiallySelectedDataField}
                containing_tab={tabs[1]}
                incidentMentionablePersons={incidentMentionablePersons}
                initiallySelectedComment={initiallySelectedComment}
                updateRowValue={updateRowValue}
                incidentFormSharedSpace={accidentFormSharedSpace}
                FormSettingsComponent={FormSettingsComponent}
                gridDataSource={gridDataSource}
                onSaveCallBack={onSaveCallBack}
            />
            {riddor?.data && riddor?.data.length ? <div className='riddor'>
                <RiddorForm
                    data={riddor.data[0] as IHASRiddor}
                    rowKey={rowKey}
                    masterViewSharedSpace={masterViewSharedSpace}
                    signalCancel={signalCancelChildren}
                    signalSave={signalSaveChildren}
                    meta={riddor.putMeta}
                    permissions={riddor.permissions}
                    formSettingsInFocus={formSettingsInFocus}
                    handleFormSettingsClose={handleFormSettingsClose}
                    signalClearFieldInfoInFocus={signalClearFieldInfoInFocus}
                    formValuesRef={riddorFormValuesRef}
                    setFullScreenForm={setFullScreenForm}
                    disableDxNavKeys={disableDxNavKeys}
                    containing_tab={tabs[2]}
                    initiallySelectedDataField={initiallySelectedDataField}
                    initiallySelectedComment={initiallySelectedComment}
                    incidentMentionablePersons={incidentMentionablePersons}
                    updateRowValue={updateRowValue}
                    incidentFormSharedSpace={riddorFormSharedSpace}
                    accident={data}
                    FormSettingsComponent={FormSettingsComponent}
                    gridDataSource={gridDataSource}
                />
            </div> : undefined}
            <AccidentImages
                data={data}
                imageParentType="accident"
                FormSettingsComponent={FormSettingsComponent}
                formSettingsInFocus={formSettingsInFocus}
                handleFormSettingsClose={handleFormSettingsClose}
            />
            <AccidentDocuments
                data={thisRecord}
                parentType="accident"
                AdditionalComponent={RenderWitnessStatements}
                docsTitle="Other Accident Documents"
                FormSettingsComponent={FormSettingsComponent}
                formSettingsInFocus={formSettingsInFocus}
                handleFormSettingsClose={handleFormSettingsClose}
            />
        </TabbedPanel>}
    </div>
}

// HASAccidentDetailView.whyDidYouRender = true;

export default React.memo(HASAccidentDetailView);