import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { IntersectionOptions } from 'react-intersection-observer';
import { Paper } from "@material-ui/core";

// Own
import { AutoInput } from 'components/Common/Components/AutoInput/AutoInput';

import { FieldsFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { FieldMetaGroup, Dictionary, Primitive, FieldGroup } from "components/Common/Interfaces/Entity.interface";
import InViewWrapper from 'components/Common/Components/InViewWrapper/InViewWrapper';
import { formValueConsideredMissing } from "store/Common/Helpers/commonHelpers";
import { InViewProgressTracker } from "components/Common/Components/InViewWrapper/InViewWrapper";

// Styles
import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss";
import { isEqual } from 'lodash';

export interface FormValues {
    [field: string]: Primitive; //represents field: actual value
}

interface RenderComponentInterface {
    Component: React.FC<any>;
    index: number;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    key: string;
    skipInViewWrapper?: Boolean;
    className?: string;
}

interface RunFieldValidatorProps {
    formValuesRef: React.MutableRefObject<FormValues>,
    previousValuesRef: React.MutableRefObject<FormValues | undefined>,
    formErrors?: Dictionary<Dictionary<string | undefined>>;
    setFormErrors?: React.Dispatch<Dictionary<Dictionary<string | undefined>>>;
    fieldConfigs: FieldsFormConfig;
    metaForForm: FieldMetaGroup;
}

const runFieldValidators = (
    {
        formValuesRef,
        previousValuesRef,
        formErrors,
        setFormErrors,
        fieldConfigs,
        metaForForm,
    }: RunFieldValidatorProps) => {
    // NB when this function is used it should be the ONLY function to alter formErrors (i.e. to call setFormErrors)
    // because otherwise an infinite regression is possible when it looks to compare the existing errors with 
    // the ones it would set now (i.e. if they're changed afterwards by some other function, every time it runs).  
    // However, as an extra guard rail, and also so that formErrors from the B/E that might be set 
    // will persist until the field values are changed, this function will only run when at least 
    // one value in the form has changed since it last ran (we don't check for the exact same field value before running a particular validation
    // as it is conceivable that this function would need to run on a different field to the one where the value has changed)
    const valuesChanged = !isEqual(previousValuesRef.current, formValuesRef.current);
    if (setFormErrors && formErrors && valuesChanged) {
        let validationsChanged: Dictionary<Dictionary<string | undefined>> = {};
        Object.keys(fieldConfigs).map(
            (k) => {
                // NOTE this function must do EVERYTHING it is going to do in terms of validation adjustments, and THEN compare the results to the 
                // existing - that way it will be possible to check if anything material has changed and prevent an infinite loop.
                // NB there will always, or nearly always be a concept of when an object can be saved and this is related to
                // B/E restrictions, communicated via the meta.  Therefore calculating when an object can be saved is a 
                // common 'special' case - hence we handle it here for convenience. 
                const meta = metaForForm[k];
                const displayName = meta?.label || k;
                const theseFormErrors = formErrors[k];
                const config = fieldConfigs[k];
                const value = formValuesRef.current[k];
                const validator = fieldConfigs[k].fieldValidator;
                const validation = validator ? validator({ formValuesRef, value, meta }) : false;
                const submitValueMissing = formValueConsideredMissing({ formValue: value, config, meta, includeRequiredForSubmitOnly: true });
                const saveValueMissing = formValueConsideredMissing({ formValue: value, config, meta });
                const missingValidation: any = {};
                if (submitValueMissing || saveValueMissing) {
                    const missingMessage = `'${displayName}' needs a value.`;
                    if (submitValueMissing) {
                        missingValidation['submit'] = missingMessage;
                    }
                    if (saveValueMissing) {
                        if (theseFormErrors?.save !== missingMessage) {
                            missingValidation['save'] = missingMessage;
                        }
                    }
                }
                const newFieldValidation = { ...validation, ...missingValidation };
                if (!isEqual(newFieldValidation, formErrors[k])) {
                    validationsChanged[k] = newFieldValidation;
                }
            }
        );
        const aValidationChanged = Object.keys(validationsChanged).length;
        if (aValidationChanged) {
            // console.log('validationsChanged: ', validationsChanged);
            const newValidation = { ...formErrors, ...validationsChanged }
            // console.log('newValidation: ', newValidation);
            setFormErrors(newValidation);
        }
    }
};

const RenderComponentInForm = ({ Component, index, key, skipInViewWrapper, className, inViewOptions, inViewProgressTracker }: RenderComponentInterface) => {
    if (inViewOptions && !skipInViewWrapper) {
        return <InViewWrapper
            key={key}
            WrappedComponent={() => <div className={className || ''}><Component /></div>} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
            inViewOptions={inViewOptions.current}
            inViewProgressTracker={inViewProgressTracker}
            i={index}
            override={undefined} // if false we want to pass this as undefined
            placeHolderMinHeight="25vh"
        />
    }
    return <div className={className}>
        <
            Component
            key={key}
        />
    </div>

}

const getGroupClassName = (group: FieldGroup) => `${group.className}${group.children?.length ? ' parent' : ''} ${group.component ? '' : 'field-group-wrapper'}`;

interface ActionFormProps {
    formValues: React.MutableRefObject<FormValues>;
    fieldConfigs: FieldsFormConfig;
    formLayout?: FieldGroup[];
    generalFieldZindex?: number;
    wipe?: boolean;
    callWithOnChange?: (newFormValues: FormValues) => void;
    caption?: string;
    gridClass?: string;
    metaForForm: FieldMetaGroup;
    refreshSignal?: any;
    showReadOnly?: boolean;
    paperElevation?: number;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    formErrors?: Dictionary<Dictionary<string | undefined>>;
    setFormErrors?: React.Dispatch<Dictionary<Dictionary<string | undefined>>>;
    setGAFormChanged?: React.Dispatch<boolean>; //NOTE that GEF has it's own 'formChanged' type function, 
    // setGAFormChange is here for other components using this component directly - TODO - analyse what's special about the GEF formChanged and if sensible, move 
    // to this GAF component to unify
    addColonToLabel?: boolean;
}

const ActionForm = (
    {
        formValues,
        fieldConfigs,
        generalFieldZindex,
        wipe,
        callWithOnChange,
        caption,
        gridClass,
        metaForForm,
        refreshSignal,
        showReadOnly,
        formLayout,
        paperElevation,
        inViewOptions,
        inViewProgressTracker,
        initiallySelectedDataField,
        formErrors,
        setFormErrors,
        setGAFormChanged,
        addColonToLabel
    }: ActionFormProps) => {

    const previousFormValues = useRef<FormValues>();
    const initialFormValues = useRef<FormValues>({ ...formValues.current });
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const currentFocus = useRef<string>();
    const [postComponentSelected, setPostComponentSelected] = useState<string | undefined>(initiallySelectedDataField?.current);

    useEffect(() => {
        forceUpdate();
    }, [refreshSignal])

    const scrollToRef: any = useRef();

    const runSideEffects = useCallback((newFormValues: FormValues, fieldConfigs: FieldsFormConfig, onChangeFormValues: (newValues: FormValues) => void) => {
        let sideEffects = [];
        let sideEffectsToRun = Object.keys(fieldConfigs).filter(x => fieldConfigs[x].sideEffect !== undefined);
        for (let index in sideEffectsToRun) {
            const field = sideEffectsToRun[index];
            //@ts-ignore
            const sideEffectChangedSomething = fieldConfigs[field].sideEffect(newFormValues, fieldConfigs, onChangeFormValues, previousFormValues.current);
            if (sideEffectChangedSomething) {
                sideEffects.push(field)
            }
        }
        runFieldValidators({
            // is a side effect, so it runs here (before previousFormValues are updated but after other sideEffects, as those may affect validation, including missing values)
            formValuesRef: formValues,
            formErrors,
            setFormErrors,
            fieldConfigs,
            metaForForm,
            previousValuesRef: previousFormValues
        });
        previousFormValues.current = newFormValues;
        if (sideEffects.length > 0) {
            forceUpdate();
        }
    }, [metaForForm, formValues, formErrors, setFormErrors]);

    useEffect(() => {
        if (wipe) {
            formValues.current = {};
            forceUpdate();
        }
    }, [wipe, formValues])

    useEffect(() => {
        initiallySelectedDataField && scrollToRef.current && scrollToRef.current.scrollIntoView && scrollToRef.current.scrollIntoView()
    }, [scrollToRef, initiallySelectedDataField])

    const onChangeFormValues = useCallback((newValues: FormValues) => {
        const updatedFormValues = { ...formValues.current, ...newValues }
        formValues.current = updatedFormValues;
        runSideEffects(updatedFormValues, fieldConfigs, onChangeFormValues);
        if (setGAFormChanged) {
            setGAFormChanged(!isEqual(initialFormValues.current, formValues.current));
        }
        if (callWithOnChange) {
            callWithOnChange(updatedFormValues);
        }
    }, [
        fieldConfigs,
        formValues,
        setGAFormChanged,
        runSideEffects,
        callWithOnChange,
    ]
    )

    const hasMeta = () => metaForForm && !!Object.keys(metaForForm).length;
    const RenderField = useCallback(({ dataField, fieldConfigs }: { dataField: string | React.FC<any>, fieldConfigs: FieldsFormConfig }) => {
        if (typeof dataField === "string") {
            const fieldConfig = fieldConfigs[dataField]
            return (fieldConfig ? <AutoInput
                wrapperRef={initiallySelectedDataField?.current === dataField ? scrollToRef : undefined}
                zIndex={generalFieldZindex || 3001}
                key={dataField}
                dataField={dataField}
                fieldConfig={fieldConfig}
                fieldMeta={metaForForm[dataField]}
                formValuesRef={formValues}
                onChangeFormValues={onChangeFormValues}
                currentFocus={currentFocus}
                refreshSignal={mustRefresh}
                dispatchRefreshContext={forceUpdate}
                showReadOnly={showReadOnly}
                setPostComponentSelected={setPostComponentSelected}
                extraClassNames={postComponentSelected === dataField ? 'postComponentSelected' : 'postComponentNotSelected'}
                formErrors={formErrors} // nothing has incorporated this yet, but this is a 'stub' to be used to integrate B/E form Error feedback
                setFormErrors={setFormErrors} // nothing has incorporated this yet, but this is a 'stub' to be used to integrate B/E form Error feedback
                addColonToLabel={addColonToLabel}
            /> : <></>)
        } else {
            const DataField = dataField
            return <DataField />
        }

    }, [
        generalFieldZindex,
        metaForForm,
        formValues,
        formErrors,
        setFormErrors,
        onChangeFormValues,
        currentFocus,
        mustRefresh,
        showReadOnly,
        postComponentSelected,
        initiallySelectedDataField,
        addColonToLabel
    ]);

    const RenderComponentOrFieldGroup = ({ group, i }: { group: FieldGroup, i: number }) => {
        if (group.component) {
            return <RenderComponentInForm
                Component={group.component}
                skipInViewWrapper={group.skipInViewWrapper}
                className={getGroupClassName(group)}
                index={i}
                key={group.group_id || group.group_title}
                inViewOptions={inViewOptions}
                inViewProgressTracker={inViewProgressTracker}
            />
        }
        return <RenderFieldGroup
            group={group}
            key={group.group_id || group.group_title}
        />
    }

    const RenderFormLayout = ({ formLayout, paperElevation }: { formLayout: FieldGroup[], paperElevation?: number }) => {
        return <>
            {paperElevation ?
                <>{
                    formLayout.map((group, i) => {
                        return <Paper elevation={paperElevation} key={group.group_id || group.group_title}>
                            <RenderComponentOrFieldGroup group={group} i={i} />
                        </Paper>
                    })
                } </>
                :
                <>{
                    formLayout.map((group, i) => {
                        return <RenderComponentOrFieldGroup group={group} i={i} key={group.group_id || group.group_title} />
                    })
                }</>
            }
        </>
    }

    const RenderFieldGroup = ({ group }: { group: FieldGroup }) => {
        return <div className={getGroupClassName(group)}>
            <h3 className='field-group-title'>{group.group_title}</h3>
            {group.group_subtitle && <h4 className='field-group-subtitle'>{group.group_subtitle}</h4>}
            {
                group.fields.map((dataField, i) => {
                    if (inViewOptions?.current && !initiallySelectedDataField && !group.skipInViewWrapper) {
                        return <InViewWrapper
                            key={i}
                            WrappedComponent={() => <RenderField
                                dataField={dataField}
                                fieldConfigs={fieldConfigs}
                            />} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
                            inViewOptions={inViewOptions.current}
                            inViewProgressTracker={inViewProgressTracker}
                            i={i}
                            override={undefined} // if false we want to pass this as undefined
                            placeHolderMinHeight="25vh"
                            dataField={dataField}
                            fieldConfigs={fieldConfigs}
                        />
                    }
                    return <RenderField
                        key={i}
                        dataField={dataField}
                        fieldConfigs={fieldConfigs}
                    />
                })
            }

            {
                group.children && <RenderFormLayout
                    formLayout={group.children}
                />

            }

        </div>
    }

    const RenderSimpleForm = ({ paperElevation }: { paperElevation?: number | undefined }) => {
        return (paperElevation ? <Paper elevation={paperElevation}>
            <>
                {
                    Object.keys(fieldConfigs).map((dataField) => {
                        let fieldConfig = fieldConfigs[dataField];
                        return <RenderField
                            key={dataField}
                            dataField={dataField}
                            fieldConfigs={fieldConfigs}
                        />
                    })
                }
            </>
        </Paper> : <>
            {
                Object.keys(fieldConfigs).map((dataField) => {
                    let fieldConfig = fieldConfigs[dataField];
                    return <RenderField
                        key={dataField}
                        dataField={dataField}
                        fieldConfigs={fieldConfigs}
                    />
                })
            }
        </>)
    }

    useEffect(() => {
        // NB there are many side effects we might want to run immediately - so it's important we run onChangeFormValues once on load
        onChangeFormValues(formValues.current);
    }, [formValues, onChangeFormValues])

    return <div className="generalActionFormWrapper">
        {caption && <span className="actionFormCaption">{caption}</span>}
        {
            hasMeta() &&
            <div className={`generalActionFormGrid ${gridClass ? gridClass : ''}`}>
                {
                    mustRefresh && <>
                        {formLayout ?
                            <RenderFormLayout
                                formLayout={formLayout}
                                paperElevation={paperElevation}
                            /> : <RenderSimpleForm paperElevation={paperElevation} />
                        }
                    </>
                }
            </div>
        }

    </div>
}

// ActionForm.whyDidYouRender = true;

export default React.memo(ActionForm);