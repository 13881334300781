import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { isEqual } from "lodash";
import { useSelector } from 'react-redux';

import { Paper } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Own
import DocumentsGrid from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import { getAccidentDocumentsRoute, fetchAccidentDocumentsPreFlightInfo, getNearMissDocumentsRoute, fetchNearMissDocumentsPreFlightInfo } from "components/AdminPanel/HAS/HASIncidentServices";
import { ContractDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { IHASAccident, IHASNearMiss, IncidentFieldType } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { profileOrganisationSelector } from 'components/Profile/Selectors/Profile.selector';
import { ColumnPropGroup } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';

export const accidentDocumentColumns: ColumnPropGroup = {
    'notes': {
        caption: 'Description',
        alignment: 'start',
        width: '50%'
    },
    "h_a_s_accident_document_type": { width: '225px', caption: 'Document Category' },
};

export const nearMissDocumentColumns: ColumnPropGroup = {
    'notes': {
        caption: 'Description',
        alignment: 'start',
        width: '50%'
    },
    "h_a_s_near_miss_document_type": { width: '225px', caption: 'Document Category' },
};

interface HASIncidentDocsProps {
    data: IHASAccident | IHASNearMiss;
    parentType: IncidentFieldType;
    AdditionalComponent?: () => JSX.Element;
    docsTitle: string;
    FormSettingsComponent: () => JSX.Element;
    formSettingsInFocus: boolean;
    handleFormSettingsClose: () => void;
}

const HASIncidentDocs = ({ data, parentType, AdditionalComponent, docsTitle, formSettingsInFocus, FormSettingsComponent, handleFormSettingsClose }: HASIncidentDocsProps) => {

    const id: string = data.id;

    const basePath = parentType == "accident" ? getAccidentDocumentsRoute(id) : getNearMissDocumentsRoute(id);
    const [fetchFilters, setFetchFilters] = useState();
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [preFlightInfo, setPreFlightInfo] = useState<PreFlightListInfo>();
    const refresh = useCallback(forceUpdate, [forceUpdate])
    const selectProfileOrganisationFromState = useSelector(profileOrganisationSelector);

    const getDocumentColumns = useCallback(() => {
        if (parentType == "accident") {
            return accidentDocumentColumns
        }
        return nearMissDocumentColumns
    }, [parentType])

    const documentColumns = useRef(getDocumentColumns());


    const getFieldFormConfig = useCallback((): FieldsFormConfig => {
        const documentTypeString = parentType == "accident" ? "h_a_s_accident_document_type" : "h_a_s_near_miss_document_type";
        return {
            'notes': {
                label: 'Description',
                forceRequired: true
            },
            [documentTypeString]: {},
            [parentType]: {
                hidden: true,
                defaultValue: id
            },
            'author_organisation': {
                hidden: true,
                defaultValue: selectProfileOrganisationFromState
            }
        }
    }, [id, selectProfileOrganisationFromState, parentType]);

    const fieldsConfig = getFieldFormConfig();

    useEffect(() => {
        const fetchPreFlightInfo = parentType == "accident" ? fetchAccidentDocumentsPreFlightInfo : fetchNearMissDocumentsPreFlightInfo;
        fetchPreFlightInfo({ parentId: id }).then((newPreFlightInfo) => {
            if (!isEqual(preFlightInfo, newPreFlightInfo)) {
                setPreFlightInfo(newPreFlightInfo);
            }
        });
    }, [preFlightInfo, id, parentType]);

    return <div className='incidentDocumentsPanel'>
        <div className={`formWithComments ${formSettingsInFocus ? 'commentsOpen' : 'commentsClosed'}`}>
            <div className='formSection'>
                <Paper elevation={3}>
                    {AdditionalComponent && <AdditionalComponent />}
                    <GeneralDocsGridWrapper>
                        <h3 className='field-group-title'>{docsTitle}</h3>
                        {documentColumns.current && <ContractDocumentFilesWrapper height={!preFlightInfo?.canCreate ? 30 : 20}>
                            <DocumentsGrid
                                listBasePath={basePath}
                                hideSearch
                                hideFilters
                                hideSource
                                columnsConfig={documentColumns.current}
                                fetchFilters={fetchFilters}
                                editMode="cell"
                                refreshSignal={mustRefresh}
                                actionColumnsWidth={140}
                                updateRecordMetaBeforeEdit
                            />
                        </ContractDocumentFilesWrapper>}

                        {preFlightInfo?.canCreate && basePath &&
                            <DocumentsUploader
                                urlContext={basePath}
                                fieldConfigs={fieldsConfig}
                                preFlightInfo={preFlightInfo}
                                gridId="HASIncidentDocumentUploader"
                                reset={forceUpdate}
                                zIndex={1300}
                            />
                        }
                    </GeneralDocsGridWrapper>
                </Paper>

            </div>
            {formSettingsInFocus && FormSettingsComponent &&
                <div className='commentsSection'>
                    <div className='sideHeader'>
                        <div className="commentsSection-close-button">
                            <IconButton onClick={handleFormSettingsClose} >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <FormSettingsComponent />
                </div>
            }
        </div>
    </div>
}

export default HASIncidentDocs;