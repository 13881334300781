import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IntersectionOptions } from 'react-intersection-observer';
import { isEqual } from "lodash";

import {
    FormWrapper,
    ActionsWrapper,
} from './GeneralDetailStyles';
import { InViewProgressTracker } from "components/Common/Components/InViewWrapper/InViewWrapper";
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { FieldMetaGroup, Dictionary, FieldGroup } from 'components/Common/Interfaces/Entity.interface';
import ActionForm from "components/Common/Components/GeneralActionForm/GeneralActionForm";
import ButtonWithToolTip from "components/Common/Components/TooltipWithButton/TooltipWithButton";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { collectFieldValidationMessages } from "store/Common/Helpers/commonHelpers";
import { GeneralTooltip } from 'components/Common/Components/InfoHint/InfoHint';

//import { logObjDifferences, isStateEqual } from "store/reducers/reducer.helper";



interface GeneralButtonDisplayArgs {
    formChanged: boolean;
    position?: "top" | "bottom";
}

type formActionFunc = (recordId?: string, callback?: any) => void;

interface GeneralButtonDefinition {
    disabled: ({ formChanged, position }: GeneralButtonDisplayArgs) => boolean;
    show: ({ formChanged, position }: GeneralButtonDisplayArgs) => boolean;
    action: formActionFunc;
    label: string;
    className: string;
    tooltipMessage?: string;
    validationsKey: string;
    formErrors?: FormErrorsType;
}

interface GeneralFormButtonsProps {
    formChanged: boolean;
    recordId?: string;
    buttons: GeneralButtonDefinition[];
    position?: "top" | "bottom";
    formErrors?: FormErrorsType;
}

export type GetStandardButtonDisabledProps = ({ formChanged, position }: GeneralButtonDisplayArgs) => boolean;

const filterFieldsForChangeComparisons = (data: any, meta: FieldMetaGroup, formFieldsConfig: FieldsFormConfig | undefined) => {
    // NB we discount image types at present because at present submission of such fields is managed separately from other fields
    // so it wasn't worth dealing with the fact that equality comparisons won't currently necessarily match as the signatures change
    // even when the underlying image hasn't.
    return formFieldsConfig ? Object.entries(data).filter(
        ([key, value]) => !formFieldsConfig[key]?.hidden &&
            !meta[key]?.type.includes("image") &&
            !meta[key]?.read_only &&
            !!meta[key] // this checks that the key exists in the meta - if not it should be removed from the post values
    ) : [];
}



export const UseFormButtons = ({
    // Note we don't need to pass formValuesRef or meta as references to both can be encapsulated in the callbacks defined in the button definitions
    formChanged,
    recordId,
    buttons,
    position,
    formErrors,
}: GeneralFormButtonsProps) => {
    return <>
        <ActionsWrapper>
            {buttons.map((x, i) => {
                let validationMessages = formErrors ? collectFieldValidationMessages(formErrors, x.validationsKey) : [];
                let message = '';
                if (validationMessages?.length) {
                    message = validationMessages.join('\n').slice(0, 300) + '...';
                } else {
                    message = x.tooltipMessage || '';
                }
                const disabled = x.disabled({ formChanged, position });
                const show = x.show({ formChanged, position });
                return show ?
                    <span key={x.label}>
                        {disabled ?
                            <ButtonWithToolTip
                                tooltipText={message || ''}
                                className={x.className}
                                onClick={() => x.action(recordId)}
                                key={x.label}
                                disabled={disabled}
                            >
                                {x.label}
                            </ButtonWithToolTip> :
                            <GeneralTooltip
                                title={message || ''}
                            >
                                <span><button key={x.label} className={x.className} onClick={() => x.action(recordId)}>{x.label}</button></span>
                            </GeneralTooltip>

                        }

                    </span> : null;
            })}
        </ActionsWrapper>
    </>
};

// <Tooltip
//     title={x.tooltipMessage || ''}
// >
//     <span><button disabled={disabled} key={x.label} className={x.className} onClick={() => x.action(recordId)}>{x.label}</button></span>
// </Tooltip>

interface GenerateStandardGeneralActionButtonProps {
    handleSave: formActionFunc;
    handleSubmit?: formActionFunc;
    overrideFormChanged?: boolean;
    overrideSubmittedLabel?: string;
    overrideSubmitLabel?: string;
    overrideSaveLabel?: string;
    hideCancel?: boolean;
    handleCancel?: formActionFunc;
    setSignalDelete?: React.Dispatch<React.SetStateAction<boolean | undefined>> | undefined;
    deleteDisabled?: boolean;
    showDelete?: boolean;
    formSubmitted?: boolean;
    generalLimitExceeded?: boolean;
    canSave?: boolean;
    canSubmit?: boolean;
    formErrors: FormErrorsType;
}

export const generateStandardGeneralActionButtonDefinitions = ({
    handleSave,
    handleSubmit,
    handleCancel,
    setSignalDelete,
    deleteDisabled,
    showDelete,
    formSubmitted,
    overrideSubmitLabel,
    overrideSaveLabel,
    overrideFormChanged,
    overrideSubmittedLabel,
    formErrors,
    canSave,
    canSubmit
    //meta,
    //fieldConfigs,
}: GenerateStandardGeneralActionButtonProps) => {

    //const saveFieldValidations = validationSignal ? collectFieldValidationMessages(validationSignal, 'Save') : [];
    const saveValidationKey = 'save'
    const saveValidationErrorsLength = collectFieldValidationMessages(formErrors, saveValidationKey).length;
    const buttons: GeneralButtonDefinition[] = [
        {
            label: overrideSaveLabel || "Save",
            disabled: ({ formChanged, position }) => !!saveValidationErrorsLength || (!formChanged && !overrideFormChanged),
            show: () => true,
            action: handleSave,
            className: 'dx-save',
            validationsKey: saveValidationKey,
            formErrors
        },
    ]

    if (handleSubmit) {
        const submitValidationKey = 'submit'
        const submitValidationErrorsLength = collectFieldValidationMessages(formErrors, submitValidationKey).length;
        buttons.push(
            {
                label: formSubmitted ? overrideSubmittedLabel || 'Submitted' : overrideSubmitLabel || 'Submit',
                disabled: ({ formChanged, position }) => (!!submitValidationErrorsLength || !!formSubmitted),
                show: ({ formChanged, position }) => true,
                action: handleSubmit,
                className: 'submit dx-jaOverride',
                validationsKey: submitValidationKey,
                formErrors
            }
        )
    }
    if (handleCancel) {
        buttons.unshift({
            label: "Revert Changes",
            disabled: ({ formChanged, position }) => !formChanged,
            show: ({ formChanged, position }) => true,
            action: handleCancel,
            className: 'dx-cancel',
            formErrors,
            validationsKey: 'cancel',
        },)
    }
    if (setSignalDelete) {
        const deleteValidationKey = 'delete'
        const deleteValidationErrorsLength = collectFieldValidationMessages(formErrors, deleteValidationKey).length;
        buttons.push(
            {
                label: "Delete",
                disabled: ({ formChanged, position }) => !!deleteValidationErrorsLength || !!deleteDisabled,
                show: () => !!showDelete,
                action: () => { setSignalDelete && setSignalDelete(true) },
                className: 'dx-delete',
                formErrors,
                validationsKey: deleteValidationKey
            }
        )
    }
    return buttons
};


interface GeneralEntityFormProps {
    formValuesRef: React.MutableRefObject<any>;
    buttons?: GeneralButtonDefinition[];
    useDefaultRevertChanges: boolean; // It makes sense to allow this form to manage this in the 'default' case, as it has all the relevant info and is really mainly field management
    refreshSignal: boolean;
    formLevelSharedSpace: any; // many uses but largely implemented to handle issues where the grid was collapsing the row and needed access to certain state to perform actions on collapse
    rowLevelSharedSpace?: any;
    // NOTE WHERE THERE IS ONLY ONE FORM BEING HANDLED IN A COMPONENT, formLevelSharedSpace WILL USUALLY POINT AT THE rowLevelSharedSpace.  
    // HOWEVER WHERE THERE MAY BE MULTIPLE FORMS BEING MANAGED IN THE PARENT COMPONENT OF THIS COMPONENT, THE PARENT COMPONENT MAY HOOK IT'S OWN METHODS TO MANAGE HANDLING
    // SAVE, CANCEL ETC ACROSS ALL FORMS IN THE PARENT COMPONENT E.G. AT THE 'ROW LEVEL'.  THEN A DIFFERENT SHARED SPACE IS PASSED IN HERE SO THAT THE
    // 'LOCAL' SAVE AND CANCEL FOR THIS FORM ARE NOT SET AT THE ROW LEVEL (BY LINES LIKE formLevelSharedSpace.handleCancel = )
    initialData: any;
    canWrite?: boolean;
    formFieldsConfig?: FieldsFormConfig;
    formLayout?: FieldGroup[];
    meta: FieldMetaGroup;
    overRideCallOnChange?: (newFormValues: any) => void;
    addToCallOnChange?: (newFormValues: any) => void; // useful in cases where we might want something else to be evaluated - e.g. specific button display logic
    dispatchRefreshContext: React.DispatchWithoutAction;
    gridClass: string;
    generalFieldZindex?: number;
    paperElevation?: number;
    signalFormChanged?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    formChangedCallback?: (changed: boolean) => void;
    inViewOptions?: React.MutableRefObject<IntersectionOptions>;
    actionsAtTop?: boolean;
    actionsAtBottom?: boolean;
    initiallySelectedDataField?: React.MutableRefObject<string | undefined>;
    showReadOnly?: boolean;
    formErrors?: Dictionary<Dictionary<string | undefined>>;
    setFormErrors?: React.Dispatch<Dictionary<Dictionary<string | undefined>>>;
    inViewProgressTracker?: React.MutableRefObject<InViewProgressTracker>;
    addColonToLabel?: boolean;
}

const GeneralEntityForm = (
    {
        formValuesRef,
        buttons,
        useDefaultRevertChanges,
        refreshSignal,
        formLevelSharedSpace,
        rowLevelSharedSpace,
        initialData,
        formFieldsConfig,
        formLayout,
        meta,
        overRideCallOnChange,
        addToCallOnChange,
        dispatchRefreshContext,
        gridClass,
        generalFieldZindex,
        paperElevation,
        signalFormChanged,
        formChangedCallback,
        inViewOptions,
        inViewProgressTracker,
        actionsAtTop,
        actionsAtBottom,
        initiallySelectedDataField,
        showReadOnly,
        canWrite,
        formErrors,
        setFormErrors,
        addColonToLabel
    }: GeneralEntityFormProps
) => {

    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [visibleInitialData, setVisibileInitialData] = useState<any>();

    useEffect(() => {
        if (initialData) {
            const visibleInitialDataKeyValueArray = filterFieldsForChangeComparisons(initialData, meta, formFieldsConfig);
            const vd = Object.fromEntries(visibleInitialDataKeyValueArray);
            setVisibileInitialData(vd);
        }

    }, [formFieldsConfig, initialData, meta]);


    // const renderedCount = useRef(0);
    // renderedCount.current = renderedCount.current + 1;
    // console.log('renderedCount: ', renderedCount);

    const handleNewValues = useCallback((newValues: any) => {
        if (!rowLevelSharedSpace?.collapse) { // THE WORK INSIDE THIS BLOCK ISN'T NECESSARY WHILE COLLAPSING
            // IT SHOULDN'T CAUSE ANY RESETTING AS LONG AS formLevelSharedSpace.data IS ABLE TO SURVIVE THE PARENT COMPONENT (WHICH IS REWRITTEN ON COLLAPSE)
            // BUT IT ISN'T NEEDED EITHER
            // console.log('newValues: ', newValues);
            const newVisibleValuesKeyValueArray = filterFieldsForChangeComparisons(newValues, meta, formFieldsConfig);
            const newVisibleValues = Object.fromEntries(newVisibleValuesKeyValueArray);
            const newValuesIsNotEmpty = !!Object.keys(newValues).length;
            const newVisibleValuesIsNotEmpty = !!Object.keys(newVisibleValues).length;
            // const newValuesDiffersFromRecord = !isEqual(newValues, initialData);
            const newVisibleValuesDiffersFromRecord = !isEqual(newVisibleValues, visibleInitialData);
            const newValuesDiffersFromStoredValues = !isEqual(newValues, formLevelSharedSpace.data);
            // console.log('newValuesDiffersFromStoredValues: ', newValuesDiffersFromStoredValues);
            // console.log('newValuesDiffersFromRecord: ', newValuesDiffersFromRecord);
            if (newValuesIsNotEmpty) {
                //console.log('newValuesIsNotEmpty: ', newValuesIsNotEmpty);
                if (newValuesDiffersFromStoredValues) {
                    formLevelSharedSpace.data = { ...newValues };
                }
            }
            formValuesRef.current = (formLevelSharedSpace.data || initialData);
            if (newVisibleValuesDiffersFromRecord && newVisibleValuesIsNotEmpty) {
                // const dataDiff = logObjDifferences(newVisibleValues, visibleInitialData);
                // console.log('dataDiff: ', dataDiff);
                setFormChanged(true);
            } else {
                setFormChanged(false);
            }
        } else {
            formValuesRef.current = initialData;
        }
    }, [initialData, formValuesRef, formLevelSharedSpace, formFieldsConfig, visibleInitialData, rowLevelSharedSpace?.collapse, meta]);

    useEffect(() => {
        formLevelSharedSpace.formChanged = formChanged;
        signalFormChanged && signalFormChanged(formChanged);
        formChangedCallback && formChangedCallback(formChanged);
        if (formLevelSharedSpace.data === undefined) {
            formLevelSharedSpace.data = { ...initialData }
        }
    }, [formChanged, initialData, formLevelSharedSpace, signalFormChanged, formChangedCallback]);

    const defaultCallOnChange = useCallback((newFormValues: any) => {
        const formValuesRefIsNotEmpty = !!Object.keys(newFormValues).length;
        formValuesRefIsNotEmpty && handleNewValues(newFormValues);
    }, [handleNewValues]);

    const callOnChange = useCallback((newFormValues: any) => {
        if (overRideCallOnChange) {
            overRideCallOnChange(newFormValues)
        } else {
            defaultCallOnChange(newFormValues)
        }
        if (addToCallOnChange) {
            addToCallOnChange(newFormValues)
        }
    }, [overRideCallOnChange, addToCallOnChange, defaultCallOnChange]);

    const defaultHandleCancel: formActionFunc = useCallback((recordId?, callback?: any) => {
        formLevelSharedSpace.data = false;
        formValuesRef.current = initialData;
        handleNewValues(initialData); // NB ensures that the values are compared again and formChanged should be set to false
        // formChanged could be set directly to false, but doing it this way is a good check on handleNewValues and also ensures
        // that any future 'reset' type work done in handleNewValues will be called 
        dispatchRefreshContext();
        callback && callback(); // can be used for any further action, is used for resuming collapse of row in some cases
    }, [dispatchRefreshContext, formValuesRef, initialData, formLevelSharedSpace, handleNewValues]);

    const getTheseButtons = useCallback(() => {
        let localButtons = buttons ? [...buttons] : [];
        if (useDefaultRevertChanges) {
            if (localButtons) {
                localButtons.unshift({
                    label: "Revert Changes",
                    disabled: ({ formChanged, position }) => !formChanged,
                    show: ({ formChanged, position }) => true,
                    action: defaultHandleCancel,
                    className: 'dx-jaOverride',
                    formErrors,
                    validationsKey: 'cancel'
                })
            }
            formLevelSharedSpace.handleCancel = defaultHandleCancel;
        }
        return localButtons
    }, [buttons, defaultHandleCancel, useDefaultRevertChanges, formLevelSharedSpace, formErrors]);

    const theseButtons = getTheseButtons();

    useEffect(() => {
        handleNewValues(formLevelSharedSpace.data || initialData); //only executed when component loads
    }, []);

    return <FormWrapper>
        {canWrite && actionsAtTop && theseButtons &&
            <UseFormButtons
                formChanged={formChanged}
                recordId={initialData?.id}
                buttons={theseButtons}
                position='top'
                formErrors={formErrors}
            />
        }
        {refreshSignal && formValuesRef && formFieldsConfig && <ActionForm
            formValues={formValuesRef}
            callWithOnChange={callOnChange}
            fieldConfigs={formFieldsConfig}
            formLayout={formLayout}
            gridClass={gridClass}
            // permissionToSubmit={canWrite} doesn't do anything any more...
            metaForForm={meta}
            refreshSignal={refreshSignal}
            showReadOnly={showReadOnly !== false}
            generalFieldZindex={generalFieldZindex}
            paperElevation={paperElevation}
            inViewOptions={inViewOptions}
            inViewProgressTracker={inViewProgressTracker}
            initiallySelectedDataField={initiallySelectedDataField}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            addColonToLabel={addColonToLabel}
        />}

        {canWrite && (!actionsAtTop || !!actionsAtBottom) && theseButtons &&
            <UseFormButtons
                formChanged={formChanged}
                recordId={initialData?.id}
                buttons={theseButtons}
                position='bottom'
                formErrors={formErrors}
            />
        }
    </FormWrapper>
}

// GeneralEntityForm.whyDidYouRender = true;

export default GeneralEntityForm;
