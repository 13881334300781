import React, { memo } from "react";
import DataGrid, { Column, Editing, Paging, Lookup, MasterDetail, RequiredRule, RemoteOperations, FilterRow } from "devextreme-react/data-grid";

import { getGridProps } from '../../../../helpers/DataGrid/DataGridColumn.helper';
import { getEventEditorPreparing } from "components/ContractInFocus/Logs/Helpers/LogsGrid.helper";
import { NewLogsProps } from "../Logs";
import LogsWrapper from "components/ContractInFocus/Logs/LogsWrapper";
import { hsIncidentsLogsColumns, hsIncidentsLogsColumnWidths, portfolioHsIncidentsLogsColumnWidths } from "../Models/logsColumns.model";
import { Dictionary } from "components/Common/Interfaces/Entity.interface";
import { reportSubtitles } from "components/ContractInFocus/Models/contractIndex.model";
import HASEventDocuments from "components/ContractInFocus/HAS/HASEventDocuments";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { removeDisabledFieldsBasedOnRecordMeta } from "helpers/Forms/form.helper";

import { downloadCSV } from "store/Common/Helpers/commonHelpers";

const getHASEndpoint = (ref?: string | number) => `health-and-safety-incident-logs/`;
const getContractEndpoint = (ref?: string | number) => `contracts/${ref}/health-and-safety-incident-logs/`;
const getPortfolioEndpoint = (id?: string | number) => `portfolios/${id}/health-and-safety-incident-logs/`;

const HealthAndSafetyLogs: React.FC<NewLogsProps> = (
    {
        dataSource,
        metadata,
        setContentReady,
        handleEditingStart,
        handleRowUpdated,
        handleOnRowPrepared,
        getColumnPropsExt,
        contract,
        portfolio,
        remoteOperations,
        pageSize,
        filterRow,
        styles
    }) => {
    const columnWidths = !contract ? portfolioHsIncidentsLogsColumnWidths : hsIncidentsLogsColumnWidths;

    const handleOnEditorPreparing = (e: any) => {
        removeDisabledFieldsBasedOnRecordMeta(e, metadata.POSTMeta);
        const func1 = getEventEditorPreparing(metadata);
        func1(e);
    }

    return (
        <div>
            {dataSource && (
                <DataGrid
                    className="no-print"
                    dataSource={dataSource}
                    {...getGridProps(metadata.activeMeta)}
                    onEditorPreparing={handleOnEditorPreparing} //{editableTextAreaOnPreparing(metadata.activeMeta)}
                    onEditingStart={handleEditingStart}
                    onRowUpdated={handleRowUpdated}
                    onRowPrepared={handleOnRowPrepared}
                    onContentReady={() => setContentReady(true)}
                    dateSerializationFormat="yyyy-MM-dd"
                    remoteOperations={remoteOperations}
                    style={styles}
                >
                    {pageSize && <Paging defaultPageSize={pageSize} />}
                    {remoteOperations && <RemoteOperations
                        groupPaging={false}
                        grouping={true}
                        filtering={true}
                        paging={!!pageSize}
                    />}
                    {filterRow && <FilterRow visible={true} applyFilter="auto" />}

                    <Editing
                        mode="cell"
                        allowUpdating={metadata.privileges.PUT}
                        allowDeleting={metadata.privileges.DELETE}
                        allowAdding={metadata.privileges.POST}
                    />
                    {!contract && <Column
                        {...getColumnPropsExt('contract')}
                        //@ts-ignore
                        width={columnWidths['contract']}
                        caption="Contract"
                    ></Column>}
                    <Column
                        {...getColumnPropsExt('date')}
                        width={columnWidths['date']}
                    >
                        <RequiredRule />
                    </Column>
                    <Column
                        {...getColumnPropsExt('category')}
                        width={columnWidths['category']}

                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={metadata.loaded ? metadata.POSTMeta?.category.choices : []}
                            valueExpr="value"
                            displayExpr="display_name"
                        />
                    </Column>
                    <Column
                        {...getColumnPropsExt('details')}
                        width={columnWidths['details']}

                    >
                        <RequiredRule />
                    </Column>
                    <Column {...getColumnPropsExt('investigation_completed')}
                        width={columnWidths['investigation_completed']}

                    />
                    <Column {...getColumnPropsExt('resolved_on')}
                        width={columnWidths['resolved_on']}
                    />
                    <Column {...getColumnPropsExt('resolution')}
                        width={columnWidths['resolution']}
                    />
                    <MasterDetail
                        enabled={true}
                        component={
                            (e) => {
                                return (<HASEventDocuments
                                    data={e.data.data}
                                />)
                            }
                        }
                    />
                </DataGrid>
            )}
        </div>

    );
};

interface WrappedHealthAndSafetyLogsProps {
    contract?: SiteContract;
    portfolio?: HydratedPortfolio;
    sectionNumberMap?: Dictionary<number>;
    remoteOperations?: boolean;
    pageSize?: number;
    filterRow?: boolean;
    csvFileNameBase?: string;
}

const getWrappedHealthAndSafetyLogs = ({ contract, portfolio, sectionNumberMap, remoteOperations, pageSize, filterRow, csvFileNameBase }: WrappedHealthAndSafetyLogsProps) => {
    return () => {
        let getEndpoint = getHASEndpoint;
        if (contract) {
            getEndpoint = getContractEndpoint;
        } else if (portfolio) {
            getEndpoint = getPortfolioEndpoint;
        }
        return <LogsWrapper
            WrappedLogsGrid={HealthAndSafetyLogs}
            getEndpoint={getEndpoint}
            contract={contract}
            portfolio={portfolio}
            logColumnsDef={hsIncidentsLogsColumns}
            logsHandle='HandSLogs'
            logsTitle={reportSubtitles.HandSIncidentSubtitle}
            sectionNumberMap={sectionNumberMap}
            remoteOperations={remoteOperations}
            pageSize={pageSize}
            filterRow={filterRow}
            csvFileNameBase={csvFileNameBase}
        />
    }
}

export default getWrappedHealthAndSafetyLogs;
