import React, { Suspense, useCallback, useEffect, useLayoutEffect, useReducer, useState, useRef, memo } from "react";
import { Switch, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';

// Own
import PageLoader from "../../Common/PageLoader";
import { PortfolioSpecialistMaintenance } from "../../Schedulers/SpecialistMaintenance";
//import { PortfolioHASManagement } from "components/ContractInFocus/HAS/HASManagement";
import { PortfolioAudit } from "../../ContractInFocus/Audit/Audit";
import { PortfolioReportPage } from "../../ContractInFocus/ContractReport/ContractReport";

import { PortfolioLogs } from "../../ContractInFocus/Logs/Logs";
import { PortfolioFinancials } from "../../ContractInFocus/Financials/Financials";
import { portfolioPPMsReport } from "../../ContractInFocus/Maintenance/PPMsReport";
import { portfolioReactiveReport } from "../../ContractInFocus/Maintenance/ReactiveReport";
import ContractSubHeader from "components/Layout/Contract/Containers/ContractSubHeading/ContractSubHeading";
// import ContractSidebar from "./Containers/ContractSidebar/ContractSidebar";
import PortfolioNavigation from "./PortfolioNavigation";
import { PrivateRoute } from "../../../services/Auth/PrivateRoute";
import { PortfolioSpend } from "components/ContractInFocus/Spend/Spend";
import { waitFor } from "../../../router.helper";
import InFocusSidebar from "components/Layout/Contract/Containers/ContractSidebar/InFocusSidebar.js";
import { useGetGroupedPortfolioMenu, getFirstPathFromGroupedMenuItems } from "components/Layout/Contract/Containers/ContractSidebar/inFocusSideBarMenuItems";
import { isGlobalStoreSetsReadySelector } from "store/selectors/store.selectors";
import { adminAccessSelector } from "components/Profile/Selectors/Profile.selector";
import { portfolioHydratedFactory } from "components/Portfolios/Selectors/Portfolio.selectors";
import { useGetPIFSideBarMenuVisibility, useShouldViewPortfolioReportVisibilitySettings, useCheckHASCompatibleEnv } from "components/Profile/Helpers/profileHelpers";
import * as visibilitySelector from 'components/ContractInFocus/Selectors/visibility.selectors';
import * as inFocusSelectors from "components/PortfolioInFocus/Selectors/portfolioInFocus.selectors";
import { usePortfolioContextShouldViewHAS } from 'components/Profile/Helpers/profileHelpers';
import { contractActiveSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { useGetLeftMargin } from "components/Layout/Common/SideBar/SidebarHelpers";
import { contractSideBarCollapsedSelector, contractSideBarHiddenSelector } from "components/Profile/Selectors/Profile.selector";
import HASSchedule from "components/Schedulers/HASSchedule";
import HASAccidents from "components/ContractInFocus/HAS/HASAccidents";
import HASNearMisses from "components/ContractInFocus/HAS/HASNearMisses";
import { PaperWrappedPTWsList } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermits";

// Styles
import {
    ContractLayoutContainer,
    ContractContentWrapper,
} from "../Contract/ContractLayoutStyles";

const PortfolioLayout = () => {
    const shouldViewHAS = usePortfolioContextShouldViewHAS();
    const shouldViewPortfolioReportVisibility = useShouldViewPortfolioReportVisibilitySettings();
    const baseRoute = "/portfolio/:portfolioId/";
    const active = useSelector(contractActiveSelector);
    const isGlobalStoreReady = useSelector(isGlobalStoreSetsReadySelector);

    // TODO: The settings state causes refresh, when the browser size is adjusted, the state is updated
    // which triggers component and sub components to re-render.  This is used to adjust the content indentation
    // against a changing side menu.  Check relative / absolute positioning of the layout
    const { portfolio_id } = useParams();
    //@ts-ignore
    const selectedPortfolio = useSelector(portfolioHydratedFactory(portfolio_id));
    const selectedPortfolioPeriod = useSelector(inFocusSelectors.portfolioInFocusPeriod);
    //const HASCompatibleEnv = useCheckHASCompatibleEnv({ portfolio: selectedPortfolio.data });

    const visibilitySettings = useSelector(visibilitySelector.mainClientVisibilitySettingsObjectsSelector).data;
    const selectedSnapShot = useSelector(
        inFocusSelectors.portfolioInFocusSnapshotHistorySelector
    );

    const currentMoment = moment();
    const start = moment(selectedPortfolioPeriod?.start_date);
    const end = moment(selectedPortfolioPeriod?.end_date);

    const isCurrentPeriod = (currentMoment <= end && currentMoment >= start);
    const selectedAdminAccess = useSelector(adminAccessSelector);

    const sideBarHidden = useSelector(contractSideBarHiddenSelector);
    const sideBarCollapsed = useSelector(contractSideBarCollapsedSelector);
    const leftMargin = useGetLeftMargin(sideBarHidden, sideBarCollapsed);

    const menuVisibility = useGetPIFSideBarMenuVisibility({
        visibilitySettings,
        portfolio: selectedPortfolio.data,
        frozenFor: selectedSnapShot?.focus?.frozen_for
    });
    const menuItems = useGetGroupedPortfolioMenu(selectedPortfolio.data, menuVisibility);
    const firstPath = getFirstPathFromGroupedMenuItems(menuItems);

    const isViewSnapshot = (): boolean => selectedSnapShot.focus !== undefined;
    const isSubHeaderDataReady = (): boolean => !!selectedPortfolio?.data;

    return (
        <>
            <PrivateRoute
                path="/portfolio/:portfolioId/"
                component={waitFor(PortfolioNavigation)}
            />
            {(isGlobalStoreReady || !selectedAdminAccess) && selectedPortfolio ? (
                <>
                    {(isSubHeaderDataReady() && selectedPortfolio) ? <ContractSubHeader portfolio={selectedPortfolio.data} isCurrentPeriod={isCurrentPeriod} /> : null}
                    {(isSubHeaderDataReady() && selectedPortfolio?.data) ? (
                        <>
                            {<InFocusSidebar
                                menuVisibility={menuVisibility}
                                menuItems={menuItems}
                                menuImage={selectedPortfolio?.data?.portfolio_image}
                            />}
                            <ContractLayoutContainer snapshotMode={isViewSnapshot()} isCurrentPeriod={isCurrentPeriod} active={active} >
                                <ContractContentWrapper
                                    className="contract-content-wrapper"
                                    leftMenuIndent={leftMargin}
                                >
                                    <Suspense fallback={<PageLoader />}>
                                        <Switch>
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}ppm`}
                                                component={waitFor(portfolioPPMsReport)}
                                            />
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}rm`}
                                                component={waitFor(portfolioReactiveReport)}
                                            />
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}specialist-maintenance`}
                                                component={waitFor(PortfolioSpecialistMaintenance)}
                                            />
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}audit`}
                                                component={waitFor(PortfolioAudit)}
                                            />
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}events`}
                                                component={waitFor(PortfolioLogs)}
                                            />
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}spend`}
                                                component={waitFor(PortfolioSpend)}
                                            />
                                            <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}financials`}
                                                component={waitFor(PortfolioFinancials)}
                                            />
                                            { // this is separate to the actual display of the menu item, it means the route won't return anything either...
                                                shouldViewPortfolioReportVisibility &&
                                                <PrivateRoute
                                                    portfolio={selectedPortfolio.data}
                                                    path={`${baseRoute}report`}
                                                    component={waitFor(PortfolioReportPage)}
                                                />
                                            }
                                            {/* {shouldViewHAS && <PrivateRoute
                                                // this is separate to the actual display of the menu item, it means the route won't return anything either...
                                                // obvs the backend should also ensure no significant data would be returned... this just handles not loading
                                                // the component
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}h-a-s`}
                                                component={waitFor(PortfolioHASManagement)}
                                            />} */}

                                            {shouldViewHAS && <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}h-a-s-checks-schedule`}
                                                component={waitFor(HASSchedule)}
                                            />}
                                            {shouldViewHAS && <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}h-a-s-work-permits`}
                                                component={waitFor(PaperWrappedPTWsList)}
                                            />}
                                            {shouldViewHAS && <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}h-a-s-accidents`}
                                                component={waitFor(HASAccidents)}
                                            />}
                                            {shouldViewHAS && <PrivateRoute
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}h-a-s-near-misses`}
                                                component={waitFor(HASNearMisses)}
                                            />}
                                            {firstPath && <PrivateRoute
                                                // THIS MUST BE PUT LAST SO WE ONLY REDIRECT TO THE FIRST MENU ITEM IF NO OTHER ROUTE IS MATCHED
                                                portfolio={selectedPortfolio.data}
                                                path={`${baseRoute}`}
                                                redirectTo={firstPath}
                                                component={() => <></>}
                                            />}
                                        </Switch>
                                    </Suspense>
                                </ContractContentWrapper>
                            </ContractLayoutContainer>
                        </>
                    ) : null}

                </>
            ) : null}
        </>
    );
};

//PortfolioLayout.whyDidYouRender = true;

export default memo(PortfolioLayout);
