import React, { useEffect, useState, useReducer, useRef } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';


// Own
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { ContractHASTaskSublistColumns, linkContractGridActions } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';
import { ContractInterface } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { EntityKeys } from "constants/entity.constants";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { HASTaskContractLink, HASTaskWithID } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import HASTaskServices from "components/AdminPanel/HAS/HASTaskServices";
interface ContractHASTaskViewProps {
    panelId: string;
    masterRecordData: ContractInterface;
    id: any;
    dataTestId: string;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    linkPanelTitle?: string;
}

const ContractHASTasksView: React.FunctionComponent<ContractHASTaskViewProps> = ({
    panelId,
    masterRecordData,
    id,
    //contractsState,
    dataTestId,
    masterRecordType,
    selectActionIdentifier,
    linkPanelTitle,
}) => {

    const currentMemberIds = useRef<(string | number)[]>();
    const [datasource, setDatasource] = useState<any>();
    const [metaData, setMetadata] = useState<FieldMetaGroup>();

    useEffect(() => {
        HASTaskServices.fetchContractHASTasksLinksPreFlightInfo(masterRecordData.contract_ref).then(response => setMetadata(response.meta));
    }, [])

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => HASTaskServices.fetchContractHASTaskLinks(masterRecordData.contract_ref).then(
                (response) => {
                    currentMemberIds.current = response.data.map((x: HASTaskContractLink) => x.task);
                    return response.data;
                }
            )
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function handleAddHASTaskToContract(taskIds: string[]) {
        const accessObjs = taskIds.map((taskId) => (
            {
                "contract": masterRecordData.id,
                "task": taskId,
            }
        ))
        HASTaskServices
            .linkHASTasksToContract(masterRecordData, accessObjs)
            .then(() => {
                // at the moment the admin panel is refreshed when closing the other admin picker panel, but we want to stop that so a reload will
                // be required
                datasource.reload();
            })
    }

    const handleRemoveHASTaskFromContract = (contract_task: HASTaskContractLink) => {
        HASTaskServices.removeHASTaskFromContract(
            masterRecordData,
            contract_task.task // we're using the task id for lookups on this route
        )
            .then(() => {
                datasource.reload();
            })
    };

    const handleTaskLink = (): void => {
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.h_a_s,
                onAddLinks: handleAddHASTaskToContract,
                currentMemberIds: currentMemberIds.current || [],
                linkPanelTitle,
            }
        )
    };

    const linkHASTask = selectActionIdentifier ? handleTaskLink : undefined;

    return (
        <>
            {metaData && <AdminDataGrid
                repaintChangesOnly
                data={datasource}
                editMode="cell"
                meta={metaData}
                dataTestId={dataTestId}
                getDatagridColumns={ContractHASTaskSublistColumns}
                masterView={false}
                entityKey={EntityKeys.HASTasks}
                columnActions={linkContractGridActions(handleRemoveHASTaskFromContract)}
                panelId={panelId}
                onUpdate={() => { }} // records are not currently updated in this context - just linked or removed
                onLink={linkHASTask}
            />}
        </>

    );
};

export default ContractHASTasksView;
