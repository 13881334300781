/* eslint-disable */
import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';

import Select from "@material-ui/core/Select";
import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

// Own
import { store } from "store/store";
import { onResponseError } from "services/API/API.interceptor.js"
import API, { APIN } from "services/API/API";
import { FieldMetaGroup, FieldGroup } from 'components/Common/Interfaces/Entity.interface';
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { newGetPayloadFromRef } from 'services/API/API.helper';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { APIPrivileges } from "services/Interface/Interface";
import { getPTWFormFieldsConfig, useGetPTWFormLayout } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermit.models";
import HotWorkIsolations from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/HotWorkIsolations";
import PTWTrainingCourses from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/PTWTrainingCourses";
import Roles from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/ConfinedSpaceRolesAndResponsibilities";
import { BasePermitToWork, ConfinedSpacesPTW, HotWorksPTW, LimitationOfAccessPTW, WorkPermitType, WorkingAtHeightPTW, workPermitTypesArray } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import CommonAPIService from "components/ContractInFocus/Services/commonAPI.services";
import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";
import commonAPIServices from 'components/ContractInFocus/Services/commonAPI.services';
import { getDataAndMeta } from "services/API/API.helper";
import { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { contractInFocusSiteSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { profileSelector } from "components/Profile/Selectors/Profile.selector";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";
import { WorkPermitDocViewer } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermitDocVIewer";
import { PreFlightListInfo } from "components/Common/Interfaces/Entity.interface";
import { Results } from 'components/Common/Interfaces/Entity.interface';
import { PtwType } from "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/WorkPermit.models";

// Styles
import "components/Schedulers/VisitModal/Components/VisitDetail/WorkPermits/workPermitForms.scss";
import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss";
import DataSource from 'devextreme/data/data_source';

export const generatePermitLabel = 'Generate Permit';
export const PTWSaveLabel = 'Save';

interface WorkPermitDetailProps {
    data: BasePermitToWork;
    visitId: string;
    initialPermissions?: APIPrivileges;
    dataSource: DataSource;
    docBaseRoute: string;
    setData: (value: React.SetStateAction<BasePermitToWork>) => void;
    preFlightDocInfo: PreFlightListInfo | undefined;
    currentListDataRef: React.MutableRefObject<Results<PtwType> | undefined>;
}

function AppriseWorkPermitDetailView({
    data,
    visitId,
    initialPermissions,
    dataSource,
    docBaseRoute,
    setData,
    preFlightDocInfo,
    currentListDataRef
}: WorkPermitDetailProps) {

    const sharedSpaceRef = useRef<any>({});
    const sharedSpace = sharedSpaceRef.current;
    const baseEndpoint = `visits/${visitId}/`
    const detailEndpoint = useRef<string>(`${baseEndpoint}ptws`);
    const pdfControllerEndpoint = `${baseEndpoint}ptw-pdf-controller/`;
    const [canRemovePDF, setCanRemovePDF] = useState(false);
    const [canGeneratePDF, setCanGeneratePDF] = useState(false);

    const canWrite = useRef<boolean>();
    const profileState = useSelector(profileSelector);

    const [thisRecord, setThisRecord] = useState<BasePermitToWork | HotWorksPTW | ConfinedSpacesPTW | WorkingAtHeightPTW | LimitationOfAccessPTW>(sharedSpace.data || data);
    const permissions = useRef<APIPrivileges | undefined>(initialPermissions);
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});

    const removePDFEndpoint = `${pdfControllerEndpoint}${thisRecord.id}/remove_permit_pdf/`;
    const generatePDFEndpoint = `${pdfControllerEndpoint}${thisRecord.id}/retry_permit_pdf_generation/`;

    const [metaData, setMetaData] = useState<FieldMetaGroup>();
    const formValuesRef = useRef<any>(data || {});

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1); // should start as 1 to ensure file is displayed

    const [permitType, setPermitType] = useState<WorkPermitType>(thisRecord.ptw_type);

    const generatePermitPDF = useCallback(() => {
        API.post(generatePDFEndpoint).then((response) => {
            processSuccessfulResponse(response, undefined, true);
        })
    }, []);

    const RetryGeneratePermitPDFButton = () => (
        <Button
            onClick={() => {
                generatePermitPDF();
            }}
        >
            Re-try Generating Permit PDF
        </Button>
    )

    useEffect(() => {
        // this should only trigger if the file has changed...
        if (thisRecord?.agreement?.file) {
            forceUpdate();
        }
    }, [thisRecord?.agreement?.file])

    const removePermitPDF = useCallback(() => {
        API.post(removePDFEndpoint).then((response) => {
            processSuccessfulResponse(response, undefined, true);
        })
    }, []);

    const RemovePermitPDFButton = () => (
        <Button
            onClick={() => {
                removePermitPDF();
            }}
        >
            Remove Permit PDF
        </Button>
    )

    const updateCanRemovePDF = useCallback((): void => {
        APIN.get(removePDFEndpoint).then((response) => {
            if (response.data) {
                setCanRemovePDF(true);
            }
        }).catch(error => {
            if (error.response?.status == 403) {
                setCanRemovePDF(false);
            } else {
                return onResponseError(error)
            }
        })
    }, [removePDFEndpoint]);

    const updateCanGeneratePDF = useCallback((): void => {
        APIN.get(generatePDFEndpoint).then((response) => {
            if (response.data) {
                setCanGeneratePDF(true);
            }
        }).catch(error => {
            if (error.response?.status == 403) {
                setCanGeneratePDF(false);
            } else {
                return onResponseError(error)
            }
        })
    }, [generatePDFEndpoint]);

    useEffect(() => {
        updateCanGeneratePDF();
        updateCanRemovePDF();
    }, [updateCanGeneratePDF, updateCanRemovePDF])

    const getFormFieldsConfig = useCallback((canWrite: boolean, thisPermitType, printable): FieldsFormConfig => {
        const thisConfig = getPTWFormFieldsConfig(
            { data, canWrite, permitType: thisPermitType, currentUser: profileState }
        );

        Object.keys(thisConfig).map(
            (x: any) => {
                thisConfig[x].printable = printable
            }
        )

        return thisConfig;
    }, [thisRecord]);

    const formFieldsConfig = useRef<FieldsFormConfig>();
    const printableFormFieldsConfig = useRef<FieldsFormConfig>();

    const getRenderPermitTypePicker = useCallback((thisPermitType: string) => () => {
        if (thisRecord.ptw_type) {
            return <></>;
        } else {
            return <FormControl className='permitTypePickerControl'>
                <InputLabel shrink htmlFor="permitTypeSelector-native-label">
                    Permit Type:
                </InputLabel>
                <Select
                    defaultValue={thisPermitType || ''}
                    onChange={(event: any) => {
                        setPermitType(event.target.value);
                    }}
                    id="permitTypeSelector"
                >
                    {workPermitTypesArray.map(wpt =>
                        <MenuItem key={wpt} value={wpt} className={wpt.replaceAll(" ", '')}>
                            {wpt}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        }

    }, [thisRecord]);

    const getRenderPermitRef = useCallback(() => () => {
        return <p className='permit-ref'>Permit Ref: {thisRecord.reference}</p>;
    }, [thisRecord]);


    const getFormLayout = useCallback((canWrite: boolean, thisPermitType): FieldGroup[] | undefined => {
        const RenderPermitTypePicker = getRenderPermitTypePicker(thisPermitType);
        const RenderPermitRef = getRenderPermitRef();
        const thisLayout = useGetPTWFormLayout({ data, permitType: thisPermitType, RenderPTWTrainingCourses, RenderRoles, RenderDescriptionOfIsolations, RenderPermitTypePicker, RenderPermitRef });
        return thisLayout;
    }, [thisRecord, getRenderPermitTypePicker, getRenderPermitRef]);

    const formLayout = useRef<FieldGroup[]>();

    useEffect(() => {
        // this useEffect will orchestrate all changes when the permitType changes.  In order to avoid multiple re-renders pretty much everything except the meta
        // (because it is fetched remotely and should be the last thing to change) will be a ref.
        // first, get the new endpoint:
        switch (permitType) {
            case "Hot Works": {
                detailEndpoint.current = `${baseEndpoint}hot-work-ptws/`;
            };
                break;
            case "Confined Space": {
                detailEndpoint.current = `${baseEndpoint}confined-space-ptws/`;
            };
                break;
            case "Limitation of Access": {
                detailEndpoint.current = `${baseEndpoint}limitation-of-access-ptws/`;
            };
                break;
            case "Working At Height": {
                detailEndpoint.current = `${baseEndpoint}working-at-height-ptws/`;
            };
                break;
            default: {
                detailEndpoint.current = `${baseEndpoint}ptws/`;
            }
        };
        // NB we don't set the id if we don't have a specific ptw_type yet as we want to use the list route for whatever the specific
        // ptw we're looking to create is - because we're using concrete inheritance on the backend the id for any specific ptw child record
        // (e.g. a 'hot works' record) will be the same as the id of the base 'permit to work' record, but we won't be using it until
        // the specific permit to work has been created by this form
        // second, get the new meta:
        let includeIdForPreFlightInfo;
        if (permitType) {
            if (!data.ptw_type) {
                // we're going to need to create the ptw child type and because permitType is defined, 
                // the meta will be for creating a child permit 'type', so visit will be removed as a readonly field
                includeIdForPreFlightInfo = undefined;
            } else {
                // we already have a child ptw
                includeIdForPreFlightInfo = thisRecord?.id;
            }
        } else {
            // we must be editing the base ptw
            includeIdForPreFlightInfo = thisRecord?.id;
        }
        commonAPIServices.getPreFlightInfo(detailEndpoint.current, includeIdForPreFlightInfo).then(response => {
            canWrite.current = response.canCreate || response.canUpdate;
            formFieldsConfig.current = getFormFieldsConfig(response.canCreate, permitType, false);
            printableFormFieldsConfig.current = getFormFieldsConfig(response.canCreate, permitType, true);
            formLayout.current = getFormLayout(!!canWrite, permitType);
            permissions.current = response.privileges;
            // now set the meta...
            setMetaData(response.meta);
        }).catch(
            (error) => { console.log(error) }
        )
    }, [permitType])

    const RenderDescriptionOfIsolations = useCallback(() => {
        if (thisRecord.ptw_type === "Hot Works") {
            return <HotWorkIsolations
                hot_works_permit={data as HotWorksPTW}
                title="Isolation Systems"
            />
        } else {
            return <span className="preSaveNote">Isolation systems for hot work permits should be added here once the permit has been saved</span>
        }

    }, [data]);

    const RenderPTWTrainingCourses = useCallback(() => {
        if (thisRecord.ptw_type === "Confined Space") {
            return <PTWTrainingCourses
                ptw={data as BasePermitToWork}
                title="Training Courses"
            />
        } else {
            return <></>
        }
    }, [data]);

    const RenderRoles = useCallback(() => {
        if (thisRecord.ptw_type === "Confined Space") {
            return <Roles
                ptw={data as BasePermitToWork}
                title="Roles and Responsibilities"
            />
        } else {
            return <></>
        }
    }, [data]);


    const processSuccessfulResponse = (response: any, callback?: any, dataOnly?: boolean | undefined) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        const thisResponse = dataOnly ? response : getDataAndMeta(response);
        const thisData = thisResponse.data;
        const thisMeta = dataOnly ? undefined : thisResponse.meta;
        sharedSpace.data = formValuesRef.current = thisData;
        setThisRecord(thisData);
        !dataOnly && setMetaData(thisMeta);
        commonUpdateGridRow({
            dataSource,
            key: data.id,
            changes: thisData,
            currentListDataRef: currentListDataRef
        });
        callback && callback();
        forceUpdate();
    }

    const handleSave = (recordId?: string, callback?: any) => {
        const thisMeta = metaData;
        let payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: thisMeta });
        payload = {
            ...payload,
            permittowork: data.id
        }
        const getEndpoint = () => detailEndpoint.current
        if (permitType && !thisRecord.ptw_type) {
            CommonAPIService.create<any>({ getEndpoint, values: payload }).then((response) => {
                processSuccessfulResponse(response, callback);
            })
        } else {
            CommonAPIService.update<any>(getEndpoint, visitId, data.id, payload).then((response) => {
                processSuccessfulResponse(response, callback);
            })
        }
    }

    const generatePermit = (recordId?: string, callback?: any) => {
        const thisMeta = metaData;
        let payload = newGetPayloadFromRef({ ref: formValuesRef, metaData: thisMeta });
        payload = {
            ...payload,
            'submit_for_signing': true,
            permittowork: data.id
        }
        const getEndpoint = () => detailEndpoint.current;
        // NB if the permitType is not set, then the endpoint should be set to point at the 'base' permit record
        if (thisRecord.ptw_type) {
            CommonAPIService.update<any>(getEndpoint, visitId, data.id, payload).then((response) => {
                processSuccessfulResponse(response, callback);
            })
        } else {
            console.log('cannot generate a permit without a permit type');
        }
    };

    sharedSpace.handleSave = handleSave;
    const workPermitFormId = 'WorkPermitForm';

    const getButtons = useCallback(() => {
        const buttons = generateStandardGeneralActionButtonDefinitions({
            handleSave,
            handleSubmit: thisRecord.ptw_type && generatePermit, //thisRecord.ptw_type ? getPDF : undefined,
            formSubmitted: thisRecord.submit_for_signing,
            overrideFormChanged: permitType !== data.ptw_type,
            overrideSubmitLabel: generatePermitLabel,
            overrideSubmittedLabel: 'Should be hidden after permit generated',
            formErrors
        })
        return buttons
    }, [handleSave, permitType, thisRecord, generatePermit]);
    if (thisRecord.agreement) {
        return <>
            <WorkPermitDocViewer
                data={data}
                setData={setData}
                docBaseRoute={docBaseRoute}
                preFlightDocInfo={preFlightDocInfo}
                isOpen={!!data.agreement}
                showDownloadButton
                signalRefresh={mustRefresh}
            />
            {canRemovePDF && <RemovePermitPDFButton />}
        </>
    } else if (thisRecord.submit_for_signing && visitId) {
        return <>
            <iframe className="signature-progress-form-wrapper" frameBorder="0" src={`${thisRecord.signature_progress_url}`} />
            {
                !thisRecord?.agreement && thisRecord.ptw_status !== "preparing" && canGeneratePDF && <RetryGeneratePermitPDFButton />
            }
        </>
    } else {
        return <>
            {
                metaData && formFieldsConfig.current && formLayout.current && mustRefresh && !thisRecord.submit_for_signing &&
                <>
                    <div id={workPermitFormId}>
                        <GeneralEntityForm
                            buttons={getButtons()}
                            useDefaultRevertChanges
                            formValuesRef={formValuesRef}
                            refreshSignal={mustRefresh}
                            formLevelSharedSpace={sharedSpace}
                            initialData={thisRecord}
                            canWrite={canWrite.current}
                            formFieldsConfig={formFieldsConfig.current}
                            formLayout={formLayout.current}
                            meta={metaData}
                            dispatchRefreshContext={forceUpdate}
                            gridClass="workPermitsForm fieldGroupsForm"
                            actionsAtTop
                            actionsAtBottom
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                    </div>
                    <div id={`printable${workPermitFormId}`}>
                        <GeneralEntityForm
                            buttons={getButtons()}
                            useDefaultRevertChanges
                            formValuesRef={formValuesRef}
                            refreshSignal={mustRefresh}
                            formLevelSharedSpace={sharedSpace}
                            initialData={thisRecord}
                            canWrite={canWrite.current}
                            formFieldsConfig={printableFormFieldsConfig.current}
                            formLayout={formLayout.current}
                            meta={metaData}
                            dispatchRefreshContext={forceUpdate}
                            gridClass="workPermitsForm fieldGroupsForm"
                            actionsAtTop
                            actionsAtBottom
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                    </div>
                </>
            }
        </>
    }
}
// AppriseWorkPermitDetailView.whyDidYouRender = true;
export default AppriseWorkPermitDetailView;