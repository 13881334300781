/* eslint-disable */
import React, { useCallback, useEffect } from 'react';
import { IHASRiddor, IHASAccident } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { useGetRiddorFormLayout, getRiddorFormConfig } from "components/ContractInFocus/HAS/HASAccidentDetailModels";
import IncidentFormManagementWrapper, { PreManagementWrapperIncidentFormProps, PrepareBasicHASIncidentFormConfigProps, GetIncidentFormLayoutProps } from "components/ContractInFocus/HAS/IncidentFormManagementWrapper";
import { simpleFetchRiddorFieldInfoList } from "components/AdminPanel/HAS/HASIncidentServices";
import { getRiddorRoute } from 'components/AdminPanel/HAS/HASIncidentServices';
import { RiddorSubList, AccidentCheckList } from "components/ContractInFocus/HAS/RiddorSubForms";

interface RiddorProps extends PreManagementWrapperIncidentFormProps {
    accident: IHASAccident;
}

const prepareBasicFieldConfigsForRiddor = ({
    thisRecord,
    HASAdminAccess
}
    : PrepareBasicHASIncidentFormConfigProps) => {
    let fieldsConfig = (getRiddorFormConfig({ HASAdminAccess: HASAdminAccess }))
    return fieldsConfig
}

function HASRiddorView({
    ...props
}: RiddorProps) {
    const gridClass = `riddor HASIncidentDetailForm fieldGroupsForm darker-disabled`;
    const WrappedGetFormLayoutForRiddor = useCallback((accident: IHASAccident) => {
        return ({
            thisRecord,
            HASAdminAccess,
            simpleLayout
        }: GetIncidentFormLayoutProps
        ) => {
            const record = thisRecord as IHASRiddor;

            const RenderAPQualificationsList = () => {
                return <RiddorSubList
                    riddor={record}
                    sublistType='qualification'
                    title="Qualifications"
                />
            };

            const RenderAPTrainingCoursesList = () => {
                return <RiddorSubList
                    riddor={record}
                    sublistType='course'
                    title="Training Courses Attended"
                />
            };

            const RenderCheckList = () => {
                return <AccidentCheckList
                    accident={accident}
                    title="Check List"
                />
            };
            if (simpleLayout) { return undefined } else {
                const layout = useGetRiddorFormLayout({
                    HASAdminAccess: HASAdminAccess,
                    RenderAPQualificationsList: RenderAPQualificationsList,
                    RenderAPTrainingCoursesList: RenderAPTrainingCoursesList,
                    RenderCheckList: RenderCheckList,
                })
                return layout;
            };
        }


    }, []);

    const UseGetFormLayoutForRiddor = WrappedGetFormLayoutForRiddor(props.accident);
    return <IncidentFormManagementWrapper
        {...props}
        prepareBasicFieldConfigs={prepareBasicFieldConfigsForRiddor}
        getFormLayout={UseGetFormLayoutForRiddor}
        gridClass={gridClass}
        simpleFetchFieldInfoList={simpleFetchRiddorFieldInfoList}
        recordType="riddor"
        getIncidentRoute={getRiddorRoute}
        showReadOnly
    />
}

export default React.memo(HASRiddorView);