import moment from "moment";
import { dateFormatMonth } from "../constants";

export const getDatesAndLabelsBetween = (startDate, endDate) => {
  const result = [
    {
      date: moment(startDate),
      label: startDate.format(dateFormatMonth)
    }
  ];

  while (startDate < endDate) {
    startDate = startDate.add(1, "months");
    result.push({
      date: moment(startDate),
      label: startDate.format(dateFormatMonth)
    });
  }

  return result;
};

export const SHORT_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const dateUDF = "YYYY-MM-DD";
export const dateTimeUDF = "YYYY-MM-DDThh:mm";

export const DISPLACE_ZERO_STARTING_MONTH = 1;

export const monthAsString = (monthAsNumber, format = "MMM") =>
  moment(`2010-${monthAsNumber}-01`, "YYYY-MM-DD").format(format);

export const toDateLabel = date => moment(date).format("MMM YYYY");

export const pickerFormat = "dd/MM/yyyy";

export const standardFormat = "DD/MM/YYYY";

export const standardDashFormat = "DD-MM-YYYY";

export const periodMonthFormat = "MMM YYYY";

export const wordDateFormat = "MMMM Do YYYY";

export const wordDateFormat2 = "Do MMMM YYYY";

export const getInWordDateFormat = date =>
  date ? moment(date || {}).format(wordDateFormat) : "";

export const standardDateFormat = date =>
  date ? moment(date || {}).format(standardFormat) : "";

export const standardDateTimeFormat = "DD/MM/YYYY hh:mm";

export const recordDateTime = date =>
  moment(date || {}).format(standardDateTimeFormat);

export const recordDate = date => moment(date || {}).format("DD MMMM YYYY");
export const periodDate = date => moment(date || {}).format(periodMonthFormat);
export const longPeriodDate = date => moment(date || {}).format("MMMM YYYY");

export const saveDateFormat = date => moment(date || {}).format(dateUDF);
export const fromSaveDateFormat = date =>
  fromSaveDateFormatMoment(date).toDate();
export const fromSaveDateFormatMoment = date => moment(date, dateUDF);

export const saveMonthDayFormat = date => moment(date || {}).format("MM-DD");

export const saveMonthDayTimeFormat = date =>
  moment(date || {}).format("MM-DDThh:mm");

export const saveDateTimeFormat = date =>
  moment(date || {}).format(dateTimeUDF);

export const fromSaveDateTimeFormat = dateTime => moment(dateTime, dateTimeUDF);

export const getMonthsBetween = (start_date, end_date, format = "MMM YYYY") => {
  const months = [];
  let thisDate = moment(start_date);
  while (thisDate <= moment(end_date)) {
    months.push(thisDate.format(format));
    thisDate = thisDate.add(1, "month");
  }
  return months;
};

export const getPeriodBetween = values => {
  let newStartMoment = moment(values.startDate);
  let newEndMoment = moment(values.endDate);
  if (values.wholeMonths) {
    newStartMoment = newStartMoment.startOf("month");
    newEndMoment = newEndMoment.endOf("month");
  }
  const newStartDate = newStartMoment.format(dateUDF);
  const newEndDate = newEndMoment.format(dateUDF);
  const months = getMonthsBetween(newStartDate, newEndDate, periodMonthFormat);
  return {
    start_date: newStartDate,
    end_date: newEndDate,
    months: months
  };
};

export const getLastXYearsFrom = (years, endDate) => {
  if (!endDate) {
    endDate = moment();
  }
  let counter = 0;
  const options = [];
  while (counter < years) {
    // all the moment re-initializing is because the methods modify the object rather than returning a new moment object
    let thisDate = moment(endDate).subtract(counter, "year");
    options.push({
      start_date: moment(thisDate).startOf("year"),
      end_date: moment(thisDate).endOf("year")
    });
    counter += 1;
  }
  return options;
};

export const getLastXYearsFromFormatted = (years, endDate) => {
  const options = getLastXYearsFrom(years, endDate);
  const formatted = options.map(x => ({
    start_date: x.start_date.format(dateUDF),
    end_date: x.end_date.format(dateUDF)
  }));
  return formatted;
};
