import React, { useState, useEffect, memo, ReactElement, useReducer, useCallback } from 'react';
import { useSelector } from 'react-redux';
// Own
import { GeneralDocsGridWrapper } from 'components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentGridStyles';
import { VisitDocumentFilesWrapper } from "components/ContractInFocus/Styles/CommonStyles";
import { SupplierService } from 'components/Schedulers/Interfaces/Schedule.interfaces';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";

//import DocumentsUploader from "components/Common/Components/DocumentsUploader/DocumentUploader";
import DocumentsGrid, { getGeneralDocsColumnConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid";
import { ColumnsConfig } from 'components/ContractInFocus/Interfaces/DataGridColumn.interface';
import { TabbedPanel } from "components/Common/Components/TabPanel";
import { getSupplierServiceVisitReportsRoute } from "components/Schedulers/Services/visitService";
import { Period } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";

import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';

function getColumnsConfig() {
    return {
        'visit_scheduled_for': {
            caption: 'Visit Scheduled For',
            dataType: 'date',
            format: 'd MMM yyyy',
            width: '180px'
        },
        'visit_service_type': {
            caption: 'Visit Service Type',
            dataType: 'string',
            width: '150px'
        },
        'visit_document_type': {
            caption: 'Document Category',
            width: '225px'
        },
        'notes': {
            caption: "Notes",
        },
        'internal_access_only': {
            caption: 'Internal',
            // this will automatically be made invisible by the documents grid component for non internal users
        },
        // 'updated_at': {
        //     caption: 'Last Updated At',
        //     dataType: 'date',
        //     format: 'd MMM yyyy HH:MM:SS'
        // }
    }
}

interface VisitDocumentGridProps {
    contractId: string | number;
    portfolio?: HydratedPortfolio;
    supplierService: SupplierService;
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    selectedPeriod: Period;
}

const SupplierContractVisitReportsGrid = ({
    contractId,
    portfolio,
    supplierService,
    index,
    setIndex,
    selectedPeriod,
}: VisitDocumentGridProps) => {
    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 0);
    const [fetchFilters, setFetchFilters] = useState<any>({})
    useEffect(() => {
        if (fetchFilters.start_date !== selectedPeriod.start_date || selectedPeriod.end_date !== fetchFilters.end_date) {
            let theseFetchFilters = fetchFilters || {}
            theseFetchFilters = { ...theseFetchFilters, start_date: selectedPeriod.start_date, end_date: selectedPeriod.end_date };
            setFetchFilters(theseFetchFilters);
        }
    }, [selectedPeriod, fetchFilters])
    const visitReportBasePath = getSupplierServiceVisitReportsRoute({
        portfolioId: portfolio?.id,
        contractId: contractId,
        supplierServiceId: supplierService.id
    })
    const [documentsBasePath, setDocumentsBasePath] = useState(`supplier-services/${supplierService.id}/documents/`)
    const [visitReportsColumnConfig, setVisitReportsColumnsConfig] = useState<ColumnsConfig>();
    const [generalDocsColumnConfig, setGeneralDocsColumnConfig] = useState<ColumnsConfig>();
    const internalMember = useSelector(internalMemberSelector);
    const showSupplierContractDocsTab = supplierService.contract__expose_supplier_quotation_documents_by_default || internalMember;
    let tabs = ["Visit Reports"];
    if (showSupplierContractDocsTab) {
        tabs = [...tabs, "Contract Documents"]
    }

    useEffect(() => {
        const thisColumnsConfig = getColumnsConfig();
        setVisitReportsColumnsConfig(thisColumnsConfig);
        setGeneralDocsColumnConfig(getGeneralDocsColumnConfig());
    }, [])

    return (
        <>
            <TabbedPanel
                pageIndex={index}
                showTabs={true}
                onChangeTab={(index: number) => { setIndex(index) }}
                tabs={tabs}
            >
            </TabbedPanel>
            {index === 0 && <GeneralDocsGridWrapper>
                <VisitDocumentFilesWrapper>
                    {visitReportsColumnConfig && <DocumentsGrid
                        listBasePath={visitReportBasePath}
                        surrogate_portfolio_id={portfolio?.id}
                        columnsConfig={visitReportsColumnConfig}
                        fetchFilters={fetchFilters}
                        refreshSignal={mustRefresh}
                        hideSource
                    />}
                </VisitDocumentFilesWrapper>

            </GeneralDocsGridWrapper>}
            {index === 1 && showSupplierContractDocsTab && <GeneralDocsGridWrapper>
                <VisitDocumentFilesWrapper>
                    {generalDocsColumnConfig && <DocumentsGrid
                        listBasePath={documentsBasePath}
                        surrogate_portfolio_id={portfolio?.id}
                        columnsConfig={generalDocsColumnConfig}
                        refreshSignal={mustRefresh}
                    />}
                </VisitDocumentFilesWrapper>

            </GeneralDocsGridWrapper>}
        </>
    )
}

export default memo(SupplierContractVisitReportsGrid);