import React, { useCallback, useEffect, useState, useRef, memo } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import { store } from "store/store";
import { getPortfolioMonthlyMaintenanceReports } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { retreivePortfolioInFocusFromCache, setSelectedPortfolioPeriod, setSelectedPortfolioSnapshot, addPortfolioACL } from 'components/PortfolioInFocus/Actions/portfolioInFocus.actions';
import { portfolioPortfolioPeriodsSelector } from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import { parseContractPeriodUrl } from 'components/Layout/Contract/helpers/contractLayout.helper';
import { historyPushPeriod } from "components/Layout/Contract/Containers/ContractSubHeading/ContractSubHeading";
import * as inFocusSelectors from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import { switchPortfolioInFocus, addPortfolioInFocusToCache } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { clearContractInFocus } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import { fetchPersonalPortfolioInFocusSettings } from "components/ContractInFocus/Services/personalInFocusSettings.services";
// import { fetchBasicPortfolioVisibilitySettings } from "components/PortfolioInFocus/Actions/portfolioVisibilitySettings.actions";
import { fetchClientStaticOpsDataVisibilitySettings } from "components/ContractInFocus/Actions/contractVisibilitySettings.actions";
import { NOTIFICATION_ERROR, NOTIFICATION_WARNING } from 'components/Notification/Constants/constants';
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { headerSearch } from 'components/Layout/actions/Layout.actions';
import { portfolioHydratedFactory } from "components/Portfolios/Selectors/Portfolio.selectors";
import { simpleFetchPortfolioPeriods } from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";
import { ContractPeriodDates, PeriodOption } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { isEqual } from 'lodash';
import { getMenuAccess } from 'components/Profile/Helpers/profileHelpers';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const findFocusedPortfolioPeriodByDate = (portfolioPeriods: PeriodOption[], contractPeriodDates?: PeriodOption): PeriodOption | undefined => {
    // this function basically just makes sure that the dates selected are ones from the list...
    // console.log('portfolioPeriods: ', portfolioPeriods);
    // console.log('contractPeriodDates: ', contractPeriodDates);
    let cpFound = undefined;
    if (contractPeriodDates) {
        cpFound = portfolioPeriods?.find((contractPeriod: PeriodOption) => contractPeriod.start_date === contractPeriodDates?.start_date && contractPeriod.end_date === contractPeriodDates?.end_date)
    }
    return cpFound;
}

export const getCurrentOrMostRecentPortfolioPeriod = (portfolioPeriods: PeriodOption[]): PeriodOption => {
    let cpDefault = portfolioPeriods[0];
    let cpFound = null;
    let today = new Date();
    cpFound = portfolioPeriods?.find((portfolioPeriod: PeriodOption) => {
        const startDate = new Date(portfolioPeriod.start_date);
        const endDate = new Date(portfolioPeriod.end_date);
        return today >= startDate && today <= endDate;
    })
    return cpFound || cpDefault;
}

const PortfolioNavigation = () => {
    const history = useHistory();
    //@ts-ignore
    const { portfolio_id } = useParams();
    // console.log('portfolio_id: ', portfolio_id);
    const snapshotQueryParam = useQuery().get('snapshot');
    const portfolioPeriodQueryParam = useQuery().get('portfolioPeriod');
    // console.log('portfolioPeriodQueryParam: ', portfolioPeriodQueryParam);
    const selectedPortfolioId = useSelector(inFocusSelectors.portfolioInFocusPortfolioId);
    // console.log('selectedPortfolioId: ', selectedPortfolioId);
    const selectedPortfolio = useSelector(portfolioHydratedFactory(selectedPortfolioId));
    const portfolioInFocus = useSelector(inFocusSelectors.portfolioInFocus);
    const selectedFocusedPortfolioPeriod = useSelector(inFocusSelectors.portfolioInFocusPeriod);
    const selectedSnapShot = useSelector(inFocusSelectors.portfolioInFocusSnapshotHistorySelector);
    const targetPortfolio = useSelector(portfolioHydratedFactory(portfolio_id));
    const active = selectedPortfolio.data?.active;
    const [checkIfActive, setCheckIfActive] = useState(false); // this exists to prevent false postives before it has had a chance for the value to settle on load.
    const portfolioInFocusCache = useSelector(inFocusSelectors.portfolioInFocusCacheSelector);
    const portfolioPeriodOptions = useSelector(portfolioPortfolioPeriodsSelector(selectedPortfolioId))?.data;
    //console.log('portfolioPeriodOptions: ', portfolioPeriodOptions);
    //console.log('selectedPortfolioId: ', selectedPortfolioId);
    const dispatch = useCallback(useDispatch(), []);

    useEffect(() => {
        // this is merely to clear the search header on the index page whenever a portfolio is chosen.
        dispatch(headerSearch({ search: '' }));
    }, [])
    const countLoop = useRef(0);
    /* 
    * This monitors primarily the snapshot part of the URL, and when there is a mismatch between what's in the URL and what's in the state,
    * the use effect will make the necessary dispatches to fetch the snapshot and update the state and also related contract data       
    */
    useEffect(() => {
        const isCurrent = snapshotQueryParam?.indexOf('Live') !== -1;
        const portfolioChanged = parseInt(selectedPortfolioId) !== parseInt(portfolio_id);
        const snapshotChanged = selectedSnapShot.focus?.frozen_for !== (snapshotQueryParam && (snapshotQueryParam.indexOf('Live') === -1) ? snapshotQueryParam : undefined); // convert null value
        const hasPortfolioPeriodChanged = !!portfolioPeriodQueryParam && `${selectedFocusedPortfolioPeriod?.start_date}_${selectedFocusedPortfolioPeriod?.end_date}` !== (portfolioPeriodQueryParam ? portfolioPeriodQueryParam : undefined);
        const focusedPortfolioPeriodDatesFromUrl = parseContractPeriodUrl(portfolioPeriodQueryParam);
        // although we might be able to allow any period to be set in the url, we use the same paradigm as contracts, largely to keep things sane for 
        // snapshots - otherwise the amount of data snapshotted could vary for the same snapshot based on what parameters were in the url at the time
        // or alternatively, the data shown for a 'snapshot' wouldn't necessarily have been taken to support the parameters currently in the url.
        let focusedPortfolioPeriod = findFocusedPortfolioPeriodByDate(portfolioPeriodOptions, focusedPortfolioPeriodDatesFromUrl);
        if (snapshotChanged && selectedSnapShot.data) {
            //console.log("the snapshot has changed... so let's dispatch the relevant select..");
            // Searches through the snapshots in state to try and find the appropriate snapshot
            const foundSnapshotId = Object.keys(selectedSnapShot.data).find(key => selectedSnapShot.data[key].frozen_for === snapshotQueryParam);
            if ((foundSnapshotId || isCurrent) && selectedFocusedPortfolioPeriod) {
                // if the snapshot is found - parse the contract period if there is one 
                const snapshot = foundSnapshotId ? selectedSnapShot.data[foundSnapshotId] : undefined;
                countLoop.current = countLoop.current + 1;
                // send a dispatch which will make the necessary API calls to update the state
                if (countLoop.current < 3) { // this shouldn't be needed, it's only here to prevent an infinite loop in case something goes wrong with snapshotChanged calculation
                    dispatch(setSelectedPortfolioSnapshot({
                        selectedSnapshot: snapshot
                    }));
                }

            }
        }
        if (targetPortfolio.data) {
            if (portfolioChanged) {
                getMenuAccess({ portfolio: targetPortfolio.data }).then((response) => {
                    dispatch(addPortfolioACL(targetPortfolio.data.id, response))
                })
                dispatch(clearContractInFocus()); // this ensures that when loading the contract next time visibility bits overwritten by the portfolio in focus will be replaced
                //console.log('portfolioPeriodOptions: ', portfolioPeriodOptions);
                const cachedPortfolioInFocus = targetPortfolio && portfolioInFocusCache ? portfolioInFocusCache[targetPortfolio.data.id] : null;
                if (cachedPortfolioInFocus) {
                    dispatch(retreivePortfolioInFocusFromCache(cachedPortfolioInFocus));
                } else {
                    dispatch(switchPortfolioInFocus(targetPortfolio.data.id));
                }
                targetPortfolio.data.id && fetchPersonalPortfolioInFocusSettings(targetPortfolio.data);
                dispatch(fetchClientStaticOpsDataVisibilitySettings({ portfolio: targetPortfolio.data }));
                // dispatch(fetchBasicPortfolioVisibilitySettings(portfolioId));
                // getContractMeterStreams(targetPortfolio.id, targetPortfolio.portfolioId);
                dispatch(getPortfolioMonthlyMaintenanceReports(targetPortfolio.data.id));
                simpleFetchPortfolioPeriods(portfolio_id).then((portfolioPeriods) => {
                    if (!portfolioPeriods.data.length) {
                        store.dispatch(addNotification(
                            {
                                message: 'No portfolio periods found.  Please create at least one portfolio period for this portfolio',
                                type: NOTIFICATION_ERROR
                            })
                        )
                        history.push(`/portfolios?noRedirect=1`);
                    } else {
                        // console.log('got portfolio periods after changing portfolio');
                        if (!hasPortfolioPeriodChanged) { //this prevents overriding the correct contract period when loading everything from scratch from the url (as with the pdf generator)
                            focusedPortfolioPeriod = getCurrentOrMostRecentPortfolioPeriod(portfolioPeriods.data);
                            //NB the next line triggers an epic that fetches the snapshot.
                            focusedPortfolioPeriod && dispatch(setSelectedPortfolioPeriod(focusedPortfolioPeriod));
                        }
                    }
                })
            } else {
                if (selectedFocusedPortfolioPeriod && !hasPortfolioPeriodChanged && !portfolioPeriodQueryParam) {
                    // console.log("now we have a selected portfolio period in the state and it has not just changed, but there is no param for it in the url..\
                    //let's add it to the url");
                    historyPushPeriod(history, selectedFocusedPortfolioPeriod, "portfolioPeriod");
                }
                if (hasPortfolioPeriodChanged) {
                    //console.log("the contract period has changed... so let's dispatch the relevant select..: ", focusedPortfolioPeriod);
                    // if (!focusedPortfolioPeriod) {
                    //     // this means we weren't able to find it, so set it to be the first
                    //     historyPushPeriod(history, portfolioPeriodOptions[0], "portfolioPeriod");
                    // } else {
                    //     focusedPortfolioPeriod && dispatch(setSelectedPortfolioPeriod(focusedPortfolioPeriod));
                    // }
                    focusedPortfolioPeriod && dispatch(setSelectedPortfolioPeriod(focusedPortfolioPeriod));
                }
            }
        }

    },
        [dispatch, targetPortfolio, snapshotQueryParam, selectedSnapShot, portfolioPeriodOptions, portfolioPeriodQueryParam, portfolio_id]
        //[dispatch, targetPortfolio, snapshotQueryParam, selectedSnapShot, portfolioPeriodOptions, selectedFocusedPortfolioPeriod, portfolioPeriodQueryParam, portfolio_id, selectedPortfolio?.data?.id]
    );
    useEffect(() => {
        if (!isEqual(portfolioInFocusCache, portfolioInFocus)) {
            dispatch(addPortfolioInFocusToCache(portfolioInFocus));
        }
    }, [portfolioInFocus]);

    useEffect(() => {
        const t = setTimeout(() => {
            // this exists to prevent false postives before it has had a chance for the value to settle on load.
            setCheckIfActive(true);
        }, 500);
        return () => { clearTimeout(t) }
    }, []);

    useEffect(() => {
        if (checkIfActive) {
            if (!active) {
                store.dispatch(addNotification({
                    message: "This contract has been deactivated!",
                    type: NOTIFICATION_WARNING
                }))
            }
        }
    }, [checkIfActive]);
    return (
        <></>
    )
}

export default memo(PortfolioNavigation);