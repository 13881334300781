import * as contractActions from "components/ContractInFocus/Actions/contractInFocus.actions";
import { PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';

export interface ContractACLI {
    planned: PreFlightListInfo,
    reactive: PreFlightListInfo,
    specialist: PreFlightListInfo,
    events: PreFlightListInfo,
    financials: PreFlightListInfo,
    audit: PreFlightListInfo,
    spend: PreFlightListInfo,
    meter_reading?: PreFlightListInfo,
    report?: PreFlightListInfo,
    health_and_safety: PreFlightListInfo,
    h_a_s_checks: PreFlightListInfo,
    h_a_s_accidents: PreFlightListInfo,
    h_a_s_near_misses: PreFlightListInfo,
    h_a_s_permits: PreFlightListInfo,
}

export type contractsACLState = {
    [contractId: number]: ContractACLI
};

const initialState = {};

const contractACLReducer = (state: contractsACLState = initialState, { type, payload }: { type: string, payload: any }) => {
    switch (type) {

        case contractActions.ADD_CONTRACT_MENU_ACCESS.reducer: {
            return {
                ...state,
                [payload.contractId]: payload.menuAccess
            };
        }

        case contractActions.CLEAR_ALL_CONTRACTS_MENU_ACCESS.reducer: {
            return initialState
        }

        case contractActions.REMOVE_CONTRACT_MENU_ACCESS.reducer: {
            const { [payload.contractId]: removed, ...rest } = state;
            return rest;
        }

        default:
            return state;
    }
};

export default contractACLReducer;
