import API, { APIR, APIN, getMultipartConfig } from '../../../services/API/API';
import { AxiosRequestConfig } from 'axios';
import path from "path";
import { map, debounceTime } from 'rxjs/operators';
import { onResponseError } from "services/API/API.interceptor.js"
import { ListResponse, Results, TransformedSingleResult, Dictionary, FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { getDataAndMetaAndPUTMeta } from "services/API/API.helper";

import {
  getListMeta,
  getListPrivileges,
  getUnpaginatedListPrivileges,
  getListPUTMeta,
  getDescription,
  getListGETMeta,
  removeReadOnlyFields,
  getPrivileges,
  getPreFlightListMeta,
  preFlightCanCreateCheck,
  getPreFlightDetailMeta,
  preFlightCanUpdateCheck
} from '../../../services/API/API.helper';
import { DataGridMeta } from '../interfaces/dataGridColumn.interface';
import { logError } from "services/Log/Log.service";
import { ContractOrPortfolio } from "services/API/common/contractAPIs";
import { isEqual } from 'lodash';
import { store } from "store/store";

import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';

type GetEndpoint = {
  (id?: string | number): string;
}

type SetMetaData = {
  (metadata: DataGridMeta): void;
}

const getBaseUrl = (url: string) => {
  let result = url;
  const indexQueryStringStart = result.indexOf("?");
  if (indexQueryStringStart !== -1) {
    result = result.substring(0, indexQueryStringStart)
  }
  return result;
}

export const updateOrderApi = async (baseUrl: string, id: number | string, data: any) => {
  const url = path.join(baseUrl, `${id}/`);
  try {
    const response = await API.put(url, data);
    return response;
  } catch (err) {
    logError(`PUT_${url}_${JSON.stringify(data)}_error:${JSON.stringify(err)}`);
    return null;
  }
};

const prepareListMetaData = (response: any, currentMetaDataRef?: any) => {
  const description = getDescription(response);
  const totalCount = response.data.count;
  const getMeta = getListGETMeta(response).GETMeta;
  const putMeta = getListPUTMeta(response).PUTMeta;
  const postMeta = getListMeta(response);
  let newMetaData = { loaded: true, totalCount: totalCount, description: description, activeMeta: (putMeta || postMeta || getMeta), GETMeta: getMeta, PUTMeta: putMeta, POSTMeta: postMeta, privileges: getPrivileges(response) }
  if (currentMetaDataRef) {
    if (isEqual(newMetaData, currentMetaDataRef?.current)) {
      return currentMetaDataRef.current
    }
    currentMetaDataRef.current = newMetaData;
  }
  return newMetaData;
}

interface PrepareResponseProps {
  response: any;
  setMetaData?: SetMetaData,
  currentMetaDataRef?: React.MutableRefObject<DataGridMeta>,
  processData?: (data: any) => any;
}

const prepareListResponse = ({ response, setMetaData, currentMetaDataRef, processData }: PrepareResponseProps) => {
  const totalCount = response.data.count;
  const grouped = response.data.grouped;
  const processedMetaData = prepareListMetaData(response, currentMetaDataRef);
  if (response.data?.results.metadata) {
    response.data.results.metadata = processedMetaData; // ensuring returned metaData is the same as metaData that would be set
  }
  if (setMetaData) {
    setMetaData(processedMetaData);
  }
  if (processData) {
    response.data.results.data = processData(response.data.results.data);
  }
  const return_obj = { ...response.data.results, totalCount: totalCount, grouped: grouped }
  //console.log('return obj: ', return_obj);
  return return_obj
}

// TODO: rename as getAllObservable and swap to named args in obj - 
// change 'params' to use requestConfig
// possibly swap from getEndpoint to simply endpoint - see if there would be much complication in doing that, as the id isn't invariably used and it can be confusing
// as this method is cheifly used for lists
const getAll = <T>(getEndpoint: GetEndpoint, setMetadata?: SetMetaData, id?: string | number, params?: object, currentMetaDataRef?: React.MutableRefObject<any>, processData?: (data: any) => any): Promise<Results<T>> => {
  return APIR.get<ListResponse>(`${getEndpoint(id)}`, { params }).pipe(
    map((response: any) => {
      const thisResponse = prepareListResponse({ response, setMetaData: setMetadata, currentMetaDataRef, processData });
      return thisResponse;
    }),
    debounceTime(500),
  ).toPromise();
}

interface SimpleFetchProps {
  route: string,
  setMetaData?: React.Dispatch<React.SetStateAction<DataGridMeta>>,
  currentMetaDataRef?: React.MutableRefObject<DataGridMeta>,
  callBack?: (response: any) => void;
  requestConfig?: AxiosRequestConfig;
  processData?: (data: any) => any;
}

// TODO: after renaming getAll above (and all usages of it) to getAllObservable, rename this to getAll
export const simpleFetchList = ({ route, setMetaData, currentMetaDataRef, callBack, requestConfig, processData }: SimpleFetchProps) => {
  // a contract user should be verified against the contract
  return API.get(route, requestConfig).then((response: any) => {
    const thisResponse = prepareListResponse({ response, setMetaData, currentMetaDataRef, processData });
    callBack && callBack(thisResponse);
    return thisResponse;
  });
}

interface FetchRecordProps {
  route: string;
  callback?: (response: any) => void;
  processResponse?: (data: any) => any;
}

export const simpleFetchRecord = ({ route, callback, processResponse }: FetchRecordProps) => {
  API.get(route).then((response: any) => {
    let thisResponse = response;
    if (processResponse) {
      thisResponse = processResponse(response);
    } else {
      thisResponse = getDataAndMetaAndPUTMeta(response);
    }
    callback && callback(thisResponse);
    return thisResponse
  });
}

const fetchUnPaginated = <T>(getEndpoint: GetEndpoint, setMetadata: SetMetaData, refOrId?: string | number, params?: object): Promise<Results<T>> => {
  return APIR.get<any>(`${getEndpoint(refOrId)}`, { params }).pipe(
    map((response: any) => {
      const putMeta = getListPUTMeta(response).PUTMeta;
      const getMeta = getListGETMeta(response).GETMeta;
      const description = getDescription(response);
      setMetadata({ loaded: true, activeMeta: putMeta || getMeta, GETMeta: getMeta, description: description, PUTMeta: putMeta, POSTMeta: getListMeta(response), privileges: getUnpaginatedListPrivileges(response) })
      return response.data
    }),
    debounceTime(1000),
  ).toPromise();
}

const triggerServerSideContractDataUpdate = (contractRef: string) => {
  return APIR.get(`contracts/${contractRef}/synch/`).subscribe();
}

const triggerServerSideFinancialsDataUpdate = (contractRef: string, params?: object) => {
  return API.get(`contracts/${contractRef}/synch_financials/`, { params });
}

const triggerPortfolioServerSideFinancialsDataUpdate = (portfolioId: number, params?: object) => {
  return API.get(`portfolios/${portfolioId}/synch_financials/`, { params });
}

const triggerServerSideSpendDataUpdate = (contractRef: string, params?: object) => {
  return API.get(`contracts/${contractRef}/synch_spend/`, { params });
}

const triggerPortfolioServerSideSpendDataUpdate = (portfolioId: number, params?: object) => {
  return API.get(`portfolios/${portfolioId}/synch_spend/`, { params });
}

interface CreateInterface extends ContractOrPortfolio {
  getEndpoint: (id?: string | number | undefined) => string;
  metadata?: FieldMetaGroup;
  values: Dictionary<any>;
}
const create = <T>(props: CreateInterface): Promise<T> => {
  let url = "create/never/";
  let payload = props.values;
  if (props.metadata) {
    payload = removeReadOnlyFields(payload, props.metadata, true);
  }
  if (props.portfolio) {
    url = props.getEndpoint(props.portfolio.id);
    // for a portfolio props.payload should, in most cases, include the specific 'child' contract the create will be for.
  } else if (props.contract) {
    url = props.getEndpoint(props.contract.contract_ref);
    payload = { ...props.values, contract: props.contract.id || props.contract } // props.contract will hold the id in the h a s centre context
  } else {
    url = props.getEndpoint();
  }
  return API.post(getBaseUrl(url), payload);
}

export const update = <T>(getEndpoint: GetEndpoint, parentId: string | number | undefined, id: string | number, payload: Dictionary<any>, metadata?: FieldMetaGroup): Promise<TransformedSingleResult<T>> => {
  let thisPayload = payload;
  if (metadata) {
    thisPayload = removeReadOnlyFields(payload, metadata, true);
  }
  return API.patch(`${getBaseUrl(getEndpoint(parentId))}${id}/`, thisPayload);
}
export interface GetPreFlightInfoConfig {
  omitEdit?: boolean;
  ignoreErrors?: boolean;
}
export const getPreFlightInfo = (endpoint: string, id?: string | number, config?: GetPreFlightInfoConfig): Promise<PreFlightListInfo> => {
  let thisEndpoint = endpoint;
  if (id) {
    thisEndpoint = `${thisEndpoint}${id}/`
  }
  return APIN.options(thisEndpoint).then((response) => {
    const meta = id ? getPreFlightDetailMeta(response) : getPreFlightListMeta(response); //preflight uses the 'options' verb
    const canCreate = preFlightCanCreateCheck(response);

    const canUpdate = preFlightCanUpdateCheck(response);
    const privileges = getPrivileges(response);
    if (!config || !config.omitEdit) {
      return { meta, canCreate, canUpdate, canRead: true, privileges: privileges }
    } else {
      const { PUT, ...thesePrivileges } = privileges;
      return { meta, canCreate, canRead: true, privileges: thesePrivileges }
    }

  }).catch(error => {
    if (error.response?.status == 403) {
      return { meta: {}, canCreate: false, canRead: false, permissions: {} }
    } else {
      if (config?.ignoreErrors) {
        console.log(`error in obtaining preFLight for ${thisEndpoint}: ${error}`)
        return { meta: {}, canCreate: false, canRead: false, permissions: {} }
      } else {
        return onResponseError(error)
      }
    }
  })
}

export const del = <T = any>(getEndpoint: GetEndpoint, parentId: string | number | undefined, id: string | number, params?: object): Promise<T> => {
  const requestConfig = {
    params
  }
  return API.delete(`${getBaseUrl(getEndpoint(parentId))}${id}/`, requestConfig);
}

export const commonHandleSave = (
  { payload, parentId, recordId, callback, putMeta, getEndpoint }:
    {
      payload: any,
      parentId?: string | number,
      recordId: string | number,
      callback?: any,
      putMeta: FieldMetaGroup,
      getEndpoint: (id?: string | number) => string
    }
) => {
  if (putMeta && getEndpoint) {
    const thisPayload = removeReadOnlyFields(payload, putMeta);
    update<any>(getEndpoint, parentId || '', recordId, thisPayload).then((response) => {
      store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
      callback && callback(response.data);
      return response.data;
    }).catch((e) => {
      console.log('issue: ', e);
    });
  } else {
    console.log('putMeta, recordId or getEndpoint undefined');
  }
};

export interface CommonUpdateGridRowProps {
  dataSource: any;
  key: string | number;
  changes: any;
  updateMasterDetailInGridRef?: any;
  currentListDataRef?: React.MutableRefObject<Results<any> | undefined>;
}

export const commonUpdateGridRow = ({ dataSource, key, changes, updateMasterDetailInGridRef, currentListDataRef }: CommonUpdateGridRowProps) => {
  dataSource && dataSource.store().push([{ type: 'update', key: key, data: changes }]);
  if (updateMasterDetailInGridRef) {
    const grid = updateMasterDetailInGridRef.current?.instance;
    if (grid) {
      const rowIndex = grid.getRowIndexByKey(key);
      const visibleRows = grid.getVisibleRows();
      const nextRow = visibleRows[rowIndex + 1];
      if (nextRow?.rowType == "detail") {
        grid.repaintRows([rowIndex + 1]);
      }
    }
  }
  if (currentListDataRef?.current) {
    // this is useful if we have a ref to a parent list and we want to make sure that we update the data in that ref at the same time
    const newList: any[] = [];
    currentListDataRef.current && currentListDataRef.current.data.map((x) => {
      if (key === x.id) {
        newList.push({ ...x, ...changes });
      } else {
        newList.push(x)
      }
    })
    currentListDataRef.current.data = newList;
  }
  //dataSource && dataSource.store().update(key, changes);
}

interface SimpleSendGeneratedDocProps {
  url: string;
  attributes: Dictionary<any>;
  file: any;
  meta?: FieldMetaGroup;
  id?: string;
}

export const simpleSendGeneratedDoc = ({ url, attributes, file, meta, id }: SimpleSendGeneratedDocProps) => {
  let fd = new FormData();
  fd.append("file", file);
  let theseAttributes = removeReadOnlyFields(attributes, meta);
  Object.keys(theseAttributes).forEach(key => fd.append(key, attributes[key]));
  return id
    ? API.patch(url, fd, getMultipartConfig())
    : API.post(url, fd, getMultipartConfig());
}


export default {
  getAll,
  simpleFetchList,
  simpleFetchRecord,
  create,
  update,
  updateOrderApi,
  getPreFlightInfo,
  del,
  fetchUnPaginated,
  triggerServerSideContractDataUpdate,
  triggerServerSideFinancialsDataUpdate,
  triggerPortfolioServerSideFinancialsDataUpdate,
  triggerPortfolioServerSideSpendDataUpdate,
  triggerServerSideSpendDataUpdate,
  simpleSendGeneratedDoc
};
