/* eslint-disable */
import React, { useState, useRef, useReducer, useCallback, useEffect } from 'react';

// Own
import { store } from "store/store";
import HASStatutoryTemplateDoc from "components/AdminPanel/HAS/Containers/HASTaskAdminDetailView/HASStatutoryTemplateDocument";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { simpleUpdateHASTask, updateContractBoundHASTask, createContractBoundHASTask, fetchContractHASTaskLinks } from "components/AdminPanel/HAS/HASTaskServices";
import { addNotification } from "components/Notification/Actions/Notification.actions";
import { NOTIFICATION_SUCCESS } from "components/Notification/Constants/constants";
import { getPayloadFromRef } from 'services/API/API.helper';
import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { APIPrivileges } from "services/Interface/Interface";
import { getDimTitle } from "helpers/Forms/modal.helper";
import { HASContractTask } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";

import GeneralEntityForm, { generateStandardGeneralActionButtonDefinitions } from "components/Common/Components/GeneralEntityForm/GeneralEntityForm";

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"

// Styles
import {
    DetailWrapper,
    NoReportWrapper,
    ReportsWrapper
} from 'components/Common/Components/GeneralEntityForm/GeneralDetailStyles';

interface HASTaskDetailProps {
    data: any;
    meta: FieldMetaGroup;
    putMeta: FieldMetaGroup;
    permissions?: APIPrivileges;
    formSharedSpace: any;
    setModalTitle?: React.Dispatch<React.SetStateAction<(() => JSX.Element) | undefined>>;
    contractRef: string;
    contractId: number | string;
    taskIsGlobal: boolean;
    refreshGrid?: React.DispatchWithoutAction;
    setContractTask: React.Dispatch<React.SetStateAction<HASContractTask>>;
    onSaveCallBack: (data: any) => void;
    contractTask?: HASContractTask;
    setSignalDelete?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

function HASTaskDetailView({
    data,
    meta,
    putMeta,
    permissions,
    formSharedSpace,
    setModalTitle,
    contractRef,
    contractId,
    taskIsGlobal,
    refreshGrid,
    setContractTask,
    onSaveCallBack,
    contractTask,
    setSignalDelete
}: HASTaskDetailProps) {

    let sharedSpace = formSharedSpace;
    const [thisRecord, setThisRecord] = useState<any>(sharedSpace.data || data);

    //const [putMeta, setPutMeta] = useState(meta);
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const formValuesRef = useRef<any>(data || {});

    const [mustRefresh, forceUpdate] = useReducer((x) => x + 1, 1);
    const canWrite = !!permissions?.PUT;

    const noReportsComments = `You can't add statutory documents until the form to the left has been submitted`;

    const getFormFieldsConfig = useCallback((canWrite: boolean): FieldsFormConfig => {
        const config: FieldsFormConfig = {
            name: {
                label: "Task Name"
            },
            guidance: {
                multiline: true,
                rows: 15
            },
            interval: {},
            interval_unit: {
            },
            applicable_from: {},
            grace_period: {
                label: "Grace Period (in days)"
            },
            statutory_doc_ref: {},
            for_contract: {
                hidden: true,
                autoCalculator: () => {
                    if (!data?.id) {
                        return contractId
                    }
                    return data.for_contract
                }
            },
            core: {
                hidden: true,
                autoCalculator: () => false
            },
        }
        if (taskIsGlobal) {
            Object.keys(config).map(x => {
                config[x].metaOverride = config[x].metaOverride || {};
                //@ts-ignore
                config[x].metaOverride.read_only = true;
                config[x].controlClassName = config[x].controlClassName || '' + ' darker-disabled';
            })
        }
        return config;
    }, []);

    const ModalTitle = useCallback(() => {
        return () => <>
            {data?.id ? <div className="detail-modal--title">
                Task Details
                {getDimTitle('for')}
                {`${data.name}${taskIsGlobal ? " (Global Task)" : ""}`}
            </div> :
                <div className="detail-modal--title">
                    New Task
                </div>}
        </>
    }, [data]);

    useEffect(() => {
        setModalTitle && setModalTitle(ModalTitle);
    }, [data])

    const processSuccessfulResponse = (response: any, callback: any) => {
        store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
        sharedSpace.data = formValuesRef.current = response.data;
        setThisRecord(response.data);
        onSaveCallBack(response);
        callback && callback(response);
        //forceUpdate();
        refreshGrid && refreshGrid();
        fetchContractHASTaskLinks(contractRef, response.data.id).then((thisResponse) => {
            setContractTask(thisResponse.data);
        })
    }

    const handleSave = (recordId?: string, callback?: any) => {
        const thisMeta = putMeta || meta;
        const payload = getPayloadFromRef(formValuesRef, meta);
        if (!recordId) { // we need to create a record...
            createContractBoundHASTask(contractRef, payload).then((response) => {
                processSuccessfulResponse(response, callback);
            }).catch((e) => {
                console.log('issue: ', e);
            });
        } else if (data.for_contract) {
            // we need to update a task tied to a specific contract...
            updateContractBoundHASTask({
                id: recordId,
                payload: payload,
                meta: putMeta || meta,
                contractRef: contractRef
            }).then((response) => {
                processSuccessfulResponse(response, callback);
            }).catch((e) => {
                console.log('issue: ', e);
            });
        } else {
            // we need to update a task that isn't tied to a specific contract
            recordId && simpleUpdateHASTask({
                id: recordId,
                payload: getPayloadFromRef(formValuesRef, thisMeta),
                meta: putMeta || meta
            }).then((response) => {
                processSuccessfulResponse(response, callback);
            }).catch((e) => {
                console.log('issue: ', e);
            });
        }

    }

    sharedSpace.handleSave = handleSave;

    const getButtons = useCallback(() => generateStandardGeneralActionButtonDefinitions({
        handleSave,
        setSignalDelete: !taskIsGlobal ? setSignalDelete : undefined,
        showDelete: !taskIsGlobal && thisRecord,
        formErrors
    }), [handleSave, thisRecord]);

    return <>
        <div>
            {
                meta && mustRefresh &&
                <>
                    <DetailWrapper>
                        <GeneralEntityForm
                            buttons={getButtons()}
                            useDefaultRevertChanges
                            formValuesRef={formValuesRef}
                            refreshSignal={mustRefresh}
                            formLevelSharedSpace={sharedSpace}
                            initialData={thisRecord}
                            canWrite={!!permissions?.PUT}
                            formFieldsConfig={getFormFieldsConfig(!!permissions?.PUT)}
                            meta={meta}
                            dispatchRefreshContext={forceUpdate}
                            gridClass="HASTaskEditForm"
                            formErrors={formErrors}
                            setFormErrors={setFormErrors}
                        />
                        <ReportsWrapper>
                            {thisRecord?.id && contractTask // we can do this because if we're showing the docs here there should be a contract task unlike for HASTaskAdminDetailView
                                ? <HASStatutoryTemplateDoc
                                    canWrite={canWrite}
                                    data={thisRecord}
                                    taskIsGlobal={taskIsGlobal}
                                    contractTask={contractTask}
                                />
                                : <NoReportWrapper>
                                    <FolderOpenIcon />
                                    {noReportsComments}
                                </NoReportWrapper>}
                        </ReportsWrapper>
                    </DetailWrapper>
                </>
            }
        </div>
    </>
}

// HASTaskDetailView.whyDidYouRender = true;

export default HASTaskDetailView;