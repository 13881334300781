import React, { useEffect, useState, useRef } from "react";
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useSelector } from "react-redux";


// Own
import { AdminDataGrid } from "components/AdminPanel/Containers/AdminDataGrid";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { HASTaskContractSublistColumns, linkContractGridActions } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { openPanelInSelectionMode } from 'components/AdminPanel/Helpers/AdminPanel.helper';

import { EntityKeys } from "constants/entity.constants";
import { FieldMetaGroup } from "components/Common/Interfaces/Entity.interface";
import { adminAccessSelector } from 'components/Profile/Selectors/Profile.selector';
import { HASContractTask, HASTaskContractLink, HASTaskWithID } from "components/AdminPanel/HAS/Interfaces/HASTaskInterfaces";
import HASTaskServices from "components/AdminPanel/HAS/HASTaskServices";
interface HASTaskContractViewProps {
    panelId: string;
    masterRecordData: HASTaskWithID;
    id: any;
    dataTestId: string;
    masterRecordType?: string;
    selectActionIdentifier?: string;
    linkPanelTitle?: string;
    meta: FieldMetaGroup;
    canCreate?: boolean;
}

const HASTaskContractsView: React.FunctionComponent<HASTaskContractViewProps> = ({
    panelId,
    masterRecordData,
    id,
    //contractsState,
    dataTestId,
    masterRecordType,
    selectActionIdentifier,
    linkPanelTitle,
    meta,
    canCreate
}) => {

    const currentMemberIds = useRef<(string | number)[]>();
    const [datasource, setDatasource] = useState<any>();
    const hasMainAdminAccess = useSelector(adminAccessSelector); //(as opposed to only H&S admin access)

    useEffect(() => {
        const custom = new CustomStore({
            key: "id",
            load: loadOptions => HASTaskServices.fetchHASTaskContractLinks(masterRecordData.id).then(
                (response) => {
                    currentMemberIds.current = response.data.map((x: HASTaskContractLink) => x.contract);
                    // const getHydratedLinks = HASTaskContractLinkHydratedFactory(response.data);
                    // const data = getHydratedLinks(store.getState()).map((x: HydratedPortfolioContractLink) => x.contract);
                    // console.log('this data: ', data);
                    // return data;
                    return response.data;
                }
            )
        });

        setDatasource(
            // @ts-ignore
            new DataSource({
                store: custom
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function handleAddContractsToHASTask(contractIds: number[]) {
        const accessObjs = contractIds.map((contractId) => (
            {
                "contract": contractId,
                "task": masterRecordData.id
            }
        ))
        HASTaskServices
            .linkContractsToHASTask(masterRecordData, accessObjs)
            .then(() => {
                console.log('reloading');
                // at the moment the admin panel is refreshed when closing the other admin picker panel, but we want to stop that so a reload will
                // be required
                datasource.reload();
            })
    }

    const handleRemoveContractFromHASTask = (el: HASContractTask) => {
        HASTaskServices.removeContractFromHASTask(
            masterRecordData,
            el.contract
        )
            .then(() => {
                datasource.reload();
            })
    };

    const handleContractLink = (): void => {
        masterRecordType && selectActionIdentifier && linkPanelTitle && openPanelInSelectionMode(
            {
                selectActionIdentifier,
                masterRecordType,
                masterRecordData,
                linkedRecordType: adminPanelType.contracts,
                onAddLinks: handleAddContractsToHASTask,
                currentMemberIds: currentMemberIds.current || [],
                linkPanelTitle,
            }
        )
    };

    const linkContract = selectActionIdentifier && canCreate ? handleContractLink : undefined;

    return (
        <>
            {meta && <AdminDataGrid
                repaintChangesOnly
                data={datasource}
                editMode="cell"
                meta={meta}
                dataTestId={dataTestId}
                getDatagridColumns={HASTaskContractSublistColumns}
                masterView={false}
                entityKey={EntityKeys.Contracts}
                // if you can't create links, for now we'll assume you can't remove them either
                columnActions={linkContractGridActions(handleRemoveContractFromHASTask, !hasMainAdminAccess, !canCreate)}
                panelId={panelId}
                onUpdate={() => { }} // records are currently updated in this context - just linked or removed
                onLink={linkContract}
            />}
        </>

    );
};

export default HASTaskContractsView;
