import { FieldsFormConfig } from 'components/Common/Components/DocumentsGrid/DocumentsGrid.interface';
import { map, catchError } from "rxjs/operators";
import Cookies from "universal-cookie";
import {
  Dictionary,
  FieldMeta,
  FieldMetaGroup,
} from "../../components/Common/Interfaces/Entity.interface";
import { ParseAPIResponse, ParseAPIResponseWithPUTMeta, APIResponse, APIPrivileges, Results, DataRecord, ResponseMessageI } from '../Interface/Interface';
import { saveDateFormat, saveDateTimeFormat } from "components/Common/Utils/Dates.js";

export const methodToEnglish: { [index: string]: any } = {
  delete: "delete",
  patch: "update",
  post: "create",
  put: "update"
};

export function isPrimitive(test: any) {
  return test !== Object(test);
}

const cookies = new Cookies();

export const getCSRF = () => cookies.get('csrftoken');

// Note that for both constants below, the url is only absolute for development.  This is because when in development, we run the api on a different port to the FE
// and that needs to be specified when we're not running the request via axios (e.g. when we're calling window.location) - axios is already configured to use the right
// port for the api
export const prependToAPI = process.env.NODE_ENV === "development" ? "http://localhost:8000/" : "";

export const getMeta = (entity: any) => {
  return [entity]
    .map(entity => entity?.data?.metadata?.actions || entity?.data?.results?.metadata?.actions)
    .map(actions =>
      !actions ? null : actions.PUT ? actions.PUT : actions.POST ? actions.POST : actions.GET
    )[0];
}

export const getOptions = (response: any) => response.data.metadata?.options || response.data.results?.metadata?.options;

export const getMessage = (response: any): ResponseMessageI => response.data.metadata?.message || response.data.results?.metadata?.message;

export const getListOptions = (response: any) => response.data.results.metadata.options;

export const getData = (entity: any) => entity?.data?.data || entity?.data?.results?.data;

export const getId = (entity: any) => entity.data.data.id;

export const getCount = (response: any): number => response.data.count || response.data.results?.count || response.data.results?.totalCount;

export const isWritable = (options: string[]): boolean => options.indexOf('POST') !== -1 || options.indexOf('PUT') !== -1;

export const getListData = (entity: any) => entity.data?.results.data || [];

export const getListIds = (entity: any) => entity.data.results.data?.map((item: DataRecord) => item.id);

export const getReadOnlyFields = (
  metaData: Dictionary<FieldMeta>
): { [id: string]: undefined } => {
  return Object.keys(metaData).reduce(
    (readonlyFields: { [id: string]: undefined }, fieldKey: string) => {
      metaData[fieldKey].read_only && (readonlyFields[fieldKey] = undefined);
      return readonlyFields;
    },
    {}
  );
};

export const getDifferenceFields = (
  data: any, metaData: Dictionary<FieldMeta>
): { [id: string]: undefined } => {
  return Object.keys(data).reduce(
    (differenceFields: { [id: string]: undefined }, fieldKey: string) => {
      !metaData[fieldKey] && (differenceFields[fieldKey] = undefined);
      return differenceFields;
    },
    {}
  );
};

export const oldRemoveReadOnlyFields = <T>(
  data: T,
  metaData: FieldMetaGroup
) => {
  const readOnlyFields = getReadOnlyFields(metaData);
  //console.log('readOnlyFields: ', readOnlyFields);
  const differenceFields = getDifferenceFields(data, metaData);
  //console.log('differenceFields: ', differenceFields);
  return { ...data, ...readOnlyFields, ...differenceFields };
};

export const removeReadOnlyFields = <T extends object>(
  data: T,
  metaData?: FieldMetaGroup,
  convertDates?: boolean
) => {
  if (metaData) {
    return Object.keys(data).reduce(
      (newData: any, fieldKey: string) => {
        let thisMetaData = metaData[fieldKey];
        //@ts-ignore  ts stupidity in thinking that fieldKey cannot be used to 'index' data
        let thisData = data[fieldKey];
        if (thisMetaData !== undefined && !thisMetaData.read_only) {
          if (convertDates && thisMetaData.type === "date" && thisData) {
            newData[fieldKey] = saveDateFormat(thisData)
          } else if (convertDates && thisMetaData.type === "datetime" && thisData) {
            newData[fieldKey] = saveDateTimeFormat(thisData)
          } else {
            newData[fieldKey] = thisData;
          }
        }
        return newData;
      },
      {}
    );
  }
  return data;
};

interface GetPayloadFromRefProps<T> {
  ref: React.MutableRefObject<T>,
  metaData?: FieldMetaGroup,
  defaultKeepReadOnly?: boolean,
  defaultKeepImages?: boolean,
  keepValuesForUndefinedMeta?: boolean,
  formConfig?: FieldsFormConfig,
  convertDates?: boolean,
}

export const newGetPayloadFromRef = <T extends object>(
  { ref, metaData, defaultKeepImages, defaultKeepReadOnly, keepValuesForUndefinedMeta, formConfig, convertDates }: GetPayloadFromRefProps<T>
) => {
  const data = ref.current;

  return Object.keys(data).reduce(
    (newData: any, fieldKey: string) => {
      let keep = true;
      let thisMeta = metaData ? metaData[fieldKey] : undefined;
      //@ts-ignore  ts stupidity in thinking that fieldKey cannot be used to 'index' data
      let thisData = data[fieldKey];
      let thisConfig = formConfig ? formConfig[fieldKey] : undefined;
      let readOnlyValue = undefined;
      // if (thisConfig && thisConfig.read_only !== undefined) {
      //   readOnlyValue = !!thisConfig.read_only;
      // } else {
      readOnlyValue = thisMeta?.read_only;
      const skipFormValue = thisConfig?.skipForm;
      //}
      let keepImageValue = undefined;
      if (thisConfig && thisConfig.submitImageWithForm !== undefined) {
        keepImageValue = thisConfig.submitImageWithForm;
      } else {
        keepImageValue = !!defaultKeepImages;
      }
      keep = (
        (keepValuesForUndefinedMeta || thisMeta !== undefined)
        && !readOnlyValue
        && !skipFormValue
        && (!thisMeta?.type.toLowerCase().includes("image") || keepImageValue)
      );
      //@ts-ignore  ts stupidity in thinking that fieldKey cannot be used to 'index' data
      if (keep) {
        if (convertDates && thisMeta?.type === "date" && thisData) {
          newData[fieldKey] = saveDateFormat(thisData)
        } else if (convertDates && thisMeta?.type === "datetime" && thisData) {
          newData[fieldKey] = saveDateTimeFormat(thisData)
        } else {
          newData[fieldKey] = thisData;
        }
      }
      //keep && (newData[fieldKey] = data[fieldKey]);

      return newData;
    },
    {}
  );
};

// useful in many scenarios where you want to use a saved call back (i.e. closure), but use values current at the time it is being called
export const getPayloadFromRef = <T extends object>(
  ref: React.MutableRefObject<T>,
  metaData?: FieldMetaGroup,
  keepReadOnly?: boolean
) => {
  const data = ref.current;
  if (metaData && !keepReadOnly) {
    return Object.keys(data).reduce(
      (newData: any, fieldKey: string) => {
        const tM = metaData[fieldKey];
        //@ts-ignore  ts stupidity in thinking that fieldKey cannot be used to 'index' data
        tM !== undefined && !tM.read_only && !tM.type.includes("image") && (newData[fieldKey] = data[fieldKey]);
        return newData;
      },
      {}
    );
  }
  return data;
};


export const getPrivileges = (response: APIResponse | Results): APIPrivileges => {
  try {
    // TODO: need to update babel paser so we can do ts assertions    
    // @ts-ignore
    if (response.data.results) {
      return {
        // @ts-ignore
        POST: (response.data.results.metadata.options || []).indexOf("POST") !== -1,
        // @ts-ignore
        //DELETE: response.data.results.metadata.options?.indexOf("POST") !== -1,
        DELETE: (response.data.results.metadata.child_options || []).indexOf("DELETE") !== -1,
        // @ts-ignore
        PUT: (response.data.results.metadata.child_options || []).indexOf("PUT") !== -1
      }
    } else {
      // @ts-ignore
      const options = response.data?.metadata?.options || response.data?.options;
      return {
        // @ts-ignore
        POST: (options || []).indexOf("POST") !== -1,
        // @ts-ignore
        //DELETE: response.data.metadata.options?.indexOf("POST") !== -1,
        DELETE: (response.data.metadata?.child_options || options || []).indexOf("DELETE") !== -1,
        // @ts-ignore
        PUT: (response.data.metadata?.child_options || options || []).indexOf("PUT") !== -1
      }
    }
  } catch (e) {
    console.error(e);
    return {
      POST: false,
      DELETE: false,
      PUT: false
    }
  }
}

export const getListPrivileges = (results: APIResponse): APIPrivileges => {
  return {
    POST: results.data.results.metadata?.options && results.data.results.metadata.options.indexOf("POST") !== -1,
    DELETE: results.data.results.metadata?.options && results.data.results.metadata?.options.indexOf("POST") !== -1,
    PUT: !!results.data.results.metadata?.child_options && results.data.results.metadata?.child_options?.indexOf("PUT") !== -1
  }
}

export const getUnpaginatedListPrivileges = (response: Results): APIPrivileges => {
  try {
    // @ts-ignore
    return {
      // @ts-ignore
      POST: response.metadata?.options && results.data.metadata.options.indexOf("POST") !== -1,
      // @ts-ignore
      DELETE: response.metadata?.options.indexOf("POST") !== -1,
      // @ts-ignore
      PUT: response.metadata?.child_options.indexOf("PUT") !== -1
    }
  } catch (e) {
    console.error(e);
    return {
      POST: false,
      DELETE: false,
      PUT: false
    }
  }
}

export const getDescription = (response: APIResponse | Results): any => {
  try {
    //@ts-ignore
    return response.data?.results?.metadata?.description ? response.data?.results?.metadata?.description : response.data.metadata?.description
  } catch (e) {
    return null;
  }
}

export const getListPUTMeta = (response: APIResponse): any => {
  try {
    return {
      PUTMeta: response.data.results.metadata.child_actions.PUT
    }
  } catch (e) {
    return {
      PUTMeta: undefined
    }
  }
}

export const getListGETMeta = (response: APIResponse): any => {
  try {
    return {
      GETMeta: response.data.results.metadata.actions.GET
    }
  } catch (e) {
    return {
      GETMeta: undefined
    }
  }
}

export const getUnPaginatedListPUTMeta = (response: Results): any => {
  try {
    return {
      PUTMeta: response.metadata.child_actions.PUT
    }
  } catch (e) {
    return {
      PUTMeta: undefined
    }
  }
}

export const getListMeta = (sublist: any) =>
  sublist.data &&
  sublist.data.results &&
  sublist.data.results.metadata &&
  sublist.data.results.metadata.actions &&
  sublist.data.results.metadata.actions.POST;

export const getPreFlightListMeta = (response: any) =>
  response.data &&
  response.data.actions &&
  response.data.actions.POST;

export const getPreFlightDetailMeta = (response: any) =>
  response.data &&
  response.data.actions &&
  response.data.actions.PUT;

export const preFlightCanCreateCheck = (response: any) =>
  response.data &&
  response.data.options &&
  !!response.data.options.find((x: string) => x == "POST");

export const preFlightCanUpdateCheck = (response: any) =>
  response.data &&
  response.data.options &&
  !!response.data.options.find((x: string) => x == "PUT");

export const arrayToDict = <T = any>(array: T[], key = 'dictKey') =>
  array.reduce((dictionary: { [index: string]: any }, item: any) => {
    dictionary[item[key] || item.id || item.value || item.key] = item;
    return dictionary;
  }, {});

export const arrayToMap = (array: any[], key = 'dictKey') =>
  array.reduce((dictionary: { [index: string]: any }, item: any) => {
    dictionary.set(item[key] || item.id || item.value || item.key, item);
    return dictionary;
  }, new Map());

//NB this doesn't convert any dict to any array, but a dict of the shape { key1: {...}, key2: {...}}
export const dictToArray = (dictionary: any, addId: boolean = true) =>
  Object.keys(dictionary).map(key => ({
    ...dictionary[key],
    id: addId || dictionary[key].id ? dictionary[key].id || key : undefined,
    dictKey: key
  }));

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.id.indexOf(value.id) === index;
}

export const existingRecordOnly = (records: any) =>
  records.filter((record: any) => typeof record.id === "number" || typeof record.value === "number");

export const unWrapListDataAndMeta = () =>
  map((response: any): ParseAPIResponse<any> => ({
    data: getListData(response),
    meta: getListMeta(response),
    options: getOptions(response),
    permissions: getPrivileges(response)
  }));

export const unWrapDataAndMeta = <T = any>() =>
  map((response: any): ParseAPIResponse<T> => ({
    data: getData(response),
    meta: getMeta(response),
    message: getMessage(response),
    options: getOptions(response),
    permissions: getPrivileges(response)
  }));

export const unWrapDataAndMetaAndPUTMeta = <T = any>() =>
  map((response: any): ParseAPIResponseWithPUTMeta<T> => ({
    data: getData(response),
    meta: getMeta(response),
    putMeta: getListPUTMeta(response)?.PUTMeta,
    options: getOptions(response),
    permissions: getPrivileges(response)
  }));

export const getDataAndMetaAndPUTMeta = (response: any): ParseAPIResponseWithPUTMeta<any> => {
  return {
    data: getData(response),
    meta: getMeta(response),
    putMeta: getListPUTMeta(response)?.PUTMeta,
    options: getOptions(response),
    permissions: getPrivileges(response),
  }
};

export const getDataAndMeta = (response: any): ParseAPIResponse<any> => ({
  data: getData(response),
  meta: getMeta(response),
  options: getOptions(response),
  permissions: getPrivileges(response)
});

const getMultCreateErrors = (entity: any) => {
  return entity?.data?.meta?.errors || entity?.data?.metadata?.errors || [];
}
export const parseMultiCreateResponse = (response: any) => ({
  data: getData(response),
  errors: getMultCreateErrors(response),
  //export const getData = (entity: any) => entity?.data?.data || entity?.data?.results?.data;
})

export const unWrapData = () =>
  map(response => ({
    data: getData(response)
  }));

export const catchErrorOps = () =>
  catchError(err => {
    console.log('err', err)
    return err;
  });

export const returnData = () =>
  map(response => getData(response));
