import React from 'react';

import { logError } from "services/Log/Log.service";
import API from "services/API/API";
import { getDataAndMetaAndPUTMeta } from "services/API/API.helper";
import path from "path";
import { DocumentsMetaInfo, FieldMeta, FormValues } from "components/Common/Interfaces/Entity.interface";
import { Dictionary, Primitive } from 'components/Common/Interfaces/Entity.interface';
import { FieldFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { FormErrorsType } from "store/Common/Interfaces/Common.interface";

export const getDocumentsApi = async (
    url: string,
    setMetaInfo: React.Dispatch<DocumentsMetaInfo>,
    fetchParams?: {
        [name: string]: string;
    },
    forRecord?: string,
    setResponse?: React.Dispatch<any> // this is designed just to allow the parent component to access the response and share it with any siblings of this component if required
) => {

    try {
        const response = await API.get(url, { params: fetchParams });
        const { data, ...metainfo } = getDataAndMetaAndPUTMeta(response);
        if (typeof (metainfo.misc) == "undefined") {
            metainfo.misc = {}
        }
        metainfo.misc.numDocs = data.length;
        metainfo.misc.forRecord = forRecord; // set even if undefined, as we want it to be undefined when called for the documents list as meta will be overwritten then
        setMetaInfo && setMetaInfo(metainfo);
        if (setResponse) {
            setResponse(response);
        }
        return data;
    } catch (err) {
        logError(`GET_${url}_error:${JSON.stringify(err)}`);
        return [];
    }
};

export const deleteDocumentApi = async (baseUrl: string, id: string | number, params: {
    [name: string]: string;
}) => {
    const url = path.join(baseUrl, `${id}`);
    try {
        return await API.delete(url, { params: params });
    } catch (err) {
        logError(`DELETE_${url}_error:${JSON.stringify(err)}`);
        return null;
    }
};

export const updateDocumentApi = async (baseUrl: string, id: number | string, data: any) => {
    const url = path.join(baseUrl, `${id}/`);
    try {
        const response = await API.put(url, data);
        return response;
    } catch (err) {
        logError(`PUT_${url}_${JSON.stringify(data)}_error:${JSON.stringify(err)}`);
        return null;
    }
};

export function downloadCSV(csv: string, filename: string) {
    let csvFile;
    let downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
}

interface hasID {
    id: string | number;
    [index: string]: any;
}
interface replaceArrayObjByIdProps {
    existingArray: hasID[];
    newObj: hasID;
    returnCopy?: boolean;
    add?: boolean;
}
export function replaceArrayObjById({ existingArray, newObj, returnCopy, add }: replaceArrayObjByIdProps) {
    let thisArray = existingArray;
    if (returnCopy) {
        thisArray = [...existingArray];
    }

    const index = thisArray.findIndex(x => x.id == newObj.id);
    if (index >= 0) {
        thisArray[index] = newObj;
    } else {
        if (add) {
            thisArray.push(newObj);
        }
    }
    return thisArray;
}

export interface CollectValuesAndRenderProps {
    collectionRef: React.MutableRefObject<Dictionary<any>>; // must start out as an empty dict!!
    keysArray: string[];
    thisKey: string;
    thisValue: any;
    timeoutBeforeRender?: number;
    dispatchRefreshContext: React.DispatchWithoutAction;
    cancelUpdateRef: React.MutableRefObject<number | undefined>;
    alwaysWait?: boolean;
}

export const collectValuesAndRender = (props: CollectValuesAndRenderProps) => {
    // This function is useful when you have a bunch of values that you want to use on a component
    // that can't be computed immediately (e.g. they need to wait for a request to return)
    // but you don't want the component to rerender for each newly set value in the 'first flurry'
    // so you don't want to set them all as individual pieces of state.
    // once they all have values, (or a certain amount of time has passed since one was set - in case one doesn't return for some reason)
    // - then you want to update the component. 
    // If another value returns after you stop waiting it will rerender when values are complete or it finishes waiting 
    // for another value again - but in that scenario this function will at least still at least act as a 'debounce' on rerendering.
    // Of course after the initial load (when each property should have been set before) - changing a value with this function
    // will always set the ref, then re-render, so it achieves the 'best of both worlds' for the given use case (you can call this
    // function and not have to call another explicit function to force a re-render afterwards).
    // I'd say this approach is better for this context than say using some operator on the asynch calls like forkjoin - because a fork join may
    // not always be wanted and I think it's a bit more flexible, loggable etc.
    // Lastly, in most use cases inside a component you'll want to wrap this function in a 'closure' callback to automatically set most of the arguments
    //  the only arguments that should need to be passed when updating an actual value are thisKey and thisValue.
    props.cancelUpdateRef.current && clearTimeout(props.cancelUpdateRef.current);
    props.collectionRef.current[props.thisKey] = props.thisValue;
    // NOTE that this next line does not check if the value is truthy, but rather if it has the attribute on the object at all,
    // so you can still use false or even undefined values on the keys. As a consequence the collectionRef must start out as a totally
    // empty dict for this to work.
    const undefinedKeys = props.keysArray.filter(x => !props.collectionRef.current.hasOwnProperty(x));
    const thisTimeout = props.timeoutBeforeRender || 800;
    if (!undefinedKeys.length) {
        props.dispatchRefreshContext();
    } else {
        if (!props.alwaysWait) {
            props.cancelUpdateRef.current = setTimeout(props.dispatchRefreshContext, thisTimeout);
        }
    }
}

export const isEmpty = (value: any, excludeNull?: boolean) => (typeof (value) === "undefined" || (typeof (value) === "string" && value.trim().length === 0) || (!excludeNull && value === null));


interface FormValueConsideredMissingProps {
    formValue: any;
    meta: FieldMeta;
    config: FieldFormConfig;
    excludeReadOnlyStatus?: boolean;
    excludeNull?: boolean;
    excludeSkipForm?: boolean;
    excludeMetaOverride?: boolean;
    includeRequiredForSubmitOnly?: boolean;
    extraOrCondition?: boolean;
    extraAndCondition?: boolean;
    log?: boolean;
}
export const formValueConsideredMissing = ({
    formValue,
    config,
    meta,
    excludeNull, // set to true if you want null values to NOT count as missing
    excludeReadOnlyStatus, // set to true if you DON'T  want read only fields to show as missing
    // but bear in mind that means users who don't have submit rights could see an enabled submit button, if
    // a separate check isn't made for action rights (generally it is recommended that that check IS made)
    excludeSkipForm, // set to true if you DO want values with skip specified as true to count as missing
    excludeMetaOverride,
    includeRequiredForSubmitOnly, // include if you are dealing with a form which has a separate submit stage and you are calculating whether to enable
    extraOrCondition,
    extraAndCondition,
    log
}: FormValueConsideredMissingProps) => {
    if (config?.ignoreMissing) {
        return false
    }
    let thisMeta = meta;
    if (!excludeMetaOverride && config?.metaOverride) {
        thisMeta = { ...meta, ...config.metaOverride }
    }
    const empty = isEmpty(formValue, excludeNull);

    let basicOrConditions = meta?.required || config?.forceRequired || (
        // NB for required any value except false (including undefined) is regarded as being required, just in case
        // the required for submit meta is omitted
        includeRequiredForSubmitOnly && (config?.requiredForSubmitOnly || meta?.required_for_submit !== false)
    );
    if (extraOrCondition !== undefined) {
        basicOrConditions = basicOrConditions || extraOrCondition;
    }
    let missing = empty && basicOrConditions;
    if (extraAndCondition !== undefined) {
        missing = missing && extraAndCondition;
    }
    if (excludeReadOnlyStatus) {
        missing = missing && !meta.read_only;
        // in other words, if it would have been considered missing and excludeReadOnlyStatus, then only show it if it isn't read only
    };
    if (!excludeSkipForm) {
        missing = missing && !config?.skipForm;
        // in other words, if it would have been considered missing and we are NOT excluding SkipForm, then only show it if skip form is not set
    }
    if (missing && log) {
        console.log('missing, meta: ', meta);
        console.log('missing formConfig: ', config);
        console.log('missing value: ', formValue);
    }
    return missing;
}

export const collectFieldValidationMessages = (formErrors: FormErrorsType, contextLabel: string, fieldName?: string) => {
    // The purpose of this function is to collect the required validation messages into a single string, which can be displayed somewhere
    // e.g. a tooltip.  Validation messages are split up by field and then by a context (each 'context' is for a separate 'use', e.g.
    // display on a save button might be different to the validations on a different 'submit' action on a form)
    const messages: string[] = [];
    let filteredFields: string[] = Object.keys(formErrors);
    if (fieldName) {
        filteredFields = filteredFields.filter(field => fieldName === field);
    }
    if (filteredFields.length) {
        filteredFields.map(ff => {
            let theseValidations: string[] = Object.keys(formErrors[ff]);
            if (contextLabel) {
                theseValidations = theseValidations.filter(context => contextLabel === context);
            }
            theseValidations.map(v => {
                const message = formErrors[ff][v];
                message && messages.push(message);
            })
        })
    }
    return messages
}

export interface CommonFieldValidatorProps {
    formValuesRef: React.MutableRefObject<FormValues>,
    value: Primitive,
    meta: FieldMeta,
}

export type CommonValidator = (({ formValuesRef, value, meta }: CommonFieldValidatorProps) => Dictionary<string | undefined>);