import { useSelector } from 'react-redux';

import { ContractMenu } from "./contractMenu.interfaces";
import { ppmRightVisibilityMenu, rmRightVisibilityMenu } from 'components/ContractInFocus/Maintenance/Models/maintenanceRightVisibilityMenu';
import { specialistRightVisibilityMenu } from 'components/Schedulers/Models/specialistRightVisibilityMenu';
import { logsRightVisibilityMenu } from 'components/ContractInFocus/Logs/Models/logsRightVisibilityMenu';
import { financialsRightVisibilityMenu } from 'components/ContractInFocus/Financials/Models/financialsRightVisibilityMenu';
import { auditRightVisibilityMenu } from 'components/ContractInFocus/Audit/Models/auditRightVisibilityMenu';
import { getContractReportMeterStreamsRightVisibilityMenu } from "components/ContractInFocus/MeterReadings/Models/meterStreamsRightVisibilityMenu";
import { contractReportRightVisibilityMenu } from 'components/ContractInFocus/ContractReport/models/contractReportRightVisibilityMenu';
import { spendRightVisibilityMenu } from 'components/ContractInFocus/Spend/Models/spendRightVisibilityMenu';
import { contractInFocusMeterStreamsSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";

import {
  SpendIcon,
  AWSIcon
} from "components/Common/Components/FileTypeIcon/FileTypeIcon";


const contractMenu: ContractMenu[] = [
  //NB the reportVisibilityFilters are currently 'looked up' from here by the ContractReportVisibility component... (mostly) the same values are simply passed to the 
  // WithPageContext HoC wrapper directly for standard (non contract report) pages.  Of course the contractReportVisibility component passes 
  // the contract visibility settings into these functions to construct the 'Visibility Menus' and in the case of standard pages it's the personal visibility settings
  // that get's passed in.  Note that in the case of meterStreams for instance, the functions are that are generated below and on the MeterStreams.tsx 
  // page are different too - they're closures that are set to 'point' to different backend APIs and toggle different states in different manners on the FE too.

];

export function useGetContractReportingMenu(contract?: SiteContract): ContractMenu[] {
  const contractMeterStreams = useSelector(contractInFocusMeterStreamsSelector);
  if (!contract) {
    return []
  }
  let newContractMenu = [...contractMenu,
  {
    icon: "fab fa-flipboard",
    menu: "Planned",
    name: "Planned",
    label: {
      value: "Planned"
    },
    path: `/contract/${contract.contract_ref}/ppm`,
    reportVisibilityFilter: ppmRightVisibilityMenu
  },
  {
    icon: "fas fa-weight",
    menu: "Reactive",
    label: {
      value: "Reactive"
    },
    path: `/contract/${contract.contract_ref}/rm`,
    reportVisibilityFilter: rmRightVisibilityMenu
  },
  {
    icon: "fas fa-toolbox",
    menu: "Specialist",
    label: {
      value: "Specialist"
    },
    path: `/contract/${contract.contract_ref}/specialist-maintenance`,
    reportVisibilityFilter: specialistRightVisibilityMenu
  },
  {
    icon: "fas fa-clipboard-list",
    menu: "Events",
    label: {
      value: "Events"
    },
    path: `/contract/${contract.contract_ref}/events`,
    reportVisibilityFilter: logsRightVisibilityMenu
  },
  {
    iconType: AWSIcon,
    className: "awsIcon",
    icon: "fas fa-money-check-alt",
    menu: "Financials",
    label: {
      value: "Additional Works"
    },
    path: `/contract/${contract.contract_ref}/financials`,
    reportVisibilityFilter: financialsRightVisibilityMenu
  },
  {
    icon: "fas fa-th-list",
    menu: "Audit",
    label: {
      value: "Audit"
    },
    path: `/contract/${contract.contract_ref}/audit`,
    reportVisibilityFilter: auditRightVisibilityMenu
  },
  {
    iconType: SpendIcon,
    className: "spendIcon",
    icon: "fas fa-comment-dollar",
    menu: "Spend",
    label: {
      value: "Spend"
    },
    path: `/contract/${contract.contract_ref}/spend`,
    reportVisibilityFilter: spendRightVisibilityMenu
  },
  {
    icon: "fas fa-tachometer-alt",
    menu: "Meter Reading",
    label: {
      value: "Metering"
    },
    path: `/contract/${contract.contract_ref}/meter-streams`,
    //meterStreamsRightVisibilityMenu cannot be a 'hook' (useEtc) because ContractReportVisibility calls it 'conditionally' (i.e. based on a user click
    // the reportVisibilityFilter called changes) - which violates react rules.  Therefore this function is converted to a hook and passes relevant selectors
    // to it.
    //the return value of this function is a 'closure' that returns RightVisibilityMenu and is passed the contractVisibilitySettings
    reportVisibilityFilter: getContractReportMeterStreamsRightVisibilityMenu(contractMeterStreams, contract)
  },
  {
    icon: "fas fa-file-contract",
    menu: "Report",
    name: "Report",
    label: {
      value: "Report"
    },
    path: `/contract/${contract.contract_ref}/report`,
    reportVisibilityFilter: contractReportRightVisibilityMenu
  }
  ]
  return newContractMenu
}


export function useGetPortfolioReportingMenu(portfolio?: HydratedPortfolio): ContractMenu[] {
  // const portfolioMeterStreams = useSelector(portfolioInFocusMeterStreamsSelector);
  if (!portfolio) {
    return []
  }
  let newPortfolioMenu = [
    {
      icon: "fab fa-flipboard",
      menu: "Planned",
      name: "Planned",
      label: {
        value: "Planned"
      },
      path: `/portfolio/${portfolio.id}/ppm`,
      reportVisibilityFilter: ppmRightVisibilityMenu
    },
    {
      icon: "fas fa-weight",
      menu: "Reactive",
      label: {
        value: "Reactive"
      },
      path: `/portfolio/${portfolio.id}/rm`,
      reportVisibilityFilter: rmRightVisibilityMenu
    },
    {
      icon: "fas fa-toolbox",
      menu: "Specialist",
      label: {
        value: "Specialist"
      },
      path: `/portfolio/${portfolio.id}/specialist-maintenance`,
      reportVisibilityFilter: specialistRightVisibilityMenu
    },
    {
      icon: "fas fa-clipboard-list",
      menu: "Events",
      label: {
        value: "Events"
      },
      path: `/portfolio/${portfolio.id}/events`,
      reportVisibilityFilter: logsRightVisibilityMenu
    },
    {
      iconType: AWSIcon,
      className: "awsIcon",
      icon: "fas fa-money-check-alt",
      menu: "Financials",
      label: {
        value: "Additional Works"
      },
      path: `/portfolio/${portfolio.id}/financials`,
      reportVisibilityFilter: financialsRightVisibilityMenu
    },
    {
      icon: "fas fa-th-list",
      menu: "Audit",
      label: {
        value: "Audit"
      },
      path: `/portfolio/${portfolio.id}/audit`,
      reportVisibilityFilter: auditRightVisibilityMenu
    },
    {
      iconType: SpendIcon,
      className: "spendIcon",
      icon: "fas fa-comment-dollar",
      menu: "Spend",
      label: {
        value: "Spend"
      },
      path: `/portfolio/${portfolio.id}/spend`,
      reportVisibilityFilter: spendRightVisibilityMenu
    },
    // {
    //   icon: "fas fa-tachometer-alt",
    //   menu: "Meter Reading",
    //   label: {
    //     value: "Metering"
    //   },
    //   path: `/portfolio/${portfolio.id}/meter-streams`,
    //   //meterStreamsRightVisibilityMenu cannot be a 'hook' (useEtc) because ContractReportVisibility calls it 'conditionally' (i.e. based on a user click
    //   // the reportVisibilityFilter called changes) - which violates react rules.  Therefore this function is converted to a hook and passes relevant selectors
    //   // to it.
    //   //the return value of this function is a 'closure' that returns RightVisibilityMenu and is passed the contractVisibilitySettings
    //   reportVisibilityFilter: getContractReportMeterStreamsRightVisibilityMenu(contractMeterStreams, contract)
    // }
    {
      icon: "fas fa-file-contract",
      menu: "Report",
      name: "Report",
      label: {
        value: "Report"
      },
      path: `/portfolio/${portfolio.id}/report`,
      reportVisibilityFilter: contractReportRightVisibilityMenu
    }
  ]
  return newPortfolioMenu
}

export function useGetContractHASMenu(contract?: SiteContract): ContractMenu[] {
  if (!contract) {
    return []
  }
  let contractHASMenu = [
    {
      icon: "fas fa-check",
      menu: "H&S Checks",
      name: "H&S Checks",
      label: {
        value: "H&S Checks"
      },
      path: `/contract/${contract.contract_ref}/h-a-s-checks-schedule`,
    },
    {
      icon: "fas fa-stamp",
      menu: "Permits",
      name: "H&S Checks",
      label: {
        value: "Permits To Work"
      },
      path: `/contract/${contract.contract_ref}/h-a-s-work-permits`,
    },
    {
      icon: "fas fa-bell",
      menu: "Near Misses",
      name: "H&S Near Misses",
      label: {
        value: "H&S Near Misses"
      },
      path: `/contract/${contract.contract_ref}/h-a-s-near-misses`,
    },
    {
      icon: "fas fa-exclamation",
      menu: "Accidents",
      name: "H&S Accidents",
      label: {
        value: "H&S Accidents"
      },
      path: `/contract/${contract.contract_ref}/h-a-s-accidents`,
    },
  ]
  return contractHASMenu
}

export function useGetPortfolioHASMenu(portfolio?: HydratedPortfolio): ContractMenu[] {
  if (!portfolio) {
    return []
  }
  let portfolioHASMenu = [
    {
      icon: "fas fa-check",
      menu: "H&S Checks",
      name: "H&S Checks",
      label: {
        value: "H&S Checks"
      },
      path: `/portfolio/${portfolio.id}/h-a-s-checks-schedule`,
    },
    {
      icon: "fas fa-stamp",
      menu: "Permits",
      name: "H&S Checks",
      label: {
        value: "Permits To Work"
      },
      path: `/portfolio/${portfolio.id}/h-a-s-work-permits`,
    },
    {
      icon: "fas fa-bell",
      menu: "Near Misses",
      name: "H&S Near Misses",
      label: {
        value: "H&S Near Misses"
      },
      path: `/portfolio/${portfolio.id}/h-a-s-near-misses`,
    },
    {
      icon: "fas fa-exclamation",
      menu: "Accidents",
      name: "H&S Accidents",
      label: {
        value: "H&S Accidents"
      },
      path: `/portfolio/${portfolio.id}/h-a-s-accidents`,
    },
  ]
  return portfolioHASMenu
}

