/* eslint-disable */
import React, { useState, useRef, useReducer, useEffect, useCallback } from 'react';
import { isEqual } from "lodash";

import NearMissForm from 'components/ContractInFocus/HAS/HASNearMissForms';
import { FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';

import { IHASNearMiss } from "components/AdminPanel/HAS/Interfaces/HASReportingInterfaces";
import { TabbedPanel } from "components/Common/Components/TabPanel";
import NearMissImages from "components/ContractInFocus/HAS/HASIncidentImages";
import NearMissDocuments from "components/ContractInFocus/HAS/HASIncidentDocument";
import { getInitiallySelectedTabIndex } from "components/ContractInFocus/HAS/HASIncidentHelpers";
import { getNearMissRelevantUsers } from "components/AdminPanel/HAS/HASIncidentServices";
import { MentionablePerson } from "components/Common/Components/FormFieldComments/FormFieldComments";
import { HASIncidentDetailViewProps, getTabBarButtonsComponent } from "components/ContractInFocus/HAS/HASAccidentDetail";
import { commonUpdateGridRow } from "components/ContractInFocus/Services/commonAPI.services";
import { HASNearMissFormSettingControls } from "components/ContractInFocus/HAS/HASFormInfoSettingControls";
import { APIResult } from 'components/Common/Interfaces/Entity.interface';
import { getNearMissesRoute } from 'components/AdminPanel/HAS/HASIncidentServices';
import commonAPIServices from 'components/ContractInFocus/Services/commonAPI.services';

import "components/Common/Components/GeneralActionForm/GeneralActionFormStyles.scss"
import "components/ContractInFocus/HAS/Styles/forms.scss"

interface HASNearMissDetailViewProps extends HASIncidentDetailViewProps {
    data: IHASNearMiss;
}

function HASNearMissDetailView({
    data,
    permissions,
    onClose,
    masterViewSharedSpace,
    rowKey,
    gridDataSource,
    tableLevelSharedSpace,
    setFullScreenForm,
    disableDxNavKeys,
    initiallySelectedDataField,
    initiallySelectedTab,
    initiallySelectedComment
}: HASNearMissDetailViewProps) {

    if (!masterViewSharedSpace.current[rowKey]) {
        masterViewSharedSpace.current[rowKey] = {}; //important to ONLY set this to an empty object when not yet defined
    }
    // console.log('HASAccidentDetail');
    const [signalClearFieldInfoInFocus, setSignalCLearFieldInfoInFocus] = useReducer((x) => x + 1, 0);
    const rowLevelSharedSpace = masterViewSharedSpace.current[rowKey];
    const [formSettingsInFocus, setFormSettingsInFocus] = useState<boolean>(rowLevelSharedSpace.formSettingsInFocus);
    const [signalSaveChildren, setSignalSaveChildren] = useReducer((x) => x + 1, 0); // it's important that this starts at 0, as we don't need to save on opening
    const [signalCancelChildren, setSignalCancelChildren] = useReducer((x) => x + 1, 0); // it's important that this starts at 0, as we don't need to save on opening
    const [detailMeta, setDetailMeta] = useState<FieldMetaGroup | undefined>();
    const TabExtraButtons = useCallback(() => {
        return getTabBarButtonsComponent({
            onClick: () => {
                //setFieldInfoInFocus(undefined);
                setSignalCLearFieldInfoInFocus();
                setFormSettingsInFocus(true);
                rowLevelSharedSpace.formSettingsInFocus = true;
            }
        });
    }, []);

    const updateDetailMeta = useCallback(() => {
        commonAPIServices.getPreFlightInfo(getNearMissesRoute(), data.id).then(response => {
            if (!isEqual(detailMeta, response.meta)) {
                setDetailMeta(response.meta)
            };
        }).catch(
            (error) => { console.log(error) }
        )
    }, []);

    useEffect(() => {
        updateDetailMeta();
    }, []);

    const formValuesRef = useRef<any>({});

    const FormSettingsComponent = useCallback(() => {
        return <>
            {detailMeta && <HASNearMissFormSettingControls
                data={data}
                parentFormRef={formValuesRef}
                meta={detailMeta}
                tableLevelSharedSpace={tableLevelSharedSpace}
                gridDataSource={gridDataSource}
                recordType="near_miss"
            />}
        </>
    }, [data, formValuesRef, detailMeta, tableLevelSharedSpace, gridDataSource]);

    const incidentMentionablePersons = useRef<MentionablePerson[]>(); // use a ref for this as by the time it is used, it will be set - no need to create rerender

    useEffect(() => {
        getNearMissRelevantUsers(data.id).then((response) => {
            incidentMentionablePersons.current = response.data.data
        }
        )
    }, [])

    const newNamespaces = useRef<any>({
        "incidentMainForm": {},
    });
    if (!rowLevelSharedSpace.namespaces) { // this conditional is important as it prevents the rowLevelSharedSpace.namespaces from being completely reset on collapse
        // e.g this component appears to be completely rewritten by dx on row collapse, and at that point it can only get old data from the existing grid 'source' 
        // so for modified data to survive it needs to be hooked into something 'outside'.  We use the masterViewSharedSpace as the 'anchor'.
        rowLevelSharedSpace.namespaces = newNamespaces;
    }
    const formSharedSpace = rowLevelSharedSpace.namespaces.current["incidentMainForm"];
    const [thisRecord, setThisRecord] = useState<any>(data);
    const getTabs = useCallback(() => ['Initial Data', 'Full Form', 'Images', 'Documents'], []);
    const tabs = getTabs();
    const initiallySelectedTabIndex = getInitiallySelectedTabIndex(tabs, initiallySelectedTab?.current);

    const [index, setIndex] = useState(rowLevelSharedSpace["tabIndex"] || initiallySelectedTabIndex || 0);

    const handleChangeTab = (index: number) => {
        //setIndexChangeRequest(index);
        setIndex(index);
        rowLevelSharedSpace["tabIndex"] = index;
    };

    const updateRowValue = useCallback((newValues: any) => {
        commonUpdateGridRow({
            dataSource: gridDataSource,
            key: data.id,
            changes: newValues
        })
    }, [gridDataSource, data]);

    const handleCancelMain = useCallback(() => {
        const record = { ...thisRecord };
        formSharedSpace.data = false;
        formValuesRef.current = record;
        setThisRecord(record); // this effectively forces a rerender
        setSignalCancelChildren();
    }, []);

    const handleRowSave = useCallback((recordId?: string, callback?: any) => {
        setSignalSaveChildren();
        setTimeout(() => callback && callback(), [500]); // This allows any child forms to save with the modified values before the callback re-renders
        // the form with the data from the freshly loaded list.
        // This is a bit hackish but it keeps it simple - no need for the parent to
        // know what the children are (e.g. to use a forkjoin) - and we'd want to call the 'callback' regardless of result anyway - so a simple 'headstart' should be 
        // fine and it shouldn't be mission critical anyway
    }, []);

    const onSaveCallBack = useCallback((recordId: string | number, response: APIResult<IHASNearMiss>) => {
        gridDataSource && commonUpdateGridRow(
            {
                dataSource: gridDataSource,
                key: recordId,
                changes: response.data
            });
        setDetailMeta(response.metadata.actions?.PUT);
    }, []);

    rowLevelSharedSpace.handleSave = handleRowSave;

    const handleRowChangeCancel = useCallback((recordId?: string | number, callback?: any) => {
        // keeping this function signature common for consumption by shared code..
        handleCancelMain();
        callback && callback();
    }, []);

    rowLevelSharedSpace.handleCancel = handleRowChangeCancel;

    const handleFormSettingsClose = useCallback(() => {
        rowLevelSharedSpace.formSettingsInFocus = false;
        setFormSettingsInFocus(false);
    }, []);

    return <>
        {detailMeta && <TabbedPanel
            pageIndex={index}
            showTabs={data?.id.toString() !== "0"}
            onChangeTab={handleChangeTab}
            tabs={tabs}
            ExtraButtons={TabExtraButtons}
        >
            <NearMissForm
                data={data}
                rowKey={rowKey}
                masterViewSharedSpace={masterViewSharedSpace}
                signalCancel={signalCancelChildren}
                signalSave={signalSaveChildren}
                meta={detailMeta}
                permissions={permissions}
                handleFormSettingsClose={handleFormSettingsClose}
                formSettingsInFocus={formSettingsInFocus}
                signalClearFieldInfoInFocus={signalClearFieldInfoInFocus}
                formValuesRef={formValuesRef}
                formType="initial_data"
                setFullScreenForm={setFullScreenForm}
                disableDxNavKeys={disableDxNavKeys}
                initiallySelectedDataField={initiallySelectedDataField}
                containing_tab={tabs[0]}
                incidentMentionablePersons={incidentMentionablePersons}
                initiallySelectedComment={initiallySelectedComment}
                updateRowValue={updateRowValue}
                incidentFormSharedSpace={formSharedSpace}
                FormSettingsComponent={FormSettingsComponent}
                onSaveCallBack={onSaveCallBack}
            />
            <NearMissForm
                data={data}
                rowKey={rowKey}
                masterViewSharedSpace={masterViewSharedSpace}
                signalCancel={signalCancelChildren}
                signalSave={signalSaveChildren}
                meta={detailMeta}
                permissions={permissions}
                handleFormSettingsClose={handleFormSettingsClose}
                formSettingsInFocus={formSettingsInFocus}
                signalClearFieldInfoInFocus={signalClearFieldInfoInFocus}
                formValuesRef={formValuesRef}
                formType="full"
                setFullScreenForm={setFullScreenForm}
                disableDxNavKeys={disableDxNavKeys}
                initiallySelectedDataField={initiallySelectedDataField}
                containing_tab={tabs[1]}
                incidentMentionablePersons={incidentMentionablePersons}
                initiallySelectedComment={initiallySelectedComment}
                updateRowValue={updateRowValue}
                incidentFormSharedSpace={formSharedSpace}
                FormSettingsComponent={FormSettingsComponent}
                onSaveCallBack={onSaveCallBack}
            />
            <NearMissImages
                data={data}
                imageParentType="near_miss"
                FormSettingsComponent={FormSettingsComponent}
                formSettingsInFocus={formSettingsInFocus}
                handleFormSettingsClose={handleFormSettingsClose}
            />
            <div className='incidentDocumentsPanel'>
                <NearMissDocuments
                    data={thisRecord}
                    parentType="near_miss"
                    docsTitle="Near Miss Documents"
                    FormSettingsComponent={FormSettingsComponent}
                    formSettingsInFocus={formSettingsInFocus}
                    handleFormSettingsClose={handleFormSettingsClose}
                />
            </div>
        </TabbedPanel>
        }
    </>
}

// HASNearMissDetailView.whyDidYouRender = true;

export default React.memo(HASNearMissDetailView);