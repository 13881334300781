export const personnelMatterLogsColumnWidths = {
  "date": "8%",
  "details": "27%",
  "category": "10%",
  "name": "20%",
  "resolved_on": "8%",
  "resolution": "27%",
}

export const portfolioPersonnelMatterLogsColumnWidths = {
  "date": "7%",
  "contract": "7%",
  "details": "25%",
  "category": "9%",
  "name": "19%",
  "resolved_on": "7%",
  "resolution": "26%",
}

export const personnelMatterLogsColumns = [
  { name: "date", width: personnelMatterLogsColumnWidths["date"] },
  { name: "details", width: personnelMatterLogsColumnWidths["details"] },
  { name: "category", width: personnelMatterLogsColumnWidths["category"] },
  { name: "name", width: personnelMatterLogsColumnWidths["name"] },
  { name: "resolved_on", width: personnelMatterLogsColumnWidths["resolved_on"] },
  { name: "resolution", width: personnelMatterLogsColumnWidths["resolution"] },
];

export const leaveColumnsWidths = {
  "name": "20%",
  "date": "8%",
  "resolved_on": "8%",
  "details": "44%",
  "leave_remaining": "20%"
}

export const portfolioLeaveColumnsWidths = {
  "name": "19%",
  "contract": "7%",
  "date": "7%",
  "resolved_on": "7%",
  "details": "41%",
  "leave_remaining": "19%"
}

export const leaveColumns = [
  { name: "name", width: leaveColumnsWidths["name"] },
  { name: "date", width: leaveColumnsWidths["date"] },
  { name: "resolved_on", width: leaveColumnsWidths["resolved_on"] },
  { name: "details", width: leaveColumnsWidths["details"] },
  { name: "leave_remaining", width: leaveColumnsWidths["leave_remaining"] }
];

export const plantConditionLogsColumnWidths = {
  "date": "8%",
  "details": "28%",
  "risk": "8%",
  "plant_name": "20%",
  "resolved_on": "8%",
  "resolution": "28%",
}

export const portfolioPlantConditionLogsColumnWidths = {
  "date": "7%",
  "contract": "7%",
  "details": "27%",
  "risk": "7%",
  "plant_name": "19%",
  "resolved_on": "7%",
  "resolution": "26%",
}

export const plantConditionLogsColumns = [
  { name: "date", width: plantConditionLogsColumnWidths["date"] },
  { name: "details", width: plantConditionLogsColumnWidths["details"] },
  { name: "risk", width: plantConditionLogsColumnWidths["risk"] },
  { name: "plant_name", width: plantConditionLogsColumnWidths["plant_name"] },
  { name: "resolved_on", width: plantConditionLogsColumnWidths["resolved_on"] },
  { name: "resolution", width: plantConditionLogsColumnWidths["resolution"] },
];

export const subContractEventLogsColumnWidths = {
  "date": "8%",
  "details": "28%",
  "system": "8%",
  "plant_name": "20%",
  "resolved_on": "8%",
  "resolution": "28%",
}

export const portfolioSubContractEventLogsColumnWidths = {
  "date": "7%",
  "contract": "7%",
  "details": "27%",
  "system": "7%",
  "plant_name": "19%",
  "resolved_on": "7%",
  "resolution": "26%",
}

export const subContractEventLogsColumns = [
  { name: "date", width: subContractEventLogsColumnWidths["date"] },
  { name: "details", width: subContractEventLogsColumnWidths["details"] },
  { name: "system", width: subContractEventLogsColumnWidths["system"] },
  { name: "plant_name", width: subContractEventLogsColumnWidths["plant_name"] },
  { name: "resolved_on", width: subContractEventLogsColumnWidths["resolved_on"] },
  { name: "resolution", width: subContractEventLogsColumnWidths["resolution"] },
]

export const technicalEventsLogsColumnWidths = {
  "date": "8%",
  "details": "28%",
  "system": "8%",
  "plant_name": "20%",
  "resolved_on": "8%",
  "resolution": "28%",
}

export const portfolioTechnicalEventsLogsColumnWidths = {
  "date": "7%",
  "contract": "7%",
  "details": "27%",
  "system": "7%",
  "plant_name": "19%",
  "resolved_on": "7%",
  "resolution": "26%",
}

export const technicalEventsLogsColumns = [
  { name: "date", width: technicalEventsLogsColumnWidths["date"] },
  { name: "details", width: technicalEventsLogsColumnWidths["details"] },
  { name: "system", width: technicalEventsLogsColumnWidths["system"] },
  { name: "plant_name", width: technicalEventsLogsColumnWidths["plant_name"] },
  { name: "resolved_on", width: technicalEventsLogsColumnWidths["resolved_on"] },
  { name: "resolution", width: technicalEventsLogsColumnWidths["resolution"] },
]

export const hsIncidentsLogsColumnWidths = {
  "date": "8%",
  "category": "11%",
  "details": "33%",
  "investigation_completed": "7%",
  "resolved_on": "8%",
  "resolution": "33%",
}

export const portfolioHsIncidentsLogsColumnWidths = {
  "date": "7%",
  "contract": "7%",
  "category": "10%",
  "details": "32%",
  "investigation_completed": "6%",
  "resolved_on": "7%",
  "resolution": "31%",
}

export const hsIncidentsLogsColumns = [
  { name: "date", width: hsIncidentsLogsColumnWidths["date"] },
  { name: "category", width: hsIncidentsLogsColumnWidths["category"] },
  { name: "details", width: hsIncidentsLogsColumnWidths["details"] },
  { name: "investigation_completed", width: hsIncidentsLogsColumnWidths["investigation_completed"] },
  { name: "resolved_on", width: hsIncidentsLogsColumnWidths["resolved_on"] },
  { name: "resolution", width: hsIncidentsLogsColumnWidths["resolution"] },
]

export const InnovationLogsColumnWidths = {
  "date": "8%",
  "details": "84%",
  "resolved_on": "8%",
}

export const portfolioInnovationLogsColumnWidths = {
  "date": "7%",
  "contract": "7%",
  "details": "79%",
  "resolved_on": "7%",
}

export const InnovationLogsColumns = [
  { name: "date", width: InnovationLogsColumnWidths["date"] },
  { name: "details", width: InnovationLogsColumnWidths["details"] },
  { name: "resolved_on", width: InnovationLogsColumnWidths["resolved_on"] },
]