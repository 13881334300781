/* eslint-disable */
/* eslint-disable no-unreachable */
import { BehaviorSubject } from "rxjs";
import { updateProfileSuccess } from 'components/Profile/Actions/Profile.actions';
import Cookies from "universal-cookie";
import API, { APIR } from "services/API/API";
import { history } from "helpers/History/History";
import { FullStoryAPI } from 'react-fullstory';
import { store } from "../../store/store";
import { saveRouteBeforeLogout } from "../../Routes.js"

const cookies = new Cookies();

const tokenSubject = new BehaviorSubject(cookies.get("token"));

const login = async (email: string, password: string) => {
  const auth = {
    username: email,
    password: password
  };

  try {
    //const response = await API.post('/login/', {}, { auth })
    clearCookies();
    const response = await API.post('/login/', {}, { withCredentials: true, auth })
    const { token, user } = response.data;
    //cookies.remove('token', { path: '/' });
    cookies.set('token', token, { path: '/' });
    cookies.set('userId', user.id, { path: '/' });

    store.dispatch(updateProfileSuccess({ data: user }));

    FullStoryAPI('identify', user.id, {
      custom_var_1: user.email,
      custom_var_2: user.job_title
    });

    tokenSubject.next(token);
    return response.status;
  } catch (err) {
    console.error('login fail', err);
    return +err.message.substr(err.message.length - 3, err.message.length);
  }
}

function clearCookies() {
  cookies.set('token', '', { path: '/' });
  cookies.set('userId', '', { path: '/' });
  cookies.remove('token', { path: '/' });
  cookies.remove('userId', { path: '/' });
}

function clearLocalStorageAndCookies() {
  clearCookies();
  // the idea here is that 'savedLocation' is the one item preserved when localStorage is cleared.
  const savedLocation = localStorage.getItem('savedLocation');
  localStorage.clear();
  if (savedLocation) {
    localStorage.setItem('savedLocation', savedLocation);
  }
}

function logout() {
  API.post('/logout/', {}, { withCredentials: true }).finally(
    () => {
      //saveRouteBeforeLogout();
      clearLocalStorageAndCookies();
      tokenSubject.next(null);
      history.push('/login');
    }
  )
}

export const authenticationService = {
  login,
  logout,
  clearLocalStorageAndCookies,
  currentUser: tokenSubject.asObservable(),
  get token() {
    return tokenSubject.value;
  }
};