import React from 'react';
import { DateBox } from 'devextreme-react';

// Own
import { FieldMeta } from '../../../Interfaces/Entity.interface';
import { useMatFormElement } from '../../../Hooks/useMatFormElement';


interface MatDatePickerProps {
  id?: string;
  className?: string;
  showLabel?: boolean;
  value?: Date | string;
  meta: FieldMeta;
  dateType?: 'datetime' | 'date';
  calendarOptions?: {
    maxZoomLevel: "month" | "year" | "century" | "decade" | undefined,
    minZoomLevel: "month" | "year" | "century" | "decade" | undefined,
  },
  dateDisplayFormat?: string,
  submitted?: boolean;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date
  onChange: (value: any) => void;
  refresh?: number;
  required?: boolean;
  inputRef?: any;
  error?: string;
}

const MatDatePicker = ({
  id,
  className,
  showLabel = true,
  value,
  meta,
  onChange,
  dateType = 'date',
  calendarOptions,
  dateDisplayFormat,
  submitted = false,
  disabled,
  minDate,
  maxDate,
  required,
  inputRef,
  error
}: MatDatePickerProps) => {

  const { bind, isElementValid } = useMatFormElement({
    value,
    meta,
    onValueChanged: onChange,
    submitted
  });

  return (
    <div className={`mat-textarea ${className ? className : ''}`}>
      {showLabel ? (
        <div className="mat-field-label">
          {meta.label + " "}
          {meta.required || required ? "*" : null}
        </div>
      ) : null}
      <DateBox
        id={id}
        {...bind}
        ref={inputRef}
        defaultValue={value}
        type={dateType}
        isValid={isElementValid() || !!error}
        disabled={disabled}
        min={minDate}
        max={maxDate}
        displayFormat={(dateDisplayFormat || dateType) === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'}
        // displayFormat='monthAndYear'
        calendarOptions={calendarOptions}
        validationStatus={error ? 'invalid' : 'valid'}
        validationError={{ message: error }}
      />
    </div>
  )
}

export default MatDatePicker;