import React, { useCallback, useMemo, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Own
import { TabbedPanel } from "components/Common/Components/TabPanel";
import AdminForm from "components/AdminPanel/Containers/AdminForm/AdminForm";
import { ContractInterface, DistributionRecipientI } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { maintenanceReportCoverImage } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { AdminImageForm } from "components/AdminPanel/Containers/AdminImageForm";
import { getCoverImageUploader } from "components/Common/Components/Input/FileUploader.js"
import { transformContractMetaForGrid } from "components/AdminPanel/Contracts/Helper/contractHelper";
import { getContractFormItems, contractFormItems } from "components/AdminPanel/Contracts/Models/ContractDetail.model";
import { setDetailPage } from "components/AdminPanel/Actions/adminPanel.actions";
import { getPanelSelectorFactory } from "components/AdminPanel/Selectors/Selectors";
import { contractWithLookupSelector } from "components/AdminPanel/Organisation/Selectors/Organisations.selectors";
import { peopleCalcLookupSelector } from "components/AdminPanel/People/Selectors/People.selector";
import { sitesLookupSelector } from "components/AdminPanel/Sites/Selectors/Sites.selectors";
import PeopleWithAccessView from "components/AdminPanel/People/Containers/PeopleSublistView/PeopleWithAccess";
import ContractHASTasks from "components/AdminPanel/People/Containers/HASTaskSublistView/ContractHASTasks";
import MonthlyMaintenanceReportDistributionView from "components/AdminPanel/People/Containers/PeopleSublistView/maintenanceReportDistribution";
import { ContractPeriodsSublistView } from "components/AdminPanel/ContractPeriods/Containers/ContractPeriodsSublistView";
import contractServices from "components/AdminPanel/Contracts/Services/contractService";
import * as selectors from "components/AdminPanel/Contracts/Selectors/Contracts.selectors";
import { maintenanceReportDistributionColumns, peopleWithAccessColumns } from "components/AdminPanel/People/Models/PeopleDetail.model";
import { FORM_ROW_ID } from "components/AdminPanel/Models/AdminPanel.model";
import { PersonWithAccessI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { updateOrderApi } from "components/ContractInFocus/Services/commonAPI.services";
import { constructHandleDistributionListSubmit } from "components/ContractInFocus/ContractReportVisibility/ReportDistributionListGrid";
import { ImageConfig } from "components/AdminPanel/Containers/AdminImageForm/AdminImageForm";

const visibilityContractFormItems = [
  {
    key: "DocumentOptions",
    caption: "Document Options",
    items: [
      {
        key: "expose_aw_focal_point_documents",
        label: "Expose Additional Works Focal Point Documents To Non Jaguar Staff"
      },
      {
        key: "expose_supplier_quotation_documents_by_default",
        label: "Expose Supplier Quotes To Non Jaguar Staff By Default"
      },
    ]
  },
  {
    key: "HealthAndSafetyOptions",
    caption: "Health and Safety Section Options",
    items: [
      {
        key: "include_h_a_s",
        label: "Include Health and Safety managment on this contract"
      },
      {
        key: "expose_h_a_s",
        label: "Expose Health and Safety managment to Non Jaguar Staff"
      },
      {
        key: "client_ptws",
        label: "Use Client Permit To Work System (upload documents)"
      },
      {
        key: "disable_work_permits_system",
        label: "Hide all work permit system information"
      }

    ]
  }
];

interface ContractDetailViewProps {
  panelId: string;
  data: { data: ContractInterface };
}

const ContractDetailView: React.FC<ContractDetailViewProps> = ({
  panelId,
  data: { data },
}) => {

  const distributionDataTestId = "distribution-contract-grid";
  const explicitDataTestId = "explicit-contract-grid";
  const contractPeriodDataTestId = "contract-period-grid";

  const contractId = data.id;
  const contractRef = data.contract_ref;
  const contractTabs = [
    "Detail",
    "Visibility and Contents",
    "Distribution",
    //"Explicit Access",
    "People",
    "Contract Periods",
    "Health and Safety Tasks"
  ];

  const selectContract = useSelector(selectors.contractByIdSelector(data.id));
  const peopleWithAccessState = useSelector(selectors.contractPeopleWithAccessSelectorFactory(data.id));
  const selectContractWithLookupState = useSelector(contractWithLookupSelector);
  const selectPeopleLookupState = useSelector(peopleCalcLookupSelector);
  const selectSiteLookupState = useSelector(sitesLookupSelector);
  const selectDistributionState = useSelector(selectors.contractDistributionListSelectorFactory(data.id));
  const selectAdminPanelState = useSelector(getPanelSelectorFactory(panelId));

  const IS_GRID_FORM = selectContract.data?.id.toString() === FORM_ROW_ID;

  const gridRecordMeta = () => {
    if (selectContract.meta) {
      const a = transformContractMetaForGrid(
        selectContract.meta,
        selectContractWithLookupState,
        selectPeopleLookupState,
        selectSiteLookupState
      )
      return a;
    }
  }

  // useEffect(() => {
  //   console.log('HASTaskAdminDetailView called!!');
  //   return () => {
  //       console.log('disposing...');
  //       if (masterViewSharedSpace?.current[rowKey]) {
  //           masterViewSharedSpace.current[rowKey] = undefined; // IMPORTANT! release any memory when this component disposes if not caching...
  //           // NB if not caching, probably makes sense to show this component conditionally to with 'detailShowing' too
  //           // though you could potentially save the component memory but cache the contents
  //       }
  //   }
  // }, [])

  const recordmeta = useMemo(
    gridRecordMeta, [selectContract.meta, selectContractWithLookupState, selectPeopleLookupState, selectSiteLookupState]
  )

  const getMainFormMeta = () => {
    if (recordmeta) {
      const contractFormMeta = getContractFormItems(recordmeta, IS_GRID_FORM, contractFormItems);
      return contractFormMeta
    }
  }

  const getVisibilityFormMeta = useCallback(() => {
    if (recordmeta) {
      const contractFormMeta = getContractFormItems(recordmeta, IS_GRID_FORM, visibilityContractFormItems);
      return contractFormMeta
    }
  }, [recordmeta, IS_GRID_FORM]);

  const formMeta = useMemo(
    getMainFormMeta, [recordmeta, IS_GRID_FORM]
  )

  const visibilityFormMeta = useMemo(
    getVisibilityFormMeta, [recordmeta, IS_GRID_FORM]
  )

  const dispatch = useDispatch();

  const handleChangeTab = (index: number) => {
    dispatch(setDetailPage(panelId, data.id, index));
  };

  const handleCancel = (contract: ContractInterface): void => {
    console.log('place holder for cancel functionality');
  };

  const callBackHandleCancel = useCallback(handleCancel, []);

  const handleUpdate = (oldContract: ContractInterface, contract: ContractInterface): void => {
    const IS_GRID_FORM = contract.id.toString() === FORM_ROW_ID;

    IS_GRID_FORM
      ? contractServices.createContract({ ...contract, monthly_maintenance_report_cover_image: undefined }, panelId)
      : contractServices.updateContract(
        contract.contract_ref,
        contract,
        selectContract.meta
      );
  };

  const callBackHandleUpdate = useCallback(handleUpdate, [selectContract.meta, panelId]);

  const [imagesConfig, setImagesConfig] = useState<ImageConfig[]>([]);

  useEffect(() => {
    const onReportCoverImageClear = () => {
      contractServices.clearReportCoverImage(data.id, data.contract_ref).subscribe();
    };

    const onReportCoverImageUpload = (file: any, progress: any) => {
      contractServices.updateReportCoverImage(data.id, data.contract_ref, file).subscribe();
    };

    setImagesConfig(
      [
        {
          imageCaption: maintenanceReportCoverImage.label,
          imageKey: maintenanceReportCoverImage.key,
          onUpload: onReportCoverImageUpload,
          onClear: onReportCoverImageClear,
          uploader: getCoverImageUploader
        },
      ]
    )
  }, [data])

  const getDetailTab = () => {
    return (
      formMeta && recordmeta && <AdminImageForm
        data={selectContract.data}
        meta={recordmeta}
        formMeta={formMeta}
        onUpdate={callBackHandleUpdate}
        onCancel={callBackHandleCancel}
        imagesConfig={imagesConfig}
      />
    );
  };

  const getVisibilityTab = () => {
    return (
      visibilityFormMeta && recordmeta && <AdminForm
        data={selectContract.data}
        meta={recordmeta}
        formMeta={visibilityFormMeta}
        onUpdate={callBackHandleUpdate}
        onCancel={callBackHandleCancel}
      />
    );
  };

  const memoisedGetDetailTab = useCallback(getDetailTab, [
    selectContract.data,
    recordmeta,
    formMeta,
    callBackHandleUpdate,
    callBackHandleCancel,
    imagesConfig
  ])

  const memoisedGetVisibilityTab = useCallback(getVisibilityTab, [
    selectContract.data,
    recordmeta,
    visibilityFormMeta,
    callBackHandleUpdate,
    callBackHandleCancel,
  ])

  if (!selectContract) {
    return <></>
  }

  function handlePeopleWithAccessListSubmit(peopleIds: number[]) {
    const accessObjs = peopleIds.map((personId) => (
      {
        "person": personId,
        "contract": selectContract.data.id
      }
    ))
    contractServices
      .linkPeopleWithAccessToContract(selectContract.data, accessObjs)
      .then(() => {
        console.log('done');
      });
  }

  const handleRemoveRecipientFromDistribution = (accessObj: DistributionRecipientI) => {
    contractServices.removeRecipientFromMaintenanceReportDistribution(
      {
        contractId: selectContract.data.id,
        contractRef: selectContract.data.contract_ref,
        accessId: accessObj.id
      }
    );
  };

  // function handleDistributionListSubmit(peopleIds: number[]) {
  //   contractServices
  //     .linkPeopleToContract("distribution", selectContract.data, peopleIds)
  //     .then(() => {
  //       console.log('done');
  //     });
  // }

  const handleDistributionListSubmit = constructHandleDistributionListSubmit({ contractId: selectContract.data?.id, contractRef: selectContract.data?.contract_ref });

  const orderBasePath = `contracts/${data.contract_ref}/monthly-maintenance-report-distribution/`;

  const handleReorderDistribution = (e: any, callback: () => void) => {
    updateOrderApi(orderBasePath, e.itemData.id, { order: e.toIndex }).then(() => {
      contractServices.simpleFetchDistribution({ contractId: data.id, contractRef: data.contract_ref })
      callback()
    });

  }

  const handleUnlinkPersonWithAccess = (accessObj: PersonWithAccessI) => {
    contractServices.unlinkPeopleWithAccessFromContract(
      selectContract.data,
      accessObj.id
    );
  };
  const tabIndex = selectAdminPanelState[data.id]?.detailIndex;

  return (
    <>
      {selectContract.data && selectDistributionState && peopleWithAccessState && (
        <TabbedPanel
          pageIndex={tabIndex && tabIndex > contractTabs.length - 1 ? 0 : selectAdminPanelState[data.id]?.detailIndex || 0}
          showTabs={data.id.toString() !== FORM_ROW_ID}
          onChangeTab={handleChangeTab}
          tabs={contractTabs}
        >
          <div>{selectContract.meta && memoisedGetDetailTab()}</div>
          <div>{selectContract.meta && memoisedGetVisibilityTab()}</div>
          <div>
            <MonthlyMaintenanceReportDistributionView
              dataTestId={distributionDataTestId}
              panelId={panelId}
              masterRecordData={selectContract.data}
              distributionListState={selectDistributionState}
              getDatagridColumns={maintenanceReportDistributionColumns}
              onUnlink={handleRemoveRecipientFromDistribution}//handleDistributionUnlink
              masterRecordType="contract"
              selectActionIdentifier="distribution"
              onAddLinks={handleDistributionListSubmit}
              linkPanelTitle={"SELECT DISTRIBUTION LIST FOR " + selectContract.data.contract_ref + " MAINTENANCE REPORT"}
              handleOnReorder={handleReorderDistribution}
            />
          </div>
          <div>
            <div className="contractDetailViewPrimaryRolesWrapper">
              <span><strong>Primary Roles:&nbsp;</strong></span>
              {!!selectContract.data.business_unit_head && <span>Business Unit Head:&nbsp;{selectContract.data.business_unit_head.email}&nbsp;&nbsp;</span>}
              {!!selectContract.data.account_manager && <span>Account Manager:&nbsp;{selectContract.data.account_manager.email}&nbsp;&nbsp;</span>}
              {!!selectContract.data.site_manager && <span>Site Manager:&nbsp;{selectContract.data.site_manager.email}&nbsp;&nbsp;</span>}
              {!!selectContract.data.client_contact &&
                <span>Client Contact:&nbsp;{selectContract.data.client_contact.email}&nbsp;&nbsp;</span>
              }
              <span>(Adjust on detail tab)</span><br />
            </div>

            <PeopleWithAccessView
              dataTestId={explicitDataTestId}
              panelId={panelId}
              masterRecordData={selectContract.data}
              peopleWithAccessState={peopleWithAccessState}
              getDatagridColumns={peopleWithAccessColumns}
              onUnlink={handleUnlinkPersonWithAccess}
              masterRecordType="contract"
              selectActionIdentifier="explicitAccess"
              onAddLinks={handlePeopleWithAccessListSubmit}
              linkPanelTitle={"GRANT EXPLICIT ACCESS TO CONTRACT " + selectContract.data.contract_ref}
            />
          </div>
          <div>
            <ContractPeriodsSublistView
              id={contractId}
              dataTestId={contractPeriodDataTestId}
              panelId={panelId}
              contractRef={contractRef}
            />
          </div>
          <div>
            <ContractHASTasks
              panelId={panelId}
              dataTestId={"contractHASTasks"}
              id={data.id}
              masterRecordData={data}
              masterRecordType="contract"
              selectActionIdentifier="addHASTasks"
              linkPanelTitle={"ADD HEALTH AND SAFETY TASKS TO CONTRACT: " + data.contract_ref}

            />
          </div>
        </TabbedPanel>
      )}
    </>
  );
};

export default ContractDetailView;


