import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { store } from "store/store";

// Own
import ContractSnapshotModal from 'components/ContractInFocus/ContractSnapshot/ContractSnapshotModal';
import ContractGeneralSettingsModal from "components/Layout/Contract/Containers/ContractSubHeading/ContractGeneralSettingsModal";
import ContractSubHeadingSite from '../../Components/ContractSubSite/ContractSubHeadingSite';
import ContractSubHeadingContractSelector from '../../Components/ContractSubContractSelector/ContractSubHeadingContractSelector';
import PortfolioSubHeadingTitle from "components/Layout/Portfolio/PortfolioSubHeaderTitle";
import InFocusSubHeadingPeriodSelector from '../../Components/ContractSubContractPeriodSelector/ContractSubHeadingContractPeriodSelector';
import ContractHistoryDropdown from 'components/Layout/Contract/Components/ContractHistoryDropdown/ContractHistoryDropdown';
import snapShotService from 'components/ContractInFocus/Services/snapShot.service';
import { ContractQuickLinks } from '../../Components/ContractQuickLinks';
import { fetchContractSnapshots, setContractDocsDialogueOpen } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import { fetchPortfolioSnapshots, setPortfolioDocsDialogueOpen } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { ContractInterface } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { ContractDocument, MonthlyMaintenanceReports } from 'interfaces/contract.interfaces';
import { simpleFetchContractPeriods } from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";
import { ContractPeriod, PortfolioPeriod } from '../../../../AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface';
import { simpleFetchPortfolioPeriods } from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";
import { portfolioInFocusPeriod } from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
// import ContractSubHeadingContactsSelector from '../../Components/ContractSubContractContacts/ContractSubHeadingContactsSelector';
import { Person } from 'components/AdminPanel/People/Interfaces/People.interface';
import { saveDateFormat, toDateLabel } from 'components/Common/Utils/Dates';
import { addInfoNotice } from 'components/Notification/helper/notification.helper';
import { insertSnapshotReducer } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import { insertPortfolioSnapshotReducer } from 'components/PortfolioInFocus/Actions/portfolioInFocus.actions';
import * as inFocusSelectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import * as portfolioInFocusSelectors from 'components/PortfolioInFocus/Selectors/portfolioInFocus.selectors';
import * as periodSelectors from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import { broadcastLayoutUpdateSelector } from "components/Profile/Selectors/Profile.selector";
import { toggleHideContractSideBar } from "components/Layout/Contract/Containers/ContractSidebar/InFocusSidebar.js";
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useQuery } from "store/Common/Helpers/commonHelper.js";
import { PeriodOption } from "components/ContractInFocus/interfaces/contractInFocusActions.interfaces";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_ERROR } from 'components/Notification/Constants/constants';
import { useInView } from 'react-intersection-observer';

// Styles
import { MainSubheadingWrapper, MainSubheadingSpacer, ControlsWrapper } from './ContractSubHeadingStyles';
import './ContractSubHeading.scss';
import { HydratedPortfolio } from "components/Portfolios/Interfaces/Portfolios.interface";
import { useScrollDirection } from 'components/Layout/CustomHooks/LayoutHooks';

export const historyPushPeriod = (history: any, period: ContractPeriod | PortfolioPeriod | PeriodOption, parameter = "contractPeriod"): void => {
  history.push(`${history.location.pathname}?${parameter}=${period.start_date}_${period.end_date}${history.location.hash}`);
}

//const ContractSubHeader = ({ contract }: { contract: ContractInterface }) => {
interface SubHeaderContractProps {
  contract?: SiteContract,
  portfolio?: HydratedPortfolio,
  isCurrentPeriod?: boolean,
}
const ContractSubHeader = ({ contract, portfolio, isCurrentPeriod }: SubHeaderContractProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const scrollDirection = useScrollDirection();

  const headerSpacerInViewOptions = useRef({
    threshold: 0,
  });

  const { ref: subHeaderRef, inView: subHeadingSpacerInView, entry: subHeaderEntry } = useInView({ ...headerSpacerInViewOptions.current });

  const [, setContacts] = useState<Person[]>([]);
  const [showFreezeDialog, setShowFreezeDialog] = useState(false);
  const [showGeneralPersonalContractSettings, setShowGeneralPersonalContractSettings] = useState(false);
  const selectedSiteState = useSelector(inFocusSelectors.contractInFocusSiteSelector);

  const selectedFocusedContractPeriodState = useSelector(inFocusSelectors.contractInFocusFocusedContractPeriodSelector);
  const selectedPortfolioPeriod = useSelector(portfolioInFocusPeriod);
  const contractPeriodsState = useSelector(periodSelectors.contractContractPeriodsSelector(contract?.id)).data;
  const portfolioPeriodsState = useSelector(periodSelectors.portfolioPortfolioPeriodsSelector(portfolio?.id)).data;

  const contractSnapshotState = useSelector(inFocusSelectors.contractInFocusSnapshotHistorySelector);
  const portfolioSnapshotState = useSelector(portfolioInFocusSelectors.portfolioInFocusSnapshotHistorySelector);

  const portfolioSelectedMonthlyReports = useSelector(portfolioInFocusSelectors.portfolioInFocusPortfolioMonthlyMaintenanceSelector);
  const contractSelectedMonthlyReports = useSelector(inFocusSelectors.contractInFocusContractMonthlyMaintenanceSelector);

  const [snapshotCreationInProgress, setSnapshotCreationInProgress] = useState(false);
  let selectedPeriod: ContractPeriod | PortfolioPeriod | undefined;
  let periodChoices: PortfolioPeriod[] | ContractPeriod[] | undefined;
  let snapshotState: any;
  let selectedMonthlyReports: MonthlyMaintenanceReports;

  if (portfolio) {
    selectedPeriod = selectedPortfolioPeriod;
    periodChoices = portfolioPeriodsState;
    snapshotState = portfolioSnapshotState;
    selectedMonthlyReports = portfolioSelectedMonthlyReports;
  } else {
    selectedPeriod = selectedFocusedContractPeriodState;
    periodChoices = contractPeriodsState;
    snapshotState = contractSnapshotState;
    selectedMonthlyReports = contractSelectedMonthlyReports;
  }

  const updateSnapshotState = useCallback(() => {
    contract && selectedFocusedContractPeriodState && dispatch(fetchContractSnapshots({ contractPeriod: selectedFocusedContractPeriodState }));
    portfolio && selectedPortfolioPeriod && dispatch(fetchPortfolioSnapshots({ portfolioPeriod: selectedPortfolioPeriod as PortfolioPeriod }));
  }, [portfolio, contract, selectedFocusedContractPeriodState, selectedPortfolioPeriod])

  const updateContractPeriodState = useCallback(() => {
    contract && simpleFetchContractPeriods(contract.contract_ref);
    portfolio && simpleFetchPortfolioPeriods(portfolio.id);
  }, [portfolio, contract])

  const active = useSelector(inFocusSelectors.contractActiveSelector);
  const layoutUpdateBroadcast = useSelector(broadcastLayoutUpdateSelector);
  const query = useQuery();
  const isPDFRequest = !!query.get('pdfView');

  const [report, setReport] = useState<ContractDocument | undefined>(undefined);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState<boolean>();
  const [showContractSubHeading, setShowContractSubheading] = useState<boolean>(false);

  useEffect(() => {
    if (snapshotState.focus) {
      //const [month, year] = toDateLabel(contractSnapshotState.focus?.frozen_for).split(' ')
      //const doc = selectedMonthlyReports?.data?.find(item => item.year === +year && item.month === month)
      const doc = selectedMonthlyReports?.data?.find(item => item.snapshot === snapshotState.focus?.id)
      setReport(doc)
    }
  }, [snapshotState, selectedMonthlyReports])

  const getLeftMargin = useCallback((): string => {
    //const sideBarHidden = isPDFRequest || store.getState().profile?.data?.hide_contract_sidebar;
    //const sideBarCollapsed = store.getState().profile?.data?.collapse_contract_sidebar;
    const sideBarHidden = isPDFRequest || store.getState().personalSettings?.data?.hide_contract_sidebar;
    const sideBarCollapsed = store.getState().personalSettings?.data?.collapse_contract_sidebar;
    if (sideBarHidden) {
      !showHamburgerMenu && setShowHamburgerMenu(true);
      return "4rem";
    } else {
      showHamburgerMenu && setShowHamburgerMenu(false);
    }
    if (sideBarCollapsed) {
      return "4rem";
    }
    return "15rem";
  }, [showHamburgerMenu]);

  const leftMarginRef = useRef(getLeftMargin());
  const [leftMargin, setLeftMargin] = useState<string>(leftMarginRef.current);

  useEffect(() => {
    setLeftMargin(getLeftMargin());
  }, [layoutUpdateBroadcast])

  useEffect(() => {
    if (scrollDirection.isScrollingUp) {
      setShowContractSubheading(true);
    } else if (scrollDirection.isScrollingDown) {
      setShowContractSubheading(false);
    }
  }, [scrollDirection])

  const handleSelectContract = (contract: ContractInterface): void => {
    history.push(`/contract/${contract.contract_ref}/`);
  }

  const handleSelectPeriod = useCallback((period: ContractPeriod | PortfolioPeriod): void => {
    //history.push(`${history.location.pathname}?contractPeriod=${period.start_date}_${period.end_date}`);
    if (portfolio) {
      historyPushPeriod(history, period, "portfolioPeriod");
    } else {
      historyPushPeriod(history, period);
    }

  }, [history])

  const handleSelectSnapshot = useCallback((frozen_for: string): void => {
    const queryParams = queryString.parse(history.location.search);
    queryParams['snapshot'] = frozen_for;
    history.push(`${history.location.pathname}?${queryString.stringify(queryParams)}`);
  }, [history]);

  const handleSiteClick = (): void => {
    window.dispatchEvent(new Event('resize'));
  }

  const handleCreateSnapshot = (e: React.MouseEvent, date: Date): void => {
    e.preventDefault();
    setSnapshotCreationInProgress(true);
    const payload = { "frozen_for": saveDateFormat(date) };
    (contract || portfolio) && snapShotService.createSnapshot({ portfolioId: portfolio?.id, contractId: contract?.contract_ref, payload }).then(
      (response: any) => {
        //return response
        addInfoNotice('Snapshot created.');
        setShowFreezeDialog(false);
        console.log('going to insert snapshot');
        if (selectedPeriod) {
          if (portfolio) {
            dispatch(insertPortfolioSnapshotReducer({ snapshot: response.data }));
          } else {
            dispatch(insertSnapshotReducer({ snapshot: response.data }));
          }
          const queryParams = new URLSearchParams(history.location.search);
          queryParams.set("snapshot", response.data.frozen_for);
          history.push(`${history.location.pathname}?${queryParams.toString()}`);
        }
      }).
      catch((error) => {
        dispatch(addNotification({ message: error, type: NOTIFICATION_ERROR }));
      }
      ).
      finally(() => {
        setSnapshotCreationInProgress(false);
      })
  }

  const openContractFiles = (): void => {
    if (portfolio) {
      dispatch(setPortfolioDocsDialogueOpen(portfolio.id))
    };
    if (contract) {
      dispatch(setContractDocsDialogueOpen(contract.id))
    };
  }

  const contractSubHeaderDataIsReady = (): boolean => !!selectedSiteState && !!contract && !!selectedPeriod && !!selectedSiteState.data;
  const portfolioSubHeaderDataIsReady = (): boolean => !!portfolio && !!selectedPeriod;
  const contractSubTitleReady = (): boolean => !!contract && !!selectedSiteState.data && !!contractPeriodsState && !!selectedPeriod;
  const portfolioSubTitleReady = (): boolean => !!portfolio && !!portfolioPeriodsState;

  const hasContractAndSites = (): boolean => !!selectedSiteState.data && !!contract;

  const openContractReport = (): void => {
    !!report?.file && window.open(report?.file)
  }

  const enableSnapshotQuicklink = (): boolean => snapshotState?.permissions?.POST && !snapshotState.focus;

  return (
    <>
      {selectedPeriod && showFreezeDialog && snapshotState.permissions.POST ? // showFreezeDialogue here as well as against isOpen ensures clean up of modal styles
        <ContractSnapshotModal
          isOpen={showFreezeDialog}
          onCancel={() => setShowFreezeDialog(false)}
          onFreezeInfo={handleCreateSnapshot}
          snapshotHistory={snapshotState?.data || {}}
          selectedPeriod={selectedPeriod}
          snapshotCreationInProgress={snapshotCreationInProgress}
        />
        : null
      }

      <ContractGeneralSettingsModal
        isOpen={showGeneralPersonalContractSettings}
        contract={contract}
        portfolio={portfolio}
        onCancel={() => setShowGeneralPersonalContractSettings(false)}
        manageOpen={setShowGeneralPersonalContractSettings}
      />

      {/* <MainSubheadingWrapper id="contractSubHeading" style={{ paddingLeft: leftMargin, opacity: active ? 1 : 0.5 }}> */}

      <MainSubheadingSpacer ref={subHeaderRef} />

      <MainSubheadingWrapper id="contractSubHeading" className="no-print" style={{
        paddingLeft: leftMargin,
        display: (subHeadingSpacerInView || showContractSubHeading) ? 'flex' : 'None',
        //opacity: (subHeadingSpacerInView || showContractSubHeading) ? 1 : 0 
      }}>

        {showHamburgerMenu && <div className="menuIconWrapper">
          <IconButton
            onClick={() => {
              toggleHideContractSideBar(dispatch);
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </div>}
        {portfolioSubTitleReady() && portfolio ?
          <PortfolioSubHeadingTitle
            selectedPortfolio={portfolio}
            portfolioPeriods={portfolioPeriodsState}
          />
          : contractSubTitleReady() && selectedSiteState.data && contract ?
            <ContractSubHeadingSite
              selectedContract={contract}
              site={selectedSiteState.data}
              contractPeriods={contractPeriodsState}
            />
            : null
        }
        <ControlsWrapper>

          {hasContractAndSites() && selectedSiteState.data ?
            <ContractSubHeadingContractSelector
              selectedContract={contract}
              contracts={selectedSiteState.data.site_contracts}
              onSelectContract={handleSelectContract}
            />
            : null}

          {contractSubHeaderDataIsReady() || portfolioSubHeaderDataIsReady() ?
            <>
              {selectedPeriod && periodChoices ?
                <InFocusSubHeadingPeriodSelector
                  selectedPeriod={selectedPeriod}
                  periodOptions={periodChoices}
                  onSelectPeriod={handleSelectPeriod}
                  updateContractPeriodsState={updateContractPeriodState}
                />
                : null
              }

              {snapshotState.data &&
                <ContractHistoryDropdown
                  history={snapshotState.data}
                  selectedHistory={snapshotState.focus}
                  onSelect={handleSelectSnapshot}
                  isCurrentPeriod={isCurrentPeriod}
                  updateSnapshotState={updateSnapshotState}

                />
              }

              <ContractQuickLinks
                showSnapshot={snapshotState.permissions.POST}
                onOpenContractFiles={openContractFiles}
                onOpenContractReport={openContractReport}
                onShowFreezeDialog={setShowFreezeDialog}
                onOpenGeneralPersonalContractSettings={setShowGeneralPersonalContractSettings}
                snapshot={snapshotState.focus}
                enableSnapshot={enableSnapshotQuicklink()}
                enableReportLink={selectedMonthlyReports?.meta?.file && !!report?.file}
              />
            </>
            : null}
        </ControlsWrapper>
      </MainSubheadingWrapper>
    </>
  );
}

export default ContractSubHeader;