import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2px;
  margin-left: 2px;
  height: 20px;
  position: relative;
  margin-bottom: 10px;
  &.warn:after {
    content: "";
    position: relative;
    width: 15px;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid red;
    display: block;
    margin-top: 3px;
  }
  // &.warn:hover:after {
  //   content: "No service report!";
  //   color: grey;
  //   font-size:0.9em;
  //   padding-left:10px;
  //   postition: absolute;
  //   left:0
  //   top: -40px;
  //   width: 200px;
  //   z-index: 900000;
  // }
`;

export const FlexCenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media print {
    display: inline;
  }
`;
