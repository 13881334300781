import { tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

// Own
import API, { APIR, getMultipartConfig } from "services/API/API"
import { ParseAPIResponse } from "services/Interface/Interface"
import { PEOPLE_ROUTE } from "services/API/common/globalAPIs";
import { Person, contractRelationType } from "components/AdminPanel/People/Interfaces/People.interface";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import {
  unWrapDataAndMeta,
  unWrapDataAndMetaAndPUTMeta,
  removeReadOnlyFields,
  getData,
  getMeta,
  getOptions,
  getListMeta,
  getPrivileges,
  getListPUTMeta,
  getListPrivileges
} from "services/API/API.helper";
import { SubmitAccessibleContractI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import { SubmitAccessiblePortfolioI, AccessiblePortfolioI } from "components/Portfolios/Interfaces/Portfolios.interface";
import { store } from "store/store";
import { FieldMetaGroup } from 'components/Common/Interfaces/Entity.interface';
import { groupFetchPipe } from 'components/AdminPanel/Helpers/Pipeline.helpers';
import { ContractInterface, AccessibleContractI } from 'components/AdminPanel/Contracts/Interfaces/Contract.interface';
import * as fromRootActions from "store/actions/root.actions";
import * as actions from "components/AdminPanel/People/Actions/People.actions";
import * as orgActions from "components/AdminPanel/Organisation/Actions/Organisation.actions";
import { addNotification } from 'components/Notification/Actions/Notification.actions';

const createPerson = (data: Partial<Person>, panelId?: string): Promise<any> => {
  const { id, ...payload } = data;
  return APIR.post(PEOPLE_ROUTE, payload).pipe(
    unWrapDataAndMeta()
  ).toPromise().then((response) => {
    store.dispatch(actions.setPersonSuccess({ ...response.data }, response.meta, response.options, response.permissions, true, panelId, response.data.id));
    if (response.message) {
      store.dispatch(addNotification({ message: response.message.text, type: response.message.type }))
    }
    data.organisation && store.dispatch(orgActions.addMember(data.organisation, response.data.id));
    return response;
  });
}

const linkAccessibleContractsToPerson = (person: Person, accessObjs: SubmitAccessibleContractI[]) => {
  return API.post(`${PEOPLE_ROUTE}${person.id}/access/`, accessObjs).then((response: any) => {
    const data = getData(response)
    store.dispatch(actions.updateAccessibleContractsReducer(person.id, data))
  });
}

const accessibleContractRoute = (personId: number, accessId: string | number) => `${PEOPLE_ROUTE}${personId}/access/${accessId}/`;

const unlinkAccessibleContractsFromPerson = (person: Person, accessObjId: number | string) => {
  return API.delete(accessibleContractRoute(person.id, accessObjId)).then(() => {
    store.dispatch(actions.removeAccessibleContractsReducer(person.id, accessObjId))
  });
}

const updatePerson = (id: string, oldData: Partial<Person>, data: Partial<Person>, meta: FieldMetaGroup) => {

  const { password, avatar, ...payload } = removeReadOnlyFields(data, meta);
  const newPayload = oldData.password !== data.password ? { ...payload, password: data.password } : payload;

  return API.patch(`${PEOPLE_ROUTE}${id}/`, newPayload).then((response) => {
    const person = response.data.data;
    if (oldData.organisation !== person.organisation) {
      store.dispatch(orgActions.removeMember(oldData.organisation, person.id));
      store.dispatch(orgActions.addMember(person.organisation, person.id));
    }
    const message = response.data.metadata?.message
    if (message) {
      store.dispatch(addNotification({ message: message.text, type: message.type }))
    }
    store.dispatch(actions.setPersonReducer(person));
  });
}

const fetchPerson = (id: string) => {
  return APIR.get(`${PEOPLE_ROUTE}${id}/`).pipe(
    unWrapDataAndMeta()
  ).toPromise().then((response) => {
    store.dispatch(actions.setPersonSuccess(response.data, response.meta, response.options, response.permissions))
    return response;
  });
}

const groupFetchPeople = (people: Person[]) => {
  const requests = people.map((person) => APIR.get(`${PEOPLE_ROUTE}${person.id}/`));

  return forkJoin(requests).pipe(
    groupFetchPipe()
  ).subscribe(response => {
    store.dispatch(actions.setGroupPersonReducer(response));
    fetchJobTitles();
  });
}

const deletePerson = (id: string) => {
  return API.delete(`${PEOPLE_ROUTE}${id}/`).then((response) => {
    store.dispatch(actions.deletePersonReducer(id))
  });
}

const updatePersonAvatar = (id: any, avatar: any) => {
  const getFileObject = () => {
    const fd = new FormData();
    fd.append("avatar", avatar);
    return fd;
  }

  return APIR.patch<ParseAPIResponse<Person>>(`${PEOPLE_ROUTE}${id}/`, avatar ? getFileObject() : { avatar: null }, avatar ? getMultipartConfig() : {}).pipe(
    unWrapDataAndMeta(),
    tap(({ data }: { data: any }) =>
      store.dispatch(fromRootActions.setBranchField(adminPanelType.people, id, 'avatar', data.avatar))
    )
  )
}

const linkPersonToOrganisation = (person: Person, toOrganisationId: any) => {
  return APIR.patch(`${PEOPLE_ROUTE}${person.id}/?format=json`, { organisation: toOrganisationId }).pipe(
    tap((response) => {
      store.dispatch(orgActions.removeMember(person.organisation, person.id));
      store.dispatch(orgActions.addMember(toOrganisationId, person.id));
      store.dispatch(actions.setPersonReducer({ ...person, organisation: toOrganisationId }));
    })
  )
}

const personFetchContractAccess = (personId: any) => {
  return APIR.get(`${PEOPLE_ROUTE}${personId}/access/?format=json`).pipe(
    unWrapDataAndMetaAndPUTMeta(),
  ).subscribe((response) => {
    const accessibleContracts = response.data;
    const meta = response.meta;
    const putMeta = response.putMeta;
    const permissions = response.permissions;
    store.dispatch(actions.storeAccessibleContractsForPerson(
      personId,
      accessibleContracts,
      meta,
      putMeta,
      permissions
    ))
    return response
  })
}

const linkAccessiblePortfoliosToPerson = (person: Person, accessObjs: SubmitAccessiblePortfolioI[]) => {
  return API.post(`${PEOPLE_ROUTE}${person.id}/portfolio-access/`, accessObjs).then((response: any) => {
    const data = getData(response)
    store.dispatch(actions.updateAccessiblePortfoliosReducer(person.id, data))
  });
}

const accessiblePortfolioRoute = (personId: number, accessId: string | number) => `${PEOPLE_ROUTE}${personId}/portfolio-access/${accessId}/`;

const unlinkAccessiblePortfoliosFromPerson = (person: Person, accessObjId: number | string) => {
  return API.delete(accessiblePortfolioRoute(person.id, accessObjId)).then(() => {
    store.dispatch(actions.removeAccessiblePortfoliosReducer(person.id, accessObjId))
  });
}

const personFetchPortfolioAccess = (personId: any) => {
  return APIR.get(`${PEOPLE_ROUTE}${personId}/portfolio-access/?format=json`).pipe(
    unWrapDataAndMetaAndPUTMeta(),
  ).subscribe((response) => {
    const accessiblePortfolios = response.data;
    const meta = response.meta;
    const putMeta = response.putMeta;
    const permissions = response.permissions;
    store.dispatch(actions.storeAccessiblePortfoliosForPerson(
      personId,
      accessiblePortfolios,
      meta,
      putMeta,
      permissions
    ))
    return response
  })
}

const personSimpleFetchPortfolioAccess = (personId: any) => {
  return API.get(`${PEOPLE_ROUTE}${personId}/portfolio-access/?format=json`).then((response) => {
    return {
      data: getData(response),
      meta: getMeta(response),
      putMeta: getListPUTMeta(response)?.PUTMeta,
      options: getOptions(response),
      permissions: getPrivileges(response)
    }
  })
}

export const fetchJobTitles = () => {
  //console.log('fetchJobTitles called');
  return APIR.get(`/person-job-title-options/?format=json`).pipe(
    unWrapDataAndMeta(),
  ).subscribe((response) => {
    store.dispatch(actions.setPersonJobTitles(response.data))
  })
}

const updateAccessibleContracts = (person: Person, accessId: string, oldData: Partial<AccessibleContractI>, data: Partial<AccessibleContractI>, meta: FieldMetaGroup) => {
  const payload = removeReadOnlyFields(data, meta);
  const route = accessibleContractRoute(person.id, accessId);//use the nested route to help ensure its the right one
  return API.patch(route, payload).then((response) => {
    const access = response.data.data;
    store.dispatch(actions.updateAccessibleContractsReducer(person.id, [access]));
  });
}

const updateAccessiblePortfolios = (person: Person, accessId: string, data: Partial<AccessiblePortfolioI>, meta?: FieldMetaGroup) => {
  const payload = meta ? removeReadOnlyFields(data, meta) : data;
  console.log('meta: ', meta);
  const route = accessiblePortfolioRoute(person.id, accessId);//use the nested route to help ensure its the right one
  return API.patch(route, payload).then((response) => {
    store.dispatch(actions.updateAccessiblePortfoliosReducer(person.id, [response.data.data]))
  })
}

const clearPersonAvatar = (id: any) => updatePersonAvatar(id, null);

export default {
  createPerson,
  updatePerson,
  updateAccessibleContracts,
  updateAccessiblePortfolios,
  deletePerson,
  personFetchPortfolioAccess,
  personSimpleFetchPortfolioAccess,
  linkAccessiblePortfoliosToPerson,
  unlinkAccessiblePortfoliosFromPerson,
  linkAccessibleContractsToPerson,
  unlinkAccessibleContractsFromPerson,
  fetchPerson,
  updatePersonAvatar,
  clearPersonAvatar,
  groupFetchPeople,
  linkPersonToOrganisation,
  personFetchContractAccess,
  fetchJobTitles
}