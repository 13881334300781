import React from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import SettingsIcon from '@material-ui/icons/Settings';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';

// Own
import { MatIconButton } from 'components/Common/Components/Material/MatIconButton/MatIconButton';
import { Snapshot } from 'components/ContractInFocus/interfaces/contractInFocus.interfaces';
import { isSnapshotReportOutOfDate } from 'components/ContractInFocus/Helper/contractInFocus.helper';

// Styles
import styles from 'styles/app/common/variables.scss';
import './ContractQuickLinks.scss';

type ContractQuickLinksProps = {
  snapshot?: Snapshot;
  onShowFreezeDialog: (show: boolean) => void;
  onOpenContractFiles: () => void;
  onOpenContractReport: () => void;
  onOpenGeneralPersonalContractSettings: (show: boolean) => void;
  showSnapshot: boolean;
  enableSnapshot: boolean;
  enableReportLink: boolean;
}

export const ContractQuickLinks: React.FC<ContractQuickLinksProps> = ({
  snapshot,
  onShowFreezeDialog,
  onOpenContractFiles,
  onOpenContractReport,
  onOpenGeneralPersonalContractSettings,
  showSnapshot,
  enableSnapshot,
  enableReportLink
}) => {

  return (
    <>
      {showSnapshot &&
        <MatIconButton
          disabled={!enableSnapshot}
          hint='Take snapshot'
          onClick={() => onShowFreezeDialog(true)}
        >

          <AddAPhotoIcon className='quick-links__icon' />
        </MatIconButton>
      }
      <MatIconButton hint='Files' onClick={onOpenContractFiles}>
        <FolderIcon className='quick-links__icon' />
      </MatIconButton>
      {snapshot &&
        <MatIconButton
          hint='Report Link'
          disabled={!enableReportLink}
          onClick={onOpenContractReport}
        >
          <DescriptionIcon
            className='quick-links__icon'
            style={{ color: isSnapshotReportOutOfDate(snapshot) ? styles.dangerDark : styles.darkBlue }}
          />
        </MatIconButton>

      }
      <MatIconButton
        hint='Open Your Contract Settings'
        onClick={() => onOpenGeneralPersonalContractSettings(true)}
      >
        <SettingsIcon className='quick-links__icon' />
      </MatIconButton>

    </>
  );
}