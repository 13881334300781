export type IHASNearMiss = {
    id: string;
    site: number;
    contract: string;
    contract_ref: string;
    ap_name: string;
    location: string;
    occurred_at: string;
    employee_name: string;
    names_of_employees_involved: string | null;
    incident_description: string;
    area_closed_off_or_restricted: boolean | null;
    work_prohibited: boolean | null;
    equipment_out_of_service_and_secured: boolean | null;
    interim_safety_procedures_implemented: boolean | null;
    confidential: boolean | null;
    fields_require_attention?: boolean;
    status: string;
}

export type IHASAccident = {
    id: string;
    site: number;
    contract: string;
    contract_ref: string;
    ap_name: string;
    location: string;
    occurred_at: string;
    incident_description: string | null;
    reported_to: string | null;
    reported_at: string | null;
    reported_by: string | null;
    full_investigation_mode?: boolean;
    riddor_required?: boolean;
    // details of injury
    injury_details: string | null;
    injury_body_parts: string | null;
    injury_diagram: string | null;
    // immediate actions taken
    first_aid_given: boolean | null;
    ambulance_in_attendance: boolean | null;
    hospital_visit_required: boolean | null;
    area_closed_off_or_restricted: boolean | null;
    work_prohibited: boolean | null;
    equipment_out_of_service_and_secured: boolean | null;
    interim_safety_procedures_implemented: boolean | null;
    working_practices_changed_and_staff_trained: boolean | null;
    equipment_examined_by_competent_person: boolean | null;
    // further actions required
    site_accident_book_submitted: boolean | null;
    cctv_available: boolean | null;
    witness_statements_gathered: boolean | null;
    // confidentiality requested
    confidential: boolean | null;
    investigation_submitted: boolean;
    status: string;
    fields_require_attention?: boolean;
}

export type IHASRiddor = {
    id: string;
    accident: string;
    fields_require_attention?: boolean;
    accident__ap_name: string;
}

export type IncidentFieldType = "accident" | "near_miss" | "riddor";

export const workPermitTypesArray = ["Hot Works", "Working At Height", "Limitation of Access", "Confined Space"] as const;

export type WorkPermitType = typeof workPermitTypesArray[number]; // "Hot Works" | "Working At Height" | "Limitation of Access" | "Confined Space";

// const objectWithAllWorkPermitTypesAsKeys: { // just a demo of how this might work
//     [ key in WorkPermitType ]: any
//   } = { 
//     "Hot Works": '',  
//     "Working At Height": '',  
//     "Limitation of Access": '',
//     "Confined Space": ''
// }

export type IncidentCategoryType = "immediate" | "further";

export type IncidentAction = {
    id: string;
    accident: string;
    description: string;
    target_completion_date: string;
    completion_date: string;
    category?: IncidentCategoryType;
}

export type PermitToWorkAgreement = {
    id: string;
    title: string;
    mime_type: "application/pdf";
    download_link: string;
    internal_access_only: boolean;
    file: string;
    notes?: string;
    in_app_viewing_location?: string;
    added_by: number,
    permit_to_work: string,
};

export type BasePermitToWork = {
    reference: string | number;
    id: string;
    visit: string;
    // Authorisation by Authorised Person
    date: string;
    start_time: string;
    finish_time: string;
    supervisor_first_name: string;
    supervisor_last_name: string;
    supervisor_position: string;
    supervisor_email: string;
    jbs_authorised_person_first_name: string;
    jbs_authorised_person_last_name: string;
    jbs_authorised_person_position: string;
    jbs_authorised_person_email: string;
    // Safe Systems of Work
    risk_assessment_document_number: string | number;
    risk_assessment_author: string;
    method_statement_document_number: string | number;
    method_statement_author: string;
    method_statement_missing: boolean;
    risk_assessment_missing: boolean;
    method_statement_not_uploaded_reason?: null | string;
    rescue_plan_missing: boolean;
    risk_assessment_not_uploaded_reason?: null | string;
    rescue_plan_not_uploaded_reason?: null | string;
    rams_reviewed: boolean,
    workers_informed_of_local_hazards: boolean,
    relevant_ppe_checked: boolean,
    communication_systems: boolean,
    communication_systems_details: null | string;

    // Documentation
    attendance_register_signed: boolean;
    induction_given: boolean;
    keys_issued: boolean;
    key_recipient_name: string;
    date_keys_issued: string;
    ptw_type: WorkPermitType;
    ptw_status: string;
    agreement?: PermitToWorkAgreement;
    submit_for_signing?: boolean;
    signature_progress_url: string;
    next_signature_url?: string;
    next_signature_label?: string;
    signed_doc_url: string;
    updated_at?: string;
    client_ptws?: boolean;
}

export interface HotWorksPTW extends BasePermitToWork {
    description: string,
    client_permission_granted: boolean,
    fire_detection_systems_will_be_affected: boolean,
    suppression_systems_will_be_affected: boolean,
    // Description of Isolations
    isolation_comments: string,
    //Documentation
    date_induction_received: string,
    equipment_certification_submitted: boolean,
    // Control measures which must be in place before issuing a Permit to work - Hot Work
    combustibles_removed: boolean,
    combustibles_protected: boolean,
    area_protected_from_fire: boolean,
    firefighting_equipment_available: boolean,
    fire_watch_to_be_maintained: boolean,
    // Primary Hazards - Fire and Explosion
    fire_alarm_can_be_sounded: boolean,
    surfaces_protected: boolean,
    vessels_or_lines_protected_and_flammables_free: boolean,
    area_to_be_wetted_or_fire_blankets_used: boolean,
    ppe_required: string,
    other_precautions: string
}

export interface ConfinedSpacesPTW extends BasePermitToWork {
    description: string,
    type_of_confined_space_and_specified_risks: string,
    activities: string,
    isolated_from_pipework: boolean,
    space_purged: string,
    electrically_and_mechanically_isolated: boolean,
    temperature_maintainable: boolean,
    breathing_apparatus_checked: boolean,
    backup_equipment_checked: boolean,
    emergency_arrangements_in_place: boolean,
    atmospheric_testing_required: boolean,
    respirable_air_assured: boolean,
    atmospheric_testing_unit_info: string,
    atmospheric_testing_calibration_date: string,
    residual_hazards: string,
    worker_competences_checked: boolean,
    rescue_plan_document_number: string,
    rescue_plan_document_author: string,
    rescue_plan_not_uploaded_reason: string,
}

export interface LimitationOfAccessPTW extends BasePermitToWork {
    description: string,
    // Documentation
    escort_required: boolean,
    date_induction_received: string,
    supervision_required: boolean,
    // Hazards. Have the following potential hazards been considered:
    access: boolean,
    egress: boolean,
    safe_routes: boolean,
    additional_information: string,
    // Safe Systems of Work
    signs_and_barriers: boolean,
    site_specific_rules_communicated: boolean,
}

export interface WorkingAtHeightPTW extends BasePermitToWork {
    description: string,
    // SSOW
    activities: string,
    //Documentation
    date_induction_received: string,
    // Hazards. Have the following potential hazards been considered:
    qualified: boolean,
    signs_and_barriers: boolean,
    signs_and_barriers_in_place: boolean,
    site_specific_rules_communicated: boolean,
    boards_handrails_ladders: boolean,
    scaffolding_and_arrest_systems: boolean,
    scaffolding_inspected_and_tagged: boolean,
    harness_inspected: boolean,
    harness_lanyard_certificate_provided: boolean,
    access_equipment_inspected: boolean,
    weather_conditions: boolean,
    ducts_and_outlets: string,
    fume_hazards: boolean,
    falling_objects_risk: boolean,
    equipment_tethered: string,
    overhead_services: boolean,
    edge_protection: boolean,
    guard_rails: boolean,
    ppe_required: string,

}

export type HotWorkIsolationSystem = {
    hot_works_ptw: string,
    system: string,
    panel: string,
    device_number: string,
}

export type PTWTrainingCourse = {
    ptw: string,
    name: string,
    course: string,
    awarding_body: string,
    expiry_date: string,
}

export type PTWRescueRole = {
    ptw: string,
    role: string,
    name: string
}

export type RiddorQualification = {
    id: string;
    qualification: string;
    date: string;
    riddor: string;
}

export type RiddorCourseAttended = {
    id: string;
    course_name: string;
    date_attended: string;
    riddor: string;
}

export type AccidentCheckListItem = {
    id: string;
    item: string;
    verified: string;
    notes: string;
    accident: string;
    checklist_item_documents_count: number;
    accident_documents: [
        {
            id: string;
            title: string;
            mime_type: string;
            document_type: string;
            download_link: string;
            internal_access_only: boolean,
            file: string;
            notes: string;
            in_app_viewing_location: string;
            h_a_s_department_only: boolean;
            added_by: number;
            accident: string;
            h_a_s_accident_document_type: string;
            author_organisation: number;
        }
    ]
}