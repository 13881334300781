import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from "moment";

import { Dictionary, FieldMeta } from "components/Common/Interfaces/Entity.interface";
import { FormValues } from 'components/Common/Components/DocumentsUploader/DocumentUploader';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from "@material-ui/core/Select";
import { MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

import { toTitleCase } from "helpers/String/String.helper";
import { saveDateFormat, saveDateTimeFormat, pickerFormat, dateUDF, dateTimeUDF, standardFormat, standardDateTimeFormat } from "components/Common/Utils/Dates.js";
import { FieldFormConfig } from "components/Common/Components/DocumentsGrid/DocumentsGrid.interface";
import { DocumentLimits, LookupEntity, Primitive } from "components/Common/Interfaces/Entity.interface";
import { getAutoFieldInputWrapperClassName } from './AutoInput.helper';
import MatDatePicker from "components/Common/Components/Material/MatDate/MatDate";
import JAAutoComplete from "components/Common/Components/JAAutoComplete/JAAutoComplete";
import { FileInputControl } from "components/Common/Components/FileInputControl";

import './AutoInputStyles.scss';
import 'components/Common/Components/Material/MatStyles.scss';
interface GetFormattedDateProps {
    currentValue?: string,
    dataType: string,
    fieldConfig: FieldFormConfig,
    thisMeta: FieldMeta
}

const getCurrentDate = ({ currentValue, fieldConfig, dataType, thisMeta }: GetFormattedDateProps) => {
    let currentDateValue: Date | undefined = undefined;
    if (!currentValue && fieldConfig.getInitialDate) {
        const initialDate = fieldConfig.getInitialDate(thisMeta);
        currentDateValue = initialDate;
    }
    try {
        if (typeof (currentValue) == "string") {
            currentDateValue = moment(currentValue).toDate(); // a javascript date object is really a datetime object
        }
    } catch {
        console.log(currentValue, " could not be co-erced to a date!");
    }
    return currentDateValue
}

export interface JABasicInputProps {
    dataField: string;
    fieldMeta: FieldMeta;
    fieldConfig: FieldFormConfig;
    formValuesRef: React.MutableRefObject<FormValues>;
    onChangeFormValues: (values: FormValues) => void;
    currentFocus: React.MutableRefObject<string | undefined>;
    documentLimits?: DocumentLimits;
    zIndex?: number;
    style?: React.CSSProperties;
    refreshSignal?: number;
    dispatchRefreshContext?: React.DispatchWithoutAction;
    endAdornment?: JSX.Element;
    showReadOnly?: boolean;
    defaultRefreshFormOnBlur?: boolean;
    extraClassNames?: string;
    inputRef?: React.MutableRefObject<any>;
    wrapperRef?: React.MutableRefObject<any>;
    formErrors?: Dictionary<Dictionary<string | undefined>>; // these errors, if passed in, should represent actual 'feedback' from the backend
    setFormErrors?: React.Dispatch<Dictionary<Dictionary<string | undefined>>>; // we pass this in so that it can be used to remove the error when the value in the field changes
    addColonToLabel?: boolean;
}

interface RenderRadioButtonProps {
    dataField: string;
    fieldConfig: FieldFormConfig;
    onChangeFormValues: (values: FormValues) => void;
    thisLabel?: string,
    currentValue: Primitive;
    thisMeta: FieldMeta;
    error?: string;
    dispatchRefreshContext?: React.DispatchWithoutAction;
    inputClassesString?: string;
    required?: boolean;
    hidden?: boolean;
    style?: React.CSSProperties;
    currentFocus: React.MutableRefObject<string | undefined>;
    disabled?: boolean;
}

const checkChoiceValuesAreAllNumbers = (choices?: LookupEntity[]) => {
    if (!choices) {
        return false;
    }
    let allNumbers = true;
    choices.map((x => {
        if (!Number.isFinite(x.value)) {
            allNumbers = false
        }
    }))
    return allNumbers;
}

export default function RenderRadioButtons({
    dataField,
    fieldConfig,
    thisLabel,
    thisMeta,
    onChangeFormValues,
    currentValue,
    error,
    dispatchRefreshContext,
    inputClassesString,
    required,
    hidden,
    style,
    currentFocus,
    disabled
}: RenderRadioButtonProps) {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let eventValue: number | string = event.target.value;
        const valuesAreNumbers = checkChoiceValuesAreAllNumbers(thisMeta.choices);
        if (valuesAreNumbers) {
            eventValue = parseFloat(eventValue);
        }
        onChangeFormValues({ [dataField]: eventValue as string | number | boolean | null | undefined })
        dispatchRefreshContext && dispatchRefreshContext();
    };
    let radioValue: string | number | string[] | undefined;
    if (currentValue === null) {
        radioValue = undefined;
    } else {
        switch (typeof (currentValue)) {
            case "bigint": {
                radioValue = Number(currentValue);
            }
                break;
            case "boolean": {
                radioValue = currentValue ? 1 : 0;
            }
                break;
            default: {
                radioValue = currentValue;
            }
        }
    }

    return (
        <FormControl error={!!error}
            variant={fieldConfig.variant || "standard"}
            className={inputClassesString}
            disabled={disabled}
            onFocus={() => { currentFocus.current = dataField }}
            required={required}
            hidden={hidden}
            style={{
                // put any defaults in here
                ...style
            }}>
            <FormLabel
                id={`autoField-Select-${dataField}`}
            >
                {thisLabel}
            </FormLabel>
            <RadioGroup
                defaultValue={radioValue}
                //value={currentValue}
                onChange={handleRadioChange}
                row={fieldConfig.radioButtonsConfig?.row}
            >
                {(thisMeta.choices || []).map((field) => {
                    return <FormControlLabel key={field.value} value={field.value} control={<Radio />} label={field.display_name} />
                })
                }
            </RadioGroup>
            <FormHelperText>{`${thisMeta.help_text || ''}${thisMeta.help_text ? '.' : ''}${error ? ' ' + error + '.' : ''}`}</FormHelperText>
        </FormControl>
    );
}

interface GetBasicPrintableFieldProps {
    currentValue?: any,
    thisLabel?: string,
    dataType: string,
    fieldConfig: FieldFormConfig,
    refreshSignal?: number;
    thisMeta: FieldMeta;
    dataField: string;
}

const RenderPrintableHelpText = ({ thisMeta }: { thisMeta: FieldMeta }) => thisMeta.help_text ? <><br /><span className='helpText'>{thisMeta.help_text}</span></> : <></>;

const GetBasicPrintableField = ({ currentValue, thisLabel, dataType, fieldConfig, refreshSignal, thisMeta, dataField }: GetBasicPrintableFieldProps) => {

    let thisElement = <></>;
    let thisValue = currentValue;

    if (fieldConfig.redacted) {
        thisElement = <>
            <span className='label'>{thisLabel}</span>&nbsp;<span className='value'>********</span>
            <RenderPrintableHelpText thisMeta={thisMeta} />
        </>
    } else {
        if (dataType === "date" || dataType === "datetime") {
            thisValue = getCurrentDate({
                currentValue: thisValue as string,
                fieldConfig: fieldConfig,
                dataType: dataType,
                thisMeta: thisMeta
            });
            if (dataType === "date") {
                thisValue = thisValue ? moment(thisValue)?.format(standardFormat) : '' as string;
            } else if (dataType === "datetime") {
                thisValue = thisValue ? moment(thisValue)?.format(standardDateTimeFormat) : '' as string;
            }
        }
        switch (dataType) {
            case "autoComplete": case "choice": case "decimal": case "integer": case "datetime": case "date": {
                if (!fieldConfig.print_bool) {
                    if (thisValue === false) {
                        thisValue = 'No'
                    }
                    if (thisValue === true) {
                        thisValue = 'Yes'
                    }
                }
                thisElement = <><span className='label'>{thisLabel}</span>&nbsp;<span className='value'>{thisValue?.toString()}</span></>
                break;
            }
            case "string": case "email": {
                thisElement = <><span className='label'>{thisLabel}</span>&nbsp;
                    {fieldConfig.multiline ? <p className='value'>{thisValue}</p> : <span className='value'>{thisValue}</span>}
                </>
                break;
            }
            case "boolean": {
                thisElement = <><span className='label'>{thisLabel}</span>&nbsp;<span className='value'>{thisValue === "undefined" || thisValue === null ? "" : thisValue ? "Yes" : "No"}</span></>
                break;
            }
            case "image": case "image upload": {
                const thumbnail = thisValue?.thumbnail;
                thisElement = <>
                    <span className="caption MuiFormLabel-root">{thisLabel}</span>&nbsp;
                    <span className="imgValue">{thumbnail ? <img src={thumbnail} /> : ''}</span>
                </>
                break;
            }
            default: thisElement = <></>;
        }

    }

    return <div className={`printable-field ${fieldConfig.hidden ? 'hidden' : ''}`}>
        {thisElement}
        <RenderPrintableHelpText thisMeta={thisMeta} />
    </div>;
}



export interface AutoInputProps extends JABasicInputProps {
    setPostComponentSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

// we have to allow for:
// boolean (checkbox), 
// choice(select) with typing, 
// free text (input), 
// free text with suggestions (input with suggested values), 
// multichoice check list, 
// multichoice chips
// integer < 10 range select or typing
// integer >= 10 range typing.

export const AutoInput = React.memo(({
    dataField,
    fieldMeta,
    fieldConfig,
    formValuesRef,
    zIndex,
    onChangeFormValues,
    //defaultRefreshFormOnBlur,
    dispatchRefreshContext,
    currentFocus,
    documentLimits,
    refreshSignal,
    showReadOnly,
    endAdornment,
    setPostComponentSelected,
    extraClassNames,
    inputRef,
    wrapperRef,
    setFormErrors,
    formErrors,
    addColonToLabel,
}: AutoInputProps) => {
    let thisMeta = fieldMeta;
    if (fieldConfig.metaOverride) {
        thisMeta = { ...fieldMeta, ...fieldConfig.metaOverride };
    }

    const PreComponent = fieldConfig.PreComponent;
    const PostComponent = fieldConfig.PostComponent;

    const labelPlacement = fieldConfig.labelPlacement || "top";
    const currentValue = formValuesRef.current[dataField];
    const [fieldError, setFieldError] = useState<string>();

    useEffect(() => {
        const thisError = formErrors ? formErrors[dataField] : undefined;
        // NB the errors from the B/E will probably be in the format of a field: message mapping - however, if so, the function that consumes that
        // should convert it to a mapping showing how the errors returned from the B/E should be mapped to override all F/E validations
        // where the validation message 'context' is nested within the field name and mapped to the validation message.
        const fieldValidation = thisError ? thisError['inputValidations'] : undefined;
        setFieldError(fieldValidation);
    }, [formErrors, dataField])

    const documentLimitsExceeded = documentLimits && documentLimits[dataField] && documentLimits[dataField].currentCount >= documentLimits[dataField].max;
    const disabled = documentLimitsExceeded || fieldConfig.disabled || thisMeta?.read_only;
    const hidden = fieldConfig?.hidden;
    let inputClassesString = getAutoFieldInputWrapperClassName(fieldConfig, dataField);
    if (extraClassNames) {
        inputClassesString = `${inputClassesString} ${extraClassNames}`;
    }
    let choices = thisMeta?.choices || [];
    const dataType = fieldConfig?.typeOverride || thisMeta?.type;
    const required = fieldConfig.forceRequired ? fieldConfig.forceRequired : thisMeta?.required;
    // console.log('dataField: ', dataField);
    // console.log('fieldMeta: ', fieldMeta);
    // console.log('fieldConfig: ', fieldConfig);
    let thisLabel = fieldConfig.skipLabel ? undefined : fieldConfig.label ? fieldConfig.label : thisMeta?.label;
    if (fieldConfig.addColonToLabel || addColonToLabel) {
        thisLabel = `${thisLabel}:`;
    }
    const defaultOnBlur = useCallback((e: any) => {
        // if (defaultRefreshFormOnBlur) {
        //        // when refreshing form on blur certain fields (e.g. text), the focus got trapped on the same field...
        //     // a HACK like the following could work... - unfortunately DateBox doesn't currently put any id we pass it on the input itself so it can't be picked
        //     // up in this way - should replace with material UI date picker anyway.  Probably want to make the identifier string more unusual than autoField too...
        //     // once it seemed to trigger an infinite loop, so be careful with it.
        //     const relatedTargetId = e.relatedTarget?.id;
        //     if (relatedTargetId && relatedTargetId.includes("autoField-")) {
        //         const relatedTargetDataField = relatedTargetId.replace("autoField-", "");
        //         currentFocus.current = relatedTargetDataField;
        //         dispatchRefreshContext && dispatchRefreshContext();
        //     }
        // }
        fieldConfig.onBlur && fieldConfig.onBlur(formValuesRef.current, onChangeFormValues);
    }, [fieldConfig, formValuesRef, onChangeFormValues]);
    const [refreshThisFieldOnly, signalRefreshThisFieldOnly] = useState(0);
    const localInputRef = useRef();
    const thisInputRef = inputRef || localInputRef;
    if (!thisMeta) {
        // console.log('field Meta not found for ', dataField);
        return null
    }
    interface GetElementProps {
        error?: string;
    }

    let getElement: (({ error }: GetElementProps) => JSX.Element | null) = ({ error }: GetElementProps) => <></>;
    const onChangeSelect = (event: React.ChangeEvent<
        {
            name?: string | undefined;
            value: unknown
        }
    >) => {
        onChangeFormValues({ [dataField]: event.target.value as string | number | boolean | null | undefined })
    }
    if (fieldConfig.skipForm) {
        return <div className={`${dataField}-skipped hide`} />;
    }
    if (!showReadOnly && thisMeta?.read_only == true) {
        return <div className={`${dataField}-readonly hide`} />;
    }
    if (dataType == "choice") {
        if (fieldConfig.excludeValues) {
            choices = choices.filter((field) => !fieldConfig.excludeValues?.includes(field.value))
        }
    }
    if (!currentValue && (fieldConfig.defaultValue || fieldConfig.selectFirstAsDefault || fieldConfig.selectLastAsDefault)) {
        let thisValue;
        if (fieldConfig.defaultValue !== undefined) {
            thisValue = fieldConfig.defaultValue;
        } else if (choices) {
            thisValue = fieldConfig.selectFirstAsDefault ? choices[0].value : fieldConfig.selectLastAsDefault ? choices[choices.length - 1].value : undefined;
        }
        if (thisValue) { //NB this line prevents a possible infinite loop!;
            onChangeFormValues({
                [dataField]: thisValue
            })
        }
    }
    if (fieldConfig.autoCalculator) {
        onChangeFormValues({
            [dataField]: fieldConfig.autoCalculator(formValuesRef.current)
        })
    }
    const finalStyles = {
        width: '100%',
        zIndex: zIndex || 1200,
        ...fieldConfig.style
    }
    if (fieldConfig.printable) {

        getElement = ({ error }: GetElementProps) => GetBasicPrintableField({
            thisLabel: thisLabel,
            currentValue: currentValue,
            dataType: dataType,
            fieldConfig: fieldConfig,
            refreshSignal: refreshSignal,
            thisMeta: thisMeta,
            dataField: dataField
        })
    } else {
        switch (dataType) {
            case "autoComplete": {
                getElement = ({ error }: GetElementProps) => (
                    <JAAutoComplete
                        dataField={dataField}
                        onChangeFormValues={onChangeFormValues}
                        fieldMeta={thisMeta}
                        currentFocus={currentFocus}
                        fieldConfig={fieldConfig}
                        formValuesRef={formValuesRef}
                        disabled={disabled}
                        inputWrapperClass={inputClassesString}
                        onBlur={defaultOnBlur}
                        inputRef={thisInputRef}
                    //error={error}
                    />)
                break;
            }
            case "choice": {
                getElement = ({ error }: GetElementProps) => {
                    if (!fieldConfig.useRadioButtons) {
                        return <FormControl
                            variant={fieldConfig.variant || "standard"}
                            className={inputClassesString}
                            required={required}
                            hidden={hidden}
                            style={{
                                // put any defaults in here
                                ...finalStyles
                            }}
                            error={!!error} // later y
                        >
                            {
                                !fieldConfig.skipLabel &&
                                <InputLabel shrink>
                                    {thisLabel}
                                </InputLabel>
                            }
                            <Select
                                id={`autoField-Select-${dataField}`}
                                hidden={hidden}
                                autoFocus={currentFocus.current == dataField}
                                onFocus={() => { currentFocus.current = dataField }}
                                disabled={disabled}
                                style={{
                                    // put any defaults in here
                                    ...finalStyles
                                }}
                                ref={thisInputRef}
                                onBlur={defaultOnBlur}
                                //value={inputValue}
                                defaultValue={currentValue ? currentValue : fieldConfig.defaultValue ? fieldConfig.defaultValue : ""}
                                //defaultValue={fieldConfig.defaultValue}
                                //value={currentValue}
                                MenuProps={{
                                    style: {
                                        zIndex: finalStyles.zIndex
                                    }
                                }}
                                onChange={(e) => onChangeSelect(e)}>
                                {(choices || []).map((field) => {
                                    return <MenuItem
                                        key={field.value}
                                        value={field.value}
                                        className={dataField}
                                        disabled={field.disabled}
                                    >
                                        {toTitleCase(field.display_name.toString())}
                                    </MenuItem>
                                })
                                }
                            </Select>
                            {thisMeta.help_text && <FormHelperText>{thisMeta.help_text}</FormHelperText>}
                        </FormControl>
                    } else {
                        return <RenderRadioButtons
                            dataField={dataField}
                            thisMeta={thisMeta}
                            fieldConfig={fieldConfig}
                            thisLabel={thisLabel}
                            onChangeFormValues={onChangeFormValues}
                            currentValue={currentValue}
                            dispatchRefreshContext={dispatchRefreshContext}
                            disabled={disabled}
                            inputClassesString={inputClassesString}
                            required={required}
                            hidden={hidden}
                            style={{
                                // put any defaults in here
                                ...finalStyles
                            }}
                            currentFocus={currentFocus}
                            error={error}
                        />
                    }
                }
                break;
            }
            case "string": case "email": case "field": {
                getElement = ({ error }: GetElementProps) => {
                    if (fieldConfig.printable) {
                        return <><span>{thisLabel}</span>&nbsp;<p>{currentValue}</p></>
                    } else {
                        return <TextField
                            style={{
                                // put any defaults here...
                                ...finalStyles
                            }}
                            error={!!error}
                            helperText={error || thisMeta.help_text}
                            required={required}
                            hidden={hidden}
                            label={thisLabel}
                            InputLabelProps={{
                                shrink: true,
                                ...fieldConfig.inputLabelProps
                            }}
                            InputProps={
                                {
                                    endAdornment: (endAdornment)
                                }
                            }
                            ref={thisInputRef}
                            onBlur={defaultOnBlur}
                            disabled={disabled}
                            autoFocus={currentFocus.current == dataField}
                            onFocus={(event) => {
                                currentFocus.current = dataField
                                event.target.selectionStart = event.target.selectionEnd = fieldConfig.cursorPosition || event.target.value?.length || 0;
                            }}
                            variant="standard"
                            id={`autoField-${dataField}`}
                            //variant={fieldConfig.variant ? fieldConfig.variant : undefined}
                            defaultValue={currentValue ? currentValue : fieldConfig.defaultValue ? fieldConfig.defaultValue : undefined}
                            //value={currentValue}
                            placeholder={fieldConfig.placeholder ? fieldConfig.placeholder : undefined}
                            multiline={fieldConfig.multiline ? fieldConfig.multiline : false}
                            rows={fieldConfig.rows ? fieldConfig.rows : 1}
                            onChange={event => {
                                const { value } = event.target;
                                fieldConfig.cursorPosition = event.target.selectionStart;
                                onChangeFormValues({ [dataField]: event.target.value as string | number | boolean | null })
                            }}>
                        </TextField>
                    }
                }
                break;
            }
            case "boolean": {
                getElement = ({ error }: GetElementProps) => {
                    if (fieldConfig.printable) {
                        return <><span>{thisLabel}</span>&nbsp;<span>{currentValue ? "Yes" : "No"}</span></>
                    } else {
                        if (!fieldConfig.useRadioButtons) {
                            return <FormControlLabel
                                label={thisLabel}
                                labelPlacement={labelPlacement}
                                hidden={hidden}
                                control={
                                    fieldConfig.forceCheckBox || fieldConfig.printable ? <Checkbox
                                        style={{
                                            //put any defaults here
                                            //
                                            ...finalStyles
                                        }}
                                        autoFocus={currentFocus.current == dataField}
                                        disabled={disabled || fieldConfig.printable}
                                        onFocus={() => { currentFocus.current = dataField }}
                                        defaultChecked={typeof (currentValue) == "boolean" ? currentValue : fieldConfig ? fieldConfig.defaultChecked : false}
                                        //inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={event => {
                                            //formValuesRef.current = { ...formValuesRef.current, [dataField]: event.target.checked };
                                            onChangeFormValues({ [dataField]: event.target.checked as boolean })
                                        }} /> :
                                        <Switch
                                            style={{
                                                //put any defaults here
                                                //
                                                //...finalStyles
                                            }}
                                            ref={thisInputRef}
                                            size="small"
                                            hidden={hidden}
                                            autoFocus={currentFocus.current == dataField}
                                            disabled={disabled}
                                            onFocus={() => { currentFocus.current = dataField }}
                                            defaultChecked={typeof (currentValue) == "boolean" ? !!currentValue : fieldConfig ? !!fieldConfig.defaultChecked : false}
                                            //inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={event => {
                                                onChangeFormValues({ [dataField]: event.target.checked as boolean })
                                            }} />
                                } />
                        } else {
                            fieldConfig.radioButtonsConfig = fieldConfig.radioButtonsConfig || {
                                row: true,
                            }
                            // we can use fieldConfig.metaOverride to manually override any parts of backend meta we want - use if you wish to change options 
                            // for boolean below.
                            thisMeta.choices = thisMeta.choices || [
                                {
                                    value: 1,
                                    display_name: 'Yes'
                                },
                                {
                                    value: 0,
                                    display_name: 'No'
                                },
                            ];

                            return <RenderRadioButtons
                                dataField={dataField}
                                thisMeta={thisMeta}
                                fieldConfig={fieldConfig}
                                thisLabel={thisLabel}
                                onChangeFormValues={onChangeFormValues}
                                currentValue={currentValue}
                                dispatchRefreshContext={dispatchRefreshContext}
                                inputClassesString={inputClassesString}
                                required={required}
                                hidden={hidden}
                                style={{
                                    // put any defaults in here
                                    ...finalStyles
                                }}
                                disabled={disabled}
                                currentFocus={currentFocus}
                                error={error}
                            />
                        }

                    }
                }


                break;
            }
            case "decimal": case "integer": {
                getElement = ({ error }: GetElementProps) => <TextField
                    style={{
                        //put any defaults here...
                        ...finalStyles
                    }}
                    label={thisLabel}
                    ref={thisInputRef}
                    onBlur={defaultOnBlur}
                    required={required}
                    InputLabelProps={{
                        shrink: true,
                        ...fieldConfig.inputLabelProps
                    }}
                    hidden={hidden}
                    disabled={disabled}
                    autoFocus={currentFocus.current == dataField}
                    inputProps={{ min: fieldConfig.min, max: fieldConfig.max }}
                    onFocus={() => { currentFocus.current = dataField }}
                    variant={fieldConfig.variant ? fieldConfig.variant : "standard"}
                    type="number"
                    id={`autoField-${dataField}`}
                    //defaultValue={typeof (currentValue) == "string" ? currentValue : typeof (currentValue) == "number" ? currentValue.toString() : undefined}
                    defaultValue={typeof (currentValue) == "string" ? currentValue : typeof (currentValue) == "number" ? currentValue.toString() : fieldConfig.defaultValue}
                    //value={currentValue}
                    //variant={fieldConfig.variant ? fieldConfig.variant : undefined}
                    placeholder={fieldConfig.placeholder ? fieldConfig.placeholder : undefined}
                    multiline={fieldConfig.multiline ? fieldConfig.multiline : false}
                    onChange={(e: any) => onChangeSelect(e)}>
                </TextField>
                break;
            }
            case "date": case "datetime": {
                let currentDateValue: Date | undefined = undefined;
                if (!currentValue && fieldConfig.getInitialDate) {
                    const initialDate = fieldConfig.getInitialDate(thisMeta);
                    if (dataType === "datetime") {
                        formValuesRef.current[dataField] = saveDateTimeFormat(moment(initialDate));
                    } else {
                        formValuesRef.current[dataField] = saveDateFormat(moment(initialDate));
                    }
                    currentDateValue = initialDate;
                }
                try {
                    if (typeof (currentValue) == "string") {
                        currentDateValue = moment(currentValue).toDate(); // a javascript date object is really a datetime object
                    }
                } catch {
                    console.log(currentValue, " could not be co-erced to a date!");
                }

                getElement = ({ error }: GetElementProps) => <div ref={thisInputRef}><MatDatePicker
                    meta={thisMeta}
                    id={`autoField-${dataField}`}
                    disabled={disabled}
                    required={required}
                    value={currentDateValue}
                    onChange={(newDate: any) => {
                        const value = moment(newDate.value);
                        onChangeFormValues({ [dataField]: dataType == "datetime" ? value.format(dateTimeUDF) as string : value.format(dateUDF) as string })
                        signalRefreshThisFieldOnly(refreshThisFieldOnly + 1);
                    }}
                    minDate={fieldConfig.getMinDate ? fieldConfig.getMinDate(thisMeta) : undefined}
                    maxDate={fieldConfig.getMaxDate ? fieldConfig.getMaxDate(thisMeta) : undefined}
                    refresh={refreshThisFieldOnly}
                    dateType={fieldConfig.datePickerDateType || dataType}
                    dateDisplayFormat={fieldConfig.dateDisplayFormat || (fieldConfig.datePickerDateType || dataType) === 'date' ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'}
                    calendarOptions={fieldConfig.datePickerCalendarOptions}
                    error={error}
                /></div>

                // getElement = () => <DatePicker
                //     //style={{ zIndex: (zIndex || 1200) }}
                //     disabled={disabled}
                //     dateFormat={fieldConfig.datePickerFormat ? fieldConfig.datePickerFormat : "dd/MM/yyyy"}
                //     popperPlacement={fieldConfig.datePickerPopperPlacement ? fieldConfig.datePickerPopperPlacement : "top-right"}
                //     selected={currentDateValue ? currentDateValue : fieldConfig.getInitialDate ? fieldConfig.getInitialDate(thisMeta) : new Date()}
                //     onChange={(newDate: Date) => {
                //         const value = saveDateFormat(newDate);
                //         onChangeFormValues({ [dataField]: value as string })
                //     }}
                //     minDate={fieldConfig.getMinDate ? fieldConfig.getMinDate(thisMeta) : undefined}
                //     maxDate={fieldConfig.getMaxDate ? fieldConfig.getMaxDate(thisMeta) : undefined}
                // />
                break;
            }
            case "image": case "image upload": {
                getElement = ({ error }: GetElementProps) => (
                    fieldConfig.fileInputControlConfig ?
                        <div key={dataField}>
                            <div className="caption MuiFormLabel-root">{thisLabel}</div>
                            <FileInputControl
                                inputRef={thisInputRef}
                                data={formValuesRef.current[dataField]}
                                onClear={fieldConfig.fileInputControlConfig.onClear}
                                onUpload={fieldConfig.fileInputControlConfig.onUpload}
                                uploader={fieldConfig.fileInputControlConfig.uploader}
                                refreshSignal={refreshSignal}
                                includeFullImageLink

                            />
                        </div>
                        : <></>
                )
                break;
            }
            default: return <></>
        }
    }
    const Element = getElement;

    return (
        Element !== null ? <div className={inputClassesString} ref={wrapperRef}>
            {PreComponent && !fieldConfig.printable && <PreComponent></PreComponent>}
            <Element error={fieldError}></Element>
            {PostComponent && !fieldConfig.printable && <div onClick={(e) => setPostComponentSelected && setPostComponentSelected(dataField)}><PostComponent></PostComponent></div>}
        </div> : null
    )

})
//AutoInput.whyDidYouRender = true;