import { map, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { store } from "store/store";

// Own
import API, { APIR, APIN, getMultipartConfig } from "services/API/API";
import { onResponseError } from "services/API/API.interceptor.js"
import { ParseAPIResponse } from "services/Interface/Interface";
import * as fromRootActions from "store/actions/root.actions";
import { adminPanelType } from "components/AdminPanel/Models/AdminPanel.model";
import { CONTRACT_ROUTE } from "services/API/common/globalAPIs";
import { contractPersonAccessRoute, peopleWithAccessListBaseRoute, ContractIdOrPortfolioId } from "services/API/common/contractAPIs";
import { ContractInterface, PersonWithAccessI, SubmitDistributionRecipientI, SubmitPersonWithAccessI } from "components/AdminPanel/Contracts/Interfaces/Contract.interface";
import { getListData, getMeta, getOptions, getData, getDataAndMeta, getListMeta, getListPUTMeta, unWrapDataAndMeta, removeReadOnlyFields, getPrivileges, arrayToDict, dictToArray, getPreFlightListMeta, preFlightCanCreateCheck, getListPrivileges } from "services/API/API.helper";
import { FieldMetaGroup, LookupEntity, PreFlightListInfo, Primitive } from 'components/Common/Interfaces/Entity.interface';
import { transformContractDataForGrid, transformContractMetaForGrid, transformContractForUpdate } from 'components/AdminPanel/Contracts/Helper/contractHelper';
import { contractsAdminApiRequests } from 'services/API/common/contractAPIs';
import { fetchContractPeriodReducer } from "components/AdminPanel/ContractPeriods/Actions/ContractPeriods.actions";
import * as actions from "components/AdminPanel/Contracts/Actions/Contracts.actions";
import { maintenanceReportDistributionBaseRoute, maintenanceReportDistributionRoute, maintenanceDistributionObjProps } from "services/API/common/contractAPIs";
import { getSnapshotMMRAppendicesRoute, APISnapshotProps } from "services/API/common/contractAPIs";
import { fetchSites } from "components/AdminPanel/Sites/Actions/AdminPanelSites.actions";
import { SiteContract } from "components/Sites/Interfaces/Site.inteface";
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { NOTIFICATION_SUCCESS } from 'components/Notification/Constants/constants';
import { getPersonalVisibilitySettingsRoute } from "components/ContractInFocus/Epics/contractVisibilitySettings.epic"


const createContract = (data: Partial<ContractInterface>, panelId?: string): Promise<any> => {
  const { id, ...payload } = transformContractForUpdate(data)
  const { organisations, people, sites } = store.getState();
  const organisationLookup = dictToArray(organisations.lookup).filter(organisation => organisation.contract_with_eligible);

  return APIR.post(CONTRACT_ROUTE, payload).pipe(
    unWrapDataAndMeta()
  ).toPromise().then((response) => {
    response.data && store.dispatch(
      actions.setContractSuccess(
        transformContractDataForGrid({ ...response.data }),
        transformContractMetaForGrid(response.meta, organisationLookup, dictToArray(people.lookup), dictToArray(sites.lookup)),
        response.options,
        response.permissions,
        true,
        panelId,
        response.data.id
      )
    );
    return response;
  });
}

const updateContract = (contract_ref: any, data: Partial<ContractInterface>, meta: FieldMetaGroup) => {
  //const payload = removeReadOnlyFields(transformContractForUpdate(data), meta);
  const { monthly_maintenance_report_cover_image, ...payload } = removeReadOnlyFields(transformContractForUpdate(data), meta);
  return API.patch(`${CONTRACT_ROUTE}${contract_ref}/`, payload).then((response) => {
    const contract = transformContractDataForGrid(response.data.data);
    store.dispatch(actions.setContractReducer(contract));
    store.dispatch(fetchSites()); // NB A HACK FOR NOW TO MAKE SURE THAT ON CHANGING CONTRACT STATUSES IT AFFECTS THE CONTRACT IN FOCUS.  IN FUTURE PROBABLY
    // BETTER TO RATIONALIZE THE LINK BETWEEN ADMIN CONTRACTS AND siteContracts ('contracts in focus') so updating one affects the other by default.
  });
}

const updateReportCoverImage = (id: any, contractRef: any, monthly_maintenance_report_cover_image: any) => {
  const getFileObject = () => {
    const fd = new FormData();
    fd.append("monthly_maintenance_report_cover_image", monthly_maintenance_report_cover_image);
    return fd;
  }

  return APIR.patch<ParseAPIResponse<ContractInterface>>(`${CONTRACT_ROUTE}${contractRef}/`, monthly_maintenance_report_cover_image ? getFileObject() : { monthly_maintenance_report_cover_image: null }, monthly_maintenance_report_cover_image ? getMultipartConfig() : {}).pipe(
    unWrapDataAndMeta(),
    tap(({ data }: { data: any }) =>
      store.dispatch(fromRootActions.setBranchField(adminPanelType.contracts, id, 'monthly_maintenance_report_cover_image', data.monthly_maintenance_report_cover_image))
    )
  )
}

const clearReportCoverImage = (id: any, contractRef: any) => updateReportCoverImage(id, contractRef, null);

export const simpleFetchContract = (contract_ref: any) => {
  return API.get(`${CONTRACT_ROUTE}${contract_ref}/`).then((response) => {
    const contract = transformContractDataForGrid(response.data.data);
    store.dispatch(actions.setContractReducer(contract));
    return contract
  });
}

export const simpleFetchContractDocuments = (contract_ref: string, docTypes?: string[]) => {
  let route = `${CONTRACT_ROUTE}${contract_ref}/documents/`;
  if (docTypes?.length) {
    route = route + `?document-types=${docTypes.join()}`;
  }

  return API.get(route).then((response) => {
    const contractDocuments = {
      data: getListData(response),
      meta: getListMeta(response),
    }
    return contractDocuments
  });
}

export const simpleFetchMMRAppendices = (props: APISnapshotProps) => {
  const route = getSnapshotMMRAppendicesRoute(props);
  return API.get(route).then((response) => {
    const appendices = {
      data: getListData(response),
      meta: getListMeta(response),
    }
    //console.log('contractAppendices: ', contractAppendices);
    return appendices
  });
}

export const simpleFetchDistributionListPreFlightInfo = (props: ContractIdOrPortfolioId): Promise<PreFlightListInfo> => {
  let route = maintenanceReportDistributionBaseRoute(props);

  return APIN.options(route).then((response) => {
    const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
    const canCreate = preFlightCanCreateCheck(response);
    return { meta, canCreate, canRead: true }
  }).catch(error => {
    if (error.response?.status == 403) {
      return { meta: {}, canCreate: false, canRead: false }
    } else {
      return onResponseError(error)
    }
  })
}

const fetchContractForAdmin = (contractRef: any, organisationLookUp: LookupEntity[], peopleLookup: LookupEntity[], siteLookup: LookupEntity[]) => {
  return forkJoin(contractsAdminApiRequests(contractRef)).pipe(
    map(({ contract, distribution, peopleWithAccess, contractPeriod }: any) => {
      const contractPeriodsData = arrayToDict(getListData(contractPeriod));
      const peopleWithAccessData = getListData(peopleWithAccess);
      const distributionData = getListData(distribution);

      return {
        sublistData: {
          contractPeriods: contractPeriodsData,
          peopleWithAccess: peopleWithAccessData, //transformContractPeriods(getListData(contractPeriod)),
          distributionList: distributionData
        },
        sublistMeta: {
          contractPeriods: getListMeta(contractPeriod),
          peopleWithAccess: getListMeta(peopleWithAccess),
          peopleWithAccessPutMeta: getListPUTMeta(peopleWithAccess)?.PUTMeta,
          distributionList: getListMeta(distribution),
          distributionListPutMeta: getListPUTMeta(distribution)?.PUTMeta
        },
        sublistPermissions: {
          contractPeriods: getPrivileges(contractPeriod),
          peopleWithAccess: getPrivileges(peopleWithAccess),
          distributionList: getPrivileges(distribution)
        },
        data: {
          ...transformContractDataForGrid(getData(contract)),
          contractPeriods: Object.keys(contractPeriodsData),
          peopleWithAccess: peopleWithAccessData,
          distribution: distributionData,
        },
        meta: {
          ...transformContractMetaForGrid(getMeta(contract), organisationLookUp, peopleLookup, siteLookup),
        },
        options: {
          contract: getOptions(contract),
        },
        permissions: {
          contract: getOptions(contract),
        },
      }
    })
  ).toPromise().then((response) => {
    store.dispatch(actions.setContractSuccess(
      response.data,
      response.meta,
      response.options,
      response.permissions
    ))
    store.dispatch(fetchContractPeriodReducer(
      response.sublistData.contractPeriods,
      response.sublistMeta.contractPeriods,
      response.sublistPermissions.contractPeriods,
    ))
    store.dispatch(actions.addPeopleWithAccessReducer(
      response.data.id,
      response.sublistData.peopleWithAccess,
      response.sublistMeta.peopleWithAccess,
      response.sublistMeta.peopleWithAccessPutMeta,
      response.sublistPermissions.peopleWithAccess
    ))
    store.dispatch(actions.addRecipientsToMaintenanceReportDistributionReducer(
      response.data.id,
      response.sublistData.distributionList,
      response.sublistMeta.distributionList,
      response.sublistMeta.distributionListPutMeta,
      response.sublistPermissions.distributionList
    ))
    return response;
  });
}

// const groupFetchContract = (contracts: ContractInterface[], organisationLookUp: LookupEntity[], peopleLookup: LookupEntity[], siteLookup: LookupEntity[]) => {
//   const requests = contracts.map((contract) => APIR.get(`${CONTRACT_ROUTE}${contract.contract_ref}/`).pipe(
//     map((response) => ({
//       ...response,
//       data: {
//         ...response.data,
//         data: transformContractDataForGrid(response.data.data),
//         metadata: {
//           actions: {
//             PUT: transformContractMetaForGrid(response.data.metadata.actions.PUT, organisationLookUp, peopleLookup, siteLookup)
//           }
//         }
//       }
//     })
//     )
//   ));

//   return forkJoin(requests).pipe(
//     groupFetchPipe(),
//   ).subscribe(response => {
//     store.dispatch(actions.setGroupContractReducer(response))
//   });
// }

const deleteContract = (id: string, contractRef: string) => {
  return API.delete(`${CONTRACT_ROUTE}${contractRef}/`).then(() => {
    store.dispatch(actions.deleteContractReducer(id))
  });
}

const updateAccess = (contract: ContractInterface, accessId: string, oldData: Partial<PersonWithAccessI>, data: Partial<PersonWithAccessI>, meta: FieldMetaGroup) => {
  const payload = removeReadOnlyFields(data, meta);
  const route = contractPersonAccessRoute(contract.contract_ref, accessId);//use the nested route to help ensure its the right one
  return API.patch(route, payload).then((response) => {
    const access = response.data.data;
    store.dispatch(actions.updatePersonWithAccessReducer(contract.id, [access]));
  });
}

const linkPeopleWithAccessToContract = (contract: ContractInterface, accessObjs: SubmitPersonWithAccessI[]) => {
  return API.post(peopleWithAccessListBaseRoute(contract.contract_ref), accessObjs).then((response: any) => {
    const data = getData(response)
    store.dispatch(actions.updatePersonWithAccessReducer(contract.id, data))
  });
}

interface AddToMaintenanceReportDistributionProps extends ContractIdOrPortfolioId {
  accessObjs: SubmitDistributionRecipientI[];
}

const addToMaintenanceReportDistribution = (props: AddToMaintenanceReportDistributionProps) => {
  return API.post(maintenanceReportDistributionBaseRoute({ contractRef: props.contractRef, portfolioId: props.portfolioId }), props.accessObjs).then((response: any) => {
    const data = getData(response);
    props.contractId && store.dispatch(actions.mergeMaintenanceReportDistributionReducer(props.contractId, data)); // distribution list for portfolios not managed in redux
    return data;
  });
}

const removeRecipientFromMaintenanceReportDistribution = (props: maintenanceDistributionObjProps) => {
  return API.delete(maintenanceReportDistributionRoute({ contractRef: props.contractRef, portfolioId: props.portfolioId, accessId: props.accessId })).then(() => {
    if (props.contractId) {
      // I think this isn't managed in redux for portfolio? Consider whether we should manage as 'live' list for contract too...
      store.dispatch(actions.removeMaintenanceReportRecipientReducer(props.contractId, props.accessId));
    }
  });
}

const unlinkPeopleWithAccessFromContract = (contract: ContractInterface, accessObjId: number | string) => {
  return API.delete(contractPersonAccessRoute(contract.contract_ref, accessObjId)).then(() => {
    store.dispatch(actions.removePeopleWithAccessReducer(contract.id, accessObjId))
  });
}

// export const simpleFetchContractDistribution = (contractId: number, contractRef: string) => {
//   return API.get(maintenanceReportDistributionBaseRoute({ contractId: contractRef })).then((response) => {
//     const distribution = {
//       data: getListData(response),
//       meta: getListMeta(response),
//       privileges: getListPrivileges(response)
//     }
//     store.dispatch(actions.replaceMaintenanceReportDistributionReducer(contractId, distribution.data))
//     return distribution
//   });
// }

export const simpleFetchDistribution = (props: ContractIdOrPortfolioId) => {
  return API.get(maintenanceReportDistributionBaseRoute({ contractRef: props.contractRef, portfolioId: props.portfolioId })).then((response) => {
    const distribution = {
      data: getListData(response),
      meta: getListMeta(response),
      privileges: getListPrivileges(response)
    }
    if (props.contractId) {
      // is this really necessary?  We seem to be fetching the list more or less as we need it rather than using redux.  There is too much redux around...
      store.dispatch(actions.replaceMaintenanceReportDistributionReducer(props.contractId, distribution.data))
    }
    return distribution
  });
}

export const simpleFetchPortfolioDistribution = (portfolioId: number) => {
  return API.get(maintenanceReportDistributionBaseRoute({ portfolioId })).then((response) => {
    const distribution = {
      data: getListData(response),
      meta: getListMeta(response),
      privileges: getListPrivileges(response)
    }
    // UPDATE NEXT LINE TO WORK WITH PORTFOLIO
    store.dispatch(actions.replaceMaintenanceReportDistributionReducer(portfolioId, distribution.data))
    return distribution
  });
}

interface UpdatePersonalContractVisibilitySettingProps extends ContractIdOrPortfolioId {
  payload: any;
}

export const updatePersonalVisibilitySetting = (props: UpdatePersonalContractVisibilitySettingProps): Promise<any> => {
  // there is an epic for updating personal visibility settings and triggering further actions based on the response.
  // however this service is particularly useful if you want to proceed whether the call suceeds or not
  const { id } = store.getState().mainInFocusVisibilityObject.basicPersonalVisibilitySettings;
  const route = getPersonalVisibilitySettingsRoute({ contractId: props.contractId, contractRef: props.contractRef, portfolioId: props.portfolioId, id });
  return API.patch(route, props.payload).then((response) => {
    return response.data
  })
}

export const supplierContractRoute = (contract_ref: string) => `${CONTRACT_ROUTE}${contract_ref}/supplier-contracts/`
export const supplierContractLookupRoute = (contract_ref: string, po_number: string) => `${supplierContractRoute(contract_ref)}lookup/`;
export const supplierContractSuggestionsRoute = (contract_ref: string) => `${CONTRACT_ROUTE}${contract_ref}/supplier-contract-suggestions/`;

interface CreateSupplierContractProps extends ContractIdOrPortfolioId {
  payload: any;
}

export const createSupplierContract = ({ contractRef, portfolioId, payload }: CreateSupplierContractProps): Promise<any> => {
  const contract_ref = contractRef as string;
  let newPayload = payload;
  if (portfolioId) {
    newPayload = { ...payload, surrogate_portfolio: portfolioId }
  }
  let route = `${CONTRACT_ROUTE}${contract_ref}/supplier-contracts/`
  return API.post(route, newPayload).then((response) => {
    store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
    return response.data
  })
}

interface UpdateSupplierContractProps extends ContractIdOrPortfolioId {
  supplierContractId: string;
  payload: any;
}

export const updateSupplierContract = ({ contractRef, supplierContractId, portfolioId, payload }: UpdateSupplierContractProps): Promise<any> => {
  const contract_ref = contractRef as string;
  let newPayload = payload;
  if (portfolioId) {
    newPayload = { ...payload, surrogate_portfolio: portfolioId }
  }
  let route = `${CONTRACT_ROUTE}${contract_ref}/supplier-contracts/${supplierContractId}/`
  return API.patch(route, newPayload).then((response) => {
    store.dispatch(addNotification({ message: "Saved", type: NOTIFICATION_SUCCESS }))
    return response.data
  })
}

interface DeleteSupplierContractProps extends ContractIdOrPortfolioId {
  supplierContractId: string;
}

export const deleteSupplierContract = ({ contractRef, portfolioId, supplierContractId }: DeleteSupplierContractProps): Promise<any> => {
  const contract_ref = contractRef as string;
  let route = `${CONTRACT_ROUTE}${contract_ref}/supplier-contracts/${supplierContractId}/`
  let params = {}
  if (portfolioId) {
    params = {
      surrogate_portfolio: portfolioId
    }
  }
  return API.delete(route, { params: params }).then((response) => {
    store.dispatch(addNotification({ message: "Deleted", type: NOTIFICATION_SUCCESS }))
    return response.data
  })
}

export const simpleFetchSupplierContractPreFlightInfo = ({ contractRef, portfolioId }: ContractIdOrPortfolioId): Promise<PreFlightListInfo> => {
  const contract_ref = contractRef as string;
  let params = {}
  if (portfolioId) {
    params = {
      surrogate_portfolio: portfolioId
    }
  }
  let route = supplierContractRoute(contract_ref);
  return APIN.options(route, { params }).then((response) => {
    const meta = getPreFlightListMeta(response); //preflight uses the 'options' verb
    const canCreate = preFlightCanCreateCheck(response);
    return { meta, canCreate, canRead: true }
  }).catch(error => {
    if (error.response?.status == 403) {
      return { meta: {}, canCreate: false, canRead: false }
    } else {
      return onResponseError(error)
    }
  })
}

export const simpleFetchBaseFromTenant = (contract_ref: string): Promise<SiteContract | null> => {
  //NB consider what needs to happen here from portfolio standpoint...
  const route = (`${CONTRACT_ROUTE}${contract_ref}/base-from-tenant/`);
  return APIN.get(route).then((response) => {
    const data = getData(response);
    if (data.length) {
      return data[0];
    }
    return null;
  }).catch(error => {
    if (error.response?.status == 403) {
      return null
    } else {
      return onResponseError(error)
    }
  })
}
interface SimpleFetchSupplierContractProps extends ContractIdOrPortfolioId {
  supplierContractId: string;
}

export const simpleFetchSupplierContract = ({ contractRef, portfolioId, supplierContractId }: SimpleFetchSupplierContractProps) => {
  const contract_ref = contractRef as string;
  let params = {}
  if (portfolioId) {
    params = {
      surrogate_portfolio: portfolioId
    }
  }
  let baseRoute = supplierContractRoute(contract_ref);
  let route = `${baseRoute}${supplierContractId}/`;
  return API.get(route, { params }).then((response) => {
    const supplierContractDetails = getDataAndMeta(response)
    return supplierContractDetails
  });
}

interface LookupSupplierContractFocalPointInfoProps extends ContractIdOrPortfolioId {
  po_number: string;
}
export const lookUpSupplierContractFocalPointInfo = (props: LookupSupplierContractFocalPointInfoProps): Promise<any> => {
  //let route = supplierContractLookupRoute(contract_ref, po_number);
  const contract_ref = props.contractRef as string;
  let params: any = {
    po_number: props.po_number,
  }
  if (props.portfolioId) {
    params = { ...params, surrogate_portfolio: props.portfolioId }
  }
  let route = supplierContractSuggestionsRoute(contract_ref);
  return API.get(route, { params: params }).then((response) => {
    const { data } = getDataAndMeta(response)
    if (data.length) {
      return data[0]
    } else {
      return {}
    }

  })
}

interface lookupSupplierPOSuggestionsInterface extends ContractIdOrPortfolioId {
  supplier_ref?: Primitive,
  refresh?: boolean,
}

export const lookUpSupplierContractSuggestions = ({ contractRef, portfolioId, supplier_ref, refresh }: lookupSupplierPOSuggestionsInterface): Promise<any> => {
  const contract_ref = contractRef as string;
  let route = supplierContractSuggestionsRoute(contract_ref);
  let params: { [index: string]: Primitive } = {};
  if (supplier_ref) {
    params["supplier_ref"] = supplier_ref;
  }
  if (refresh) {
    params["refresh"] = refresh;
  }
  if (portfolioId) {
    params["surrogate_portfolio"] = portfolioId;
  }
  return API.get(route, { params }).then((response) => {
    const suggestions = getDataAndMeta(response)
    return suggestions
    //return response.data
  })
}

export default {
  createContract,
  updateContract,
  updateAccess,
  deleteContract,
  simpleFetchContract,
  simpleFetchDistribution,
  addToMaintenanceReportDistribution,
  removeRecipientFromMaintenanceReportDistribution,
  //simpleFetchMMRAppendices,
  //simpleFetchContractDocuments,
  updateReportCoverImage,
  clearReportCoverImage,
  fetchContractForAdmin,
  //groupFetchContract,
  linkPeopleWithAccessToContract,
  unlinkPeopleWithAccessFromContract,
  updatePersonalVisibilitySetting
}