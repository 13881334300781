import React, { memo, useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PostAddIcon from '@material-ui/icons/PostAdd';
import moment from 'moment';

// Own
// Report Elements
import PPMsReport from '../Maintenance/PPMsReport';
import ReactiveReport from '../Maintenance/ReactiveReport';
import Financials from '../Financials/Financials';
import MeterStreams from '../MeterReadings/MeterStreams';

import { Spend } from 'components/ContractInFocus/Spend';
import Audit from '../Audit/Audit';
import SpecialistMaintenance from 'components/Schedulers/SpecialistMaintenance';
import Logs from '../Logs/Logs';
import withPageContext from '../HoC/WithPageContext/WithPageContext';
import withPortfolioPageContext from "components/PortfolioInFocus/HOC/WithPortfolioPageContext/WithPortfolioPageContext";
import { useQuery } from "store/Common/Helpers/commonHelper.js";
import InViewWrapper from 'components/Common/Components/InViewWrapper/InViewWrapper';

// Redux 
// Selectors
import * as inFocusSelector from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import * as visibilitySelector from 'components/ContractInFocus/Selectors/visibility.selectors';
import { reportReadySelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
// Actions
import { fetchSites } from 'components/AdminPanel/Sites/Actions/AdminPanelSites.actions';
import { addNotification } from '../../Notification/Actions/Notification.actions';
import { downloadStandby, downloadClear } from '../HoC/WithPageContext/Actions/Download.actions';
import { setContractDocsDialogueOpen } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import { setPortfolioDocsDialogueOpen } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { fetchContractSnapshots } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import * as contractActions from "components/ContractInFocus/Actions/contractDocuments.actions";
import * as portfolioActions from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";

// Misc
import { ContractReportAppendices } from "components/ContractInFocus/ContractReportVisibility/ContractReportAppendices";
import { WithPageContext } from 'components/ContractInFocus/HoC/WithPageContext/interfaces/withPageContext.interfaces';
import { SnapshotPublication } from 'components/ContractInFocus/Interfaces/ContractInFocus.interfaces';
import { MatFabButton } from 'components/Common/Components/Material/MatFabButton/MatFabButton';
import { MonthlyReportViewer } from 'components/ContractInFocus/Components/MonthlyReportViewer/MonthlyReportViewer';
import { fromSaveDateFormat } from 'components/Common/Utils/Dates';
import { printingElements } from 'components/ContractInFocus/HoC/WithPageContext/Constants/elementToBePrinted';
import { delayPipe } from 'helpers/Pipelines/delayCallback';
import { calculateSectionNumber, isSnapshotReportOutOfDate } from 'components/ContractInFocus/Helper/contractInFocus.helper';
import * as contractReportServices from './Services/ContractReport.service';
import * as notificationTypes from 'components/Notification/Constants/constants';
import { contractOrPortfolioFullSelectedVisibilitySelector } from "components/ContractInFocus/Selectors/visibility.selectors";
import AppendicesButton from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/appendicesButton";
import DistributionListButton from "components/Common/Components/RightVisibilityMenu/Components/RightContextButton/distributionListButton";
import { fetchClientStaticOpsDataVisibilitySettings } from "components/ContractInFocus/Actions/contractVisibilitySettings.actions";
// import { fetchBasicPortfolioVisibilitySettings } from "components/PortfolioInFocus/Actions/portfolioVisibilitySettings.actions";

import { DistributionListDialogue } from 'components/ContractInFocus/ContractReportVisibility/ReportDistributionListDialogue';
import { FieldMetaGroup, PreFlightListInfo } from 'components/Common/Interfaces/Entity.interface';
import { simpleFetchDistributionListPreFlightInfo, simpleFetchDistribution } from "components/AdminPanel/Contracts/Services/contractService";
import { simpleFetchSnapshotDocumentPreFlightInfo, simpleFetchPublishSnapshotPreFlightInfo, simpleFetchSnapshotPublications } from "components/ContractInFocus/Services/snapShot.service";
import useGenerateMonthlyMaintenanceReport from "components/ContractInFocus/ContractReport/ReportProduction";
import { PortfolioPeriod } from "components/AdminPanel/ContractPeriods/Interfaces/ContractPeriod.interface";
import { collectValuesAndRender } from "store/Common/Helpers/commonHelpers";
// Styles
import styles from 'styles/app/common/variables.scss';
import './ContractReport.scss';

export class ContractReportStateTrackerC {
  // we are putting these values in a separate state tracker because they can't be computed at once, 
  // but rely on asynch responses.  However, we don't want them each to cause a rerender when they're first loaded
  // so we track when they've all at least got a value (or it's been a while since a value was last set for one of them)
  // and trigger a component refresh at that point.
  preFlightDistributionListInfo?: PreFlightListInfo;
  preFlightSnapshotDocumentInfo?: PreFlightListInfo;
  preFlightPublishSnapshotInfo?: PreFlightListInfo;
  snapshotPublications?: SnapshotPublication[];
}

export interface ContractReportStateTracker extends ContractReportStateTrackerC { }

type ContractReportStateTrackerT = Array<keyof ContractReportStateTracker>;

export const ContractReportStateTrackerPropsArray: ContractReportStateTrackerT =
  Object.keys(new ContractReportStateTrackerC()) as ContractReportStateTrackerT;



const Report = ({
  contract,
  portfolio,
  //onGeneratPDF,
  isDownloadReady,
  isDownloadRequested,
  documentBase64,
}: WithPageContext) => {

  const selectedFocusedPeriod = useSelector(inFocusSelector.contractOrPortfolioPeriodSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  const selectedFocusedSnapshot = useSelector(inFocusSelector.contractOrPortfolioSnapshotSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  const selectedFrozenFor = useSelector(inFocusSelector.contractOrPortfolioFrozenForSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  const selectedMonthlyReports = useSelector(inFocusSelector.contractOrPortfolioMonthlyReportsSelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  const fullSelectedVisibilityData = useSelector(contractOrPortfolioFullSelectedVisibilitySelector({ portfolioId: portfolio?.id, contractId: contract?.id }));
  const selectedVisibilitySettings = useSelector(visibilitySelector.mainClientVisibilitySettingsObjectsSelector);
  const reportReady = useSelector(reportReadySelector);
  const [mustRefresh, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const dispatch = useDispatch();

  const reg = useRef<ContractReportStateTracker>({});
  const cancelUpdateRef = useRef();

  let query = useQuery();
  const isReportPDFRequest = !!query.get('report');

  //console.log('selectedMonthlyReports: ', selectedMonthlyReports);

  const generateMonthlyMaintenanceReport = useGenerateMonthlyMaintenanceReport({ contract: contract, portfolio: portfolio });

  const handleGeneratePDF = (): void => {
    const url = new URL(window.location.href);
    url.searchParams.set('report', '1');
    if (selectedFocusedSnapshot && selectedFocusedPeriod) {
      const frozenForDate = fromSaveDateFormat(selectedFocusedSnapshot?.frozen_for)
      //onGeneratPDF({ elementToBePrinted: printingElements.contractReport, month: frozenForDate.getMonth(), year: frozenForDate.getFullYear(), snapshot: selectedFocusedSnapshot });
      generateMonthlyMaintenanceReport({
        elementToBePrinted: printingElements.contractReport,
        month: frozenForDate.getMonth(),
        year: frozenForDate.getFullYear(),
        snapshot: selectedFocusedSnapshot,
        focusedPeriod: selectedFocusedPeriod,
        fullVisibilityData: fullSelectedVisibilityData,
        remoteTargetUrl: url.href
      })
    }
  };

  const updateAsynchState = useCallback(
    (thisKey: string, thisValue: any) => collectValuesAndRender(
      {
        thisKey,
        thisValue,
        collectionRef: reg,
        keysArray: ContractReportStateTrackerPropsArray,
        dispatchRefreshContext: forceUpdate,
        cancelUpdateRef: cancelUpdateRef
      }
    ), [cancelUpdateRef, reg, forceUpdate]);

  const saveDocument = (snapshotId: string, id?: string) => {
    if ((contract || portfolio) && selectedFocusedPeriod) {
      const $sendGeneratedDocSubcription = contractReportServices.sendGeneratedDoc({
        contract,
        portfolio,
        id,
        file: documentBase64,
        data: {
          snapshot: snapshotId
        },
        meta: selectedMonthlyReports.meta
      });

      delayPipe(handleCloseDialog)($sendGeneratedDocSubcription).subscribe(
        () => {
          dispatch(fetchSites());
          contract && dispatch(contractActions.getMonthlyMaintenanceReports(contract.contract_ref));
          portfolio && dispatch(portfolioActions.getPortfolioMonthlyMaintenanceReports(portfolio.id));
          dispatch(addNotification({ message: 'Document saved', type: notificationTypes.NOTIFICATION_SUCCESS }));
          contract && dispatch(setContractDocsDialogueOpen(contract.id));
          portfolio && dispatch(setPortfolioDocsDialogueOpen(portfolio.id));
          if (selectedFocusedPeriod) {
            //@ts-ignore typescript too dumb to realise selectedFocusedPeriod cannot be undefined here
            contract && dispatch(fetchContractSnapshots({ contractPeriod: selectedFocusedPeriod }));
            portfolio && dispatch(portfolioActions.fetchPortfolioSnapshots({ portfolioPeriod: selectedFocusedPeriod as PortfolioPeriod }));
          }
        },
        (err: any) => dispatch(addNotification({ message: err, type: notificationTypes.NOTIFICATION_ERROR }))
      )
    }
  }

  const handleCloseDialog = (): void => {
    dispatch(downloadStandby());
  };

  const handleOnClosedDialog = (): void => {
    dispatch(downloadClear());
  };

  const getSectionNumberMap = useCallback(() => {
    if (fullSelectedVisibilityData) {
      const thisMap = calculateSectionNumber({ visibility: fullSelectedVisibilityData });
      return thisMap;
    }
  }, [fullSelectedVisibilityData]);

  const sectionNumberMap = getSectionNumberMap();

  const [showAppendixManagement, setShowAppendixManagement] = useState(false);
  const [showDistributionList, setShowDistributionList] = useState(false);
  const setSnapshotPublications = useCallback((publications) => updateAsynchState('snapshotPublications', publications), [updateAsynchState]);

  useEffect(() => {
    if (portfolio?.id) {
      const portfolioId = portfolio.id;
      simpleFetchDistributionListPreFlightInfo({ portfolioId }).then((preFlightDistributionListInfo) => {
        //setDistributionPreFlightInfo(preFlightDistributionListInfo);
        updateAsynchState('preFlightDistributionListInfo', preFlightDistributionListInfo);
      });
    } else if (contract?.contract_ref) {
      const contract_ref = contract.contract_ref
      simpleFetchDistributionListPreFlightInfo({ contractRef: contract_ref }).then((preFlightDistributionListInfo) => {
        //setDistributionPreFlightInfo(preFlightDistributionListInfo);
        updateAsynchState('preFlightDistributionListInfo', preFlightDistributionListInfo);
      });
    }
  }, [contract?.contract_ref, portfolio?.id, updateAsynchState]);

  useEffect(() => {
    // THIS CAUSES A CONSTANT REFRESH IF THERE ISN'T A DEEP EQUALS SELECTOR ON 
    // fetchBasicPortfolioVisibilitySettings
    if (portfolio?.id) {
      dispatch(fetchClientStaticOpsDataVisibilitySettings({ portfolio: portfolio }))
    } else if (contract?.contract_ref) {
      dispatch(fetchClientStaticOpsDataVisibilitySettings({ contract: contract }));
    }
  }, [contract, portfolio, dispatch]);

  useEffect(() => {
    (contract?.contract_ref || portfolio?.id) && simpleFetchSnapshotDocumentPreFlightInfo({
      contractId: contract?.contract_ref,
      portfolioId: portfolio?.id,
      portfolioEndpoint: 'snapshot-documents' // in this context we should be talking about documents attached to the portfolio itself, not the nested contracts
    }).then((preFlightSnapshotDocumentInfo) => {
      updateAsynchState('preFlightSnapshotDocumentInfo', preFlightSnapshotDocumentInfo);
    });
  }, [contract?.contract_ref, portfolio?.id, updateAsynchState]);

  useEffect(() => {
    (contract?.contract_ref || portfolio?.id) && selectedFocusedSnapshot?.id && simpleFetchPublishSnapshotPreFlightInfo({
      contractId: contract?.contract_ref,
      portfolioId: portfolio?.id,
      snapshotId: selectedFocusedSnapshot.id
    }).then((preFlightSnapshotInfo) => {
      updateAsynchState('preFlightPublishSnapshotInfo', preFlightSnapshotInfo);
    });
  }, [contract, portfolio, selectedFocusedSnapshot?.id, updateAsynchState]);

  useEffect(() => {
    (contract?.contract_ref || portfolio?.id) && selectedFocusedSnapshot && simpleFetchSnapshotPublications({ contractId: contract?.contract_ref, portfolioId: portfolio?.id, snapshotId: selectedFocusedSnapshot.id }).then((response: { data: any, meta: any, privileges: any }) => {
      // setSnapshotPublications(response.data);
      updateAsynchState('snapshotPublications', response.data);
    })
  }, [selectedFocusedSnapshot, portfolio, contract, updateAsynchState]);

  const isReportOutOfDate = selectedFocusedSnapshot && isSnapshotReportOutOfDate(selectedFocusedSnapshot);
  const snapshotNeedsToBePublished = !!selectedFocusedSnapshot && reg.current.snapshotPublications && !reg.current.snapshotPublications.length;
  const snapshotCouldBePublished = (
    !!reg.current.snapshotPublications?.length && !!selectedFocusedSnapshot && reg.current.snapshotPublications && moment(selectedFocusedSnapshot.last_frozen_at) > moment(reg.current.snapshotPublications[reg.current.snapshotPublications.length - 1].updated_at)
  )

  const [theseReportSections, setTheseReportSection] = useState<any[]>([]);

  const inViewOptions = useRef({
    /* Optional options */
    threshold: 0,
    rootMargin: '0px 0px 200px'
  });

  const getCommonProps = useCallback((animate = false) => ({
    sectionNumberMap: sectionNumberMap,
    contract: contract,
    portfolio: portfolio,
    visibilitySettings: selectedVisibilitySettings.data,
    forceHidePersonalVisibilitySettingsMenu: true,
    animate,
    frozenFor: selectedFrozenFor
  }), [portfolio, contract, selectedFrozenFor, selectedVisibilitySettings, sectionNumberMap]);

  const [commonProps, setCommonProps] = useState(getCommonProps());

  const getReportSections = useCallback(() => {
    const theseSections = [
      <PPMsReport key="PPMSReport" {...commonProps} animate />,
      <ReactiveReport key="ReactiveReport" {...commonProps} />,
      <SpecialistMaintenance key="SpecialistMaintenance" contractReportContext={true} className="contract-report-block"  {...commonProps} />,
      <Logs key="Logs" className="contract-report-block"  {...commonProps} />,
      <Financials key="Financials" contractReportContext={true} className="contract-report-block"  {...commonProps} />,
      <Audit key="Audit" className="contract-report-block"  {...commonProps} />,
      <Spend key="Spend" className="contract-report-block"  {...commonProps} />,
    ]
    if (!portfolio) {
      theseSections.push(<MeterStreams key="MeterStreams" contractReportContext={true} className="contract-report-block" {...commonProps} />)
    }
    return theseSections;
  }, [portfolio, commonProps]);

  useEffect(() => { setTheseReportSection(getReportSections()) }, [getReportSections]);

  return <>
    {/* <MatIconButton
      hint={"getPDF"}
      onClick={createPDFDocRaptor}
    >
      <SettingsIcon className='quick-links__icon' />
    </MatIconButton> */}
    {
      showDistributionList &&
      reg.current.preFlightDistributionListInfo &&
      reg.current.preFlightPublishSnapshotInfo &&
      reg.current.snapshotPublications &&
      <DistributionListDialogue
        preFlightInfo={reg.current.preFlightDistributionListInfo}
        preFlightPublishSnapshotInfo={reg.current.preFlightPublishSnapshotInfo}
        snapshotPublications={reg.current.snapshotPublications}
        setSnapshotPublications={setSnapshotPublications}
        snapshotNeedsToBePublished={snapshotNeedsToBePublished}
        snapshotCouldBePublished={snapshotCouldBePublished}
        snapshot={selectedFocusedSnapshot}
        contract={contract}
        portfolio={portfolio}
        onModalClose={() => setShowDistributionList(false)}
      />
    }

    {
      showAppendixManagement &&
      selectedFocusedSnapshot &&
      reg.current.preFlightSnapshotDocumentInfo &&
      <ContractReportAppendices
        preFlightInfo={reg.current.preFlightSnapshotDocumentInfo}
        onModalClose={() => showAppendixManagement && setShowAppendixManagement(false)}
        contract={contract}
        portfolio={portfolio}
      />
    }

    {
      theseReportSections && fullSelectedVisibilityData && (!contract || reportReady) && mustRefresh &&
      <>
        <div id={printingElements.contractReport}>
          {theseReportSections.map((x: any, i: number) => (
            <InViewWrapper
              key={`section${i}`}
              WrappedComponent={() => x} // using "() => x" rather than defining the element in the theseReportSections array as () => <SomeSectionComponent .../> means that commonProps isn't called every time there's a rerender cycle
              inViewOptions={inViewOptions.current}
              i={i}
              override={(isReportPDFRequest || undefined)} // if false we want to pass this as undefined
              placeHolderMinHeight="100vh"
            />
          ))}
          <MonthlyReportViewer
            isOpen={isDownloadReady}
            fileBase64={documentBase64}
            onSaveReport={saveDocument}
            onCancel={handleCloseDialog}
            onClosed={handleOnClosedDialog}
            existingDocuments={selectedMonthlyReports}
          />

          {reg.current.preFlightSnapshotDocumentInfo?.canRead && selectedFocusedSnapshot && < AppendicesButton
            showSettings={true}
            onShowSettings={() => setShowAppendixManagement(true)}
          />}

          {reg.current.preFlightDistributionListInfo?.canRead && selectedFocusedSnapshot && <DistributionListButton
            snapshotNeedsToBePublished={snapshotNeedsToBePublished}
            snapshotCouldBePublished={snapshotCouldBePublished}
            showSettings={true}
            onShowSettings={() => setShowDistributionList(true)}
          />}

          {
            selectedFocusedSnapshot || isDownloadRequested ?
              <MatFabButton
                visible={selectedMonthlyReports.permissions?.POST}
                onClick={handleGeneratePDF}
                loading={!isDownloadReady && isDownloadRequested}
                styles={{ backgroundColor: isReportOutOfDate ? styles.dangerDark : styles.darkBlue }}
                className={`${isReportOutOfDate ? 'contract-report__generate-report no-print' : 'no-print'}`}
              >
                <PostAddIcon fontSize='large' />
              </MatFabButton>
              : null
          }
        </div>
      </>
    }
  </>
}
const report = memo(Report);
const reportConfig = { customVisibilityMenu: true };
const contractReportPage = withPageContext(report, reportConfig);
// contractReportPage.whyDidYouRender = true;

export const PortfolioReportPage = memo(withPortfolioPageContext(report, reportConfig));

export default memo(contractReportPage);