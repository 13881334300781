import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from "./../../../styles/app/common/variables.scss";


//own
import { authenticationService } from "services/Auth/AuthService";
import { projectConfigSelector } from 'components/ContractInFocus/HoC/WithPageContext/Selectors/Download.selectors';
import { internalMemberSelector } from 'components/Profile/Selectors/Profile.selector';
import zIndex from '@material-ui/core/styles/zIndex';


const Version = () => {

  // const getVersion = () => {
  //   return process.env.REACT_APP_RC_VER;
  // };
  const versionRef = useRef<any>(null);
  const projectConfig = useSelector(projectConfigSelector);
  const internalMember = useSelector(internalMemberSelector);

  useEffect(() => {
    const storedVersion = projectConfig ? projectConfig.version : null;
    if (!versionRef.current) {
      versionRef.current = storedVersion;
    } else if (storedVersion && versionRef.current !== storedVersion) {
      //console.log('versions do not match, logging out');
      authenticationService.logout();
    }
  }, [projectConfig?.version]);


  //return getVersion()
  return projectConfig?.version
    ? (<div id="appriseVer" style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      padding: '.25rem 1rem',
      backgroundColor: styles.blueDark,
      color: '#fff',
      borderTopLeftRadius: '.2rem',
      opacity: .75,
      zIndex: 3
    }}>
      {internalMember ? <a className="white" href="https://drive.google.com/drive/folders/1wm40NkcbVAcr0TkrfwbyzBfiI1ynpxFm?usp=sharing" target="_blank">
        Ver {projectConfig?.version ? projectConfig.version : "0.0.0.0"}
      </a> : <span className="white"> Ver {projectConfig?.version ? projectConfig.version : "0.0.0.0"} </span>}
    </div>)
    : <> </>;

}

export default Version;