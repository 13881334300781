import React, { useCallback, useEffect, useState, useRef, memo } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

// Own
import { store } from "store/store";
import { getMonthlyMaintenanceReports } from "components/ContractInFocus/Actions/contractDocuments.actions";
import { retreiveContractInFocusFromCache, setSelectedContractPeriod, setSelectedSnapshot } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import { parseContractPeriodUrl } from './helpers/contractLayout.helper';
import { findFocusedContractPeriodByDate, getCurrentOrMostRecentContractPeriod } from 'components/ContractInFocus/Helper/contractInFocus.helper';
import { historyPushPeriod } from "components/Layout/Contract/Containers/ContractSubHeading/ContractSubHeading";
import * as contractPeriodSelectors from "components/AdminPanel/ContractPeriods/Selectors/ContractPeriods.selectors";
import * as inFocusSelectors from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { switchContractInFocus, addContractInFocusToCache, addContractACL } from "components/ContractInFocus/Actions/contractInFocus.actions";
import { clearPortfolioInFocus } from 'components/PortfolioInFocus/Actions/portfolioInFocus.actions';
import { simpleFetchContractPeriods } from "components/AdminPanel/ContractPeriods/Services/contractPeriodsService";
import { getContractMeterStreams } from "components/ContractInFocus/MeterReadings/Services/MeterStreamServices";
import { fetchPersonalContractInFocusSettings } from "components/ContractInFocus/Services/personalInFocusSettings.services";
import { fetchClientStaticOpsDataVisibilitySettings } from "components/ContractInFocus/Actions/contractVisibilitySettings.actions";
import { NOTIFICATION_ERROR, NOTIFICATION_WARNING } from 'components/Notification/Constants/constants';
import { addNotification } from 'components/Notification/Actions/Notification.actions';
import { headerSearch } from 'components/Layout/actions/Layout.actions';
import { getMenuAccess } from 'components/Profile/Helpers/profileHelpers';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ContractNavigation = () => {
    const history = useHistory();
    const { contract_ref } = useParams();
    const snapshotQueryParam = useQuery().get('snapshot');
    const contractPeriodQueryParam = useQuery().get('contractPeriod');

    const selectedContractId = useSelector(inFocusSelectors.contractInFocusContractId);
    const selectedContract = useSelector(inFocusSelectors.contractInFocusContractSelector);
    const selectedContractPeriods = useSelector(contractPeriodSelectors.contractContractPeriodsSelector(selectedContractId)).data;
    const selectedFocusedContractPeriod = useSelector(inFocusSelectors.contractInFocusFocusedContractPeriodSelector);
    const selectedSnapShot = useSelector(inFocusSelectors.contractInFocusSnapshotHistorySelector);
    const targetContract = useSelector(inFocusSelectors.siteContractByContractRefSelector(contract_ref));
    const active = useSelector(inFocusSelectors.contractActiveSelector);
    const [checkIfActive, setCheckIfActive] = useState(false); // this exists to prevent false postives before it has had a chance for the value to settle on load.
    const contractInFocus = useSelector(inFocusSelectors.contractInFocusSelector);
    const contractInFocusCache = useSelector(inFocusSelectors.contractInFocusCacheSelector);
    //console.log('process env: ', process.env);

    const dispatch = useCallback(useDispatch(), []);

    // const myCurrentStoreState = store.getState();
    // const size = new TextEncoder().encode(JSON.stringify(myCurrentStoreState)).length;
    // const kiloBytes = size / 1024;
    // const megaBytes = kiloBytes / 1024;
    // console.log('MB: ', megaBytes);

    useEffect(() => {
        // this is merely to clear the search header on the index page whenever a contract is chosen.
        dispatch(headerSearch({ search: '' }));
    }, [])


    //     /* eslint-disable-next-line react-hooks/exhaustive-deps */
    // }, [contract_ref, dispatch, selectedFocusedContractPeriod, selectedContract.data?.contract_ref]);
    /* 
    * This monitors primarily the snapshot part of the URL, and when there is a mismatch between what's in the URL and what's in the state,
    * the use effect will make the necessary dispatches to fetch the snapshot and update the state and also related contract data       
    */
    useEffect(() => {
        const isCurrent = snapshotQueryParam?.indexOf('Live') !== -1;
        const contractChanged = selectedContract?.data?.contract_ref !== contract_ref;
        const snapshotChanged = selectedSnapShot.focus?.frozen_for !== (snapshotQueryParam && (snapshotQueryParam.indexOf('Live') === -1) ? snapshotQueryParam : undefined); // convert null value
        const hasContractPeriodChanged = !!contractPeriodQueryParam && `${selectedFocusedContractPeriod?.start_date}_${selectedFocusedContractPeriod?.end_date}` !== (contractPeriodQueryParam ? contractPeriodQueryParam : undefined);
        const focusedContractPeriodDatesFromUrl = parseContractPeriodUrl(contractPeriodQueryParam);
        let focusedContractPeriod = findFocusedContractPeriodByDate(selectedContractPeriods, focusedContractPeriodDatesFromUrl);
        if (snapshotChanged && selectedSnapShot.data) {
            //console.log("the snapshot has changed... so let's dispatch the relevant select..");
            // Searches through the snapshots in state to try and find the appropriate snapshot
            const foundSnapshotId = Object.keys(selectedSnapShot.data).find(key => selectedSnapShot.data[key].frozen_for === snapshotQueryParam);
            if ((foundSnapshotId || isCurrent) && selectedFocusedContractPeriod) {
                // if the snapshot is found - parse the contract period if there is one 
                const snapshot = foundSnapshotId ? selectedSnapShot.data[foundSnapshotId] : undefined;                        // send a dispatch which will make the necessary API calls to update the state
                dispatch(setSelectedSnapshot({
                    selectedSnapshot: snapshot
                }));
            }
        }
        if (targetContract) {
            if (contractChanged) {
                getMenuAccess({ contract: targetContract }).then((response) => {
                    dispatch(addContractACL(targetContract.id, response))
                })
                dispatch(clearPortfolioInFocus()); // this ensures that when loading the portfolio next time visibility bits overwritten by the contract in focus will be replaced
                //console.log('contractChanged');
                //console.log('selectedContractPeriods: ', selectedContractPeriods);
                const cachedContractInFocus = targetContract ? contractInFocusCache[targetContract.id] : null;
                if (cachedContractInFocus) {
                    dispatch(retreiveContractInFocusFromCache(cachedContractInFocus))
                } else {
                    dispatch(switchContractInFocus(targetContract.id));
                }
                fetchPersonalContractInFocusSettings(targetContract);
                dispatch(fetchClientStaticOpsDataVisibilitySettings({ contract: targetContract }));
                getContractMeterStreams(targetContract.id, targetContract.contract_ref);
                dispatch(getMonthlyMaintenanceReports(contract_ref));
                simpleFetchContractPeriods(contract_ref).then((contractPeriods) => {
                    if (!contractPeriods.data.length) {
                        store.dispatch(addNotification(
                            {
                                message: 'No contract periods found.  Please create at least one contract period for this contract',
                                type: NOTIFICATION_ERROR
                            })
                        )
                        history.push(`/sites?noRedirect=1`);
                    } else {
                        //console.log('got contract periods after changing contract');
                        if (!hasContractPeriodChanged) { //this prevents overriding the correct contract period when loading everything from scratch from the url (as with the pdf generator)
                            focusedContractPeriod = getCurrentOrMostRecentContractPeriod(contractPeriods.data);
                            //NB the next line triggers an epic that fetches the snapshot.
                            focusedContractPeriod && dispatch(setSelectedContractPeriod(focusedContractPeriod));
                        }
                    }
                })
                //store.dispatch(addContractInFocusToCache())
            } else {
                if (selectedFocusedContractPeriod && !hasContractPeriodChanged && !contractPeriodQueryParam) {
                    //console.log("now we have a selected contract period in the state and it has not just changed, but there is no param for it in the url..\
                    //let's add it to the url");
                    historyPushPeriod(history, selectedFocusedContractPeriod);
                }
                if (hasContractPeriodChanged) {
                    //console.log("the contract period has changed... so let's dispatch the relevant select..: ", focusedContractPeriod);
                    focusedContractPeriod && dispatch(setSelectedContractPeriod(focusedContractPeriod));
                }
            }
        }

    }, [dispatch, targetContract, snapshotQueryParam, selectedSnapShot, selectedContractPeriods, selectedFocusedContractPeriod, contractPeriodQueryParam, contract_ref, selectedContract?.data?.contract_ref]);

    useEffect(() => {
        dispatch(addContractInFocusToCache(contractInFocus));
    }, [contractInFocus]);

    useEffect(() => {
        const t = setTimeout(() => {
            // this exists to prevent false postives before it has had a chance for the value to settle on load.
            setCheckIfActive(true);
        }, 500);
        return () => { clearTimeout(t) }
    }, []);

    useEffect(() => {
        if (checkIfActive) {
            if (!active) {
                store.dispatch(addNotification({
                    message: "This contract has been deactivated!",
                    type: NOTIFICATION_WARNING
                }))
            }
        }
    }, [checkIfActive]);
    return (
        <></>
    )
}

//ContractNavigation.whyDidYouRender = true;

export default memo(ContractNavigation);