import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IconButton } from '@material-ui/core';

// Own
import ContractDocumentGrid2 from "components/SiteDocuments/Components/ContractDocumentsViewGrid/ContractDocumentsViewGrid2";
import PortfolioDocumentsView from "components/SiteDocuments/Components/ContractDocumentsViewGrid/PortfolioDocumentsView";
import { setContractDocsDialogueClose, toggleContractDocsDialogueOpen } from 'components/ContractInFocus/Actions/contractInFocus.actions';
import { setPortfolioDocsDialogueClose, togglePortfolioDocsDialogueOpen } from "components/PortfolioInFocus/Actions/portfolioInFocus.actions";
import { simpleFetchContractDocuments } from "components/AdminPanel/Contracts/Services/contractService";
import { contractInFocusContractDataSelector, contractInFocusSelector, contractDocumentsViewerSelector } from 'components/ContractInFocus/Selectors/contractInFocus.selectors';
import { portfolioDocumentsViewerSelector, portfolioInFocusPortfolio } from "components/PortfolioInFocus/Selectors/portfolioInFocus.selectors";
import { portfolioHydratedFactory } from "components/Portfolios/Selectors/Portfolio.selectors";
import { TabbedPanel } from "components/Common/Components/TabPanel";

import './DocumentSelectorModal.scss';

const DocumentSelectorModal = () => {

  const [search,] = useState('');

  const dispatch = useDispatch();//useCallback(useDispatch(), []);
  const contract = useSelector(contractInFocusContractDataSelector);
  const portfolioInFocusState = useSelector(portfolioInFocusPortfolio);
  const portfolio = useSelector(portfolioHydratedFactory(portfolioInFocusState.id))?.data;
  const entityId = portfolio?.id || contract?.id;
  const [docs, setDocs] = useState<any[]>([]);
  const [docTypes, setDocTypes] = useState<string[]>(['monthlymaintenancereport', 'snapshotdocument']);
  const [modalTitle, setModalTitle] = useState('');
  const [documentTabTitles, setDocumentTabTitles] = useState<string[]>();

  const contractDocumentsViewerState = useSelector(contractDocumentsViewerSelector);
  const portfolioDocumentsViewerState = useSelector(portfolioDocumentsViewerSelector);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const open = portfolio ? portfolioDocumentsViewerState[portfolio?.id]?.open : contract ? contractDocumentsViewerState[contract.id]?.open : false;

  useEffect(() => {
    if (contract && contract.id) {
      simpleFetchContractDocuments(contract.contract_ref, docTypes).then(
        (docs: any) => {
          setDocs(docs.data);
        }
      )
    }
  }, [contract, docTypes]);


  const handleClose = (): void => {
    if (contract?.id) {
      dispatch(setContractDocsDialogueClose(contract.id));
    }
    if (portfolio?.id) {
      dispatch(setPortfolioDocsDialogueClose(portfolio.id));
    }
  }

  const toggleOpen = (): void => {
    if (contract?.id) {
      dispatch(toggleContractDocsDialogueOpen(contract.id));
    }
    if (portfolio?.id) {
      dispatch(togglePortfolioDocsDialogueOpen(portfolio.id));
    }
  }

  const isModalReady = (): boolean => !!contract || !!portfolio;

  return (
    <Modal
      id="documentSelectorModal"
      centered={true}
      isOpen={isModalReady() && open}
      toggle={toggleOpen}
      size={'xl'}
      transitionDuration={{ appear: 0, enter: 1000, exit: 0 }}
    >
      <ModalHeader toggle={toggleOpen}>
        <div className="document-selector--tab-items">
          <IconButton>
            <PhotoCamera />
          </IconButton>
        </div>
        <div className="document-selector--title">{modalTitle}</div>
        <div className="document-selector--close-button">
          <IconButton onClick={handleClose} >
            <CloseIcon />
          </IconButton>
        </div>

      </ModalHeader>
      {!!documentTabTitles?.length && tabIndex !== undefined && <TabbedPanel
        pageIndex={tabIndex}
        showTabs={true}
        onChangeTab={(index: number) => { setTabIndex(index) }}
        tabs={documentTabTitles}
      >
      </TabbedPanel>}
      <ModalBody>

        {/* <ContractDocumentGrid
          documents={docs}
          meta={{}}
          search={search}
        /> */}
        {contract && <ContractDocumentGrid2
          contract={contract}
          setModalTitle={setModalTitle}
          setDocumentTabTitles={setDocumentTabTitles}
        />}
        {portfolio?.id && <PortfolioDocumentsView
          portfolio={portfolio}
          modalTitle={modalTitle}
          setModalTitle={setModalTitle}
          setDocumentTabTitles={setDocumentTabTitles}
          initialIndex={tabIndex}
        />}
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </Modal>
  )
}

export default DocumentSelectorModal;