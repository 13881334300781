/* eslint-disable */
import { useState } from "react";
import { inputType } from "../Validation/Validation.helper";
import { FieldMeta, FieldChangeType } from "../Interfaces/Entity.interface";

interface useFormElementProps {
  value?: any;
  submitted: boolean;
  meta: FieldMeta;
  onValueChanged?: (value: FieldChangeType) => void;
}

export const useMatFormElement = ({
  value,
  meta,
  onValueChanged,
  submitted
}: useFormElementProps
) => {
  const [touched, setTouched] = useState(false);

  const getValue = (event: any) => 
    meta.type === inputType.INTEGER
      ? event.value.replace(/\D/g, "")
      : event.value;

  const isValid = (): boolean => {
    return !(meta.required && (submitted || touched) && (`${value}`.length === 0))
  }

  const handledChanged = (event: any) => {
    const sanitisedValue = getValue(event);
    setTouched(true);
    if (onValueChanged) {
      onValueChanged({ fieldName: meta.field_name, value: !!sanitisedValue ? sanitisedValue : '' });
    }
  }

  return {
    isElementValid: isValid,
    setTouched: setTouched,
    bind: {
      value,
      readOnly: meta.read_only,
      key: meta.field_name,
      isValid: isValid(),
      onValueChanged: handledChanged,
    }
  };
};
