import { switchMap, map, catchError, filter, tap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import Cookies from "universal-cookie";

import { mode } from '../../Common/Consts/DataMode';
import { APIR, getMultipartConfig } from "../../../services/API/API";
import { unWrapListDataAndMeta, unWrapDataAndMeta, removeReadOnlyFields } from "../../../services/API/API.helper";
import { adminPanelType } from '../../AdminPanel/Models/AdminPanel.model';
import { NOTIFICATION_ERROR } from '../../Notification/Constants/constants';
import * as fromNotificationActions from "../../Notification/Actions/Notification.actions";
import { history } from "helpers/History/History";
import { EntityState } from 'components/Common/Interfaces/Entity.interface';
import { Site } from 'components/Sites/Interfaces/Site.inteface';

import * as fromSitesActions from 'components/AdminPanel/Sites/Actions/AdminPanelSites.actions';
import * as fromAdminPanelActions from "../../AdminPanel/Actions/adminPanel.actions";
import * as fromRootActions from "../../../store/actions/root.actions";




const cookies = new Cookies();
const BASE_ROUTE = '/sites/';

export const fetchSitesEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.FETCH_SITES.start),
  switchMap(({ payload }) =>
    APIR.get(BASE_ROUTE).pipe(
      unWrapListDataAndMeta(),
      tap((sites: EntityState<Site[]>) => {
        if (payload.redirect) { // <=== THIS SHOULD NOT BE HERE.  THIS WHOLE BLOCK SHOULD BE IN SOMETHING THAT IS INDEX PAGE LOADING RELATED, AND IT SHOULD 
          // EITHER CALL A SPECIAL ROUTE THAT JUST RETURNS THE RELEVANT COUNTS, OR JUST USE REDUX STATE.
          const savedLocation = localStorage.getItem('savedLocation');
          if (savedLocation) {
            // remove the savedLocation just in case there is an auth problem with it
            // so next time it will reboot to index
            localStorage.removeItem('savedLocation');
            console.log('navigating to savedLocation: ', savedLocation);
            history.push(savedLocation);
          } else {
            history.push(`/sites`);
          }
        }
      }),
      map(sites => fromSitesActions.fetchSitesSuccess(sites)),
      catchError(error => {
        return of(fromSitesActions.fetchSitesError(error));
      })
    )
  )
);

export const updateSiteEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.UPDATE_SITE.start),
  map((action: any) => {
    const { panelId, site_ref, meta, site_contracts, site_image, ...siteData } = action.site;
    return { panelId, site_ref, siteData, meta: action.meta };
  }),
  switchMap(({ panelId, site_ref, siteData, meta }) => {
    const payload = removeReadOnlyFields(siteData, meta);
    return APIR.patch(`${BASE_ROUTE}${site_ref}/`, payload).pipe(
      map(siteResponse => siteResponse.data),
      map(siteResponse => fromSitesActions.updateSiteSuccess({ ...siteResponse.data })),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error)))
    )
  })
);

export const updateSiteSuccessEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.UPDATE_SITE.success),
  map(() => fromSitesActions.fetchSites()),
);

// export const updateSiteImageEpic = (action$: any) => action$.pipe(
//   ofType(fromSitesActions.UPDATE_SITE_IMAGE.start),
//   map((action: any) => {
//     const fd = new FormData();
//     fd.append("site_image", action.siteImage[0]);
//     return { ...action, fd };
//   }),
//   switchMap(({ panelId, site, fd }) =>
//     APIR.patch(`${BASE_ROUTE}${site.site_ref}/`, fd, getMultipartConfig()).pipe(
//       map(({ data }: { data: any }) => fromRootActions.setBranchField(adminPanelType.sites, site.id, 'site_image', data.data.site_image)),
//       catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error)))
//     )
//   )
// );

export const deleteSiteEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.DELETE_SITE.start),
  switchMap(({ site, panelId }) =>
    APIR.delete(`${BASE_ROUTE}${site.site_ref}`).pipe(
      map(_ => fromSitesActions.deleteSiteSuccess(site.id, panelId)),
      catchError((error) => of(fromAdminPanelActions.setApiErrors(panelId, error)))
    )
  ),
);

export const deleteSiteSuccessEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.DELETE_SITE.success),
  switchMap(({ panelId }: { panelId: string }) => [
    fromAdminPanelActions.setMode(panelId, mode.list),
    fromSitesActions.fetchSites()
  ])
);

export const siteToFavouriteEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.FAVOURITE_SITE.start),
  map(({ siteId, favourited }: { siteId: number, favourited: boolean }) => {
    const key = favourited ? 'add_to_favourite_sites_list' : 'remove_from_favourite_sites_list';
    return { [key]: [siteId], siteId, favourited }
  }),
  switchMap(({ siteId, favourited, ...payload }) =>
    APIR.post(`/people/${cookies.get("userId")}/favourite-sites/`, payload).pipe(
      map(() => fromSitesActions.favouriteSiteSuccess(siteId, favourited)),
      catchError((error) => of(fromNotificationActions.addNotification({ message: error, type: NOTIFICATION_ERROR })))
    )
  )
);

export const siteToFavouriteSuccessEpic = (action$: any) => action$.pipe(
  ofType(fromSitesActions.FAVOURITE_SITE.success),
  switchMap(() => [
    fromSitesActions.fetchSites()
  ])
);

export default [
  // sitefetchAndOpenEpic,
  fetchSitesEpic,
  updateSiteEpic,
  updateSiteSuccessEpic,
  //updateSiteImageEpic,
  deleteSiteEpic,
  deleteSiteSuccessEpic,
  siteToFavouriteEpic,
  siteToFavouriteSuccessEpic
];
