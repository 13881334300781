import React, { useEffect, useCallback, useState } from "react";
import PropTypes, { string } from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Own
import { history } from "helpers/History/History";
import AdminPanels from "../AdminPanel/Containers/AdminPanels/AdminPanels";
import Version from "./Version/Version";
import HeaderHorizontalNew from "./HeaderHorizontal/HeaderHorizontal";
import DocumentSelectorModal from "components/SiteDocuments/Containers/DocumentSelectorModal/DocumentSelectorModal";
import { ReportVisibility } from "components/ContractInFocus/ContractReportVisibility/ContractReportVisibility";
import { debounce } from "../../services/CommonService";
import { Wrapper } from "./BaseHorizontalStyles";
import { fetchLists } from "../../App.actions";
import { Profile } from "../Profile/Containers/Profile";
import { newNotificationsSelector } from "../Notification/Selectors/Notification.selector";
import { withRouter } from "react-router";
import BaseHeaderHorizontal from "components/Layout/BaseHeader/BaseHeaderHorizontal";
import * as fromNotificationsActions from "../../components/Notification/Actions/Notification.actions";
import * as actions from "../../store/actions/actions";
import { allSitesSelector } from "components/AdminPanel/Sites/Selectors/Sites.selectors";

const BaseHorizontal = ({ setScreenWidth, location, children }) => {
  const dispatch = useCallback(useDispatch(), []);

  const selectNotifications = useSelector(newNotificationsSelector);

  const atIndexLocation =
    location.pathname === "/sites" ||
    location.pathname === "/portfolios" ||
    location.pathname === "/sites/" ||
    location.pathname === "/portfolios/";
  const sites = useSelector(allSitesSelector);
  const [contractDocsViewerOpen, toggleContractDocsViewerOpen] = useState(
    false
  );

  const [notification, setNotification] = React.useState({
    open: false,
    displayMessage: string,
    notification: undefined,
    notificationType: undefined
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      window && setScreenWidth(window.innerWidth);
    }, 1000);

    window && window.addEventListener("resize", debouncedHandleResize);

    return () =>
      window && window.removeEventListener("resize", debouncedHandleResize);
  });

  const notify = useCallback(notification => {
    setNotification({
      ...notification,
      displayMessage: getMessage(notification),
      open: true,
      notification: notification,
      notificationType: notification.type
    });
  }, []);

  useEffect(() => {
    dispatch(fetchLists());
  }, []);

  useEffect(() => {
    selectNotifications.forEach(notifcation => {
      notify(notifcation);
    });
  }, [selectNotifications, notify]);

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
    dispatch(fromNotificationsActions.notificationViewed("errors"));
  };

  const getMessage = notification => {
    try {
      return typeof notification.message === "string"
        ? notification.message
        : notification.message.message;
    } catch (e) {
      return `Unkown error ${e}`;
    }
  };

  return (
    <>
      <Wrapper className="app-base wrapper">
        <DocumentSelectorModal />
        <ReportVisibility />
        <AdminPanels />
        <Profile />
        {atIndexLocation ? <HeaderHorizontalNew /> : <BaseHeaderHorizontal />}
        {children}
        <Version />
      </Wrapper>
      <Snackbar
        className="app-notifications"
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        style={{ zIndex: 5500 }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => handleCloseNotification()}
          severity={notification.notificationType}
        >
          {notification.displayMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

BaseHorizontal.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  children: PropTypes.object,
  setScreenWidth: PropTypes.func
};

const mapStateToProps = state => ({
  settings: state.settings
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  setScreenWidth: payload =>
    dispatch({ type: actions.common.SET_SCREEN_WIDTH, payload })
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BaseHorizontal)
);
